<template>
  <GridChartCard
  :title="title"
  :hasData="hasData"
  :csvTooltip="`${$t('downloadCsv')} (${$t(groupByDefect ? 'groupedByDefect' : 'notGroupedByDefect')})`"
  ref="chartCard"
  @download-csv="viewingLoggers ? data.generateCSVForLoggerData(selectedDestName, groupByDefect) : data.generateCSVForAccountData(selectedDestName, groupByDefect)"
  @draw="createChart"
  >
    <template #actions>
      <Icon
      iconColor="white"
      margin="mr-3"
      :tooltipText="$t(groupByDefect ? 'disableGroupingByCategory' : 'groupByCategory', {category: $t('defect')})"
      :small="false"
      @icon-clicked="groupByDefect = !groupByDefect"
      :disabled="!hasData"
      :icon="groupByDefect ? 'mdi-alert-decagram' : 'mdi-alert-decagram-outline'"/>

      <Icon
      iconColor="white"
      margin="mr-3"
      :tooltipText="$t(mismanufactureOnly ? 'viewAllDefects' : 'viewOnlyMismanufactureDefects', {category: $t('defect')})"
      :small="false"
      @icon-clicked="mismanufactureOnly = !mismanufactureOnly"
      :disabled="!hasData || data.dataMMO[loggerIndex].length < 1"
      :icon="mismanufactureOnly ? 'mdi-pine-tree-box' : 'mdi-saw-blade'"/>

      <Icon
      iconColor="white"
      margin="mr-3"
      :tooltipText="viewingLoggers ? $t('byAccount') : $t('byLogger')"
      :small="false"
      @icon-clicked="viewingLoggers = !viewingLoggers"
      :disabled="!hasData"
      :icon="viewingLoggers ? 'mdi-account' : 'mdi-axe'"/>
    </template>
  </GridChartCard>
</template>

<script>
import { Chart } from 'chart.js'
import { mapActions, mapGetters } from 'vuex'
import { logChartColorFor, byproductChartColorFor, getLogChartColors } from './ChartColors'
import { AccountLoggerToggle } from '@/components/dashboard/WidgetMappings'

export default {
  name: 'StackedBarChartCardForDefectsByAccount',

  components: {
    GridChartCard: () => import('./GridChartCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    chart: null,
    viewingLoggers: false,
    groupByDefect: false,
    mismanufactureOnly: false,
    loggerIndex: AccountLoggerToggle.toInt('Logger'),
    accountIndex: AccountLoggerToggle.toInt('Account')
  }),

  props: {
    data: {
      type: Object,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    selectedDestName: {
      type: String,
      required: false,
      defualt: ''
    },
    isByproduct: {
      type: Boolean,
      default: false
    },
    overrideTitle: {
      type: String,
      default: ''
    },
    type: String,
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    data: {
      handler (_) {
        this.triggerDraw()
      },
      deep: true
    },

    groupByDefect () {
      this.triggerDraw()
    },

    viewingLoggers () {
      this.triggerDraw()
    },

    mismanufactureOnly () {
      this.triggerDraw()
    }
  },

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    title () {
      if (this.overrideTitle !== '') {
        return this.overrideTitle
      } else {
        const destination = this.selectedDestName !== '' ? `(${this.selectedDestName})` : ''
        const viewing = this.viewingLoggers ? this.$t('logger') : this.$t('account')
        return this.$t(this.mismanufactureOnly ? 'mismanufactureDefectsByTitle' : 'defectsByTitle', { viewing, destination })
      }
    },

    headerStyle () {
      return this.isByproduct ? 'background-color: #363636; color: white;' : 'background-color: #d15f27; color: white;'
    },

    hasData () {
      return this.data[this.dataVersion][this.loggerIndex].length > 0 || this.data[this.dataVersion][this.accountIndex].length > 0
    },

    indexForLoggerOrAccount () {
      return this.viewingLoggers ? this.loggerIndex : this.accountIndex
    },

    dataVersion () {
      return this.mismanufactureOnly ? 'dataMMO' : 'data'
    },

    dataSetsVersion () {
      return this.mismanufactureOnly ? 'dataSetsMMO' : 'dataSets'
    }
  },

  methods: {
    ...mapActions('dashboard', ['getNestedCSV', 'getWidgetInteractionStatus']),

    triggerDraw () {
      this.$nextTick(() => {
        this.$refs.chartCard.draw()
      })
    },

    createChart (canvas) {
      if (this.viewingLoggers) {
        this.createAccountChart(0, canvas)
      } else {
        this.createAccountChart(1, canvas)
      }
    },

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'point', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        const labelSet = data.datasets[element[0]?.datasetIndex]?.label?.defectLabelSet
        const yAxisValue = labelSet ? labelSet[element[0].index * this.data[this.dataSetsVersion]?.setLength] : undefined
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue, y: yAxisValue })) {
          this.$emit('data-clicked', {
            xAxisLabel: this.viewingLoggers ? 'logger' : 'account',
            yAxisLabel: 'defect',
            xAxisValue: xAxisValue,
            yAxisValue: yAxisValue,
            details: { mismanufactureOnly: this.mismanufactureOnly }
          })
        }
      }
    },

    createAccountChart (index, canvas) {
      const dataSets = this.data[this.dataSetsVersion]
      if (dataSets.loggerLabels.length === 0 && dataSets.accountLabels.length === 0) {
        return
      }

      var labelSet

      if (index === 0) {
        labelSet = dataSets.loggerLabels
      } else if (index === 1) {
        labelSet = dataSets.accountLabels
      }

      const indent = '    '
      const noWeightLabel = this.$t('noWeight')
      const noQuantityLabel = this.$t('noQuantity')
      const tonsLabel = this.$t('tons')
      const countLabel = this.$t('count')
      const mismanufacture = this.$t('mismanufacture')
      const defectLabel = this.$t('defect')
      const grossTonsLabel = this.$t('grossTons')
      const percentDefectByWeightLabel = this.$t(this.mismanufactureOnly ? 'percentOfMismanufactureDefectByCategory' : 'percentOfTotalDefectByCategory', { category: this.$t('weight') })
      const percentDefectByQuantityLabel = this.$t(this.mismanufactureOnly ? 'percentOfMismanufactureDefectByCategory' : 'percentOfTotalDefectByCategory', { category: this.$t('quantity') })
      const percentGrossTons = this.$t('percentGrossTons')

      var chart = new Chart(canvas, {
        type: 'bar',
        data: {
          labels: labelSet,
          datasets: this.groupByDefect ? dataSets.sets[index].map((set, i) => {
            return {
              stackHeight: dataSets.setLength,
              label: {
                defectLabelSet: set.label,
                grossWeightLabelSet: this.viewingLoggers ? this.data[this.dataVersion][this.loggerIndex] : this.data[this.dataVersion][this.accountIndex],
                weightTotalsSet: this.data[`weightTotalsBy${this.viewingLoggers ? 'Logger' : 'Account'}`]
              },
              data: set.defectPercentData,
              backgroundColor: this.isByproduct ? byproductChartColorFor(i, dataSets.sets[index].length) : logChartColorFor(i, dataSets.sets[index].length)
            }
          })
            : [{
              data: this.data[`weightTotalsBy${this.viewingLoggers ? 'Logger' : 'Account'}${this.mismanufactureOnly ? 'MMO' : ''}`]
                .map((i, idx) => i / this.data[this.dataVersion][this.indexForLoggerOrAccount][idx].grossWeight * 100),
              backgroundColor: getLogChartColors(this.data[this.dataVersion][this.indexForLoggerOrAccount].length),
              label: {
                data: this.data,
                xAxisIndex: this.indexForLoggerOrAccount,
                isMismanufactureOnly: this.mismanufactureOnly
              }
            }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: this.groupByDefect ? {
                label: function (labels) {
                  const defectLabelSet = labels.dataset.label.defectLabelSet
                  const startingIndex = (labels.dataIndex) * labels.dataset.stackHeight
                  const defectName = [`${defectLabelSet[startingIndex]}`]
                  const percentage = defectLabelSet[startingIndex + 4] !== 0
                    ? Math.round((defectLabelSet[startingIndex + 2] / defectLabelSet[startingIndex + 4]) * 100) + '%'
                    : noWeightLabel
                  const percentageCount = defectLabelSet[startingIndex + 3] !== 0
                    ? Math.round(defectLabelSet[startingIndex + 1] / defectLabelSet[startingIndex + 3] * 100) + '%'
                    : noQuantityLabel
                  const totalTons = defectLabelSet[startingIndex + 2] / 2000
                  const toolTipText = defectName
                  const count = defectLabelSet[startingIndex + 1]
                  toolTipText.push(`${indent}${percentGrossTons}: ${(labels.raw).toFixed(3)}%`)
                  toolTipText.push(`${indent}${percentDefectByWeightLabel}: ${percentage}`)
                  toolTipText.push(`${indent}${percentDefectByQuantityLabel}: ${percentageCount}`)
                  toolTipText.push(`${indent}${tonsLabel}: ${totalTons}`)
                  toolTipText.push(`${indent}${countLabel}: ${count}`)
                  return toolTipText
                }
              } : {
                label: function (labels) {
                  const accountName = labels.label
                  const dataIndex = labels.dataIndex
                  const data = labels.dataset.label
                  const xAxisIndex = data.xAxisIndex
                  const mismanufactureOnly = data.isMismanufactureOnly
                  const accountLevelData = data.data[`data${mismanufactureOnly ? 'MMO' : ''}`][xAxisIndex]
                  const defectTonsForAccount = data.data[`weightTotalsBy${xAxisIndex === 0 ? 'Logger' : 'Account'}${mismanufactureOnly ? 'MMO' : ''}`][dataIndex] / 2000
                  const grossTonsForAccount = accountLevelData.find(i => i.accountName === accountName).grossWeight / 2000
                  const toolTipText = [`${labels.formattedValue}${percentGrossTons}`]
                  toolTipText.push(`${indent}${grossTonsLabel}: ${grossTonsForAccount}`)
                  toolTipText.push(`${indent}${mismanufactureOnly ? mismanufacture : ''} ${defectLabel} ${tonsLabel}: ${defectTonsForAccount}`)
                  return toolTipText
                }
              },
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              ticks: {
                callback: function (val) {
                  return `${val}%`
                }
              },
              title: {
                display: true,
                text: this.$t('percentOfGrossWeightForCategory', { category: this.$t(this.viewingLoggers ? 'logger' : 'account') })
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    }
  }
}
</script>
