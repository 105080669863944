<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" :lg="isLogsContractMode ? 3 : 4" v-if="isLogsContractMode">
        <v-text-field
        ref="tractNameField"
        v-model="tractName"
        :label="$t('tract')"
        :disabled="disabled"
        dataTestId="adv-search-tract-autocomplete"
        color="primary"
        maxlength="64"
        counter
        v-show="!tractSearch.active"
        clearable>
          <template #append v-if="!tractName?.length">
            <Icon
            icon="mdi-crosshairs"
            :small="false"
            iconColor="grey"
            :tooltipText="$t('searchTracts')"
            @icon-clicked="startTractSearch"/>
          </template>
        </v-text-field>
        <v-text-field
        v-if="tractSearch.active"
        :label="$t('searchForTractName')"
        v-model="tractSearch.searchInput"
        :hint="$t('searchMustBeAtLeastThreeCharacters')"
        >
          <template #prepend>
            <v-icon color="grey">mdi-crosshairs-gps</v-icon>
          </template>
          <template #append-outer>
            <v-menu>
              <template #activator="{ on }">
                <v-icon v-on="on" @click="searchTracts" :disabled="tractSearch.searchInput.length < 3" color="primary">mdi-magnify</v-icon>
              </template>
              <v-list>
                <v-progress-circular v-if="tractSearch.fetchTractsLoader.loading" indeterminate color="primary" small class="ma-6"/>
                <v-list-item v-else-if="tractSearch.fetchTractsLoader.error">
                  <span class="error">{{$t('tractFetchError')}}</span>
                </v-list-item>
                <v-list-item v-else-if="!(tractSearch.tracts?.length > 0)" disabled>{{$t('noTractsMatchedThisSearch')}}</v-list-item>
                <v-list-item v-for="i in tractSearch.tracts" :key="`list-${i.tractId}`" @click="selectTractName(i)">{{i.name}}</v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" :lg="isLogsContractMode ? 3 : 4">
        <v-text-field
        v-model="accountName"
        :label="$t('account')"
        :disabled="disabled"
        dataTestId="adv-search-account-autocomplete"
        color="primary"
        maxlength="64"
        counter
        clearable/>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" :lg="isLogsContractMode ? 3 : 4">
        <v-text-field
        v-model="destinationAccountName"
        :label="$t('destinationAccount')"
        :disabled="disabled"
        dataTestId="adv-search-destination-autocomplete"
        color="primary"
        maxlength="64"
        counter
        clearable
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" :lg="isLogsContractMode ? 3 : 4" v-if="!isLogsContractMode">
        <v-text-field
        v-model="fromAccountName"
        :label="$t('fromAccount')"
        :disabled="disabled"
        dataTestId="adv-search-from-account-autocomplete"
        color="primary"
        maxlength="64"
        counter
        clearable
        onlyInternal
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" :lg="isLogsContractMode ? 3 : 4" v-if="isLogsContractMode">
        <v-text-field
        v-model="settingName"
        :label="$t('setting')"
        :disabled="disabled"
        dataTestId="adv-search-contract-setting-autocomplete"
        color="primary"
        maxlength="64"
        counter
        clearable
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ResourceLoader } from '../../../utils/ResourceLoader'
import { TractFilter } from '../../../model/Tract.js'

export default {
  name: 'ContractKeySearch',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    contractMode: {
      type: Object,
      default: () => ({ value: 0 })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialContractKeys: {
      type: Object,
      default: () => ({
        tractName: '',
        settingName: '',
        destinationAccountName: '',
        accountName: '',
        fromAccountName: '',
        tractId: null
      })
    }
  },

  data: () => ({
    destinationAccountName: '',
    accountName: '',
    settingName: '',
    tractName: '',
    fromAccountName: '',
    tractSearch: {
      active: false,
      searchInput: '',
      lastSearchInput: '',
      tracts: [],
      fetchTractsLoader: ResourceLoader.empty
    },
    selectedTract: null
  }),

  computed: {
    contractStrings () {
      return {
        destinationAccountName: this.destinationAccountName,
        tractName: this.tractName,
        tractId: this.selectedTract?.tractId,
        settingName: this.settingName,
        accountName: this.accountName,
        fromAccountName: this.fromAccountName
      }
    },

    isLogsContractMode () {
      return this.contractMode.value === 0
    }
  },

  watch: {
    contractStrings: {
      handler (val) {
        this.$emit('contract-strings-selected', val)
      },
      deep: true
    }
  },

  created () {
    this.destinationAccountName = this.initialContractKeys.destinationAccountName
    this.accountName = this.initialContractKeys.accountName
    this.settingName = this.initialContractKeys.settingName
    this.tractName = this.initialContractKeys.tractName
    this.fromAccountName = this.initialContractKeys.fromAccountName

    this.tractSearch.fetchTractsLoader = new ResourceLoader(async ss => {
      this.tractSearch.tracts = (await this.fetchTractsOnce(new TractFilter({ nameContains: ss })))
    })
  },

  methods: {
    ...mapActions('tract', ['fetchTractsOnce']),

    startTractSearch () {
      this.selectedTract = null
      this.tractSearch.active = true
      this.tractSearch.searchInput = ''
    },

    async searchTracts () {
      this.selectedTract = null

      if (this.tractSearch.searchInput === this.tractSearch.lastSearchInput) return
      this.tractSearch.tracts = []
      await this.tractSearch.fetchTractsLoader.load(this.tractSearch.searchInput)
      this.tractSearch.lastSearchInput = this.tractSearch.searchInput
    },

    selectTractName (tract) {
      this.tractName = tract.name
      this.selectedTract = tract
      this.tractSearch.active = false
    }
  }
}
</script>
