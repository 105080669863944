<template>
  <v-container fluid>
    <v-row v-if="loading" class="pa-12 ma-12" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
    <v-row v-if="!loading && showNoDataText" class="pa-12 ma-12" align="center" justify="center">
      <span class="headline black--text">
        {{$t('noAvailableDownloads')}}
      </span>
    </v-row>
    <v-row dense v-if="!loading && !showNoDataText">
      <v-col cols="auto">
        <Selector
          :label="$t('selectYear')"
          width="150px"
          :items.sync="availableYears"
          @item-selected="yearSelected">
        </Selector>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !showNoDataText">
      <v-col
      cols="12" lg="6" xl="4"
      v-for="(itemKey, index) in Object.keys(exportData)"
      :key="index">
        <v-card hover min-height=150>
          <v-card-title>
            <span>{{exportData[itemKey].name}}</span>
            <v-spacer/>
            <v-btn class="primary" :disabled="exportData[itemKey].resources[selectedYear] === undefined" @click="open(exportData[itemKey].resources)">
              <span class="mr-2">{{$t('download')}}</span>
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get } from 'axios'
import { saveAs } from 'file-saver'
export default {
  name: 'TicketsAndFinancialsDownloads',

  components: {
    Selector: () => import('@/components/core/Selector.vue')
  },

  data: () => ({
    selectedYear: undefined,
    selectedTicketYear: undefined,
    selectedTicketFinancialYear: undefined,
    selectedRecoveryYear: undefined,
    selectedCorrectionYear: undefined,
    selectedCorrectionFinancialYear: undefined,
    selectedCorrectionRecoveryYear: undefined
  }),

  created () {
    this.fetchExportDownloadLinks()
  },

  computed: {
    ...mapGetters('export-downloads', ['loading', 'exportData']),

    showNoDataText () {
      let showNoDataText = true

      if (this.exportData !== undefined) {
        Object.values(this.exportData).forEach(value => {
          if (Object.keys(value.resources).length > 0) { showNoDataText = false }
        })
      }

      return showNoDataText
    },

    availableYears () {
      if (this.exportData === undefined) {
        return []
      }

      const yearMap = {}
      Object.values(this.exportData).forEach(value => {
        Object.keys(value.resources).forEach(key => { yearMap[key] = key })
      })

      return Object.keys(yearMap).sort((a, b) => b - a)
    }
  },

  methods: {
    ...mapActions('export-downloads', ['fetchExportDownloadLinks']),

    yearSelected (year) {
      this.selectedYear = year
    },

    async open (resources) {
      const url = resources[this.selectedYear]

      try {
        const response = await get(url, { responseType: 'blob' })
        let fileName = url.split('/').pop().split('?')[0]

        // The server currently writes the file name as 'corections' instead of 'corrections' to blob storage.
        if (fileName.includes('corections')) {
          fileName = 'corrections.csv'
        }

        saveAs(response.data, fileName)
      } catch (error) {
        console.error('Error downloading file from blob storage: ', error)
        this.setSnackError(this.$t('errorDownloadingFinancialData'))
      }
    }
  }
}
</script>
