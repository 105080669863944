<template>
  <v-card data-testid="custom-date-range-form">
    <v-card-title class="primary white--text">
      <span class="headline">{{ $t('customDateRange') }}</span>
      <v-spacer/>
      <Icon
      dataTestId="custom-date-close-button"
      @icon-clicked="$emit('close')"
      :tooltipText="$t('close')"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        formRef="customDateRangeForm"
        testId="save-custom-date-range"
        :buttonText="$t('saveDates')"
        :disabled="isButtonDisabled"
        :lazyValidation="true"
        @submit="setDateButtonPressed"
      >
        <v-container grid-list-lg class="mt-6">
          <v-row>
            <v-col sm="12" md="12" lg="12">
              <DatePicker
              dataTestId="custom-start-date"
              :dateLabel="$t('startDate')"
              :startDate="chosenSinceTime"
              :allowFuture="false"
              @valid-date="(val) => {isValidStartDate = val}"
              @date-picked="startDateChosen"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="12">
              <DatePicker
              dataTestId="custom-end-date"
              :dateLabel="$t('endDate')"
              :startDate="chosenUntilTime"
              :allowFuture="false"
              @valid-date="(val) => {isValidEndDate = val}"
              @date-picked="endDateChosen"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import moment from 'moment'
import { localToUTC, localToCompanyOffsetEndOfDay } from '@/utils/DateFormatter.js'

export default {
  name: 'CustomDateRangeForm',

  components: {
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    sinceTime: {
      type: String,
      required: true
    },
    untilTime: {
      type: String,
      default: new Date().toISOString(),
      required: false
    },
    max: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    isValidStartDate: true,
    isValidEndDate: true,
    chosenSinceTime: '',
    chosenUntilTime: ''
  }),

  computed: {
    isButtonDisabled () {
      return !(this.isValidStartDate && this.isValidEndDate)
    },

    dateRangeObj () {
      return {
        sinceTime: this.chosenSinceTime ? moment(this.chosenSinceTime).format() : moment(this.sinceTime).format(),
        untilTime: moment(this.chosenUntilTime).format()
      }
    }
  },

  created () {
    this.chosenSinceTime = moment.utc(this.sinceTime).local().format()
    this.chosenUntilTime = moment.utc(this.untilTime).local().startOf('day').format()
  },

  methods: {
    startDateChosen (date) {
      this.chosenSinceTime = date
    },

    endDateChosen (date) {
      this.chosenUntilTime = date
    },

    setDateButtonPressed () {
      const sinceTime = moment(this.chosenSinceTime)
      const untilTime = moment(this.chosenUntilTime)

      if (untilTime.isBefore(sinceTime)) {
        this.setSnackError(this.$t('startDateBeforeEndDate'))
        return
      }

      const differenceInDays = untilTime.diff(sinceTime, 'days') + 1

      if (differenceInDays < 0) {
        this.setSnackError(this.$t('startDateBeforeEndDate'))
        return
      }

      if (differenceInDays > this.max) {
        this.setSnackError(this.$t('dateRangeExceedsMax', { differenceInDays: differenceInDays, max: this.max }))
        return
      }

      const dateRange = {
        sinceTime: localToUTC(this.chosenSinceTime),
        untilTime: localToCompanyOffsetEndOfDay(this.chosenUntilTime)
      }
      this.$emit('dates-chosen', dateRange)
    }
  }
}
</script>
