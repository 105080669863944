<template>
  <v-card max-width="400px">
    <v-card-title class="primary white--text headline">
      {{this.title}}
    </v-card-title>
    <v-card-text class="mt-6">
      <p class="subtitle-1 black--text font-weight-medium">{{this.body}}</p>
      <v-text-field
        :rules="[rules.validRange]"
        :value="count"
        v-model.number="count"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn text color="black" @click="$emit('cancel')">{{this.cancelText}}</v-btn>
      <v-spacer/>
      <v-btn color="primary" class="mr-n4" @click="onConfirmClick">{{this.selectText}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import messages from '@/lang'
import i18n from '@/i18n'
export default {
  name: 'NumberPickerDialog',

  props: {
    title: String,
    body: String,
    count: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    increment: {
      type: Number,
      default: 1
    },
    selectText: {
      type: String,
      default: 'Select'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    }
  },

  data () {
    return {
      rules: {
        validRange: (value) => {
          const min = this.min
          const max = this.max
          let out = true
          if (min !== undefined) out &&= value >= min
          if (max !== undefined) out &&= value <= max
          return (parseInt(value))
            ? out || `Number must be between ${min} and ${max}`
            : messages[i18n.locale].invalidNumber
        }
      }
    }
  },

  methods: {
    onConfirmClick () {
      return this.$emit('confirm', this.count)
    }
  }
}
</script>
