<template>
  <v-card height="350px" class="d-flex flex-column">
    <v-card-title class="primary white--text">
      <Icon
      v-if="!tractDestination.isExternal"
      icon="mdi-home"
      iconColor="tertiary"
      :small="false"
      :tooltipText="$t('internal')"
      />
      <span>
        {{tractDestination.destinationAccountName}}
      </span>
      <v-spacer></v-spacer>
      <span v-if="tractDestination.distance > 0">
        {{ $t('distanceInMiles', { distance: tractDestination.distance }) }}
      </span>
    </v-card-title>
    <v-card-text class="flex-grow-1" style="overflow: hidden !important;overflow-y: auto">
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" align="right">
            <div style="position:relative;height:0;">
              <Icon
              icon="mdi-pencil"
              iconColor="success"
              :small="false"
              dataTestId="tract-destination-edit-button"
              :tooltipText="$t('edit')"
              @icon-clicked="$emit('edit-destination')"
              :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
              <Icon
              icon="mdi-delete-forever"
              iconColor="error"
              :small="false"
              dataTestId="contract-delete-button"
              :tooltipText="$t('delete')"
              @icon-clicked="$emit('delete-destination')"
              :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
            </div>
            <v-data-table
            :items="products"
            :headers="headers"
            :hide-default-footer="products.length < 10"
            dense
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row dense class="mt-6">
          <Icon
          :small="false"
          icon="mdi-altimeter"
          :tooltipText="$t('contracts')"
          />
          <span class="font-weight-bold mt-1" style="font-size: 15px;">{{tractDestination.contractCount}}</span>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <Icon
            v-if="tractDestination.requiresExt2"
            icon="mdi-pound"
            :small="false"
            :tooltipText="$t('requiresExt2')"/>
          </v-col>
          <v-col cols="auto">
            <Icon
            :small="false"
            icon="mdi-plus"
            iconColor="black"
            dataTestId="tract-destination-contract-add"
            :tooltipText="$t('newContract')"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"
            @icon-clicked="openForm"
            />
          </v-col>
        </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { TractDestination } from '@/model/TractDestination.js'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'DestinationCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    tractDestination: {
      type: TractDestination,
      required: true
    }
  },

  data: () => ({
    UserClaims
  }),

  computed: {
    headers () {
      return [
        {
          text: this.$t('products'),
          value: 'productName'
        }
      ]
    },
    products () {
      return this.tractDestination.products
    }
  },

  created () {

  },

  methods: {
    userAssignedClaim,
    openForm () {
      this.$emit('new-contract-pressed')
    }
  }
}
</script>
