<template>
<div>
  <v-expansion-panel-header>
    <v-row dense align="center">
      <span>
        {{ propLocation ? propLocation.name : '' }}
      </span>
      <v-spacer/>
      <v-col cols="auto">
        <Icon
        iconColor="success"
        v-if="index === openPanel"
        icon="mdi-pencil"
        class="rotate"
        dataTestId="location-edit-button"
        :tooltipText="$t('edit')"
        :small="false"
        @icon-clicked="editLocation"
        :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
        />
      </v-col>
      <v-col cols="auto">
        <Icon
        iconColor="error"
        v-if="index === openPanel"
        icon="mdi-delete"
        dataTestId="locaiton-delete-button"
        :tooltipText="$t('delete')"
        :small="false"
        @icon-clicked="deleteLocationConfirm"
        :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
      />
      </v-col>
    </v-row>
    <template #actions>
      <v-icon class="ml-2">mdi-chevron-down</v-icon>
    </template>
  </v-expansion-panel-header>
  <v-expansion-panel-content>
    <v-row v-if="loading" class="mt-6" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
    <v-row v-else class="mt-3">
      <v-col cols="12" lg="4">
        <v-card outlined v-if="location.address !== null" data-testid="location-address">
          <v-card-title>{{$t('address')}}</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{location.address.addressLine1}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12" v-if="location.address.addressLine2 !== ''">
                <span>{{location.address.addressLine2}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{`${location.address.city}, ${location.address.state}`}} {{location.address.postalCode}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12" v-if="location.address.addressLine2 === ''">
                <span><br/></span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <span v-else>{{$t('noAddressDefined')}}</span>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card outlined v-if="location.mainContact !== null" data-testid="location-main-contact">
          <v-card-title>{{$t('mainContact')}}</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{`${location.mainContact.firstName} ${location.mainContact.lastName}`}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{convertToValidPhoneNumber(location.mainContact.phoneNumber)}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{location.mainContact.email}}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <span v-else>{{$t('noMainContactDefined')}}</span>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card outlined v-if="location.ticketContact !== null" data-testid="location-ticket-contact">
          <v-card-title>{{$t('ticketContact')}}</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{`${location.ticketContact.firstName} ${location.ticketContact.lastName}`}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{convertToValidPhoneNumber(location.ticketContact.phoneNumber)}}</span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" xs="12">
                <span>{{location.ticketContact.email}}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <span v-else>{{$t('noTicketContactDefined')}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12">
        <Decks
        :location="location"
        :decks="location.decks"
        :consumptionMode="location.consumptionMode"
        @deck-mutated="decksMutated"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xs="12">
        <DefectConfiguration
        v-if="location.locationId !== -1"
        :location="location"/>
      </v-col>
    </v-row>
    <!-- TODO: Remove once Receiver has been released to production. -->
    <v-row>
      <v-col cols="12" xs="12">
        <ReceiverSettings
          :locationId="propLocation.locationId"
          :defectCollectionMode="propLocation.defectCollectionMode"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12">
        <MultiDefects :locationId="location.locationId" :defects="defects" @defect-mutated="defectsMutated"/>
      </v-col>
    </v-row>
  </v-expansion-panel-content>
  <Dialog :stateId="dialogId" maxWidth="80vw" @close="resetDialogs">
    <LocationForm
      v-if="locationForm"
      :propLocation="location"
      :businessEntityId="businessEntityId"
      @location-mutated="locationMutated"
      @close="resetDialogs"
    />
    <ConfirmDialog
      v-if="deleteDialog"
      :title="`Delete ${location.name}`"
      :body="$t('deleteLocationBody')"
      @confirm="confirmDeleteLocation"
      @cancel="resetDialogs"
    />
  </Dialog>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'LocationPanel',

  props: {
    locations: {
      type: Array,
      default: () => []
    },
    propLocation: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    openPanel: {
      type: Number,
      default: undefined
    },
    businessEntityId: {
      type: Number,
      default: undefined
    }
  },

  components: {
    Decks: () => import('@/components/settings-components/decks/Decks.vue'),
    MultiDefects: () => import('@/components/defects/MultiDefects.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DefectConfiguration: () => import('@/components/settings-components/company-config/DefectConfiguration.vue'),
    LocationForm: () => import('./LocationForm.vue'),
    ReceiverSettings: () => import('./ReceiverSettings.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    dialogId: 'location-panel',
    location: {
      locationId: -1,
      name: '',
      mainContact: {},
      address: {},
      ticketContact: {},
      ticketNote: '',
      timeZoneOffset: -1,
      consumptionMode: 0,
      accountId: -1,
      segmentLength: null,
      collectLogCounts: false,
      decks: [],
      logLengths: []
    },
    locationForm: false,
    deleteDialog: false,
    defects: [],
    loading: false,
    UserClaims
  }),

  created () {
    this.dialogId = `location-panel-${Math.floor(Math.random() * 65536).toString(16)}`
  },

  watch: {
    async openPanel () {
      if (this.index === this.openPanel) {
        this.loading = true
        try {
          const response = await this.fetchLocationWithId(this.propLocation.locationId)
          this.location = JSON.parse(JSON.stringify(response))
          await this.receiverSettingsMutated()
          await this.defectsMutated()
        } finally {
          this.loading = false
        }
      }
    }
  },

  methods: {
    ...mapActions('locations', ['deleteLocation', 'fetchLocationWithId']),
    ...mapActions('defects', ['fetchDefectsForLocation']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('receiver-settings', ['fetchReceiverSettings']),
    userAssignedClaim,

    async defectsMutated () {
      if (this.location.locationId) {
        this.loading = true
        try {
          const response = await this.fetchDefectsForLocation(this.location.locationId)
          this.defects = JSON.parse(JSON.stringify(response))
        } finally {
          this.loading = false
        }
      }
    },

    async receiverSettingsMutated () {
      if (this.location.locationId) {
        this.loading = true
        try {
          await this.fetchReceiverSettings(this.location.locationId)
        } finally {
          this.loading = false
        }
      }
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.locationForm = false
      this.deleteDialog = false
    },

    deleteLocationConfirm () {
      this.deleteDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '400', allowFullscreen: false })
    },
    async confirmDeleteLocation () {
      this.loading = true
      try {
        await this.deleteLocation(this.location.locationId)
        this.deleteDialog = false
        this.$emit('entity-mutated')
      } finally {
        this.loading = false
      }
    },
    convertToValidPhoneNumber (phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ')- ' + match[2] + '-' + match[3]
      }
      return ''
    },
    editLocation () {
      this.locationForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },
    async locationMutated () {
      this.loading = true
      try {
        const response = await this.fetchLocationWithId(this.propLocation.locationId)
        this.locationForm = false
        this.closeDialogsAtOrAbove(this.dialogId)
        this.location = JSON.parse(JSON.stringify(response))
      } finally {
        this.loading = false
      }
      this.$emit('entity-mutated')
    },
    async decksMutated () {
      this.loading = true
      try {
        const response = await this.fetchLocationWithId(this.propLocation.locationId)
        this.location = JSON.parse(JSON.stringify(response))
      } finally {
        this.loading = false
      }
    }
  }

}
</script>

<style>
.v-expansion-panel-header--active .v-icon.mdi-chevron-down {
  transform: rotate(180deg);
}
</style>
