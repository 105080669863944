<template>
  <v-container fluid>
    <v-data-table
    :items="mappedPayees"
    :headers="headers"
    :expanded="expanded"
    item-key="payeeId"
    show-expand
    single-expand
    @click:row="rowClicked"
    style="cursor: pointer;"
    dense>
      <template #item.amount="{item}">
        {{formatMoney(item.amount)}}
      </template>
      <template #item.grossAmount="{item}">
        {{formatMoney(item.grossAmount)}}
      </template>
      <template #item.rate="{item}">
        <Icon
        v-if="item.inlineRates?.length > 1"
        icon="mdi-dots-horizontal"
        iconColor="black"
        :tooltipText="$t('multipleRatesForPayee')"
        @icon-clicked="rateVerificationClicked(item)"
        elementId="slrv"
        />
        <span v-else>
          {{ item.inlineRates[0] ? `${formatMoney(item.inlineRates[0])}/t` : $t('notAvailable') }}
        </span>
      </template>
      <template #item.status="{item}">
        <v-chip
        small
        @click.stop="toggleSettlementStatus(item)"
        :class="getSettlementStatus(item.payeeId).color">
          {{getSettlementStatus(item.payeeId).text}}
        </v-chip>
      </template>
      <template #item.actions="{item}">
        <Icon
        dataTestId="pdf-icon"
        icon="mdi-download"
        iconColor="black"
        :tooltipText="$t('downloadPDF')"
        @icon-clicked.stop="handleDownloadSinglePdf(item)"/>
        <Icon
        v-if="isExportBatch"
        dataTestId="email-icon"
        :icon="emailStatusStyleFor(item).icon"
        :iconColor="emailStatusStyleFor(item).color"
        :tooltipColor="emailStatusStyleFor(item).color"
        :tooltipText="emailStatusStyleFor(item).text"
        @icon-clicked.stop="emailSettlementReportDialogOpen(item)"/>
      </template>
      <template #expanded-item="{item}">
        <td :colspan="headers.length + 1">
          <SettlementsByPayeeTable :payee="item" @refresh-settlement="refreshSettlement" :isByproduct="isByproduct"/>
        </td>
      </template>
    </v-data-table>
    <Dialog
    :stateId="dialogId"
    @close="closeDialogs"
    >
      <SBPEmailDialog
      v-if="currentPayee"
      :payeeId="currentPayee.payeeId"
      @submit="handleSendEmail"/>
      <RateVerificationDialog
      v-if="rateVerification"
      :payeeId="rateVerificationPayeeId"
      @close="closeRateVerification()"
      @refresh-batch="refreshSettlement()"/>
    </Dialog>
  </v-container>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations'
import { settlementsByPayeeHeaders } from '@/headers/Cycles.js'
import { generateZippedSettlementReport } from '@/utils/pdf-formatters/ZippedSettlementsReport.js'
import { generateSinglePDFSettlementReport } from '@/utils/pdf-formatters/SinglePDFSettlementsReport.js'
import { mapActions, mapGetters } from 'vuex'
import { PayPeriodStatus, EmailRequestStatus, SettlementArchiveStatus } from '@/utils/Enumerations.js'
import { uploadSettlementReportForEmail } from '@/utils/SettlementReportEmail'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
import { singlePayeeEbSettlementTitle } from '../../../../utils/pdf-formatters/GenericSettlementsFunctions'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import saveAs from 'file-saver'
export default {
  name: 'SettlementsByPayee',

  components: {
    SettlementsByPayeeTable: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SettlementsByPayeeTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    SBPEmailDialog: () => import('@/components/settlements/accounts-payable-module/settlements-by-payee/SBPEmailDialog.vue'),
    RateVerificationDialog: () => import('@/components/settlements/rate-verification/RateVerificationDialog.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  props: {
    settlementsObject: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    expanded: [],
    rateVerification: false,
    rateVerificationPayeeId: undefined,
    currentPayee: null,
    dialogId: uniqueDialogId('settlements-by-payee'),
    payees: []
  }),

  computed: {
    ...mapGetters('settlements', ['dateConfiguration', 'settlementPayeeStatuses', 'settlementContractStatuses', 'currentSubModuleKey']),
    mappedPayees () {
      if (this.settlementsObject === undefined) { return [] }
      const payeesModified = this.settlementsObject.payees
      payeesModified.forEach(e => {
        Object.assign(e, {
          loads: this.getNumberOfLoads(e),
          netTons: this.getPayeeNetTonsTotal(e),
          grossTons: this.getPayeeGrossTonsTotal(e),
          amount: this.getPayeeTotalUnformatted(e),
          grossAmount: this.getPayeeTotalUnformatted(e, true),
          status: this.getSettlementStatus(e.payeeId).text,
          rate: e.inlineRates
        })
      })
      return payeesModified
    },

    headers () {
      return settlementsByPayeeHeaders().getColumns()
    },

    isExportBatch () {
      return this.dateConfiguration?.exportBatch.exportBatchId > 0
    },

    exportBatchId () {
      return this.dateConfiguration.exportBatch.exportBatchId
    },

    exportBatchExportedUnixMs () {
      return (this.dateConfiguration.exportBatch)
        ? Date.parse(`${this.dateConfiguration.exportBatch.exportDate}Z`)
        : undefined
    },

    payPeriodId () {
      return this.dateConfiguration.payPeriod.payPeriodId
    },

    payPeriodIsOpen () {
      return this.dateConfiguration.payPeriod.status === PayPeriodStatus.Open.value
    }
  },

  watch: {
    settlementsObject: {
      handler (val) {
        this.payees = val?.payees ?? []
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions('settlements', ['togglePayeeStatus', 'clearStaleSubModuleData', 'setCurrentModule', 'refreshModuleData', 'createSettlementEmailRequest', 'downloadSubmoduleBlob']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('export-batch', ['fetchExportBatches']),
    formatMoney,
    tonStringFromPounds,

    rowClicked (item) {
      this.expanded = []
      this.$nextTick(() => {
        this.expanded = [item]
      })
    },

    getNumberOfLoads (payee) {
      let allContractLoads = []
      payee.contracts.forEach(contract => {
        allContractLoads = [...allContractLoads, ...contract.loadSummaries]
      })
      return [...new Set(allContractLoads.map(load => load.ticketNumber))].length
    },

    getUnixTimestamp90DaysAgo () {
      return Date.now() - (1000 * 60 * 60 * 24 * 90)
    },

    getPayeeTotalUnformatted (payee, isGross) {
      const ticketTotal = payee.contracts.reduce((total, c) => total + (isGross ? c.grossAmount : c.amount), 0)
      const tractPaymentTotal = payee.tractPayables?.map(payable => payable.amount).reduce((prev, curr) => prev + curr, 0) ?? 0
      const advanceTotal = payee.advances.map(advance => advance.amount).reduce((prev, curr) => prev + curr, 0)
      const correctionTotal = payee.corrections.reduce((prev, curr) => prev + curr.amount, 0)
      const accountPaymentTotal = payee.accountPayables?.reduce((total, payable) => total + payable.amount, 0)
      return tractPaymentTotal + advanceTotal + ticketTotal + correctionTotal + accountPaymentTotal
    },

    getPayeeGrossTonsTotal ({ contracts }) {
      return (contracts.reduce((total, c) => total + c.grossTons, 0)).toFixed(3)
    },

    getPayeeNetTonsTotal ({ contracts }) {
      return (contracts.reduce((total, c) => total + c.netTons, 0)).toFixed(3)
    },

    getAverageRate ({ contracts }, isNet) {
      const amount = contracts.reduce((total, c) => total + c.grossAmount, 0)
      const weight = isNet
        ? contracts.reduce((total, c) => total + c.netTons, 0)
        : contracts.reduce((total, c) => total + c.grossTons, 0)

      const o = Number((amount / weight).toFixed(2))

      return (!Number.isNaN(o) && Number.isFinite(o))
        ? formatMoney(o)
        : this.$t('notAvailable')
    },

    toggleSettlementStatus (payee) {
      if (this.dateConfiguration?.payPeriod.status === PayPeriodStatus.Exported.value || this.isExportBatch) return
      this.togglePayeeStatus(payee)
    },

    getSettlementStatus (payeeId) {
      if (this.dateConfiguration.payPeriod.status === PayPeriodStatus.Exported.value || this.isExportBatch) {
        return {
          text: this.$t('verified'),
          color: 'success white--text'
        }
      }

      const status = this.settlementPayeeStatuses[this.dateConfiguration.payPeriod.payPeriodId][payeeId][this.currentSubModuleKey]
      const isSameAmount = status.oldAmt === status.amt

      if (status.verified === 1) {
        return {
          text: isSameAmount ? this.$t('verified') : `${this.$t('modified')} (${formatMoney(status.oldAmt)})`,
          color: isSameAmount ? 'success white--text' : 'error white--text'
        }
      } else {
        return {
          text: this.$t('pending'),
          color: ''
        }
      }
    },

    async downloadSettlementReportZip (payee) {
      const payeeSettlement = { ...this.settlementsObject }
      payeeSettlement.payees = this.settlementsObject.payees.filter(p => p.payeeId === payee.payeeId)
      return await generateZippedSettlementReport(payeeSettlement, { exportBatch: this.dateConfiguration?.exportBatch })
    },

    async downloadSettlementReportPdf (payee) {
      return await generateSinglePDFSettlementReport({
        ...this.settlementsObject,
        payees: [payee]
      }, {
        filenamePrefix: payee?.payeeName,
        exportBatch: this.dateConfiguration?.exportBatch
      })
    },

    async emailSettlementReport (payee, emailAddress, documentBlob) {
      try {
        await uploadSettlementReportForEmail(emailAddress, documentBlob)
        this.setSnack(this.$t('sentSettlementReportToEmailAddress', { emailAddress }))
      } catch (e) {
        console.error(e)
        this.setSnackError(this.$t('failedToSendEmailToEmailAddress', { emailAddress }))
      }
    },

    emailSettlementReportDialogOpen (payee) {
      if (!this.isExportBatch) {
        this.setSnackError(this.$t('cannotEmailUnexportedSettlements'))
        return
      }
      const eb = this.dateConfiguration.exportBatch
      if (eb?.settlementArchiveStatus !== SettlementArchiveStatus.Success.value) {
        this.setSnack(this.$t('documentGenerationInProgress'))
        return
      }
      this.currentPayee = payee
      this.openOrUpdateDialog({ id: this.dialogId, width: '600px' })
    },

    emailSettlementReportDialogClose () {
      this.currentPayee = null
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async handleDownloadSinglePdf (payee) {
      const eb = this.dateConfiguration.exportBatch
      if (eb?.exportBatchId) {
        if (eb.settlementArchiveStatus !== SettlementArchiveStatus.Success.value) {
          await this.fetchExportBatches(eb.payPeriodId)
        }
        const filename = singlePayeeEbSettlementTitle({ payee: payee.payeeName, exportDate: utcToLocalDate(eb.exportDate), label: eb.label })
        await this.downloadSubmoduleBlob({ accountId: payee.payeeId, filename })
      } else {
        const { blob, filename } = await this.downloadSettlementReportPdf(payee)
        saveAs(blob, filename)
      }
    },

    async handleSendEmail (request) {
      if (request.type === 'upload') {
        await this.createSettlementEmailRequest({ ...request })
        this.closeDialogsAtOrAbove(this.dialogId)

        const indexOfPayeeToUpdate = this.payees.findIndex(p => p.payeeId === request.accountId)
        if (indexOfPayeeToUpdate === -1) return
        this.payees[indexOfPayeeToUpdate].emailRequestStatus = EmailRequestStatus.EmailRequested.value
        return
      }

      const { blob, filename } = await this.downloadSettlementReportPdf(this.currentPayee)
      saveAs(blob, filename)
      window.open(`mailto:${request.emailAddress}?subject=Settlement Sheet`, '_blank')

      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async emailDownloadConfirm () {
      const pdfBlob = await this.downloadSettlementReportPdf(this.currentPayee)
      this.emailSettlementReport(this.currentPayee, pdfBlob)
      this.emailSettlementReportDialogClose()
    },

    emailStatusStyleFor (item) {
      return (item.emailRequestStatus !== null)
        ? {
          icon: 'mdi-email-check-outline',
          color: 'success',
          text: this.$t('emailSent')
        } : {
          icon: item.emailAddress !== null ? 'mdi-email-outline' : 'mdi-email-plus-outline',
          color: item.emailAddress !== null ? 'black' : 'error',
          text: item.emailAddress !== null ? this.$t('emailSettlement') : this.$t('addPayeeContact')
        }
    },

    rateVerificationClicked (payee) {
      this.rateVerification = true
      this.rateVerificationPayeeId = payee.payeeId
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },

    closeRateVerification () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.rateVerification = false
      this.rateVerificationPayeeId = undefined
    },

    closeDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.rateVerification = false
      this.rateVerificationPayeeId = undefined
      this.currentPayee = null
    },

    async refreshSettlement () {
      this.clearStaleSubModuleData({ isByproduct: this.isByproduct })
      await this.refreshModuleData(false)
    }
  }
}
</script>
