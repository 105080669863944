<template>
  <v-card>
    <v-card-title :class="colorClassForContractMode(contractMode.value)">
      <span class="headline">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-select
          v-model="correctionType"
          data-testid="correction-type"
          :items="correctionTypes"
          :label="$t('correctionType')"
          item-text="name"
          color="black"
          item-color="black"
          return-object/>
        </v-row>
        <v-row dense>
          <ContractAutocomplete
          v-if="correctionType.value !== 2"
          data-testid="correction-contract"
          :contractId="originalTicket.contractId"
          :disabled="correctionType.value === 1"
          :contractMode="contractMode"
          :contractType="originalTicket.contractType"
          :validStatuses="validContractStatuses"
          :includeActiveApprovals="false"
          :includePaused="false"
          :includeDraft="false"
          @contract-chosen="contractChosen"/>
        </v-row>
        <v-row
        v-if="contract && correctionType.value === 0"
        dense>
          <ProductAutocomplete
          data-testid="correction-product"
          :productId="originalTicket.productID"
          :contract.sync="contract"
          :includePreexistingProduct="originalTicket.contractId === contract.contractId"
          :propFetchProducts="false"
          @product-chosen="productChosen"
          />
        </v-row>
        <!-- Destination Deck -->
        <v-row dense v-if="decks.length > 0">
          <v-col cols="12">
            <v-select
            v-model="newDeck"
            data-testid="correction-deck"
            :items="decks"
            :label="$t('decks')"
            item-color="primary"
            item-text="name"
            item-value="deckId"
            return-object
            color="black"/>
          </v-col>
        </v-row>

        <!-- From Deck -->
        <v-row dense v-if="fromDecks.length > 0">
          <v-col cols="12">
            <v-select
            v-model="newFromDeck"
            data-testid="correction-from-deck"
            :items="fromDecks"
            :label="$t('decks')"
            item-color="primary"
            item-text="name"
            item-value="deckId"
            return-object
            color="black"/>
          </v-col>
        </v-row>

        <v-row dense v-if="showDriverSelection">
          <v-col cols="12">
            <AccountAutocomplete
            :title="$t('driver')"
            :accountId="ticket.driverAccountId"
            userSetting="driverAccountAutocomplete"
            @account-chosen="newDriver = $event"/>
          </v-col>
        </v-row>
        <WeightInput
        v-if="correctionType.value === 0"
        :originalTicket="originalTicket"
        :defects="defects"
        :isSimpleDefect="isSimpleDefect"
        @weights-entered="weightsInputed"/>
        <v-row dense>
          <v-textarea
          v-model="correctionNote"
          data-testid="correction-note"
          :rules="[rules.required]"
          color="black"
          :label="$t('note')"
          />
        </v-row>
        <v-row dense v-if="invalidContractReason">
          <v-col>
            <v-alert
            border="left"
            color="error"
            dense
            text>
              {{invalidContractReason}}
            </v-alert>
          </v-col>
        </v-row>
        <v-dialog v-model="consumedWarningDialog" persistent width="400">
          <ConfirmDialog
          :title="$t('consumedWarning')"
          :body="$t('consumedWarningBodyInternal')"
          :confirmText="$t('unconsume')"
          color="warning"
          @confirm="unconsumeTicket"
          @cancel="refreshForm(originalTicket.ticketId)"/>
        </v-dialog>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer/>
      <v-btn :class="colorClassForContractMode(contractMode.value)" @click="saveChanges" :disabled="invalidContractChosen">
        {{$t('saveChanges')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { rules } from '@/utils/FormRules.js'
import { CorrectionType, ContractStatus, ContractApprovalStatus, RuntimeCounterparty, ErrorSource, ContractType, ConsumptionMode, DeckContentType } from '@/utils/Enumerations.js'
import { colorClassForContractMode } from '@/utils/componentHelpers'
export default {
  name: 'CorrectionForm',

  props: {
    ticket: {
      type: Object,
      required: true
    },
    defects: {
      type: Array,
      required: true
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  components: {
    ContractAutocomplete: () => import('@/components/autocomplete/ContractAutocomplete.vue'),
    ProductAutocomplete: () => import('@/components/autocomplete/ProductAutocomplete.vue'),
    WeightInput: () => import('@/components/ticket/corrections/form/WeightInput.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue')
  },

  data: () => ({
    correctionTypes: CorrectionType.enums,
    rules,
    consumedWarningDialog: false,
    correctionType: CorrectionType.RecalculateWithNewContractValues,
    newWeights: {},
    decks: [],
    fromDecks: [],
    newDeck: undefined,
    newFromDeck: undefined,
    originalTicket: undefined,
    contract: undefined,
    product: undefined,
    correctionNote: '',
    validContractStatuses: [ContractStatus.Open.value, ContractStatus.Closed.value, ContractStatus.Expired.value],
    newDriver: undefined,
    contractActivities: []
  }),

  computed: {
    ...mapGetters('locations', ['allLocations']),
    isSimpleDefect () { return this.defects.length === 0 },
    title () { return this.$t('correctionFormTitle', { ticketNumber: this.ticket.ticketNumber }) },

    invalidContractChosen () {
      if (this.correctionType === CorrectionType.DeleteTicket || this.contract === undefined) {
        return false
      }

      return (!this.contract && (this.correctionType.value === CorrectionType.SpecifyNewTicketValues.value)) ||
        !(this.validContractStatuses.includes(this.contract.status)) ||
        (this.contract.approvalStatus !== ContractApprovalStatus.Approved.value && this.contract.approvalStatus !== null) ||
        this.contract.paused ||
        this.contract.isDraft ||
        (this.showDriverSelection && !this.newDriver)
    },

    invalidContractReason () {
      if (!this.invalidContractChosen) return null

      if (!this.validContractStatuses.includes(this.contract.status)) {
        return this.$t('contractIsOnHold')
      }

      if (this.contract.approvalStatus !== ContractApprovalStatus.Approved.value && this.contract.approvalStatus !== null) {
        return this.$t('contractHasPendingApproval')
      }

      if (this.contract.paused) {
        return this.$t('contractIsPaused')
      }

      if (this.contract.isDraft) {
        return this.$t('contractIsDraft')
      }

      return null
    },

    contractRequiresDriver () {
      return this.contractActivities.some(a => a.runtimeCounterparty === RuntimeCounterparty.Driver.value)
    },

    showDriverSelection () {
      switch (this.correctionType) {
        case CorrectionType.SpecifyNewTicketValues:
          return this.contractRequiresDriver
        case CorrectionType.RecalculateWithNewContractValues:
          return this.contractRequiresDriver && this.originalTicket.driverAccountId === null
        case CorrectionType.DeleteTicket:
        default:
          return false
      }
    }
  },

  created () {
    this.originalTicket = Object.assign({}, this.ticket)
  },

  methods: {
    colorClassForContractMode,
    ...mapActions('ticket', ['fetchTicketById', 'postTicketCorrection']),
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('consumption', ['revertTickets']),
    ...mapActions('activity', ['fetchActivities']),

    weightsInputed (newWeights) {
      this.newWeights = newWeights
    },

    productChosen (product) {
      this.product = product
    },

    async contractChosen (contract) {
      this.originalTicket.contract ?? (this.originalTicket.contract = contract)
      this.contract = contract
      if (!contract) return

      this.contractActivities = await this.fetchActivities(contract.contractId)

      const { contract: originalContract } = this.originalTicket

      if (contract.contractId === originalContract.contractId) {
        this.hideDecks()
        return
      }

      if (this.originalTicket.consumed && contract.destination !== this.originalTicket.destination) {
        this.showConsumedWarning(true)
        return
      }

      if (contract.isExternal) {
        this.hideDecks()
      }

      if (contract.isExternal && contract.type === ContractType.LogYardSale.value && contract.fromAccountId !== this.originalTicket.fromAccountId) {
        const decks = await this.fetchDecks(contract.fromAccountId)
        this.fromDecks = decks.filter(d => d.consumptionMode === ConsumptionMode.WeightBased.value && d.contentType === DeckContentType.Logs.value)
        this.newFromDeck = this.fromDecks[0]
      }

      if (!contract.isExternal && contract.destination !== this.originalTicket.destination) {
        const decks = await this.fetchDecks(contract.destinationAccountId)
        this.decks = decks
        this.newDeck = decks[0]
      }
    },

    hideDecks () {
      this.decks = []
      this.fromDecks = []
      this.newDeck = undefined
      this.newFromDeck = undefined
    },

    async fetchDecks (destinationAccountId) {
      let locations
      if (this.allLocations.length > 0) {
        locations = this.allLocations
      } else {
        locations = await this.fetchLocations()
      }

      const location = locations.find(l => l.accountId === destinationAccountId)
      return location.decks
    },

    showConsumedWarning (shouldShow) {
      this.consumedWarningDialog = shouldShow
    },

    async unconsumeTicket () {
      const request = {
        consumptionBatchId: this.originalTicket.consumptionBatchId,
        ticketIds: [this.ticket.ticketId]
      }

      await this.revertTickets(request)
      this.originalTicket.consumed = false
      this.originalTicket.consumptionBatchId = undefined
      this.ticket.consumed = false
      this.ticket.consumptionBatchId = undefined
      this.showConsumedWarning(false)
      this.fetchDecks()
    },

    validateForm () {
      if (this.correctionNote?.trim() === '') {
        this.setSnackError({
          message: this.$t('ticketCorrectionsRequireCorrectionNote'),
          code: 'FORM_ERROR',
          source: ErrorSource.WebClient
        })
        return false
      }

      if (!this.contract && this.correctionType.value === CorrectionType.SpecifyNewTicketValues.value) {
        this.setSnackError({
          message: this.$t('ticketCorrectionsRequireContractSelection'),
          code: 'FORM_ERROR',
          source: ErrorSource.WebClient
        })
        return false
      }

      if (!this.product && this.correctionType.value === CorrectionType.SpecifyNewTicketValues.value) {
        this.setSnackError({
          message: this.$t('ticketCorrectionsRequireProductSelection'),
          code: 'FORM_ERROR',
          source: ErrorSource.WebClient
        })
        return false
      }

      return true
    },

    async saveChanges () {
      if (!this.validateForm()) {
        return
      }

      let request = {
        ticketId: this.ticket.ticketId,
        contractId: this.originalTicket.contractId,
        type: this.correctionType.value,
        notes: this.correctionNote
      }

      if (this.correctionType.value === CorrectionType.SpecifyNewTicketValues.value) {
        request = {
          ...request,
          ...this.newWeights,
          defectWeight: this.isSimpleDefect ? this.newWeights.defectWeight : this.newWeights.natureDefectWeight + this.newWeights.mismanufactureDefectWeight,
          contractId: this.contract.contractId,
          productId: this.product.productId,
          deckId: this.newDeck?.deckId,
          fromDeckId: this.newFromDeck?.deckId,
          driverAccountId: this.newDriver?.accountId ?? this.originalTicket.driverAccountId
        }
      }

      const response = await this.postTicketCorrection(request)
      this.$emit('correction-created', {
        ticketId: response.ticketId,
        ticketCorrectionId: response.ticketCorrectionId
      })
    }
  }
}
</script>
