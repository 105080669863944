<template>
  <ShortcutWrapper isDialog :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="createAdvance({ actionType: 'open-form' })">
  <v-card flat>
    <v-card-text>
      <v-container fluid>
        <v-dialog
        v-model="deleting"
        width="400">
          <ConfirmDelete
          v-if="deleting"
          :title="$t('advance')"
          @delete="deleteItem"
          @cancel-delete="resetDialogs(false)"/>
        </v-dialog>
        <Dialog :stateId="dialogId" maxWidth="80vw" @close="resetDialogs(true)">
          <AdvanceForm
          v-if="editing || creating"
          :propAdvance="focusedAdvance"
          :account="account"
          :editing="editing"
          @advance-changed="resetDialogs(true)"
          @close-form="resetDialogs(true)"
          />
          <Recoveries
          :advance="focusedAdvance"
          @close="resetDialogs(true)"
          @cancel="resetDialogs(false)"
          v-if="applyingRecovery"/>
          <AdvanceModHistory
          v-if="advanceModificationsDialog.status"
          :propModifications="advanceModificationsDialog.modifications"
          @close="closeAdvanceModificationsDialog()"
          />
        </Dialog>
        <v-row dense>
          <v-spacer/>
          <Icon
          iconColor="primary"
          icon="mdi-plus"
          dataTestId="advance-add-button"
          :small="false"
          :large="true"
          :tooltipText="$t('newAdvance')"
          @icon-clicked="createAdvance()"
          :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
        </v-row>
        <AdvanceTable
        :dense="true"
        :propAdvances.sync="advances"
        @viewRecoveries="applyRecovery"
        @edit="editAdvance"
        @delete="confirmDeleteAdvance"/>
      </v-container>
    </v-card-text>
  </v-card>
</ShortcutWrapper>
</template>

<script>
import AdvanceHeaders from '@/headers/Advances.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'AccountAdvances',

  components: {
    AdvanceForm: () => import('./AdvanceForm.vue'),
    AdvanceModHistory: () => import('@/components/account/advances/AdvanceModHistory.vue'),
    AdvanceTable: () => import('@/components/accounting/advances/AdvanceTable'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    Recoveries: () => import('../../accounting/recoveries/Recoveries.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')

  },

  props: {
    account: Object,
    accountDetail: { type: Boolean, default: false },
    accountId: {
      type: Number,
      default: undefined
    }
  },

  data: () => ({
    dialogId: 'account-advances',
    creating: false,
    editing: false,
    deleting: false,
    applyingRecovery: false,
    width: '1000',
    focusedAdvance: {},
    advances: [],
    UserClaims
  }),

  computed: {
    ...mapGetters('advances', ['allAdvances', 'advancesLoading', 'advanceModificationsDialog']),
    headers () { return AdvanceHeaders.advanceHeaders(this.$i18n.locale) },
    titleText () { return this.$t('advancesTitle', { accountName: this.account.name }) }
  },

  created () {
    this.refreshAdvances()
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapMutations('advances', ['setAdvanceModificationsDialog']),
    ...mapActions('advances', ['fetchAdvancesForAccount', 'deleteAdvance']),
    utcToLocalDate,
    formatMoney,
    userAssignedClaim,

    async refreshAdvances () {
      this.advances = await this.fetchAdvancesForAccount(this.account.accountId || this.accountId)
    },

    applyRecovery (advance) {
      this.focusedAdvance = advance
      this.width = '65%'
      this.applyingRecovery = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    editAdvance (advance) {
      this.focusedAdvance = advance
      this.editing = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    createAdvance () {
      this.focusedAdvance = {}
      this.creating = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    async deleteItem () {
      await this.deleteAdvance(this.focusedAdvance)
      this.resetDialogs(true)
    },

    resetDialogs (refresh) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.creating = false
      this.deleting = false
      this.editing = false
      this.applyingRecovery = false
      this.width = '65%'
      this.focusedAdvance = {}
      if (refresh) {
        this.refreshAdvances()
      }
    },

    getRecoveryColumn (item) {
      const { recoveryRate, recoveryType } = item
      return recoveryType === 1 ? recoveryRate + '%' : this.formatMoney(recoveryRate)
    },

    confirmDeleteAdvance (advance) {
      this.focusedAdvance = advance
      this.deleting = true
    },

    getGLCodeText (advance) {
      let glString = advance.activityTemplate.glCode
      if (advance.activityTemplate.glOffset) {
        glString = `${glString} (${advance.activityTemplate.glOffset})`
      }

      return glString
    },
    viewAdvanceModifications (advance) {
      this.setAdvanceModificationsDialog({ modifications: advance.modifications, status: true })
      this.openOrUpdateDialog({ id: this.dialogId, width: '45vw' })
    },
    closeAdvanceModificationsDialog () {
      this.setAdvanceModificationsDialog({ modifications: undefined, status: false })
      this.closeDialogsAtOrAbove(this.dialogId)
    }
  }

}
</script>
