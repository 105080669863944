<template>
  <v-card max-width="500px" data-testid="add-activities-dialog" :loading="templatesLoading">
    <v-card-title :class="contractColor">
      {{$t('createActivitiesNow')}}
    </v-card-title>
    <v-card-text class="mt-6">
        <v-container>
          <v-row>
            <v-col>
              <p class="subtitle-1 black--text">{{ $t('addActivityNow') }}</p>
              <p class="subtitle-1 black--text" v-if="includeBundles">{{ $t('maySelectBundle') }}</p>
            </v-col>
          </v-row>
          <v-row v-if="includeBundles">
            <v-col>
              <v-autocomplete
                v-model="selectedBundle"
                persistent-hint
                return-object
                item-text="name"
                item-value="name"
                label="Bundles"
                clearable
                :loading="templatesLoading"
                :items="templateBundles"
                :hint="$t('selectBundleToUse')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col>
            <v-btn data-testid="activity-cancel-button" class="not-now" outlined text color="black" @click="closeBtnClicked">{{$t('notNow')}}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn data-testid="activity-add-button" :class="contractColor" @click="saveBtnClicked" :disabled="templateBundles?.length === 0">{{ selectedBundle ? $t('useBundle') : $t('addActivities')}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { colorClassForContractMode } from '@/utils/componentHelpers.js'
import { TemplateSpecialization } from '@/utils/Enumerations.js'
export default {
  name: 'AddActivitiesDialog',

  props: {
    contractMode: {
      type: Object,
      required: true
    },
    includeBundles: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    selectedBundle: undefined
  }),

  computed: {
    ...mapGetters('activity-templates', ['templatesLoading', 'allTemplateBundles']),

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    templateBundles () {
      return this.allTemplateBundles.filter(tb => !tb.activityBuilders.some(b => b.specialization === TemplateSpecialization.Depletion.value))
    }
  },

  async created () {
    await this.fetchTemplateBundles()
  },

  methods: {
    ...mapActions('activity-templates', ['fetchTemplateBundles']),
    saveBtnClicked () {
      if (this.selectedBundle?.activityBuilders.length === 0) {
        this.setSnackError(this.$t('bundleHasNoTemplates'))
        return
      }
      this.$emit('create-activity', { shouldAddActivity: true, selectedBundle: this.selectedBundle })
    },

    closeBtnClicked () {
      this.$emit('cancel-activity', { shouldAddActivity: false, selectedBundle: this.selectedBundle })
    }
  }
}
</script>
