import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { getAdditionalFinancialTables, getUnitTextForActivity, getPeriodDateTable } from '@/utils/pdf-formatters/GenericSettlementsFunctions.js'
import { padArrayTo, scatterToObject } from '../base'
import { ContractType, ActivityModifier } from '../Enumerations'
const noBorder = [false, false, false, false]
const bottomBorder = [false, false, false, true]
const topBorder = [false, true, false, false]

export function getCoverPageForPayee (dateInfo, payee, params = {}) {
  const {
    pageBreak = true,
    isByproducts = false,
    unexported = false
  } = params

  const header = getHeader(dateInfo, payee)

  const coverPage = {
    table: {
      widths: ['*'],
      body: [
        header
      ]
    },
    layout: 'noBorders',
    style: 'nestedTable',
    pageBreak: pageBreak ? 'after' : 'none'
  }

  const contractsByType = scatterToObject(payee.contracts, {
    transfers: c => c.type === ContractType.Transfer.value,
    byproducts: c => c.type === ContractType.ByproductPurchase.value || c.type === ContractType.ByproductSale.value,
    logs: c => c.type === ContractType.WoodsSale.value || c.type === ContractType.Production.value,
    logYardSales: c => c.type === ContractType.LogYardSale.value
  })

  if (contractsByType.logs.length > 0) {
    coverPage.table.body.push([{ text: 'Contract Payments', border: noBorder, fontSize: 15, bold: true }])
    coverPage.table.body.push(getPaymentDetailSection(contractsByType.logs))
  }

  if (contractsByType.byproducts.length > 0) {
    coverPage.table.body.push([{ text: 'Byproduct Contract Payments', border: noBorder, fontSize: 15, bold: true }])
    coverPage.table.body.push(getPaymentDetailSection(contractsByType.byproducts))
  }

  if (contractsByType.transfers.length > 0) {
    coverPage.table.body.push([{ text: 'Transfer Payments', border: noBorder, fontSize: 15, bold: true }])
    coverPage.table.body.push(getPaymentDetailSection(contractsByType.transfers))
  }

  if (contractsByType.logYardSales.length > 0) {
    coverPage.table.body.push([{ text: 'Log Yard Sales', border: noBorder, fontSize: 15, bold: true }])
    coverPage.table.body.push(getPaymentDetailSection(contractsByType.logYardSales))
  }

  const { tractPayables, advances, corrections, recoveryInfo, accountPayables } = payee

  const additionalTables = getAdditionalFinancialTables(tractPayables, advances, corrections, recoveryInfo, accountPayables, { isByproducts, unexported })
  additionalTables.forEach(table => {
    coverPage.table.body.push(...table.table)
  })

  coverPage.table.body.push(getTotalTable(payee))

  return coverPage
}

const getHeader = (dateInfo, payee) => {
  const { payeeName, address, contracts } = payee
  const payeeInfoTable = {
    table: {
      body: [
        [{ text: 'Payee: ' }, { text: `${payeeName}` }]
      ]
    },
    layout: 'noBorders'
  }

  if (address !== null && address !== undefined) {
    const { addressLine1, addressLine2, city, state, postalCode } = address

    const addressLine = (addressLine2 && addressLine2 !== '')
      ? [[{ text: 'Address: ' }, { text: addressLine1 }], [{ text: '' }, { text: addressLine2 }]]
      : [[{ text: 'Address: ' }, { text: addressLine1 }]]

    payeeInfoTable.table.body.push(
      ...addressLine,
      [{ text: '' }, { text: `${city}, ${state} ${postalCode}` }]
    )
  } else {
    payeeInfoTable.table.body.push(
      [{ text: 'Address: ' }, { text: 'N/A' }]
    )
  }

  const totals = contracts.reduce((total, contract) => {
    total.loads += contract.loadSummaries.length
    total.totalGrossWeight += contract.loadSummaries.reduce((acc, ls) => acc + (ls.inWeight - ls.outWeight), 0)
    total.totalNetWeight += contract.loadSummaries.reduce((acc, ls) => acc + (ls.inWeight - ls.outWeight - ls.defectWeight), 0)
    return total
  }, { loads: 0, totalGrossWeight: 0, totalNetWeight: 0 })

  const totalsTable = {
    table: {
      body: [
        [{ text: 'Total Loads: ' }, { text: totals.loads, alignment: 'right' }],
        [{ text: 'Total Gross Tons: ' }, { text: tonStringFromPounds(totals.totalGrossWeight), alignment: 'right' }],
        [{ text: 'Total Net Tons: ' }, { text: tonStringFromPounds(totals.totalNetWeight), alignment: 'right' }]
      ]
    },
    layout: 'noBorders'
  }

  const dateInfoTable = getPeriodDateTable(dateInfo)

  return [{
    table: {
      widths: ['*', '*', '*'],
      body: [
        [
          payeeInfoTable,
          totalsTable,
          dateInfoTable
        ]
      ]
    },
    layout: {
      vLineColor: function () {
        return 'white'
      },
      hLineColor: function () {
        return 'white'
      }
    }
  }]
}

const getPaymentDetailSection = (contracts) => {
  const includeRecoveries = (contracts?.[0].type === 0 || contracts?.[0].type === 1) ?? false

  const rowPaddedWith = padArrayTo.bind(this, 8)
  const emptyRow = padArrayTo(8, { text: '', border: noBorder })
  const noUndefined = d => d !== undefined

  const keys = rowPaddedWith('', {
    start: [
      { text: 'Contract' },
      { text: 'Activity' },
      { text: 'Loads' },
      { text: 'Unit', alignment: 'right' }
    ],
    end: [
      { text: 'Weighted Avg. Rate', alignment: 'right' },
      { text: 'Gross Payout', alignment: 'right' },
      (includeRecoveries) ? { text: 'Recovered', alignment: 'right' } : undefined,
      { text: 'Payout', alignment: 'right' }
    ].filter(noUndefined)
  }).map(text => ({ text, bold: true, border: bottomBorder }))

  const values = []

  contracts.forEach(contract => {
    contract.paymentRollup.forEach((pr, i) => {
      const rateText = pr.modifier === ActivityModifier.None.value
        ? `${formatMoney(pr.averageRate)}`
        : `${formatMoney(pr.averageRate)} (${contract.distance}mi.)`

      const grossPayout = pr.productSummaries.reduce((a, b) => a + b.grossPayout, 0)
      const unitText = getUnitTextForActivity(pr, contract.distance)

      values.push(rowPaddedWith({ text: '' }, {
        start: [
          getContractCellForIndex(contract, i),
          { text: pr.activityName },
          { text: pr.loadCount, alignment: 'right' },
          { text: unitText, alignment: 'right' }
        ],
        end: [
          { text: rateText, alignment: 'right' },
          { text: formatMoney(grossPayout), alignment: 'right' },
          (includeRecoveries) ? { text: formatMoney(Math.abs(grossPayout - pr.payout)), alignment: 'right' } : undefined,
          { text: formatMoney(pr.payout), alignment: 'right' }
        ].filter(noUndefined)
      }).map(r => ({ border: noBorder, ...r })))
    })

    if (contract.paymentRollup.length <= 1) {
      values.push(rowPaddedWith({ text: '', border: noBorder }, {
        start: [getContractCellForIndex(contract, 1)]
      }))
    }

    values.push(rowPaddedWith({ text: '', bold: true }, {
      start: (contract.paymentRollup.length <= 2) ? [getContractCellForIndex(contract, 2, true)] : [],
      end: [
        { text: `${formatMoney(contract.grossAmount)}`, alignment: 'right', bold: true },
        (includeRecoveries) ? { text: `${formatMoney(Math.abs(contract.grossAmount - contract.amount))}`, alignment: 'right', bold: true } : undefined,
        { text: `${formatMoney(contract.amount)}`, alignment: 'right', bold: true }
      ].filter(noUndefined)
    }).map(r => ({ border: bottomBorder, ...r })))

    values.push(emptyRow)
    values.push(emptyRow)
    values.push(emptyRow)
  })

  return [
    {
      table: {
        widths: [150, '*', 'auto', '*', 'auto', 'auto', 'auto', 'auto'],
        body: [
          keys,
          ...values
        ]
      },
      style: 'nestedTable'
    }
  ]
}

const getContractCellForIndex = (contract, i, bold) => {
  const isLogs = contract.type === 0 || contract.type === 1

  const textLines = [
    (isLogs) ? `Tract: ${contract.tractName}` : `From Account: ${contract.fromAccountName}`,
    `Destination: ${contract.destinationName}`,
    (isLogs) ? `Setting: ${contract.settingName}` : ''
  ]

  return {
    text: textLines?.[i] ?? '',
    border: bold ? bottomBorder : noBorder,
    bold: i >= textLines.length
  }
}

const getTotalTable = ({ contracts, advances, tractPayables, recoveryInfo, corrections, accountPayables }) => {
  contracts ??= []
  advances ??= []
  tractPayables ??= []
  recoveryInfo ??= []
  corrections ??= []
  accountPayables ??= []

  const contractTotals = contracts.reduce((totals, contract) => {
    switch (contract.type) {
      case ContractType.Transfer.value:
        totals.grossTransfer += contract.grossAmount
        break
      case ContractType.LogYardSale.value:
        totals.grossLogYardSale += contract.grossAmount
        break
      default:
        totals.grossLogs += contract.grossAmount
    }

    totals.net += contract.amount
    return totals
  }, { grossLogs: 0, grossTransfer: 0, grossLogYardSale: 0, net: 0 })

  const advanceTotal = advances.reduce((total, advance) => total + advance.amount, 0)
  const tractPaymentTotal = tractPayables.reduce((total, tractPayment) => total + tractPayment.amount, 0)
  const accountPaymentTotal = accountPayables.reduce((total, ap) => total + ap.amount, 0)
  const recoveredTotals = recoveryInfo.reduce((total, ri) => {
    total.contract += ri.contractRecovered
    total.manual += ri.manualRecovered
    return total
  }, { contract: 0, manual: 0 })
  const correctionTotal = corrections.reduce((total, correction) => total + correction.amount, 0)

  const totalNetPayments = contractTotals.net + tractPaymentTotal + advanceTotal + correctionTotal + accountPaymentTotal

  const totalsLineItems = [
    {
      name: 'Gross Contract Payments',
      amount: contractTotals.grossLogs,
      alwaysShow: false
    },
    {
      name: 'Gross Transfer Payments',
      amount: contractTotals.grossTransfer,
      alwaysShow: false
    },
    {
      name: 'Gross Log Yard Sale Payments',
      amount: contractTotals.grossLogYardSale,
      alwaysShow: false
    },
    {
      name: 'Contract Recoveries',
      amount: recoveredTotals.contract,
      alwaysShow: true
    },
    {
      name: 'Net Contract Payments',
      amount: contractTotals.net,
      alwaysShow: false
    },
    {
      name: 'Corrected',
      amount: correctionTotal,
      alwaysShow: false
    },
    {
      name: 'Tract Payments',
      amount: tractPaymentTotal,
      alwaysShow: tractPaymentTotal !== 0
    },
    {
      name: 'Account Payments',
      amount: accountPaymentTotal,
      alwaysShow: accountPaymentTotal !== 0
    },
    {
      name: 'New Advances',
      amount: advanceTotal,
      alwaysShow: false
    },
    {
      name: 'Total Net Payments',
      amount: totalNetPayments,
      alwaysShow: true,
      border: topBorder
    },
    {
      name: '',
      alwaysShow: true
    },
    {
      name: 'Manual Recoveries',
      amount: recoveredTotals.manual,
      alwaysShow: false
    }
  ].filter(li => li.alwaysShow || Math.abs(li.amount) > 0)

  const emptyRow = new Array(3).fill({ text: '', border: noBorder })

  const body = totalsLineItems.map(item => item.name !== '' ? [
    { text: '', border: noBorder },
    { text: `${item.name}:`, bold: true, fontSize: 10, border: item.border ?? noBorder },
    { text: formatMoney(item.amount), bold: true, fontSize: 10, alignment: 'right', border: item.border ?? noBorder }
  ] : emptyRow)

  const table = {
    table: {
      widths: ['*', 'auto', 'auto'],
      body
    }
  }

  return [table]
}
