<template>
  <v-container fluid class="pb-6 pt-4">
    <DestinationDateSelector :contractMode="ContractMode.Logs" :timeLabel="getTimeLabel"
      @item-selected="destinationDateSelected">
      <template #prepend>
        <div class="ma-0 pa-0 mt-n1">
          <Icon v-if="allowDenseCharts" :icon="denseCharts ? 'mdi-grid-large' : 'mdi-grid'" iconColor="primary"
            :tooltipText="denseCharts ? $t('displayExpanded') : $t('displayDense')" :small="false" margin="mt-4 mr-3"
            @icon-clicked="toggleDenseCharts" />
          <TicketsWidgetPicker :propChartSet="chartSet" :openMenuKey="chartPickerMenuKey"
            @new-chart-layout="newChartLayout" />
        </div>
      </template>
    </DestinationDateSelector>
    <v-row justify="end" dense no-gutters class="mt-n8">
      <v-col cols="auto">
        <v-chip><span>{{dateString}}</span></v-chip>
      </v-col>
    </v-row>
    <v-row v-if="widgetSlotSet.widgetSlots.length === 0" style="opacity: 0.2;">
      <div style="text-align: center; width: 100%; padding-top: min(48px, 10vh);">
        <v-icon size="40vmin" @click="addNewChart">mdi-chart-box-plus-outline</v-icon>
        <br>
        <span class="title" @click="addNewChart" style="user-select: none;">{{$t('clickToAddAWidget')}}</span>
      </div>
    </v-row>
    <GridContainer v-if="widgetSlotSet.widgetSlots.length" :rows="containerRows" :columns="containerColumns" flow="row"
      gap="20px" class="py-4">
      <GridArea v-for="(widget, idx) in widgetSlotSet.widgetSlots" :key="`${idx}`" :area="widget.dimensions" :id="widget.id">
        <LoadableComponent :loading="showChartLoader(widget)" type="dashboard-widget">
          <StatsCard
            v-if="widget.details?.widgetType === 'card'"
            :loading="getWidgetDataByEnum(widget.details.widget).value === undefined"
            :title="getTitleForTotalCard($t(widget.details.title))"
            :interactive="widget.details?.isInteractive && getWidgetDataByEnum(widget.details.widget).value !== $t('notAvailable')"
            :icon="widget.details?.icon"
            :color="widget.details?.color"
            :content="getWidgetDataByEnum(widget.details.widget).value"
            @click="onDataClicked(widget.details?.widget)"
          />

          <StackedBarChartCard
            v-else-if="getWidgetDataByEnum(widget.details?.widget)?.data && widget.details?.widgetType === 'chart' && !widget.details?.chartType && widgetData.length > 0"
            :data="getWidgetDataByEnum(widget.details?.widget).data"
            :selectedDestName="selectedDestName"
            :type="widget.details?.type === 'ItemizedDefectSummaryChart' ? widget.details?.type : $t(widget.details?.type)"
            :tooltip="widget.details?.loadStatus ? $t(`${widget.details?.loadStatus}LoadsThisPeriodTooltip`) : undefined"
            :loadStatus="$t(widget.details?.loadStatus)"
            :elementId="widget.details?.elementId ?? ''"
            :allowToggle="widget.details?.loadStatus === 'completed' || widget.id === 'itemizedDefectSummary'"
            :overrideTitle="widget.details?.overrideTitle ?? ''"
            :loadsOrTonsInTitle="widget.details?.loadsOrTonsInTitle"
            :allowSecondaryGrouping="widget.details?.allowSecondaryGrouping"
            :isInteractive="widget.details?.isInteractive"
            @data-clicked="onDataClicked(widget.details?.widget, widget.details?.type, getWidgetDataByEnum(widget.details?.widget).data.miniBarType, getWidgetDataByEnum(widget.details?.widget).data, $event)" />

          <BarChartCardLoadsByProduct
            v-else-if="widget.details?.chartType === 'barChartCardLoadsByProduct' && getWidgetDataByEnum(widget.details?.widget).data && widgetData.length > 0"
            :data="getWidgetDataByEnum(widget.details?.widget).data"
            :tooltip="$t(`${widget.details?.loadStatus}LoadsThisPeriodTooltip`)"
            :loadStatus="$t(widget.details?.loadStatus)"
            :allowToggle="widget.details?.loadStatus === 'completed'"
            :destinationName="selectedDestName"
            :isInteractive="widget.details?.isInteractive"
            :contractMode="ContractMode.Logs"
            @data-clicked="onDataClicked(widget.details?.widget, 'product', undefined, getWidgetDataByEnum(widget.details?.widget).data, $event)" />

          <LogAnalysisChart
            v-else-if="widget.details?.chartType === 'logAnalysisChart' && summaryRequestObj && logAnalysisData"
            :elementId="widget.details.elementId"
            :logAnalysis="logAnalysisData ?? []"
            :selectedLocation="summaryRequestObj?.location"
            :isInteractive="widget.details.isInteractive"
            @data-clicked="onDataClicked(widget.details?.widget, 'date', undefined, logAnalysisData, $event)" />

          <StackedBarChartCardForDefectsByAccount
            v-else-if="widget.details?.chartType === 'stackedBarChartCardForDefectsByAccount' && getWidgetDataByEnum(widget.details?.widget).data && widgetData.length > 0"
            :selectedDestName="selectedDestName"
            :data="getWidgetDataByEnum(widget.details?.widget).data"
            :type="widget.details.type"
            :elementId="widget.details.elementId"
            :isInteractive="widget.details.isInteractive"
            @data-clicked="onDataClicked(widget.details?.widget, $event.xAxisLabel, $event.yAxisLabel, getWidgetDataByEnum(widget.details?.widget).data, $event)" />

          <LogQualityAnalysisChart
            v-else-if="widget.details?.chartType === 'logQualityAnalysisChart' && summaryRequestObj && logQualityAnalysisData"
            :elementId="widget.details.elementId"
            :title="$t(widget.details?.title)"
            :logQualityAnalysis="logQualityAnalysisData ?? []"
            :selectedLocation="summaryRequestObj?.location"
            :isInteractive="widget.details?.isInteractive"
            @data-clicked="onDataClicked(widget.details?.widget, 'date', undefined, logQualityAnalysisData, $event)" />

          <BarChartCardTonsByCertification
            v-else-if="widget.details?.chartType === 'barChartCardTonsByCertification' && tonsByCertification && widgetData.length > 0"
            :selectedDestName="selectedDestName"
            :data="orUndefined(tonsByCertification, tbc => new BarChartTonsByCertification(tbc, 'certificationName', 'Certifications'))"
            :allowToggle="false"
            :isInteractive="widget.details?.isInteractive"
            @data-clicked="onDataClicked(widget.details?.widget, 'certification', undefined, orUndefined(tonsByCertification, tbc => new BarChartTonsByCertification(tbc, 'certificationName', 'Certifications')), $event)" />
        </LoadableComponent>
      </GridArea>
    </GridContainer>
    <v-dialog v-model="customDateRangeDialog" width="400px">
      <CustomDateRangeForm :sinceTime="sinceTime" :untilTime="untilTime" :max="98"
        @dates-chosen="customTimePeriodChosen" @close="customDateRangeDialog = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { utcToLocalDate, localToUTC, timeBetween } from '@/utils/DateFormatter.js'
import { numberWithCommas } from '@/utils/NumericMutations'
import { LogsDashboardWidgetSet } from '@/model/DashboardWidget.js'
import { BarChartLoadsByProduct, BarChartTonsByCertification } from '@/model/BarChart.js'
import {
  StackedBarChart,
  StackedBarChartForDefectsByAccount,
  StackedBarChartForItemizedDefectSummary
} from '@/model/StackedBarChart.js'
import { WidgetSlot, WidgetSlotSet } from '@/model/WidgetSlot.js'
import { indexArrayBy } from '@/utils/base'
import { ContractMode } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/constants.js'
import { logsWidgetMappings, DashboardLogsWidgets } from '@/components/dashboard/WidgetMappings'
import moment from 'moment'
import { MAX_DAYS_FOR_WIDGET_INTERACTION } from './DashboardTimeframe'
import { getNumEmptySlots } from '@/utils/DashboardHelpers'

export default {
  name: 'TicketSummaries',

  components: {
    GridArea: () => import('@/components/helper/GridArea.vue'),
    GridContainer: () => import('@/components/helper/GridContainer.vue'),
    CustomDateRangeForm: () => import('@/components/dashboard/CustomDateRangeForm.vue'),
    BarChartCardLoadsByProduct: () => import('@/components/dashboard/BarChartCardLoadsByProduct.vue'),
    BarChartCardTonsByCertification: () => import('@/components/dashboard/BarChartCardTonsByCertification.vue'),
    StackedBarChartCard: () => import('@/components/dashboard/StackedBarChartCard.vue'),
    StackedBarChartCardForDefectsByAccount: () => import('@/components/dashboard/StackedBarChartCardForDefectsByAccount.vue'),
    LogAnalysisChart: () => import('@/components/dashboard/LogAnalysisChart.vue'),
    LogQualityAnalysisChart: () => import('@/components/dashboard/LogQualityAnalysisChart.vue'),
    DestinationDateSelector: () => import('@/components/dashboard/DestinationDateSelector.vue'),
    TicketsWidgetPicker: () => import('@/components/dashboard/TicketsWidgetPicker.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    LoadableComponent: () => import('../helper/LoadableComponent.vue'),
    StatsCard: () => import('./StatsCard.vue')
  },

  props: {
    allowDenseCharts: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    ContractMode,
    DashboardLogsWidgets,
    BarChartTonsByCertification,
    logsWidgetMappings,
    customDateRangeDialog: false,
    sinceTime: '',
    untilTime: '',
    timeLabel: undefined,
    logAnalysisData: [],
    logQualityAnalysisData: [],
    selectedLocation: {},
    summaryRequestObj: undefined,
    chartSet: undefined,
    containerRows: { sm: '60px', md: 'calc(45px + 0.5vw)' },
    containerColumns: { sm: 'repeat(12, 1fr)', md: 'repeat(24, 1fr)' },
    denseCharts: false,
    createdTicketSummaries: undefined,
    completedTicketSummaries: undefined,
    landownerTicketSummaries: undefined,
    defectsByAccount: undefined,
    tonsByCertification: undefined,
    itemizedDefectSummaryData: undefined,
    loadsInTransitByDestination: undefined,
    trucksInYard: undefined,
    truckCount: undefined,
    chartPickerMenuKey: 0,
    dialogId: 'dashboard-widget-tickets',
    widgetData: []
  }),

  computed: {
    getTimeLabel () {
      return localStorage.getItem(LocalStorageKeys.DASHBOARD_TICKETS_TIME_LABEL)
    },

    selectedDestName () {
      return (this.summaryRequestObj?.locationId !== -1 || this.summaryRequestObj?.destinationAccountId !== -1)
        ? this.summaryRequestObj?.location?.name
        : ''
    },

    productChartTitle () {
      if (this.loadsByProduct === undefined) {
        return ''
      }

      const destinationString = this.selectedDestName?.length > 0 ? `(${this.selectedDestName})` : ''
      const unitType = this.loadsByProduct.isTons ? 'Tons' : 'Loads'

      return `${unitType} by Product ${destinationString}`
    },

    dateString () {
      if (this.summaryRequestObj === undefined) return ''

      return `${utcToLocalDate(this.summaryRequestObj.sinceTime)} \u2014 ${utcToLocalDate(this.summaryRequestObj.untilTime)}`
    },

    chartArea () {
      return (this.denseCharts)
        ? [7, 8]
        : [9, 12]
    },

    cardArea () {
      return [2, {
        lg: 12, xl: 8
      }]
    },

    widgetSlotSet () {
      const widgets = this.chartSet.widgets
        .filter(ch => ch.visible)
        .map(ch => ({ ...ch, widgetId: this.idToWidgetId(ch.id) }))
        .filter(ch => ch.widgetId !== '')
        .map(ch => new WidgetSlot(ch.widgetId,
          this.widgetTypeDimensions(this.widgetMappingByWidgetId(ch.widgetId).widgetType),
          {
            index: ch.id,
            title: ch.text,
            widget: this.widgetMappingByWidgetId(ch.widgetId).widget, // Enum
            widgetType: this.widgetMappingByWidgetId(ch.widgetId).widgetType,
            chartType: this.widgetMappingByWidgetId(ch.widgetId).chartType,
            loadStatus: this.widgetMappingByWidgetId(ch.widgetId).loadStatus,
            icon: this.widgetMappingByWidgetId(ch.widgetId).icon,
            color: this.widgetMappingByWidgetId(ch.widgetId).color,
            type: this.widgetMappingByWidgetId(ch.widgetId).type,
            elementId: this.widgetMappingByWidgetId(ch.widgetId).elementId,
            overrideTitle: this.widgetMappingByWidgetId(ch.widgetId).overrideTitle,
            loadsOrTonsInTitle: this.widgetMappingByWidgetId(ch.widgetId).loadsOrTonsInTitle,
            allowSecondaryGrouping: this.widgetMappingByWidgetId(ch.widgetId).allowSecondaryGrouping,
            isInteractive: this.widgetMappingByWidgetId(ch.widgetId).isInteractive
          }
        ))

      const isCardWidget = w => w.dimensions === this.cardArea
      const numCards = widgets.filter(isCardWidget).length
      if (getNumEmptySlots(numCards) > 0) {
        const li = widgets.findLastIndex(isCardWidget)
        return new WidgetSlotSet([
          ...widgets.slice(0, li + 1),
          ...new Array(getNumEmptySlots(numCards)).fill(new WidgetSlot('empty', this.cardArea)),
          ...widgets.slice(li + 1)
        ])
      } else {
        return new WidgetSlotSet(widgets)
      }
    },

    locationId () {
      return this.summaryRequestObj?.locationId
    },

    completedStats () {
      return this.statsFromTicketSummaries(this.completedTicketSummaries)
    },

    createdStats () {
      return this.statsFromTicketSummaries(this.createdTicketSummaries)
    },

    _indices () {
      return {
        widgetMappings: {
          widgetId: indexArrayBy(logsWidgetMappings, wm => wm.widget.label)
        }
      }
    }
  },

  watch: {
    async locationId (id) {
      this.trucksInYard = (id !== undefined && id > 0) ? await this.fetchTrucksInYard(id) : undefined
      this.truckCount = this.trucksInYard?.truckCount
      this.widgetData = this.widgetData.map(i => i.widget === DashboardLogsWidgets.TrucksInYard ? { ...i, value: this.truckCount !== undefined ? `${this.truckCount}` : this.$t('notAvailable') } : i)
    }
  },

  async created () {
    this.initializeCharts()
    if (!localStorage.getItem(LocalStorageKeys.DASHBOARD_TICKETS_TIME_LABEL)) localStorage.setItem(LocalStorageKeys.DASHBOARD_TICKETS_TIME_LABEL, this.timeLabel)
  },

  methods: {
    ...mapActions('dashboard',
      ['fetchSummaryData',
        'fetchLandownerSummaries',
        'fetchLogAnalysis',
        'fetchLogQualityAnalysis',
        'fetchItemizedDefectSummary',
        'fetchTonsByCertification',
        'fetchTrucksInYard'
      ]),

    numberWithCommas,

    orUndefined (d, fn) {
      return (d !== undefined)
        ? fn(d)
        : undefined
    },

    initializeCharts () {
      this.chartSet = new LogsDashboardWidgetSet()
      this.setWidgetData()
    },

    newChartLayout (chartSet) {
      this.fetchChartData(this.summaryRequestObj, chartSet)
    },

    async fetchChartData (summaryRequest, chartSet = this.chartSet) {
      const dlw = DashboardLogsWidgets
      const hasExternalData = summaryRequest.destinationAccountId !== undefined
      if (chartSet.someWidgetShowing([
        dlw.CompletedLoadsByAccount,
        dlw.CompletedLoadsByLogger,
        dlw.CompletedLoadsByTract,
        dlw.CompletedLoadsByProduct,
        dlw.CompletedLoadsByRegion,
        dlw.DefectsByAccount,
        dlw.CompletedLoads,
        dlw.TotalTons,
        dlw.PercentageTractCertified,
        dlw.PercentageAccountCertified,
        dlw.TransferLoads,
        dlw.CompletedLoadsByTractType,
        dlw.CompletedLoadsByDestination
      ].map(w => w.value))) {
        const summaryData = await this.fetchSummaryData({ ...summaryRequest, useLoadCreatedAt: false })
        this.completedTicketSummaries = summaryData.ticketSummaries
        this.defectsByAccount = (!hasExternalData)
          ? summaryData.defectsByAccount
          : {
            itemizedDefectsByContractAccount: [],
            itemizedDefectsByLogger: [],
            totalItemizedDefectCount: 0,
            totalItemizedDefectPounds: 0
          }
        this.initializeDataCompleted()
      }
      if (chartSet.someWidgetShowing([
        dlw.CreatedLoads,
        dlw.CreatedLoadsByAccount,
        dlw.CreatedLoadsByLogger,
        dlw.CreatedLoadsByTract,
        dlw.CreatedLoadsByTractType,
        dlw.CreatedLoadsByProduct,
        dlw.CreatedLoadsByRegion,
        dlw.CreatedLoadsByDestination
      ].map(w => w.value))) {
        const summaryData = await this.fetchSummaryData({ ...summaryRequest, useLoadCreatedAt: true })
        this.createdTicketSummaries = summaryData.ticketSummaries
        this.initializeDataCreated()
      }
      if (chartSet.someWidgetShowing([
        dlw.DailyInventoryCost,
        dlw.LogQualityAnalysis
      ].map(w => w.value))) {
        this.refreshLogAnalysis(summaryRequest)
      }
      if (chartSet.someWidgetShowing([
        dlw.CompletedLoadsByLandOwner
      ].map(w => w.value))) {
        const summaryData = await this.fetchLandownerSummaries({ ...summaryRequest, useLoadCreatedAt: false })
        this.landownerTicketSummaries = summaryData.landownerTicketSummaries
        this.widgetData = this.widgetData.map(i => i.widget === dlw.CompletedLoadsByLandOwner ? { ...i, data: this.orUndefined(this.landownerTicketSummaries, lblo => new StackedBarChart(lblo, 'landOwnerName', 'landOwner', 'tract')) } : i)
      }
      if (chartSet.someWidgetShowing([
        dlw.ItemizedDefectSummary
      ].map(w => w.value))) {
        this.itemizedDefectSummaryData = (!hasExternalData) ? await this.fetchItemizedDefectSummary(summaryRequest) : []
        this.widgetData = this.widgetData.map(i => i.widget === dlw.ItemizedDefectSummary ? { ...i, data: this.orUndefined(this.itemizedDefectSummaryData, ids => new StackedBarChartForItemizedDefectSummary(ids, 'defectName', 'itemizedDefect')) } : i)
      }
      if (chartSet.someWidgetShowing([
        dlw.TonsByCertification
      ].map(w => w.value))) {
        this.tonsByCertification = await this.fetchTonsByCertification(summaryRequest)
      }
      this.chartSet = chartSet
    },

    initializeDataCompleted () {
      const dlw = DashboardLogsWidgets
      const widgetsWithData = this.widgetData.map(i => {
        switch (i.widget) {
          // Cards
          case dlw.CompletedLoads: return { ...i, value: numberWithCommas(this.completedStats.total.loads) }
          case dlw.TotalTons: return { ...i, value: numberWithCommas(this.completedStats.total.tons ?? 0, 3) }
          case dlw.PercentageTractCertified: return { ...i, value: `${this.completedStats.percentageCertifiedBy?.tract ?? 0}%` }
          case dlw.PercentageAccountCertified: return { ...i, value: `${this.completedStats.percentageCertifiedBy?.account ?? 0}%` }
          case dlw.TransferLoads: return { ...i, value: numberWithCommas(this.completedStats.total.transfer) }
          // Charts
          case dlw.CompletedLoadsByAccount: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByAccount, lba => new StackedBarChart(lba, 'accountName', 'account')) }
          case dlw.CompletedLoadsByLogger: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByLogger, lbl => new StackedBarChart(lbl, 'accountName', 'logger')) }
          case dlw.CompletedLoadsByTract: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByTract, lbt => new StackedBarChart(lbt, 'tractName', 'tract')) }
          case dlw.CompletedLoadsByProduct: return { ...i, data: new BarChartLoadsByProduct(this.completedTicketSummaries?.loadsByProduct ?? [], 'productName', 'product') }
          case dlw.CompletedLoadsByRegion: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByRegion, lbr => new StackedBarChart(lbr.map(r => ({ ...r, region: `${r.countrySecondarySubdivision} ${r.countrySubdivision}` })), 'region', 'region')) }
          case dlw.DefectsByAccount: return { ...i, data: this.orUndefined(this.defectsByAccount, dba => new StackedBarChartForDefectsByAccount(dba, 'accountName', 'defectSummary')) }
          case dlw.CompletedLoadsByTractType: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByTractType, lbtt => new StackedBarChart(lbtt, 'tractType', 'tractType')) }
          case dlw.CompletedLoadsByDestination: return { ...i, data: this.orUndefined(this.completedTicketSummaries?.loadsByDestinationAccount, lbd => new StackedBarChart(lbd, 'accountName', 'destination')) }
        }
        return i
      })
      this.widgetData = widgetsWithData
    },

    initializeDataCreated () {
      const dlw = DashboardLogsWidgets
      const widgetsWithData = this.widgetData.map(i => {
        switch (i.widget) {
          // Card
          case dlw.CreatedLoads: return { ...i, value: numberWithCommas(this.createdStats.total.loads) }
          // Charts
          case dlw.CreatedLoadsByAccount: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByAccount, lba => new StackedBarChart(lba, 'accountName', 'account', 'product', { csv: { includeWeight: false, includeLoadCount: true } })) }
          case dlw.CreatedLoadsByLogger: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByLogger, lbl => new StackedBarChart(lbl, 'accountName', 'logger', 'product', { csv: { includeWeight: false, includeLoadCount: true } })) }
          case dlw.CreatedLoadsByTract: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByTract, lbt => new StackedBarChart(lbt, 'tractName', 'tract', 'product', { csv: { includeWeight: false, includeLoadCount: true } })) }
          case dlw.CreatedLoadsByTractType: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByTractType, lbtt => new StackedBarChart(lbtt, 'tractType', 'tractType', 'product', { csv: { includeWeight: false, includeLoadCount: true } })) }
          case dlw.CreatedLoadsByProduct: return { ...i, data: new BarChartLoadsByProduct(this.createdTicketSummaries?.loadsByProduct ?? [], 'productName', 'product', false, false) }
          case dlw.CreatedLoadsByRegion: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByRegion, lbr => new StackedBarChart(lbr.map(r => ({ ...r, region: `${r.countrySecondarySubdivision} ${r.countrySubdivision}` })), 'region', 'region', 'product', { csv: { includeWeight: false, includeLoadCount: true } })) }
          case dlw.CreatedLoadsByDestination: return { ...i, data: this.orUndefined(this.createdTicketSummaries?.loadsByDestinationAccount, lbd => new StackedBarChart(lbd, 'accountName', 'destination')) }
        }
        return i
      })
      this.widgetData = widgetsWithData
    },

    idToWidgetId (id) {
      return logsWidgetMappings.map(wm => wm.widget).find(e => e.value === id).label ?? ''
    },

    widgetTypeDimensions (wt) {
      switch (wt) {
        case 'card':
          return this.cardArea
        case 'chart':
        default:
          return this.chartArea
      }
    },

    widgetMappingByWidgetId (widgetId) {
      return logsWidgetMappings.find(i => i.widget.label === widgetId)
    },

    async customTimePeriodChosen (dateRangeObj) {
      this.setDateUI(dateRangeObj)
      this.summaryRequestObj.sinceTime = dateRangeObj.sinceTime
      this.summaryRequestObj.untilTime = dateRangeObj.untilTime
      this.customDateRangeDialog = false
      await this.fetchChartData(this.summaryRequestObj)
    },

    setDateUI (dateRangeObj) {
      this.sinceTime = utcToLocalDate(dateRangeObj.sinceTime)
      this.untilTime = utcToLocalDate(dateRangeObj.untilTime)
    },

    getTitleForTotalCard (titleString) {
      const titleDestination = this.selectedDestName !== '' ? ` (${this.selectedDestName})` : ''
      return `${titleString}${titleDestination}`
    },

    async refreshLogAnalysis (summaryRequest) {
      const requestObj = {
        locationId: summaryRequest.locationId,
        sinceTime: summaryRequest.sinceTime,
        untilTime: summaryRequest.untilTime
      }

      const doRefresh = (
        requestObj.locationId !== null &&
        requestObj.locationId !== undefined &&
        requestObj.locationId !== -1)

      if (summaryRequest.destinationAccountId !== undefined) {
        this.logAnalysisData = []
        this.logQualityAnalysisData = []
        return
      }

      if (doRefresh) {
        this.logAnalysisData = await this.fetchLogAnalysis(requestObj) ?? []
        this.logQualityAnalysisData = await this.fetchLogQualityAnalysis(requestObj) ?? []
      } else {
        this.logAnalysisData = []
        this.logQualityAnalysisData = []
      }
    },

    async destinationDateSelected (destDateObj) {
      this.timeLabel = destDateObj.label
      if (this.timeLabel === 'custom') localStorage.setItem(LocalStorageKeys.DASHBOARD_TICKETS_TIME_LABEL, 'today')
      else localStorage.setItem(LocalStorageKeys.DASHBOARD_TICKETS_TIME_LABEL, destDateObj.label)
      this.summaryRequestObj = {
        ...destDateObj,
        includeExternal: destDateObj.destinationAccountId === -1,
        includeInternal: destDateObj.locationId === -1
      }
      this.setDateUI(destDateObj)
      await this.fetchChartData(this.summaryRequestObj)
    },

    toggleDenseCharts () {
      this.denseCharts = !this.denseCharts
    },

    statsFromTicketSummaries (ticketSummaries) {
      return {
        total: {
          tons: ticketSummaries?.totalTons,
          transfer: ticketSummaries?.transferLoads,
          loads: ticketSummaries?.totalLoads
        },
        percentageCertifiedBy: {
          account: ticketSummaries?.percentageAccountCertifiedTons,
          tract: ticketSummaries?.percentageTractCertifiedTons
        }
      }
    },

    addNewChart () {
      this.chartPickerMenuKey += 1
    },

    getWidgetDataByEnum (widget) {
      return this.widgetData.find(i => i.widget === widget) ?? {}
    },

    getWidgetDataByIndex (index) {
      return this.widgetData.find(i => i.id === index) ?? {}
    },

    getTicketStatuses (completedOnly) {
      return completedOnly ? [5, 6, 7] : []
    },

    onDataClicked (widget, xAxisLabel, yAxisLabel, data, dataObject) {
      if (!this.validateTimePeriodForInteraction()) return
      switch (widget) {
        case DashboardLogsWidgets.ItemizedDefectSummary: {
          xAxisLabel = 'defect'
          break
        }
        case DashboardLogsWidgets.DailyInventoryCost:
        case DashboardLogsWidgets.LogQualityAnalysis: {
          const dataFormattedDate = data.map(d => ({ ...d, day: moment(d.day).format('MM/DD/YYYY') }))
          data = {
            data: dataFormattedDate,
            dataSets: { sets: dataFormattedDate },
            key: 'day'
          }
          break
        }
        case DashboardLogsWidgets.TrucksInYard: data = this.trucksInYard; break
        case DashboardLogsWidgets.TransferLoads: data = { ticketIds: this.completedTicketSummaries.transferTicketIds }
      }

      if (dataObject?.details?.mismanufactureOnly) {
        data = {
          ...data,
          data: data.dataMMO,
          dataSets: data.dataSetsMMO
        }
      }

      this.$emit('data-clicked', {
        widget: widget,
        requestObject: this.summaryRequestObj,
        data: data,
        primaryFilterKey: xAxisLabel,
        secondaryFilterKey: yAxisLabel,
        primaryFilterValue: dataObject?.xAxisValue,
        secondaryFilterValue: dataObject?.yAxisValue,
        details: dataObject?.details
      })
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    openDialog () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
    },

    getType (type) {
      if (type.charAt(0).toUpperCase() === type.charAt(0)) {
        return type
      } else {
        return this.$t(type)
      }
    },

    setWidgetData () {
      this.widgetData = logsWidgetMappings.map(wm => ({ widget: wm.widget }))
    },

    showChartLoader (widget) {
      return ((widget.details?.widget === DashboardLogsWidgets.TonsByCertification && !this.tonsByCertification) ||
        (widget.details?.widget === DashboardLogsWidgets.LogQualityAnalysis && !this.logQualityAnalysisData) ||
        (widget.details?.widget === DashboardLogsWidgets.DailyInventoryCost && !this.logAnalysisData) ||
        (!this.getWidgetDataByEnum(widget.details?.widget)?.data && [
          DashboardLogsWidgets.TonsByCertification,
          DashboardLogsWidgets.LogQualityAnalysis,
          DashboardLogsWidgets.DailyInventoryCost
        ].every(i => i !== widget.details?.widget))) &&
        widget.id !== 'empty' &&
        widget.details?.widgetType !== 'card'
    },

    validateTimePeriodForInteraction () {
      const timePeriodLength = timeBetween(localToUTC(this.summaryRequestObj.sinceTime), this.summaryRequestObj.untilTime ? localToUTC(this.summaryRequestObj.untilTime) : Date.now())
      if (timePeriodLength.includes('days') && parseInt(timePeriodLength) > MAX_DAYS_FOR_WIDGET_INTERACTION) {
        this.setSnack(this.$t('timePeriodMustBe15DaysOrShorterToViewTickets'))
        return false
      }
      return true
    }
  }
}
</script>
