import store from '@/store/index.js'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { BlobServiceClient } from '@azure/storage-blob'
import i18n from '../i18n'
import { AccountFileCategory } from './Enumerations'
import { SasTokenLocations } from './constants'

class AccountFilesClient {
  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  async getEndpoint () {
    return 'companyinfo/blobinfo/accountfiles'
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.ACCOUNT_FILES_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = await this.getEndpoint()
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.ACCOUNT_FILES_SAS, JSON.stringify(response))
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.ACCOUNT_FILES_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  getBlobLocation (accountId, accountFileCategory = undefined, policyId = undefined) {
    var result = `${this.location}/${accountId}`
    if (accountFileCategory) {
      result += `/${accountFileCategory.name.toUpperCase()}`
    }
    if (policyId) {
      result += `/${policyId}`
    }
    return result
  }

  async getFilesForAccount (accountId, fileCategory = undefined) {
    if (!accountId) return []
    const files = []
    try {
      const containerClient = await this.getContainerClient()
      const listOptions = {
        includeMetadata: false,
        includeSnapshots: false,
        includeTags: false,
        includeVersions: false,
        prefix: this.getBlobLocation(accountId)
      }
      const iterator = containerClient.listBlobsFlat(listOptions).byPage()
      const response = (await iterator.next()).value
      for (const blob of response.segment.blobItems) {
        const resourceUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.ACCOUNT_FILES_SAS)).data[0].blobSasUri, this.container, blob.name)
        const resourceUrlTokens = resourceUrl.pathname.split('/')
        const filename = resourceUrlTokens.pop()
        const filetime = resourceUrlTokens.pop()
        const userId = resourceUrlTokens.pop()
        const policyId = resourceUrlTokens.pop()
        const accountFileCategory = resourceUrlTokens.pop()
        files.push({
          name: decodeURIComponent(filename),
          type: filename.split('.').pop(),
          fileCategory: this.stringToFileCategory(accountFileCategory),
          policyId: policyId,
          user: userId,
          date: filetime,
          ref: resourceUrl.href
        })
      }
    } catch (e) {
      console.error(e)
      return []
    }
    return fileCategory ? files.filter(f => f.fileCategory === fileCategory) : files
  }

  async getFileByPolicy (policyObject, fileCategory) {
    const idKey = fileCategory === AccountFileCategory.Insurance ? 'accountInsurancePolicyId'
      : fileCategory === AccountFileCategory.Certification ? 'certificationId'
        : undefined
    if (!idKey) return
    if (!policyObject) return []
    const files = []
    try {
      const containerClient = await this.getContainerClient()
      const listOptions = {
        includeMetadata: false,
        includeSnapshots: false,
        includeTags: false,
        includeVersions: false,
        prefix: this.getBlobLocation(policyObject.accountId, fileCategory, policyObject[idKey])
      }
      const iterator = containerClient.listBlobsFlat(listOptions).byPage()
      const response = (await iterator.next()).value
      for (const blob of response.segment.blobItems) {
        const resourceUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.ACCOUNT_FILES_SAS)).data[0].blobSasUri, this.container, blob.name)
        const resourceUrlTokens = resourceUrl.pathname.split('/')
        const filename = resourceUrlTokens.pop()
        const filetime = resourceUrlTokens.pop()
        const userId = resourceUrlTokens.pop()
        files.push({
          name: decodeURIComponent(filename),
          type: filename.split('.').pop(),
          user: userId,
          date: filetime,
          ref: resourceUrl.href
        })
      }
    } catch (e) {
      console.error(e)
      return []
    }
    return files
  }

  async deleteFile (fileUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (fileUrl instanceof URL)
        ? decodeURI(fileUrl.pathname)
        : decodeURI(new URL(decodeURI(fileUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.delete()
    } catch (e) {
      console.error(e)
    }
  }

  async uploadAccountFile (file, policyObject, fileCategory) {
    const idKey = fileCategory === AccountFileCategory.Insurance ? 'accountInsurancePolicyId'
      : fileCategory === AccountFileCategory.Certification ? 'certificationId'
        : undefined
    if (!idKey) return
    const files = await this.getFilesForAccount(policyObject.accountId, fileCategory)
    if (files.length > 0) return
    const storedFileName = `${store.getters['user/userInfo'].applicationUserId}/${Date.now()}/${file.name}`
    const fileForUpload = new File([file], storedFileName, { type: file.type })

    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = `${this.getBlobLocation(policyObject.accountId, fileCategory, policyObject[idKey])}/${fileForUpload.name}`
      const blockBlobClient = containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: fileForUpload.type
        }
      }
      await blockBlobClient.uploadData(fileForUpload, options)
    } catch (e) {
      store.dispatch('snackbar/setSnackError', i18n.t('errorUploadingAccountFile', { policy: policyObject.name }))
      console.error(e)
    }
  }

  stringToFileCategory (string) {
    return string.toLowerCase().localeCompare(AccountFileCategory.Insurance.label) === 0
      ? AccountFileCategory.Insurance.value
      : AccountFileCategory.Certification.value
  }
}

class BolClient {
  async checkForRefresh () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.BOL_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = 'companyinfo/blobinfo/accountbol'
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.BOL_SAS, JSON.stringify(response))

      this.container = response.data[0].container
      this.location = response.data[0].location
      this.uri = response.data[0].blobSasUri
      this.client = new BlobServiceClient(this.uri)
      this.containerClient = this.client.getContainerClient(this.container)
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.BOL_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.uri = storedResponse.data[0].blobSasUri
      this.client = new BlobServiceClient(this.uri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  async fetchBolFromBlobStorage (accountId) {
    try {
      await this.checkForRefresh()
      const listOptions = {
        includeMetadata: false,
        includeSnapshots: false,
        includeTags: false,
        includeVersions: false,
        prefix: `${this.location}/${accountId}/`
      }
      const iterator = this.containerClient.listBlobsFlat(listOptions).byPage()
      const response = (await iterator.next()).value
      const blobResponse = response.segment.blobItems[0]
      if (blobResponse === undefined) return undefined // Not Found
      return {
        ref: createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.ACCOUNT_FILES_SAS)).data[0].blobSasUri, this.container, blobResponse.name),
        name: blobResponse.name.split('/').pop()
      }
    } catch (e) {
      console.error(e)
      store.dispatch('snackbar/setSnackError', i18n.t('unexpectedErrorFetchingBol'))
    }
  }

  async uploadBol (accountId, bol) {
    try {
      await this.checkForRefresh()
      const options = { blobHTTPHeaders: { blobContentType: bol.type } }
      const blobName = `${this.location}/${accountId}/${bol.name}`
      const blobClient = this.containerClient.getBlockBlobClient(blobName)
      await blobClient.uploadData(bol, options)
    } catch (e) {
      console.error(e)
      store.dispatch('snackbar/setSnackError', i18n.t('unexpectedErrorUploadingBol'))
    }
  }

  async deleteBol (accountId, bol) {
    try {
      await this.checkForRefresh()
      const blobName = `${this.location}/${accountId}/${bol.name}`
      const blobClient = this.containerClient.getBlockBlobClient(blobName)
      await blobClient.delete()
    } catch (e) {
      console.error(e)
      store.dispatch('snackbar/setSnackError', i18n.t('unexpectedErrorDeletingBol'))
    }
  }
}

export const accountFilesClient = new AccountFilesClient()
export const accountBolClient = new BolClient()
