import PDFMake from 'pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { ContractType } from '@/utils/Enumerations'
import CryptoJS from 'crypto-js'

PDFMake.vfs = pdfFonts.pdfMake.vfs

export function getContractQrCodeData (contractId, tenantCode) {
  const payload = {
    type: 'contract',
    v: 2,
    id: contractId,
    param: ''
  }

  const key = CryptoJS.enc.Utf8.parse(tenantCode.padEnd(32, '0'))
  const iv = CryptoJS.lib.WordArray.random(16)
  const encryptedMessage = CryptoJS.AES.encrypt(JSON.stringify(payload), key, { mode: CryptoJS.mode.CBC, iv: iv })

  const ap = CryptoJS.enc.Base64.stringify(encryptedMessage.ciphertext) + ':' + CryptoJS.enc.Base64.stringify(encryptedMessage.iv)

  const qrContent = {
    ap: ap
  }

  return JSON.stringify(qrContent)
}

export async function generateContractIdSheetPDF (contract, qrCode) {
  const passContent = () => {
    if (contract.type !== ContractType.Transfer.value && contract.type !== ContractType.ByproductSale.value) {
      return [
        { text: `Destination: ${contract.destination}`, style: 'subtitle', margin: [25, 30, 0, 5], alignment: 'left', listType: 'none' },
        { text: `Tract: ${contract.tract ?? 'N/A'}`, style: 'subtitle', margin: [25, 5], alignment: 'left', listType: 'none' },
        { text: `Account: ${contract.account}`, style: 'subtitle', margin: [25, 5], alignment: 'left', listType: 'none' },
        { text: `Logger: ${contract.tractDefaultLogger ?? 'N/A'}`, style: 'subtitle', margin: [25, 5], alignment: 'left', listType: 'none' },
        { text: `Setting: ${contract.setting ?? 'N/A'}`, style: 'subtitle', margin: [25, 5, 0, 0], alignment: 'left', listType: 'none' }
      ]
    } else {
      return [
        { text: `Destination: ${contract.destination}`, style: 'subtitle', margin: [25, 30, 0, 5], alignment: 'left', listType: 'none' },
        { text: `Account: ${contract.account}`, style: 'subtitle', margin: [25, 5], alignment: 'left', listType: 'none' },
        { text: `From Account: ${contract.fromAccount}`, style: 'subtitle', margin: [25, 5], alignment: 'left', listType: 'none' }
      ]
    }
  }

  var pass = () => [
    { text: 'Contract Identification Sheet', style: 'title', margin: [0, 0, 0, 0] },
    {
      columns: [
        {
          ol: passContent()
        },
        { image: qrCode, width: 150, margin: [0, 17, 25, 0], alignment: 'center' }
      ]
    },
    { text: 'Scan code at scale to weigh in', italics: false, fontSize: 12, alignment: 'center', margin: [0, 5, 0, 3] },
    { text: 'Generated using Angel, by Legna Software', italics: true, fontSize: 8, alignment: 'center', margin: [0, 0, 0, 0] }
  ]

  var divider = () => [
    {
      canvas: [
        { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1, dash: { length: 5, space: 10 } }
      ],
      margin: [0, 19, 0, 19]
    }
  ]

  var content = []

  content.push(...pass(), ...divider())
  content.push(...pass(), ...divider())
  content.push(...pass())

  const documentDefinition = {
    content,

    defaultStyle: {
      margin: [0, 0]
    },

    styles: {
      title: {
        fontSize: 16,
        bold: true,
        alignment: 'center'
      },
      subtitle: {
        fontSize: 14,
        bold: false,
        alignment: 'center'
      }
    }
  }

  // eslint-disable-next-line no-useless-escape
  const encode = (string) => string.trim().replace(' ', '_').replace(/[\\<>:"\/\|\?\*~#%&+{}[\]';,`@!^$\.]+/g, '')

  await PDFMake.createPdf(documentDefinition).download(`cid-${encode(contract.tract)}-${encode(contract.destination)}`)
}
