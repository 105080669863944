<template>
  <v-card>
    <v-card-title class="primary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close')"
      :tooltipText="$t('close')"
      iconColor="white"
      icon="mdi-close"
      dataTestId="payment-close"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="advanceForm"
        :disabled="!validForm"
        :buttonText="propTractPayment ? $t('saveChanges') : $t('create')"
        @submit="saveChangesPressed"
      >
        <v-container grid-list-lg>
          <TemplateAutocomplete
            data-testid="payment-template"
            :templateId="idOrUndefined(tractPayment.activityTemplateId)"
            :propAccountingCategories="accountingCategories"
            :includeDepletionActivities="false"
            @template-chosen="templateChosen"
          />
          <v-row justify="end">
            <v-col cols="12" sm="4">
              <AccountAutocomplete
                data-testid="payment-account"
                :accountId="idOrUndefined(tractPayment.accountId)"
                @account-chosen="accountChosen"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <MoneyTextField
                data-testid="payment-amount"
                :initialValue="tractPayment.amount"
                :label="$t('amount')"
                :allowNegative="true"
                @val-changed="paymentAmountEntered"
                :extraRules="['validMiscPayable']"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <DatePicker
                dataTestId="issued-date"
                :dateLabel="$t('issuedAt')"
                :startDate="chosenIssuedAt"
                @valid-date="(val) => {isValidIssuedAt = val}"
                @date-picked="issuedAtChosen"
              />
            </v-col>
            <v-col cols="auto">
              <v-checkbox
              :disabled="preIssuedCheckBoxDisabled"
              v-model="tractPayment.preIssued"
              color="primary"
              data-testid="tract-payable-preissued-check"
              :label="$t('preIssued')"
              />
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-select
              :disabled="restrictToCost"
              :label="$t('paymentSpecialization')"
              v-model="tractPayment.specialization"
              :items="filteredSpecializations"
              item-text="name"
              item-value="value"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12">
              <v-textarea
                v-model="tractPayment.paymentNote"
                data-testid="payment-note"
                :hint="$t('paymentTitle')"
                color="black"
                name="input-7-4"
                maxLength="128"
                counter="128"
                outlined
                auto-grow
                clearable
                dense
                ref="payableTitle"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import { mapActions } from 'vuex'
import { MAX_MISC_PAYABLE, MIN_MISC_PAYABLE } from '@/utils/rules'
import { TemplateAccountingCategory, TractPayableSpecialization } from '@/utils/Enumerations.js'
export default {
  name: 'TractPaymentForm',

  props: {
    propTractPayment: {
      type: Object,
      default: undefined
    },
    tractId: {
      required: true,
      type: Number
    },
    performanceBonds: {
      required: true,
      type: Array
    },
    includeCost: {
      type: Boolean,
      default: false
    },
    restrictToCost: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    TemplateAutocomplete: () => import('@/components/autocomplete/TemplateAutocomplete.vue'),
    MoneyTextField: () => import('@/components/helper/MoneyTextField.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  data: () => ({
    TractPayableSpecialization,
    selectedBusinessEntity: '',
    tractPayment: {
      tractId: undefined,
      accountId: undefined,
      activityTemplateId: undefined,
      amount: 0,
      paymentNote: '',
      issuedAt: '',
      preIssued: false,
      specialization: TractPayableSpecialization.None.value
    },
    isValidIssuedAt: true,
    chosenIssuedAt: '',
    performanceBondSum: undefined,
    accountingCategories: [TemplateAccountingCategory.AccountsPayable.value]
  }),

  watch: {
    'tractPayment.preIssued': {
      handler (val) {
        this.accountingCategories = val && this.tractPayment.performanceBond !== TractPayableSpecialization.PerformanceBond.value
          ? [TemplateAccountingCategory.AccountsPayable.value, TemplateAccountingCategory.Accruals.value]
          : [TemplateAccountingCategory.AccountsPayable.value]

        if (!val && this.tractPayment.specialization === TractPayableSpecialization.PerformanceBond.value) {
          this.tractPayment.specialization = TractPayableSpecialization.None.value
        }
      }
    },

    'tractPayment.specialization' (val) {
      if (val === TractPayableSpecialization.None.value || val === TractPayableSpecialization.Cost.value) return
      this.accountingCategories = val === TractPayableSpecialization.PerformanceBond.value
        ? [TemplateAccountingCategory.AccountsPayable.value]
        : [TemplateAccountingCategory.AccountsPayable.value, TemplateAccountingCategory.Accruals.value]
    }
  },

  computed: {
    title () {
      if (this.restrictToCost) {
        return this.$t('createTractCostPayment')
      }
      if (this.propTractPayment) {
        return this.$t('editTractPayment')
      } else {
        return this.$t('createTractPayment')
      }
    },

    filteredSpecializations () {
      return TractPayableSpecialization.enums.filter(s =>
        (this.tractPayment.preIssued || s !== TractPayableSpecialization.PerformanceBond) &&
        (this.includeCost || this.restrictToCost || s !== TractPayableSpecialization.Cost))
    },

    validForm () {
      const { tractId, accountId, amount, paymentNote, activityTemplateId } = this.tractPayment
      return (
        this.isValidId(tractId) &&
        this.isValidId(accountId) &&
        this.isValidId(activityTemplateId) &&
        amount !== 0 &&
        paymentNote &&
        this.isValidIssuedAt
      )
    },

    preIssuedCheckBoxDisabled () {
      if (this.propTractPayment) {
        return this.propTractPayment.preIssued
      } else {
        return false
      }
    }
  },

  created () {
    if (this.propTractPayment) {
      this.tractPayment = JSON.parse(JSON.stringify(this.propTractPayment))
      this.chosenIssuedAt = this.tractPayment.issuedAt ? utcToLocalDate(this.tractPayment.issuedAt) : utcToLocalDate(this.tractPayment.createdAt)
    } else {
      this.chosenIssuedAt = new Date().toISOString()
    }
    if (this.restrictToCost) {
      this.tractPayment.specialization = TractPayableSpecialization.Cost.value
    }
    this.tractPayment.tractId = this.tractId
    this.setPerformanceBondSum()
    setTimeout(_ => {
      this.$refs.payableTitle.focus()
    }, 0)
  },

  methods: {
    ...mapActions('tract', ['createTractPayment', 'updateTractPayment']),
    paymentAmountEntered (val) { this.tractPayment.amount = val },
    templateChosen (val) { this.tractPayment.activityTemplateId = val?.activityTemplateId },
    accountChosen (val) { this.tractPayment.accountId = val.accountId },
    idOrUndefined: id => (id !== -1) ? id : undefined,
    isValidId: id => id !== -1 && id !== undefined && id !== null,

    async saveChangesPressed () {
      if (this.tractPayment.specialization === TractPayableSpecialization.PerformanceBond.value && this.performanceBondSum + this.tractPayment.amount < 0) {
        this.setSnackError(this.$t('netPerformanceBondValueCannotBeNegative'))
      } else if (this.tractPayment.amount > MAX_MISC_PAYABLE || this.tractPayment.amount < MIN_MISC_PAYABLE) {
        this.setSnackError(this.$t('invalidMiscPayable'))
      } else {
        const requestObj = {
          tractPayment: this.tractPayment,
          tractId: this.tractId
        }
        if (this.propTractPayment) {
          requestObj.tractPaymentId = this.tractPayment.tractPayableId
          await this.updateTractPayment(requestObj)
          this.$emit('submitted')
        } else {
          await this.createTractPayment(requestObj)
          this.$emit('submitted', this.tractPayment.amount)
        }
        const specsWithBalance = [TractPayableSpecialization.PerformanceBond.value, TractPayableSpecialization.Cost.value]
        if (specsWithBalance.includes(this.tractPayment.specialization) || specsWithBalance.includes(this.propTractPayment?.specialization)) {
          this.$emit('balance-changed')
        }
      }
    },

    issuedAtChosen (date) {
      this.chosenIssuedAt = date
      this.tractPayment.issuedAt = localToUTC(date)
    },

    setPerformanceBondSum () {
      this.performanceBondSum = this.performanceBonds.filter(pb => pb.tractPayableId !== this.propTractPayment?.tractPayableId).map(tp => tp.amount).reduce((acc, tp) => acc + tp, 0)
    }
  }
}
</script>
