export default class TicketQuery {
  constructor ({
    weighedIn,
    weighedOut,
    readyForPayment,
    inTransit,
    posted,
    exported,
    limitInternal,
    dateRange,
    byProduct,
    contractId,
    tractId,
    destinationAccountId,
    accountId,
    settingId,
    productId,
    fromAccountId,
    phrase,
    extTicketNumber1,
    extTicketNumber2,
    ticketIds,
    ticketNumbers,
    tractName,
    destinationAccountName,
    accountName,
    settingName,
    fromAccountName,
    hasImages,
    tractForester,
    contractMode = 0,
    includeOffice = true,
    includeReceiver = true,
    includeLoader = true,
    includeYardOperator = true,
    includeTransporter = true,
    includeKiosk = true
  }) {
    this.weighedIn = weighedIn
    this.weighedOut = weighedOut
    this.readyForPayment = readyForPayment
    this.inTransit = inTransit
    this.posted = posted
    this.exported = exported
    this.limitInternal = limitInternal
    this.dateRange = dateRange
    this.byProduct = byProduct
    this.contractId = contractId
    this.tractId = tractId
    this.destinationAccountId = destinationAccountId
    this.accountId = accountId
    this.settingId = settingId
    this.productId = productId
    this.ticketIds = ticketIds
    this.ticketNumbers = ticketNumbers
    this.fromAccountId = fromAccountId
    this.phrase = phrase
    this.extTicketNumber1 = extTicketNumber1
    this.extTicketNumber2 = extTicketNumber2
    this.includeOffice = includeOffice
    this.includeReceiver = includeReceiver
    this.includeLoader = includeLoader
    this.includeYardOperator = includeYardOperator
    this.includeTransporter = includeTransporter
    this.tractName = tractName
    this.destinationAccountName = destinationAccountName
    this.accountName = accountName
    this.settingName = settingName
    this.fromAccountName = fromAccountName
    this.hasImages = hasImages
    this.contractMode = contractMode
    this.tractForester = tractForester
    this.includeKiosk = includeKiosk

    this.buildQueryString = () => {
      let queryString = '?'
      // Ticket Statuses
      if (this.weighedIn) queryString += 'IncludeWeighedIn=true&'
      if (this.weighedOut) queryString += 'IncludeWeighedOut=true&'
      if (this.readyForPayment) queryString += 'IncludeReadyForPayment=true&'
      if (this.inTransit) queryString += 'IncludeInTransit=true&'
      if (this.posted) queryString += 'IncludePosted=true&'
      if (this.exported) queryString += 'IncludeExported=true&'
      if (this.limitInternal) queryString += 'LimitToInternal=true&'

      if (this.hasImages !== undefined) queryString += `HasImages=${this.hasImages}&`
      if (this.contractMode !== undefined) {
        switch (this.contractMode) {
          case 1:
            queryString += 'Byproduct=true&'
            queryString += 'ByproductPurchase=true&'
            break
          case 2: queryString += 'Transfer=true&'; break
          case 3: queryString += 'LogYardSale=true&'; break
        }
      }

      if (this.dateRange) {
        const { startDate, endDate } = this.dateRange
        if (startDate) queryString += `LowerBound=${startDate}&`
        if (endDate) queryString += `UpperBound=${endDate}&`
      }

      // Ids used for internal testing.
      if (this.contractId) queryString += `ContractId=${this.contractId}&`
      if (this.productId) queryString += `ProductId=${this.productId}&`
      if (this.tractId) queryString += `TractId=${this.tractId}&`
      if (this.settingId) queryString += `SettingId=${this.settingId}&`
      if (this.destinationAccountId) queryString += `DestinationAccountId=${this.destinationAccountId}&`
      if (this.accountId) queryString += `AccountId=${this.accountId}&`
      if (this.fromAccountId) queryString += `FromAccountId=${this.fromAccountId}&`

      if (this.phrase) queryString += `Phrase=${this.phrase}&`
      if (this.extTicketNumber1) queryString += `ExtTicketNumber1=${this.extTicketNumber1}&`
      if (this.extTicketNumber2) queryString += `ExtTicketNumber2=${this.extTicketNumber2}&`

      // Ticket Origins
      if (this.includeLoader) queryString += `IncludeLoader=${this.includeLoader}&`
      if (this.includeOffice) queryString += `IncludeOffice=${this.includeOffice}&`
      if (this.includeReceiver) queryString += `IncludeReceiver=${this.includeReceiver}&`
      if (this.includeYardOperator) queryString += `IncludeYardOperator=${this.includeYardOperator}&`
      if (this.includeTransporter) queryString += `IncludeTransporter=${this.includeTransporter}&`
      if (this.includeKiosk) queryString += `IncludeKiosk=${this.includeKiosk}&`

      // Strings to match up with ticket's contract keys.
      if (!isNullOrWhiteSpace(this.tractName)) queryString += `Tract=${this.tractName}&`
      if (!isNullOrWhiteSpace(this.destinationAccountName)) queryString += `DestinationAccount=${this.destinationAccountName}&`
      if (!isNullOrWhiteSpace(this.accountName)) queryString += `Account=${this.accountName}&`
      if (!isNullOrWhiteSpace(this.settingName)) queryString += `Setting=${this.settingName}&`
      if (!isNullOrWhiteSpace(this.fromAccountName)) queryString += `FromAccount=${this.fromAccountName}&`
      if (this.tractForester) queryString += `TractForesterId=${this.tractForester.applicationUserId}&`

      return queryString.slice(0, -1)
    }
  }
}

const isNullOrWhiteSpace = (str) => {
  if (str === null || str === undefined) return true
  return str.trim().length === 0
}
