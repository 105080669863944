<template>
  <DataTable
    v-show="!activitiesLoading"
    :items.sync="entityActivities"
    :headers="activityHeaders"
    :showExpand="true"
    :actions="actions"
    :hideHeader="true"
    expandIcon="mdi-view-list"
    @create="$emit('create')"
    @refresh="$emit('refresh')"
    tableKey="activityId"
    :customCells="customColumns"
  >
    <template #status="{item}">
      <Icon
        v-if="item.activityTemplate.costType !== 0"
        small
        icon-color="info"
        dataTestId="activity-cost-type"
        :icon="costIcons[item.activityTemplate.costType]"
        :tooltipText="CostTypeFromInt(item.activityTemplate.costType)"
      />
      <Icon
        v-if="item.recoveryMode !== RecoveryMode.None.value"
        small
        icon="mdi-cash-multiple"
        icon-color="success"
        dataTestId="activity-advance-recovery"
        :tooltipText="recoveryModeString(item)"
      />
      <Icon
      v-if="item.activityTemplate.specialization === TemplateSpecialization.Transfer.value"
      dataTestId="activity-transfer-icon"
      icon="mdi-transfer"
      :tooltipText="$t('transferActivity')"
      :small="false"
      />
      <Icon
      v-if="item.activityTemplate.specialization === TemplateSpecialization.Depletion.value"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletionActivity')"
      iconColor="black"
      :small="false"/>
    </template>
    <template #activity-name=" { item }">
      <span>{{item.activityTemplate.name}}</span>
    </template>
     <template #base-cost-rate="{ item }">
        <span v-if="item.baseCost !== null">
          {{ formatMoney(item.baseCost.rate) }}
        </span>
        <span v-else>
          {{$t('notAvailable')}}
        </span>
    </template>
    <template #gl-offset="{ item }">
      {{item.activityTemplate.glOffset ? item.activityTemplate.glOffset : $t('notAvailable')}}
    </template>
    <template #base-cost-pay-on="{ item }">
      <span v-if="item.baseCost !== null">
      {{payOnFromInt(item.baseCost.payOn)}}
      </span>
      <span v-else>
        {{$t('notAvailable')}}
      </span>
    </template>
    <template #base-cost-pay-by="{ item }">
      <span v-if="item.baseCost !== null">
      {{ payByFromInt(item.baseCost.payBy) }}
      </span>
      <span v-else>
        {{$t('notAvailable')}}
      </span>
    </template>
    <template #category="{ item }">{{
      accountingCategoryFromInt(item.activityTemplate.category)
    }}</template>
    <template #actions="{ item }">
      <Icon
      margin="mr-2"
      icon="mdi-pencil"
      icon-color="success"
      dataTestId="activity-edit-button"
      :tooltipText="$t('editActivity')"
      @icon-clicked="emitTableAction(item, 'edit')"/>
      <Icon
      v-if="item.activityTemplate.specialization !== TemplateSpecialization.Transfer.value"
      margin="mr-2"
      icon="mdi-plus"
      icon-color="success"
      dataTestId="activity-add-detail-button"
      :tooltipText="$t('createDetail')"
      @icon-clicked="createDetail(item)"/>
      <Icon
      icon="mdi-delete-forever"
      icon-color="error"
      dataTestId="activity-delete-button"
      :tooltipText="$t('deleteActivity')"
      :disabled="item.activityDetails.length !== 0"
      @icon-clicked="emitTableAction(item, 'delete')"/>
    </template>
    <template #counterparty="{ item }">
      <span v-if="counterpartySet(item)">
        <Icon
        iconColor="info"
        v-if="item.runtimeCounterparty !== null"
        icon="mdi-alpha-r-circle-outline"
        :tooltipText="`${$t('runtimeCounterparty')}: ${runtimeCounterparty(item.runtimeCounterparty)}`"/>
        {{ counterpartyString(item) }}
      </span>
      <span v-else>
        {{ $t('notSet') }}
        <Icon
        v-if="item.runtimeCounterparty !== null"
        icon="mdi-alert"
        iconColor="error"
        tooltipTcolor="error"
        :tooltipText="$t('runtimeFieldNotSpecified', { runtimeField: runtimeCounterparty(item.runtimeCounterparty) })"/>
      </span>
    </template>
    <template #expanded-item="{ item }">
        <ActivityDetailTable
          :contract="contract"
          :showActions="showActions"
          :activity="item"
          @detail-deleted="$emit('refresh')"
          @edit-detail="editDetail"
        >
        </ActivityDetailTable>
    </template>
  </DataTable>
</template>

<script>
import { PayOn, PayBy, AccountingCategory, CostType, RuntimeCounterparty, RecoveryMode, TemplateSpecialization } from '@/utils/Enumerations'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters, mapActions } from 'vuex'
import ActivityHeaders from '@/headers/Activity.js'
export default {
  name: 'EntityActivityTable',

  props: {
    showActions: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Object,
      default: undefined
    },
    businessEntityId: {
      type: Number,
      default: undefined
    },
    multiEntity: {
      type: Boolean,
      default: true
    },
    allActivities: {
      type: Array,
      default: undefined
    }
  },

  components: {
    ActivityDetailTable: () => import('./activity-detail/DetailTable.vue'),
    Icon: () => import('../helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    TemplateSpecialization,
    RecoveryMode,
    tract: []
  }),

  computed: {
    ...mapGetters('activity', ['activitiesLoading']),
    activityHeaders () { return !this.showActions ? ActivityHeaders.activityNoCRUDHeaders(this.$i18n.locale) : ActivityHeaders.activityCRUDHeaders(this.$i18n.locale) },
    customColumns () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'activity-name',
          value: 'activityTemplate.name'
        },
        {
          slotName: 'base-cost-rate',
          value: 'baseCost.rate'
        },
        {
          slotName: 'gl-offset',
          value: 'activityTemplate.glOffset'
        },
        {
          slotName: 'base-cost-pay-on',
          value: 'baseCost.payOn'
        },
        {
          slotName: 'base-cost-pay-by',
          value: 'baseCost.payBy'
        },
        {
          slotName: 'category',
          value: 'activityTemplate.category'
        },
        {
          slotName: 'effective-date',
          value: 'effectiveDate',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'counterparty',
          value: 'contractor.name'
        }
      ]
    },

    actions () {
      if (!this.showActions) {
        return []
      } else {
        return [
          {
            actionName: 'refresh',
            icon: 'mdi-refresh',
            text: this.$t('refresh')
          },
          {
            actionName: 'create',
            icon: 'mdi-plus',
            text: this.$t('new')
          }
        ]
      }
    },

    costIcons () {
      return ['mdi-alpha-u-circle-outline', 'mdi-alpha-p-circle-outline', 'mdi-alpha-s-circle-outline', 'mdi-alpha-n-circle-outline']
    },

    entityActivities () {
      if (this.multiEntity) {
        return this.allActivities.filter(activity => parseInt(activity.activityTemplate.businessEntityId) === this.businessEntityId)
      } else {
        return this.allActivities
      }
    }
  },

  async created () {
    if (this.allActivities.some(a => a.runtimeCounterparty === RuntimeCounterparty.Landowner.value) && this.contract.tractId) {
      this.tract = await this.fetchTract(this.contract.tractId)
    }
  },

  methods: {
    ...mapActions('tract', ['fetchTract']),
    formatMoney,
    accountingCategoryFromInt: (ac) => AccountingCategory.fromInt(ac),
    CostTypeFromInt: (ct) => CostType.fromInt(ct),
    expand (activity) {
      if (this.expandedActivities.includes(activity)) {
        this.expandedActivities = []
      } else {
        this.expandedActivities = [activity]
      }
    },

    emitTableAction (activity, actionType) {
      const event = {
        activity: activity,
        type: actionType
      }
      this.$emit('table-event', event)
    },

    createDetail (activity) {
      this.emitTableAction(activity.activityId, 'new-detail')
    },

    editDetail (detail) {
      this.emitTableAction(detail, 'edit-detail')
    },

    payByFromInt (val) {
      return PayBy.fromInt(val)
    },

    payOnFromInt (val) {
      return PayOn.fromInt(val)
    },

    recoveryModeString (val) {
      return this.$t('recoverAdvancesWithDetail', {
        detail: val.advanceNotes ?? RecoveryMode.fromInt(val.recoveryMode)
      })
    },

    counterpartySet (activity) {
      return activity.runtimeCounterparty === RuntimeCounterparty.Driver.value ||
        activity.contractor !== null ||
        (activity.runtimeCounterparty === RuntimeCounterparty.Landowner.value && this.tract.landownerCount > 0)
    },

    counterpartyString (activity) {
      if (activity.runtimeCounterparty === RuntimeCounterparty.Driver.value) return this.$t('ticketDriver')
      return activity.contractor?.name ?? (this.tract.landOwnerAccountName || this.$t('multiple'))
    },

    runtimeCounterparty: (val) => RuntimeCounterparty.fromInt(val)
  }

}
</script>
