<template>
  <table style="border-spacing: 5px;">
    <thead>
      <span>{{ $t('totals') }}</span>
    </thead>
    <tr>
      <td v-if="showTicketTotals && aggregateTotal.rpi !== 0">
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-2" v-on="on">mdi-looks</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('rpi') }}</span>
        </v-tooltip>
      </td>
      <td v-if="showTicketTotals && aggregateTotal.rpi !== 0" width="70px">
        <span>{{ aggregateTotal.rpi }}</span>
      </td>
    </tr>
    <tr v-if="showTicketTotals">
      <td>
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-2" v-on="on">mdi-ruler</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('mbf') }}</span>
        </v-tooltip>
      </td>
      <td width="70px" v-if="showTicketTotals">
        <span>{{aggregateTotal.mbf}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-2" v-on="on">mdi-weight</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('tons') }}</span>
        </v-tooltip>
      </td>
      <td width="70px">
        <span class="mr-2">{{aggregateTotal.weightTons}}</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AggregateTotalTable',

  props: {
    aggregateTotal: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    isByproducts: {
      type: Boolean,
      default: false
    },

    isWeightBased: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showTicketTotals () {
      return !this.isByproducts && !this.isWeightBased
    }
  }
}
</script>
