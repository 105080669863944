<template>
  <v-card>
    <v-card-title class="primary headline white--text">
      <Icon
        :icon="integrationItem.statusIcon"
        iconColor="tertiary"
        :small="false"
        :tooltipText="integrationItem.statusText"
      />
      <span class="ml-2">{{ titleText }}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col
          cols="12"
          xl="6"
          v-for="(card, i) in cardDefinitions"
          :key="`integreation-detail-card-${i}`">
            <v-card
            hover>
              <v-card-title>
                <span>{{ card.titleText }}</span>
              </v-card-title>
              <v-card-text>
                <v-row
                v-for="(row, j) in card.rowDefinitions" :key="`detail-row-${j}`"
                dense
                >
                  <v-col cols="4">
                    {{ row.label }}:
                  </v-col>
                  <v-col>
                    {{ row.value }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="detailString">
            <v-card hover>
              <v-card-title>
                <span>{{ $t('error') }}</span>
              </v-card-title>
              <v-card-text>
                <div>
                  <pre class="black--text" :style="{ fontSize: '16px', fontWeight: 'bold' }">{{detailString}}</pre>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import {
  IntegrationSourceType,
  IntegrationPolarity,
  AccountingCategory
} from '@/utils/Enumerations.js'
export default {
  name: 'IntegrationDetailDialog',

  props: {
    integrationItem: {
      type: Object,
      required: true
    },
    accountingCategory: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  computed: {
    titleText () {
      return this.$t('integrationDetails')
    },

    detailString () {
      const detail = this.integrationItem.integrationDetail || undefined
      try {
        return JSON.stringify(JSON.parse(detail), null, 2).trim()
      } catch (e) {
        if (e instanceof SyntaxError) {
          return detail || undefined
        }
        throw e
      }
    },

    cardDefinitions () {
      const paymentDetailCard = {
        titleText: this.$t('paymentDetails'),
        rowDefinitions: [
          { label: this.$t('activity'), value: this.integrationItem.activity },
          { label: this.$t('sourceType'), value: IntegrationSourceType.fromInt(this.integrationItem.sourceType) },
          { label: this.$t('debitCode'), value: this.integrationItem.debitAccountCode },
          { label: this.$t('creditCode'), value: this.integrationItem.creditAccountCode },
          { label: this.$t('amount'), value: this.getFormattedAmount(this.integrationItem) }
        ]
      }

      const integrationDetailCard = {
        titleText: this.$t('integrationDetails'),
        rowDefinitions: [
          { label: this.$t('identifier'), value: this.integrationItem.identifier },
          { label: this.$t('entityCode'), value: this.integrationItem.businessEntityCode },
          { label: this.$t('objectId'), value: this.integrationItem.integrationObjectId ?? this.$t('notAvailable') },
          { label: this.$t('periodEndDate'), value: utcToLocalDate(this.integrationItem.periodEndDate) }
        ]
      }

      switch (this.accountingCategory.value) {
        case AccountingCategory.Payable.value:
          integrationDetailCard.rowDefinitions.splice(3, 0, ...[{ label: this.$t('group'), value: this.integrationItem.groupingKey1 }])
          break
        case AccountingCategory.Receivable.value:
          paymentDetailCard.rowDefinitions.splice(3, 0, ...[
            { label: this.$t('ext1'), value: this.integrationItem.ext1 ?? this.$t('notAvailable') },
            { label: this.$t('ext2'), value: this.integrationItem.ext2 ?? this.$t('notAvailable') }
          ])
          integrationDetailCard.rowDefinitions.splice(3, 0, ...[{ label: this.$t('productCode'), value: this.integrationItem.productCode }])
          break
        case AccountingCategory.Accrual.value:
          integrationDetailCard.rowDefinitions.splice(3, 0, ...[{ label: this.$t('group'), value: this.integrationItem.groupingKey1 }])
          paymentDetailCard.rowDefinitions.splice(3, 0, ...[
            { label: this.$t('counterpartyExportCode'), value: this.integrationItem.counterpartyExportCode ? this.integrationItem.counterpartyExportCode : this.$t('notAvailable') }
          ])
          break
      }

      return [paymentDetailCard, integrationDetailCard]
    }
  },

  methods: {
    getFormattedAmount: ({ absAmount, polarity }) => formatMoney(polarity === IntegrationPolarity.Negative.value ? absAmount * -1 : absAmount)
  }
}
</script>

<style scoped>
pre {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}

code {
  font-family: "Courier New", Courier, monospace;
}
</style>
