export default {
  userSettings: {
    contractFormSettings: {
      accountAutocompleteTags: [],
      destinationAutocompleteTags: [],
      fromAccountAutocompleteTags: []
    },
    activityFormSettings: {
      accountAutocompleteTags: []
    },
    tractFormSettings: {
      accountAutocompleteTags: [],
      defaultAccountAutocomplete: [],
      landOwnerAccountAutocomplete: [],
      driverAccountAutocomplete: [],
      haulerAccountAutocomplete: [],
      supplierAccountAutocomplete: [],
      tractContractAccountAutocomplete: [],
      tractDestinationAutocomplete: [],
      consultingForesterAccountAutocomplete: []
    },
    tableSettings: {
      tractCruiseHiddenColumns: [],
      tractCruiseHiddenColumnsMobile: [],
      ticketGridHiddenColumns: [],
      ticketGridHiddenColumnsMobile: [],
      byProductTicketGridHiddenColumns: [],
      transferTicketGridHiddenColumns: [],
      logYardSaleTicketGridHiddenColumns: [],
      tractGridHiddenColumns: [],
      tractGridHiddenColumnsMobile: [],
      dashboardLogCharts: [],
      dashboardBpCharts: [],
      dashboardOperationalCharts: [],
      productCostsHiddenColumns: [],
      ticketCostsHiddenColumns: [],
      tractProductCostsHiddenColumns: [],
      logsContractGridHiddenColumns: [],
      byProductContractGridHiddenColumns: [],
      transfersContractGridHiddenColumns: [],
      contractFilterTypes: []
    },
    dashboardSettings: {
      contractMode: 0
    }
  },
  mutatedUserSettings: {
    contractFormSettings: {
      accountAutocompleteTags: [],
      destinationAutocompleteTags: [],
      fromAccountAutocompleteTags: []
    },
    activityFormSettings: {
      accountAutocompleteTags: []
    },
    tractFormSettings: {
      accountAutocompleteTags: [],
      defaultAccountAutocomplete: [],
      landOwnerAccountAutocomplete: [],
      driverAccountAutocomplete: [],
      haulerAccountAutocomplete: [],
      supplierAccountAutocomplete: [],
      tractContractAccountAutocomplete: [],
      tractDestinationAutocomplete: [],
      consultingForesterAccountAutocomplete: []
    },
    tableSettings: {
      tractCruiseHiddenColumns: [],
      tractCruiseHiddenColumnsMobile: [],
      ticketGridHiddenColumns: [],
      ticketGridHiddenColumnsMobile: [],
      byProductTicketGridHiddenColumns: [],
      transferTicketGridHiddenColumns: [],
      logYardSaleTicketGridHiddenColumns: [],
      tractGridHiddenColumns: [],
      tractGridHiddenColumnsMobile: [],
      dashboardLogCharts: [],
      dashboardBpCharts: [],
      dashboardOperationalCharts: [],
      dashboardHiddenSections: [],
      dashboardBpHiddenSections: [],
      productCostsHiddenColumns: [],
      ticketCostsHiddenColumns: [],
      tractProductCostsHiddenColumns: [],
      logsContractGridHiddenColumns: [],
      byProductContractGridHiddenColumns: [],
      transfersContractGridHiddenColumns: [],
      contractFilterTypes: []
    },
    dashboardSettings: {
      contractMode: 0
    }
  }
}
