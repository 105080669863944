import { ContractType } from '@/utils/Enumerations'
const iconDataFor = (d, settings) => ({
  icon: d?.icon ?? settings.icon,
  tooltip: d?.tooltip ?? settings.tooltip,
  testId: d?.testId ?? settings.testId,
  value: d?.value ?? settings.value
})

const logsIconSet = (testIdPrefix, overrides) => ({
  tract: iconDataFor(overrides?.tract, {
    icon: 'mdi-crosshairs-gps',
    tooltip: 'tract',
    testId: `${testIdPrefix}-name`,
    value: contract => contract.tractName || 'N/A'
  }),
  account: iconDataFor(overrides?.account, {
    icon: 'mdi-account',
    tooltip: 'account',
    testId: `${testIdPrefix}-account`,
    value: contract => contract.accountName
  }),
  destination: iconDataFor(overrides?.destination, {
    icon: 'mdi-domain',
    tooltip: 'destination',
    testId: `${testIdPrefix}-destination`,
    value: contract => contract.destinationName
  }),
  setting: iconDataFor(overrides?.setting, {
    icon: 'mdi-map-marker-circle',
    tooltip: 'setting',
    testId: `${testIdPrefix}-setting`,
    value: contract => contract.settingName || 'N/A'
  })
})

const noTractIconSetet = (testIdPrefix, overrides) => ({
  tract: iconDataFor(overrides?.tract, {
    icon: 'mdi-account-arrow-right',
    tooltip: 'fromAccount',
    testId: `${testIdPrefix}-from-account`,
    value: contract => contract.fromAccountName || 'N/A'
  }),
  account: iconDataFor(overrides?.account, {
    icon: 'mdi-account',
    tooltip: 'account',
    testId: `${testIdPrefix}-account`,
    value: contract => contract.accountName
  }),
  destination: iconDataFor(overrides?.destination, {
    icon: 'mdi-domain',
    tooltip: 'destination',
    testId: `${testIdPrefix}-destination`,
    value: contract => contract.destinationName
  })
})

export const logsIcons = (testIdPrefix, overrides = {}) => Object.values(logsIconSet(testIdPrefix, overrides ?? {}))
export const noTractIconSet = (testIdPrefix, overrides = {}) => Object.values(noTractIconSetet(testIdPrefix, overrides ?? {}))

// TODO: IH - Restore BPP Tract icon for TSBP
export const contractIconsFor = (contractType, testIdPrefix, overrides) =>
  (contractType === ContractType.ByproductSale.value ||
  contractType === ContractType.Transfer.value ||
  contractType === ContractType.ByproductPurchase.value ||
  contractType === ContractType.LogYardSale.value)
    ? noTractIconSet(testIdPrefix, overrides)
    : logsIcons(testIdPrefix, overrides)
