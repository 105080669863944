<template>
  <table style="border-spacing: 5px;">
    <tr>
      <td v-if="showTicketTotals && aggregateTotal.rpi !== 0">
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-1" v-on="on">mdi-looks</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('rpi') }}</span>
        </v-tooltip>
      </td>
      <td v-if="showTicketTotals && aggregateTotal.rpi !== 0" style="min-width: 80px;">
        <span class="mr-6">{{ aggregateTotal.rpi }}</span>
      </td>
      <td v-if="showTicketTotals && aggregateTotal.mbf !== 0">
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-1" v-on="on">mdi-ruler</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('mbf') }}</span>
        </v-tooltip>
      </td>
      <td v-if="showTicketTotals && aggregateTotal.mbf !== 0" style="min-width: 100px;" class="mr-6">
        <span class="mr-6">{{aggregateTotal.mbf}}</span>
      </td>
      <td>
        <v-tooltip bottom color="black">
          <template #activator="{on}">
            <v-icon color="black" class="mr-1" v-on="on">mdi-weight</v-icon>
          </template>
          <span class="subtitle-1 white--text">{{ $t('tons') }}</span>
        </v-tooltip>
      </td>
      <td class="mr-6" style="min-width: 100px;">
        <span>{{aggregateTotal.weightTons}}</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AggregateTotalTable',

  props: {
    aggregateTotal: {
      type: Object,
      required: true
    },

    isActive: {
      type: Boolean,
      default: false
    },

    isByproducts: {
      type: Boolean,
      default: false
    },

    isWeightBased: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    showTicketTotals () {
      return !this.isByproducts && !this.isWeightBased
    }
  }
}
</script>
