<template>
  <v-menu
    ref="timePicker"
    v-model="timePickerMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-row dense>
        <v-col>
          <Icon
          v-if="!expanded"
          class="mt-5"
          margin="mt-5"
          :small="false"
          iconColor="primary"
          :icon="isEditing ? 'mdi-clock-edit' : 'mdi-clock-plus'"
          :tooltipText="$t(isEditing ? 'editTime' : 'addTime')"
          @icon-clicked="showPicker()"/>
        </v-col>
      </v-row>
      <v-text-field
        v-show="expanded"
        ref="field"
        v-model="formattedTime"
        :label="label"
        :disabled="disabled"
        persistent-hint
        :hint="hint"
        color="black"
        :rules="[rules.required, rules.validTime]"
        @input="setTime()"
      >
        <template #append>
          <v-icon v-on="on" @click="setTime()" color="primary" tabindex="-1" :disabled="disabled">mdi-clock</v-icon>
        </template>
        <template #append-outer>
          <slot name="append-outer"/>
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      @change="() => {
        timePickerMenu = false
        }"
      @input="setFormattedTime"
      :value="time"
      color="primary"
      scrollable
    ></v-time-picker>
  </v-menu>
</template>

<script>
import fieldRules from '@/utils/rules.js'

export default {
  name: 'TimePicker',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    label: String,
    disabled: Boolean,
    hint: {
      type: String,
      default: ''
    },
    startTime: { type: String, default: '0:00' },
    expandable: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  watch: {
    time (val) {
      this.$emit('time-picked', (val))
    },

    focus () {
      this.$refs.field.focus()
    }
  },

  data: () => ({
    showTimePicker: false,
    timePickerMenu: false,
    time: '08:00',
    formattedTime: '',
    rules: fieldRules.rules
  }),

  created () {
    this.time = this.startTime
    const timeComponents = this.time.split(':')
    let hours = parseInt(timeComponents[0])
    let minutes = parseInt(timeComponents[1])
    const meridian = hours >= 12 ? 'PM' : 'AM'
    if (hours === 0) {
      hours = 12
    } else if (hours > 12) {
      hours = hours % 12
    }
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    this.formattedTime = `${hours}:${minutes} ${meridian}`
    this.setTime()
  },

  computed: {
    expanded () {
      return this.expandable ? this.showTimePicker : true
    }
  },

  methods: {
    focusField () {
      this.$refs.field.focus()
    },
    setTime () {
      if (this.rules.validTime(this.formattedTime) !== 'Invalid time.') {
        const timeComponents = this.formattedTime.split(' ')
        const hourMinuteComponents = timeComponents[0].split(':')
        let hours = parseInt(hourMinuteComponents[0])
        const minutes = parseInt(hourMinuteComponents[1])
        const meridian = timeComponents[1]
        if (hours === 12) {
          if (meridian === 'AM') {
            hours = 0
          } else {
            hours = 12
          }
        } else if (meridian === 'PM' && hours !== 12) {
          hours += 12
        }
        this.time = `${hours}:${minutes}`
        this.$emit('valid-time', true)
      } else {
        this.$emit('valid-time', false)
      }
    },
    setFormattedTime (time) {
      this.time = time
      const timeComponents = this.time.split(':')
      let hours = parseInt(timeComponents[0])
      let minutes = parseInt(timeComponents[1])
      const meridian = hours >= 12 ? 'PM' : 'AM'
      if (hours === 0) {
        hours = 12
      } else if (hours > 12) {
        hours = hours % 12
      }
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      this.formattedTime = `${hours}:${minutes} ${meridian}`
    },
    showPicker () {
      this.showTimePicker = true
    }
  }
}
</script>

<style scoped>
  .label {
    font-size: .8em;
    color: rgba(193, 66, 66, 0);
  }
</style>
