<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text mr-2">
        {{ title }}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh @close="$emit('close')"/>
    </v-card-title>
    <v-card-text>
      <RateVerificationSBC
      v-if="isSBC"
      :singleContract="!!contractId"
      :contracts="contracts"
      @refresh-batch="$emit('refresh-batch')"/>
      <RateVerificationSBP
      v-else
      :singlePayee="!!payeeId"
      :payees="payees"
      @refresh-batch="$emit('refresh-batch')"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RateVerificationDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    RateVerificationSBC: () => import('./RateVerificationSBC.vue'),
    RateVerificationSBP: () => import('./RateVerificationSBP.vue')
  },

  props: {
    payeeId: {
      type: Number,
      required: false,
      default: undefined
    },
    contractId: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    rateObjects: [],
    expanded: [],
    contracts: undefined,
    payees: undefined
  }),

  watch: {
    currentSubModule () {
      this.refreshData()
    }
  },

  computed: {
    ...mapGetters('settlements', ['currentSubModuleKey', 'currentSubModule', 'dateConfiguration']),
    title () {
      return this.$t('rateVerificationSubtitle', { exportBatch: this.dateConfiguration.exportBatch.label, subModule: this.$t(this.currentSubModuleKey) })
    },

    isSBC () {
      return this.currentSubModuleKey === 'settlementsByContract'
    }
  },

  created () {
    this.refreshData()
  },

  methods: {
    refreshData () {
      if (this.currentSubModuleKey === 'settlementsByContract') {
        this.contracts = this.contractId
          ? [this.currentSubModule.settlements.find(s => s.contractInfo.contractId === this.contractId)]
          : this.currentSubModule.settlements
      } else {
        this.payees = this.payeeId
          ? [this.currentSubModule.payees.find(p => p.payeeId === this.payeeId)]
          : this.currentSubModule.payees
      }
    }
  }
}
</script>
