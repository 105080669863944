<template>
  <v-data-table
    :items="products"
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    dense
  >
    <template #item.productionCost="{ item }">
      {{ formatMoney(item.productionCost) }}
    </template>
    <template #item.stumpageCost="{ item }">
      {{ formatMoney(item.stumpageCost) }}
    </template>
    <template #item.totalCost="{ item }">
      {{ formatMoney(item.totalCost) }}
    </template>
    <template #item.costPerTon="{ item }">
      {{ useMetricTons ? formatMoney(item.costPerMetricTon) : formatMoney(item.costPerTon) }}
    </template>
    <template #item.totalRevenue="{ item }">
      {{ formatMoney(item.totalRevenue) }}
    </template>
    <template #item.netTons="{ item }">
      {{ useMetricTons ? item.netMetricTons.toFixed(3) : item.netTons.toFixed(3) }}
    </template>
    <template #item.revenuePerTon="{ item }">
      {{ useMetricTons ? formatMoney(item.revenuePerMetricTon) : formatMoney(item.revenuePerTon) }}
    </template>
    <template #item.grossProfit="{ item }">
      {{ formatMoney(item.grossProfit) }}
      ({{ (item.grossProfitPercentage * 100).toFixed(2) }}%)
    </template>
    <template #item.grossProfitPerTon="{ item }">
      {{ useMetricTons ? formatMoney(item.grossProfitPerMetricTon) : formatMoney(item.grossProfitPerTon) }}
    </template>
    <template #body.append>
      <tr class="font-weight-bold">
        <td class="text-lg-left">{{ totalProductText }}</td>
        <td class="text-right">{{ totals.loads.toFixed(0) }}</td>
        <td class="text-right">{{ useMetricTons ? totals.netMetricTons.toFixed(3) : totals.netTons.toFixed(3) }}</td>
        <td class="text-right">{{ formatMoney(totals.totalRevenue) }}</td>
        <td class="text-right">{{ useMetricTons ? formatMoney(totals.revenuePerMetricTon) : formatMoney(totals.revenuePerTon) }}</td>
        <td class="text-right">{{ formatMoney(totals.productionCost) }}</td>
        <td class="text-right" v-if="!deck">{{ formatMoney(totals.stumpageCost) }}</td>
        <td class="text-right">{{ formatMoney(totals.totalCost) }}</td>
        <td class="text-right">{{ useMetricTons ? formatMoney(totals.costPerMetricTon) : formatMoney(totals.costPerTon) }}</td>
        <td class="text-right">
          {{ formatMoney(totals.grossProfit) }}
          ({{ (totals.grossProfitPercentage * 100).toFixed(2) }}%)
        </td>
        <td class="text-right">{{ useMetricTons ? formatMoney(totals.grossProfitPerMetricTon) : formatMoney(totals.grossProfitPerTon) }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { productSalesAnalysisHeaders } from '@/headers/Cycles.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { mapGetters } from 'vuex'
export default {
  name: 'SalesAnalysisTable',

  props: {
    tract: {
      type: Object,
      required: false,
      default: null
    },
    deck: {
      type: Object,
      required: false,
      default: null
    }
  },

  computed: {
    ...mapGetters('settlements', ['useMetricTons']),
    headers () {
      return productSalesAnalysisHeaders(this.deck !== null)
    },

    hasTract () {
      return this.tract !== null
    },

    products () {
      return this.hasTract ? this.tract.products : this.deck.products
    },

    totals () {
      const totals = this.products.reduce(
        (totals, product) => {
          totals.loads += product.loads
          totals.netTons += product.netTons
          totals.netMetricTons += product.netMetricTons
          totals.productionCost += product.productionCost
          totals.stumpageCost += product.stumpageCost
          totals.totalCost += product.totalCost
          totals.totalRevenue += product.totalRevenue
          totals.grossProfit += product.grossProfit
          return totals
        },
        {
          loads: 0,
          netTons: 0,
          productionCost: 0,
          stumpageCost: 0,
          totalCost: 0,
          totalRevenue: 0,
          grossProfit: 0,
          netMetricTons: 0
        }
      )

      totals.costPerTon = totals.totalCost / totals.netTons
      totals.costPerMetricTon = totals.totalCost / totals.netMetricTons
      totals.revenuePerTon = totals.totalRevenue / totals.netTons
      totals.revenuePerMetricTon = totals.totalRevenue / totals.netMetricTons
      totals.grossProfitPerTon = totals.grossProfit / totals.netTons
      totals.grossProfitPerMetricTon = totals.grossProfit / totals.netMetricTons
      totals.products = this.products.length
      totals.grossProfitPercentage = totals.totalRevenue > 0 ? totals.grossProfit / totals.totalRevenue : 0
      return totals
    },

    totalProductText () {
      return this.totals.products > 1
        ? this.$t('productsLabelPlural', { productCount: this.totals.products })
        : this.$t('productsLabelSingle')
    }
  },
  methods: {
    formatMoney
  }
}
</script>
