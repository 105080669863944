<template>
  <v-card>
    <v-card-title class="primary white--text">
      <span v-if="ticket !== undefined">{{ $t(isConsumption ? 'unconsumeTicketWithNumber' : 'unpostTicketWithNumber', { ticketNumber: ticket.ticketNumber }) }}</span>
      <v-spacer/>
      <BaseDialogActions
      @close="$emit('close')"
      hideRefresh></BaseDialogActions>
    </v-card-title>
    <v-card-text>
      <TicketInformation
      v-if="ticket"
      :ticket="ticket"
      :shouldFetchImages="false"
      v-on:logCounts="false"/>
      <v-container fluid v-if="ticketLoader.loading">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <v-progress-circular indeterminate color="primary"/>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn class="primary" @click="confirm">
        {{ confirmText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { ResourceLoader } from '@/utils/ResourceLoader.js'
export default {
  name: 'TicketConfirmation',

  props: {
    propTicket: {
      type: Object,
      default: undefined
    },
    isConsumption: {
      type: Boolean,
      default: false
    },
    ticketId: {
      type: Number,
      default: undefined
    }
  },

  components: {
    TicketInformation: () => import('@/components/ticket/ticket-details/TicketInformation'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  data: () => ({
    ticketLoader: new ResourceLoader(),
    ticket: undefined
  }),

  computed: {
    confirmText () {
      return this.isConsumption ? this.$t('revertTicket') : this.$t('unpostTicket')
    }
  },

  created () {
    if (this.propTicket !== undefined) {
      this.ticket = this.propTicket
    } else if (this.ticketId !== undefined) {
      this.ticketLoader = new ResourceLoader(async () => {
        const ticket = await this.fetchTicketById(this.ticketId)
        this.ticket = ticket
      })
    }
    this.ticketLoader.load()
  },

  methods: {
    ...mapActions('consumption', ['revertTickets']),
    ...mapActions('posting', ['unpostTickets']),
    ...mapActions('ticket', ['fetchTicketById']),

    confirm () {
      if (this.isConsumption) {
        this.confirmRevertTicket()
      } else {
        this.confirmUnpostTicket()
      }
    },

    async confirmRevertTicket () {
      const { consumptionBatchId, ticketId } = this.ticket
      const unpostObj = {
        consumptionBatchId: consumptionBatchId,
        ticketIds: [ticketId]
      }

      const results = await this.revertTickets(unpostObj)

      if (results) {
        this.setSnack(this.$t('ticketReverted'))
      }

      this.$emit('close', true)
    },

    async confirmUnpostTicket () {
      const { postBatchId, ticketId } = this.ticket
      const unpostObj = {
        postBatchId: postBatchId,
        ticketIds: [ticketId]
      }

      const results = await this.unpostTickets(unpostObj)

      if (results.successes > 0) {
        this.setSnack(this.$t('ticketUnposted'))
      }

      this.$emit('close', true)
    }
  }
}
</script>
