<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{ this.$t('importUsers') }}</span>
      <v-spacer/>
      <Icon
      icon="mdi-close"
      iconColor="white"
      :small="false"
      large
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"/>
    </v-card-title>
    <v-card-text>
      <UserImports
      @usersImported="$emit('usersImported')"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ImportUsers',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    UserImports: () => import('@/components/settings-components/imports/UserImports.vue')
  }
}
</script>
