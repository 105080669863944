<template>
  <v-container fluid grid-list-xl>
    <v-row dense class="mb-n10">
      <PayPeriodSelector
        selectPeriod
        entireFiscalYearSelectable
        @period-selected="periodSelected"/>
      <v-spacer/>
      <v-select
      v-model="selectedTractType"
      :items="availableTractTypes"
      item-text="tractTypeName"
      return-object/>
    </v-row>
    <v-row v-if="balanceSummaries.length === 0">
      <v-col>
        <NoData class="mt-4">
          {{ $t('noDataForSelection') }}
        </NoData>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-expansion-panels popout v-model="openPanels" accordion tile multiple>
          <v-expansion-panel v-for="(key) in Object.keys(groupedDepletionSummaries)" :key="key">
            <v-expansion-panel-header>
              <v-row>
                <v-col>
                  {{ $t('currentTractStatus') }}: {{ TractStatus.find(ts => ts.value === Number(key)).name }}
                </v-col>
                <v-col align="right">
                  {{ remainingDepletionTotal(groupedDepletionSummaries[key]) }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <DataTable
              :items="groupedDepletionSummaries[key]"
              :headers="headers"
              :showSearch="false"
              :showDense="false"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { TractStatus } from '@/utils/Enumerations.js'
import { mapActions } from 'vuex'
import TractHeaders from '@/headers/Tract.js'
export default {
  name: 'Depletion',

  components: {
    PayPeriodSelector: () => import('@/components/settlements/PayPeriodSelector.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  data: () => ({
    TractStatus,
    selectedPeriod: undefined,
    balanceSummaries: [],
    openPanels: [0],
    selectedTractType: undefined
  }),

  computed: {
    groupedDepletionSummaries () {
      const filteredSummaries = this.selectedTractType?.tractTypeId !== undefined
        ? this.balanceSummaries.filter(s => s.tractTypeId === this.selectedTractType?.tractTypeId)
        : this.balanceSummaries
      return Object.groupBy(filteredSummaries, s => s.tractStatus)
    },

    headers () {
      return TractHeaders.liveDepletionHeaders({ isPeriod: this.isPeriod }).columns
    },

    isPeriod () {
      return this.selectedPeriod?.payPeriodId !== undefined
    },

    availableTractTypes () {
      const uniqueTypes = this.balanceSummaries.filter((s, i) => this.balanceSummaries.findIndex(sr => s.tractTypeId === sr.tractTypeId) === i) // Take unique
        .map(s => ({
          tractTypeId: s.tractTypeId,
          tractTypeName: s.tractTypeName
        }))

      uniqueTypes.unshift({
        tractTypeName: this.$t('allTractTypes')
      })

      return uniqueTypes
    }
  },

  created () {
    this.selectedTractType = this.availableTractTypes[0]
  },

  methods: {
    formatMoney,
    utcToLocalDate,
    ...mapActions('tract', ['fetchDepletion']),
    async periodSelected (p) {
      this.selectedPeriod = p
      this.balanceSummaries = await this.fetchDepletion(p)
    },

    remainingDepletionTotal (items) {
      const unformattedAmount = items.reduce((remaining, curr) => remaining + curr.remaining, 0)
      const amount = formatMoney(unformattedAmount)
      return `${this.$t('remainingBalance')}: ${amount}`
    }
  }
}
</script>
