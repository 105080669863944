export const isMobile = () => {
  /*
  Use this function with discretion.
  It tries to guess whether the user's device is mobile based on a combination of screen size and touch screen presence.
  Use vuetify breakpoints/screen size detection or simple touch screen detection instead where applicable.
  */
  const portrait = screen.orientation.type === 'portrait-primary' || screen.orientation.type === 'portrait-secondary'
  const height = portrait ? window.screen.width : window.screen.height
  const width = portrait ? window.screen.height : window.screen.width

  if (width < MOBILE_BREAKPOINT || (width < 1366 && height < 768)) {
    return true
  } else if (width >= 1920 && height >= 1080) {
    return false
  } else {
    return navigator.maxTouchPoints > 0 || 'ontouchstart' in window
  }
}

export const hasTouchScreen = () => {
  return navigator.maxTouchPoints > 0 || 'ontouchstart' in window
}

export const MOBILE_BREAKPOINT = 991

export const mobileCompatible = () => {
  /* This function determines whether to use "mobile compatible" versions of the UI,
  like hiding the navigation drawer and Tract Detail dialog tabs */
  return window.innerWidth < MOBILE_BREAKPOINT
}
