<template>
  <v-row>
    <v-col cols="12">
      <BasicFinancialTable
      :entries="advancePayables"
      @aggregate-selected="advancePaymentSelected"/>
    </v-col>
    <v-dialog v-model="advancePayableDialogOpen" width="1200px">
      <v-card v-if="focusedAdvancePayable">
        <v-card-title class="headline py-4 primary white--text">
          <v-row>
            <v-col cols="10">
              <span style="overflow-wrap: anywhere; word-wrap: break-word; word-break: normal;">
                {{ $t('advancesByEntityAndActivity', {entity: focusedAdvancePayable.businessEntity, activity: focusedAdvancePayable.activity}) }}
              </span>
            </v-col>
            <v-spacer/>
            <v-col align="right">
              <Icon
              icon="mdi-close"
              icon-color="white"
              large
              :small="false"
              :tooltipText="$t('close')"
              @icon-clicked="closeDialog()"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
              :items="focusedAdvancePayable.advances"
              :headers="breakdownHeaders">
              <template #item.amount="{item}">
                {{ formatMoney(item.amount) }}
              </template>
              <template #item.balance="{item}">
                {{ formatMoney(item.balance) }}
              </template>
              <template #item.effective="{item}">
                {{ utcToLocalDate(item.effective, 'L - LT') }}
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { formatMoney } from '@/utils/NumericMutations'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'AdvancePayablesAggregations',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BasicFinancialTable: () => import('@/components/accounting/adjusting-entries/v1/BasicFinancialTable.vue')
  },

  props: {
    advancePayables: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    focusedAdvancePayable: undefined,
    advancePayableDialogOpen: false
  }),

  computed: {
    aggregateHeaders () {
      return AdjustingEntriesHeaders.miscPayments()
    },

    breakdownHeaders () {
      return AdjustingEntriesHeaders.advancePayableBreakdown()
    }
  },

  methods: {
    utcToLocalDate,
    formatMoney,
    advancePaymentSelected (advancePayment) {
      this.focusedAdvancePayable = advancePayment
      this.advancePayableDialogOpen = true
    },

    closeDialog () {
      this.advancePayableDialogOpen = false
      this.focusedAdvancePayable = undefined
    }
  }
}
</script>
