<template>
  <Dialog
  :stateId="dialogId"
  max-width="1500px"
  @click:outside="closeDialog">
    <v-card>
      <v-card-title :class="headerStyle">
        <Icon
          v-if="isExported"
          icon="mdi-checkbox-marked-circle"
          iconColor="tertiary"
          :tooltipText="$t('exported')"
          :small="false"
        />
        <span class="headline">
          {{titleText}}
        </span>
        <v-spacer/>
        <BaseDialogActions
        @close="closeDialog"
        hideRefresh/>
      </v-card-title>
      <v-card-text>
        <CorrectionDetail
        v-if="correctionDetailDialog"
        :correction="correction"
        v-bind="{ contractMode }"/>
      </v-card-text>
    </v-card>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { ContractMode, CorrectionStatus } from '@/utils/Enumerations'
import { uniqueDialogId, colorClassForContractType } from '@/utils/componentHelpers'
export default {
  name: 'DetailedCorrectionDialog',
  props: {
    propCorrection: {
      type: Object,
      required: false
    },
    correctionId: {
      type: Number,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    CorrectionDetail: () => import('@/components/ticket/corrections/CorrectionDetail.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    ContractMode,
    correctionDetailDialog: false,
    correction: undefined,
    dialogId: uniqueDialogId('correctionDetails')
  }),

  computed: {
    titleText () {
      return this.correction
        ? this.$t('correctionForTicket', { ticketNumber: this.correction.correction.ticket.ticketNumber })
        : ''
    },

    isExported () {
      return this.correction?.correction?.status === CorrectionStatus.Exported.value
    },

    headerStyle () {
      return colorClassForContractType(this.correction?.correction?.contract?.type)
    }
  },

  watch: {
    correctionDetailDialog (val) {
      if (!val) this.closeDialog()
    }
  },

  created () {
    this.getData(this.correctionId, this.ticketId)
  },

  methods: {
    ...mapActions('ticket', ['getCorrectionsForTicket', 'deleteTicketCorrection', 'fetchCorrectionById']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    async getData (correctionId, ticketId) {
      let correction = this.propCorrection
      if (!correction) {
        const corrections = await this.getCorrectionsForTicket(ticketId)
        correction = corrections[0]
      }

      const detailedCorrection = await this.fetchCorrectionById({ ticketId: ticketId, correctionId: correctionId })
      correction.correction = detailedCorrection
      this.correction = correction
      this.correctionDetailDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '90wv' })
    },

    closeDialog () {
      this.correctionDetailDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)
      this.$emit('close')
    }
  }
}
</script>
