<template>
  <v-card>
    <v-card-title class="primary white--text">
      {{ $t('selectBusiness') }}
    </v-card-title>
    <v-card-text class="mt-4">
      <v-select
      v-model="selectedTenant"
      :items="tenants"
      :label="$t('business')"
      :menu-props="{ bottom: true, offsetY: true }"
      ref="tenantSelect"
      item-text="name"
      return-object
      />
      <span>{{ $t('selectApplicableBusiness') }}</span>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
      :disabled="saveButtonDisabled"
      class="primary"
      @click="saveChanges"
      >
        {{ $t('setBusiness') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MultiTenantSelection',

  props: {
    configuration: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    selectedTenant: null
  }),

  computed: {
    tenants () {
      return this.configuration.filteredTenants
    },

    saveButtonDisabled () {
      return this.selectedTenant === null
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.$refs.tenantSelect.focus()
    })
  },

  methods: {
    close () {
      this.$emit('close')
    },

    saveChanges () {
      this.$emit('set-tenant', this.selectedTenant)
    }
  }
}
</script>
