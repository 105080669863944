<template>
  <v-card>
    <v-card-title class="primary white--text">
      <span>{{title}}</span>
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      :buttonText="$t('saveChanges')"
      @submit="saveChangesPressed"
      :disabled="saveBtnDisabled"
      >
        <template v-slot:right-action>
          <v-checkbox
            v-if="!isEditing"
            color="primary"
            v-model="createContractOnSave"
            :label="$t('createContractOnSave')"
            class="mr-2"
          />
        </template>
        <v-container fluid>
          <v-row dense>
            <v-col>
              <AccountAutocomplete
              data-testid="tract-destination-account"
              :accountId="tractDestination.destinationAccountId"
              :title="$t('destinationAccount')"
              :disabled="isEditing"
              :prohibitedSet="destinationAccountsIdsSet"
              clearable
              showCertified
              userSetting="tractDestinationAutocomplete"
              @account-chosen="destinationAccountChosen"
              :fetchTagsAndAccounts="false"/>
            </v-col>
            <v-col cols="auto">
              <v-text-field
              data-testid="tract-destination-distance"
              v-model="tractDestination.distance"
              :label="$t('distance')"
              color="black"
              :rules="[rules.integer, rules.validDistance]"
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="10">
              <!-- TODO: IH - show byproducts in tract destination setup with TSBP -->
              <ProductSelector
              :includeByproducts="false/**includeByproducts || tractDestination.products.some(p => p.isByproduct)**/"
              :propProducts="tractDestination.products"
              :cruiseProductIds="cruiseProductIds"
              @products-selected="productsSelected"/>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                data-testid="contract-requires-ext-ticket-2"
                v-model="tractDestination.requiresExt2"
                color="primary"
                :label="$t('requiresExt2')"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
    <Dialog
      :stateId="dialogId"
      hideRefresh>
      <ConfirmDialog
        :title="$t('confirmDistanceUpdate')"
        :body="$t('thisWillUpdateDistanceOnContracts')"
        :confirmText="$t('confirm')"
        @cancel="closeDialog"
        @confirm="saveChanges"
      />
    </Dialog>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'
import { CookieKeys } from '@/utils/constants.js'
import { TractTypeCategory } from '@/utils/Enumerations'
import { uniqueDialogId } from '@/utils/componentHelpers'

export default {
  name: 'TractDestinationForm',

  components: {
    ProductSelector: () => import('@/components/tract/tract-form/ProductSelector.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  props: {
    propTractDestination: {
      type: Object
    },
    tract: {
      type: Object,
      required: true
    },
    allTractDestinations: {
      type: Array
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    selectedTags: [],
    tags: [],
    accounts: [],
    showMenu: false,
    selectedAccount: null,
    createContractOnSave: (Cookies.get(CookieKeys.TRACT_DESTINATION_TO_CONTRACT))
      ? Cookies.get(CookieKeys.TRACT_DESTINATION_TO_CONTRACT) === 'true'
      : false,
    tractDestination: {
      requiresExt2: false,
      distance: 0,
      products: []
    },
    dialogId: uniqueDialogId('tract-destination-confirm-dialog')
  }),

  computed: {
    isEditing () {
      return !(this.propTractDestination === null || this.propTractDestination === undefined)
    },

    title () {
      return this.isEditing ? this.propTractDestination.destinationAccountName : this.$t('newTractDestination')
    },

    saveBtnDisabled () {
      if (this.selectedAccount === null) return true
      if (this.tractDestination.distance === '') return true
      const castedDistance = Number(this.tractDestination.distance)
      if (isNaN(castedDistance) || castedDistance < 0 || castedDistance > 250) return true
      if (!(this.tractDestination.products.length > 0)) return true

      return false
    },

    cruiseProductIds () {
      if (this.tract.type.category === 1) {
        return null
      } else {
        return this.tract.tractContents.map(tc => tc.productId)
      }
    },

    includeByproducts () {
      return this.selectedAccount !== null &&
        !this.selectedAccount.isExternal &&
        this.tract.type.category === TractTypeCategory.Delivered.value
    },
    destinationAccountsIdsSet () {
      const accountIds = new Set()
      this.allTractDestinations.forEach(tractD => {
        accountIds.add(tractD.destinationAccountId)
      })
      return accountIds
    }
  },

  watch: {
    showMenu (val) {
      if (!val) {
        this.getAccounts(false)
      }
    },

    createContractOnSave (create) {
      Cookies.set(CookieKeys.TRACT_DESTINATION_TO_CONTRACT, `${create}`)
    }
  },

  created () {
    if (this.propTractDestination) {
      this.tractDestination = JSON.parse(JSON.stringify(this.propTractDestination))
    } else {
      this.tractDestination.requiresExt2 = this.tract?.requiresExt2 ?? false
    }
  },

  methods: {
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tract-destination', ['postTractDestination', 'updateTractDestination']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    productsSelected (products) {
      this.tractDestination.products = products
    },

    destinationAccountChosen (account) {
      this.selectedAccount = account
    },

    close () {
      this.$emit('close')
    },

    saveChangesPressed () {
      if (this.propTractDestination &&
      Number(this.propTractDestination.distance) !== Number(this.tractDestination.distance) &&
      this.propTractDestination.contractCount > 0) {
        this.openOrUpdateDialog({ id: this.dialogId, width: '400px' })
      } else {
        this.saveChanges()
      }
    },

    closeDialog () {
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async saveChanges () {
      const requestObj = {
        tractDestinationId: this.tractDestination.tractDestinationId,
        destinationAccountId: this.selectedAccount.accountId,
        distance: Number(this.tractDestination.distance),
        requiresExt2: this.tractDestination.requiresExt2,
        productIds: this.tractDestination.products.map(p => p.productId),
        tractId: this.tract.tractId
      }

      const targetTD = (this.isEditing)
        ? await this.updateTractDestination(requestObj)
        : await this.postTractDestination(requestObj)

      if (!this.isEditing && this.createContractOnSave) {
        this.$emit('create-contract', targetTD)
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
