<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    right
  >
    <template #activator="{ on: menu, attrs }">
      <v-tooltip bottom :color="tooltipColor || iconColor">
        <template #activator="{ on: tooltip }">
          <v-icon
            v-on="{...tooltip, ...menu}"
            v-bind="attrs"
            :x-large="xLarge"
            :color="iconColor"
            data-testid="tract-filter-btn"
            class="mr-2"
          >
            mdi-filter
          </v-icon>
        </template>
        <span class="subtitle-1 white--text">
          {{$t('filter')}}
        </span>
      </v-tooltip>
    </template>
    <v-card width="600px">
      <v-card-title class="primary white--text">
        {{$t('tractFilter')}}
      </v-card-title>
      <v-card-text>
        <v-container fluid :style="this.containerStyle">
          <v-row dense>
            <v-col v-for="ts in tractStatuses" :key="`status-${ts.name}`" cols="6" xs="6" sm="4" class="ma-0 pa-0">
              <v-checkbox :label="ts.name" v-model="filterBuilder.status" :value="ts.value"/>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="10">
              <v-select
              :items="allForesters"
              :label="$t('forester')"
              v-model="filterBuilder.selectedForesters"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="f => f.name">
              <template #prepend-inner>
                <v-icon>mdi-forest</v-icon>
              </template>
              </v-select>
            </v-col>
            <v-col cols="10">
              <v-select
              :items="allTractTypes"
              :label="$t('tractType')"
              v-model="filterBuilder.selectedTractTypes"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="tt => tt.name">
              <template #prepend-inner>
                <v-icon>mdi-target-variant</v-icon>
              </template>
              </v-select>
            </v-col>
            <v-col cols="10">
              <v-select
              :items="allTeams"
              :label="$t('team')"
              v-model="filterBuilder.selectedTeams"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="t => t.name">
              <template #prepend-inner>
                <v-icon>mdi-account-group</v-icon>
              </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4 mt-n4">
        <v-row>
          <v-col>
            <v-btn color="primary" outlined @click="resetFilter">
              {{$t('reset')}}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
            color="primary"
            class="text--white"
            data-testid="tract-filter-apply-btn"
            :disabled="!changed"
            @click="emitFilter">
              {{$t('apply')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TractStatus } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '../../utils/constants'
import { getMenuContainerStyle } from '../../utils/componentHelpers'

export default {
  name: 'TractFilter',

  props: {
    filter: {
      type: Object,
      required: true
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    iconColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    tooltipColor: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    menuOpen: false,
    allForesters: [],
    filterBuilder: {
      status: [3], // Active status has value 3.
      selectedForesters: [],
      selectedTractTypes: [],
      selectedTeams: [],
      myTracts: false
    },
    containerStyle: undefined,
    changed: false,
    dataFetched: false
  }),

  watch: {
    filter: {
      handler (newFilter) {
        this.filterBuilder = JSON.parse(JSON.stringify(newFilter))
      },
      deep: true
    },

    menuOpen (isOpen) {
      if (isOpen) {
        this.changed = false
      } else {
        this.filterBuilder = JSON.parse(JSON.stringify(this.filter))
      }

      if (!this.dataFetched) {
        this.dataFetched = true
        this.initializeFilter()
      }
    },

    filterBuilder: {
      handler () {
        this.changed = true
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('tract-type', ['allTractTypes']),
    ...mapGetters('team', ['allTeams']),

    tractStatuses () {
      return TractStatus
    }
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.setContainerStyle)
  },

  methods: {
    ...mapActions('user', ['getAllForesterUsers']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('team', ['fetchTeams']),

    async initializeFilter () {
      const [foresterResponse] = await Promise.all([
        this.getAllForesterUsers(),
        this.fetchTractTypes(),
        this.fetchTeams()
      ])

      this.allForesters = foresterResponse
      this.filterBuilder = JSON.parse(JSON.stringify(this.filter))

      window.addEventListener('resize', this.setContainerStyle)
    },

    resetFilter () {
      this.filterBuilder = {
        status: [3],
        selectedForesters: [],
        selectedTractTypes: [],
        selectedTeams: [],
        myTracts: false
      }
      this.emitFilter()
    },

    emitFilter () {
      localStorage.setItem(LocalStorageKeys.TRACT_STATUS_FILTER, this.filterBuilder.status)
      this.$emit('filter-applied', this.filterBuilder)
      this.menuOpen = false
    },

    setContainerStyle () {
      return getMenuContainerStyle(window.innerWidth, window.innerHeight)
    }
  }
}
</script>
