<template>
  <v-card min-height="800px">
    <v-card-title class="headline py-4 primary white--text">
      <span>{{ $t("receivablesFor", { account: accountName }) }}</span>
      <v-spacer/>
      <span class="mr-2" >{{$t("accountTotal", { amount: totalReceivable })}} </span>
      <div v-if="isTDialog">
         <BaseDialogActions hideRefresh />
      </div>
      <Icon
      v-else
      iconColor="white"
      icon="mdi-close"
      dataTestId="receivables-by-tract-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <p class="headline mt-2" v-if="noReceivables">
        {{$t('noTractReceivables')}}
      </p>
      <v-expansion-panels popout v-model="openPanel" accordion class="mt-10" tile v-else>
        <v-expansion-panel
        v-for="(tract, index) in tractReceivables"
        :key="index">
          <v-expansion-panel-header :ripple="true">
            <v-layout>
              {{$t("tractNamePlaceholder", { tractName: tract.tractName })}}
              <v-spacer/>
            </v-layout>
            <template #actions>
              <v-container>
                <v-row justify="end">
                  {{tract.totalJournalEntry}}
                </v-row>
                <v-row justify="end">
                  {{tract.totalNetWeight}}
                </v-row>
                <v-row justify="end">
                  {{tract.loadCount}}
                </v-row>
              </v-container>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="lg_expansion-panel_wrapper">
              <ReceivableTable
              :hideFooter="true"
              :dense="true"
              :receivables.sync="tract.receivables"
              @receivable-detail="viewReceivableDetail"/>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-dialog v-model="viewingDetail" width="60%">
      <ReceivableDetail
      @close="viewingDetail = false"
      v-if="viewingDetail"
      :receivable="focusedReceivable"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { formatMoney, getFormattedTons } from '@/utils/NumericMutations'
import { mapActions } from 'vuex'

export default {
  name: 'ReceivablesByTract',

  props: {
    accountReceivable: Object,
    account: {
      type: Object,
      default: undefined
    },
    isTDialog: Boolean
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    ReceivableTable: () => import('./ReceivableTable.vue'),
    ReceivableDetail: () => import('./ReceivableDetail.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    totalReceivable () {
      return formatMoney(this.receivables.reduce((a, b) => a + b.amount, 0))
    },

    accountName () {
      if (this.account) {
        return this.account.name
      } else {
        return this.accountReceivable.accountName
      }
    }
  },

  async created () {
    if (this.account) {
      const receivables = await this.getReceivablesForAccount(this.account.accountId)
      this.receivables = receivables
      this.noReceivables = receivables.length === 0
      this.createTractReceivables(receivables)
    } else {
      this.createTractReceivables(this.accountReceivable.receivables)
    }
  },

  data: () => ({
    amountTotal: 0,
    openPanel: -1,
    tractReceivables: [],
    receivables: [],
    noReceivables: false,
    viewingDetail: false,
    focusedReceivable: undefined
  }),

  methods: {
    ...mapActions('receivable', ['getReceivablesForAccount']),
    formatMoney,
    getFormattedTons,

    viewReceivableDetail (receivable) {
      this.focusedReceivable = receivable
      this.viewingDetail = true
    },

    createTractReceivables (receivables) {
      this.receivables = receivables
      const tractReceivables = {}

      receivables.forEach(receivable => {
        if (tractReceivables[receivable.tractName]) {
          tractReceivables[receivable.tractName].receivables.push(receivable)
        } else {
          tractReceivables[receivable.tractName] = {
            tractName: receivable.tractName,
            receivables: [receivable]
          }
        }
      })

      this.tractReceivables = Object.values(tractReceivables)

      let totalReceivables = 0
      let totalNetWeight = 0
      let uniqueTicketNumbers = {}

      this.tractReceivables.forEach(tract => {
        tract.receivables.forEach(receivable => {
          totalReceivables += receivable.amount
          totalNetWeight += receivable.netWeight
          uniqueTicketNumbers[receivable.ticketNumber] = receivable.ticketNumber
        })
        tract.totalJournalEntry = this.formatMoney(totalReceivables)
        tract.totalNetWeight = `${this.getFormattedTons(totalNetWeight)} tons`
        tract.loadCount = `${Object.keys(uniqueTicketNumbers).length} loads`

        totalReceivables = 0
        totalNetWeight = 0
        uniqueTicketNumbers = {}
      })
    }
  }
}
</script>
