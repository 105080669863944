<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom color="primary">
        <template #activator="{ on: tooltip }">
          <v-icon color="primary" class="mt-4 mr-n2" v-bind="attrs" v-on="{...tooltip, ...menu}">mdi-application-edit</v-icon>
        </template>
        <span class="subtitle-1 white--text" style="white-space: pre-line">
          {{$t('adjustWidgets')}}
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="primary white--text">
        {{$t('adjustWidgets')}}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-container :style="`max-width: 750px;`">
          <v-tabs v-if="!isReorderMode">
            <v-tab v-for="tab in validTabs" :key="tab">
              <span class="mr-3">{{$t(tab)}}</span>
              <v-tooltip bottom color="black" max-width="250px">
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-checkbox color="success" :input-value="tabsChecked[tab]" @click="toggleCheckbox(tab)" :ripple="false"/>
                  </span>
                </template>
                <span class="subtitle-1 white--text">{{$t(tooltipForCheckbox(tab), { tabType: $t(tab) })}}</span>
              </v-tooltip>
            </v-tab>
            <v-tab-item v-for="tab in validTabs" :key="tab">
              <v-row dense>
                <v-col cols="12" sm="6" v-for="chart in getChartsByTab(tab)" :key="`chart-chip${chart.id}`">
                  <v-sheet :style="chartSheetStyles" outlined @click="toggleVisibility(chart)">
                    <v-row dense align="center">
                      <v-col cols="auto" class="pr-0 py-0">
                        <Icon iconColor="black" :tooltipText="$t(chart.widgetType.label)" :icon=chart.widgetType.icon />
                      </v-col>
                      <v-col class="px-1">
                        {{ $t(chart.text) }}
                      </v-col>
                      <v-col cols="auto" class="pl-0 py-0">
                        <v-icon :color=visibilityColor(chart.visible)>{{ visibilityIcon(chart.visible) }}</v-icon>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
          <draggable v-else
          v-model="chartSet.widgets"
          group="charts"
          animation=400
          swapThreshold=".75"
          tag="v-row"
          :component-data="{ props: { dense: true } }">
            <v-col cols="12" sm="6" v-for="chart in chartSet.widgets" :key="chart.id">
              <v-sheet :style="chartSheetStyles" outlined>
                <v-row dense align="center">
                  <v-col cols="auto" class="pr-0 py-0">
                    <Icon iconColor="black" :tooltipText="$t(chart.widgetType.label)" :icon=chart.widgetType.icon />
                  </v-col>
                  <v-col class="px-1">
                    {{ $t(chart.text) }}
                  </v-col>
                  <v-col cols="auto" class="pl-0 py-0">
                    <v-icon :color=visibilityColor(chart.visible)>{{ visibilityIcon(chart.visible) }}</v-icon>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </draggable>
        </v-container>
      </v-card-text>
      <v-card-actions bottom class="pb-4">
        <v-btn class="primary" @click="isReorderMode = !isReorderMode">{{$t(chooseOrReorderText)}}</v-btn>
        <v-btn v-if="isReorderMode" color="primary" outlined @click="restoreDefaultOrder">{{$t('defaultOrder')}}</v-btn>
        <v-spacer/>
        <v-btn class="primary" @click="apply" :disabled="!changed">{{$t('apply')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { BaseDashboardWidgetSet } from '@/model/DashboardWidget.js'
import draggable from 'vuedraggable'

export default {
  name: 'TicketsWidgetPicker',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    draggable
  },

  props: {
    propChartSet: BaseDashboardWidgetSet,
    openMenuKey: { type: Number, required: false, default: 0 }
  },

  data: () => ({
    chartSet: null,
    menuOpen: false,
    changed: false,
    tabsChecked: { general: false, mill: false, supplier: false },
    isReorderMode: false
  }),

  watch: {
    menuOpen (val) {
      if (val === true) {
        this.changed = false
      } else {
        this.chartSet = this.propChartSet.clone()
      }
    },

    openMenuKey () {
      if (!this.menuOpen) this.menuOpen = true
    },

    chartSet: {
      handler () {
        this.changed = true
        this.validTabs.forEach(tab => { this.tabsChecked[tab] = this.getChartsByTab(tab).every(chart => chart.visible) })
      },
      deep: true
    }
  },

  created () {
    this.chartSet = this.propChartSet.clone()
  },

  computed: {
    validTabs () {
      return ['general', 'mill', 'supplier'].filter(tab => this.getChartsByTab(tab).length > 0)
    },

    chartSheetStyles () {
      return {
        fontSize: '18px',
        padding: '8px',
        cursor: this.isReorderMode ? 'move' : 'pointer',
        userSelect: !this.isReorderMode ? 'none' : undefined
      }
    },

    chooseOrReorderText () {
      return this.isReorderMode ? 'chooseWidgets' : 'reorderWidgets'
    }
  },

  methods: {
    apply () {
      this.chartSet.saveWidgetInfo()
      this.menuOpen = false
      this.$emit('new-chart-layout', this.chartSet)
    },

    toggleVisibility (chart) {
      chart.visible = !chart.visible
    },

    visibilityIcon (visible) {
      return visible ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
    },

    visibilityColor (visible) {
      return visible ? 'success' : 'black'
    },

    getChartsByTab (tab) {
      return this.chartSet.widgets.filter(chart => chart.group === tab)
    },

    selectAllByTab (tab) {
      this.getChartsByTab(tab).forEach(chart => { chart.visible = true })
    },

    deSelectAllByTab (tab) {
      this.getChartsByTab(tab).forEach(chart => { chart.visible = false })
    },

    visibilityByTab (tab) {
      return this.getChartsByTab(tab).map(chart => chart.visible)
    },

    toggleCheckbox (tab) {
      this.tabsChecked[tab] ? this.deSelectAllByTab(tab) : this.selectAllByTab(tab)
    },

    tooltipForCheckbox (tab) {
      return this.tabsChecked[tab] ? 'tabSelectNoneCheckboxTooltip' : 'tabSelectAllCheckboxTooltip'
    },

    restoreDefaultOrder () {
      this.chartSet.restoreDefaultOrder()
    }
  }
}
</script>
<style scoped>
.sortable-ghost {
  background-color: black;
}
</style>
