<template>
<v-form v-model="isValid" :ref="formRef" :lazy-validation="lazyValidation" @submit.prevent="submit">
  <div>
    <slot></slot>
  </div>

  <v-row dense no-gutters align="center">
    <v-col cols="auto">
      <slot name="left-action"/>
    </v-col>
    <v-spacer/>
    <v-col cols="auto">
      <slot name="right-action"/>
    </v-col>
    <v-col cols="auto">
      <v-btn v-if="!hideButton" :color="buttonColor" type="submit" :data-testid="testId" :disabled="submitDisabled">{{ buttonText }}</v-btn>
    </v-col>
  </v-row>
</v-form>
</template>

<script>
export default {
  name: 'FormWrapper',

  props: {
    hideButton: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Save Changes'
    },
    testId: {
      type: String,
      required: false,
      default: undefined
    },
    formRef: {
      type: String,
      required: false,
      default: undefined
    },
    lazyValidation: {
      type: Boolean,
      required: false,
      default: false
    },
    strongValidation: {
      type: Boolean,
      required: false,
      default: false
    },
    validateOnSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'primary'
    }
  },

  data: () => ({
    isValid: false
  }),

  computed: {
    submitDisabled () {
      return this.strongValidation
        ? (!this.isValid || this.disabled)
        : this.disabled
    }
  },

  methods: {
    submit () {
      this.$emit('submit')
    },

    validate () {
      if (this.$refs[this.formRef]) return this.$refs[this.formRef].validate()
      return true
    },

    reset () {
      if (this.$refs[this.formRef]) this.$refs[this.formRef].reset()
    },

    resetValidation () {
      if (this.$refs[this.formRef]) this.$refs[this.formRef].resetValidation()
    }
  }
}
</script>
