<template>
  <v-tooltip bottom :disabled="!tooltipText" :color="colorForTooltip">
    <template #activator="{on}">
      <v-icon
        ref="vIcon"
        :tabindex="tabindex"
        :data-testid="dataTestId"
        :disabled="disabled" v-on="on"
        :style="`cursor: ${clickable ? 'pointer' : 'default'}`"
        :large="large"
        :small="small"
        :x-large="xLarge"
        :color="colorForIcon"
        :class="margin"
        @click="iconClicked"
        :id="elementId">
        {{ icon }}
      </v-icon>
    </template>
    <span class="subtitle-1 white--text icon-tooltip">{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'Icon',

  props: {
    tooltipText: { type: String, default: undefined },
    tooltipColor: { type: String, default: undefined },
    iconColor: { type: String, default: undefined },
    small: { type: Boolean, default: true },
    icon: { type: String, default: 'mdi-exclamation' },
    margin: { type: String, default: 'mr-1' },
    large: { type: Boolean, default: false },
    xLarge: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dataTestId: { type: String, default: '' },
    propagateClick: { type: Boolean, default: false },
    elementId: { type: String, default: undefined },
    tabindex: { type: String, default: '-1' }
  },

  computed: {
    clickable () {
      return this.$listeners['icon-clicked'] !== undefined && !this.disabled
    },

    colorForIcon () {
      if (this.iconColor) return this.iconColor
      return IconHelpers.getColorForIcon(this.icon)
    },

    colorForTooltip () {
      if (this.tooltipColor) return this.tooltipColor
      return ['white', 'tertiary'].includes(this.colorForIcon) ? 'primary' : this.colorForIcon
    }
  },

  methods: {
    iconClicked (event) {
      if (!this.propagateClick) {
        event.stopImmediatePropagation()
      }
      this.$emit('icon-clicked', event)
      event.target.blur()
    }
  }
}
</script>

<style scoped>
.icon-tooltip {
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
