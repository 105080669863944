<template>
  <v-row no-gutters align="center" id="dialog-with-tabs-title">

    <v-col cols="auto">
      <v-btn v-if="mobileCompatible" color="white" icon size="medium" class="ma-0 pa-0"
      @click.stop="navDrawer = true">
      <v-icon color="tertiary">mdi-menu</v-icon>
      </v-btn>
    </v-col>

    <v-col>
      <v-row no-gutters :justify="mobileCompatible ? 'center' : 'start'" class="flex-nowrap">
        <span class="headline white--text mr-2">{{ titleText }}</span>
        <slot name="append-title"></slot>
      </v-row>
    </v-col>

    <v-col cols="auto">
      <BaseDialogActions :hideRefresh="hideRefresh" @close="closeClicked" @fullscreen-toggled="fullscreenToggled">
        <template #left>
          <slot name="prepend-base-actions"/>
        </template>
      </BaseDialogActions>
    </v-col>

    <v-navigation-drawer v-if="mobileCompatible" v-model="navDrawer" width="auto" temporary absolute class="dialog-with-tabs-nav">
      <v-tabs v-model="currentTab" vertical>
        <v-tab
          v-for="(item, index) in tabs"
          :key="index"
          :value="index"
          :style="navItemStyle"
          @click.stop="tabClicked(index)"
        >
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>

  </v-row>
</template>

<script>
export default {
  name: 'DialogWithTabsTitle',

  props: {
    mobileCompatible: {
      type: Boolean,
      required: true
    },
    titleText: {
      type: String,
      required: true
    },
    hideRefresh: {
      type: Boolean,
      default: true
    },
    tabs: {
      type: Array,
      required: true
    },
    enlargeTabs: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    navDrawer: false,
    currentTab: 0
  }),

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  computed: {
    navItemStyle () {
      return this.enlargeTabs
        ? {
          fontSize: '24px',
          paddingTop: '1.85rem',
          paddingBottom: '1.85rem',
          justifyContent: 'left'
        }
        : {
          justifyContent: 'left'
        }
    }
  },

  methods: {
    closeClicked () {
      this.$emit('close')
    },
    fullscreenToggled () {
      this.$emit('fullscreen-toggled')
    },
    tabClicked (index) {
      this.navDrawer = false
      this.$emit('tab-clicked', index)
    }
  }
}
</script>
