<template>
  <v-card outlined rounded class="fill-height">
    <v-card-title>
      <v-row justify="space-between" no-gutters style="width: 100%;">
        <v-col class="mr-2 text-truncate">
          <span class="subtitle-1">
            {{ scale.name }}
          </span>
        </v-col>
        <v-col cols="auto" v-if="editing">
          <Icon
            dataTestId="scale-edit-button"
            icon="mdi-pencil"
            iconColor="success"
            :tooltipText="$t('edit')"
            @icon-clicked="$emit('click:edit', scale)"
            tabindex="0"
          />
          <Icon
            dataTestId="scale-delete-button"
            icon="mdi-delete"
            iconColor="error"
            :tooltipText="$t('delete')"
            @icon-clicked="$emit('click:delete', scale)"
            tabindex="0"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-n2">
        <v-row no-gutters>
          <v-col data-testid="scale-card-connection-type" cols="12">{{ $t('connectionType') }}: {{$t(scale.connectionType.label)}}</v-col>
            <v-col data-testid="scale-card-address" cols="12">{{ $t('address') }}: {{scale.address}}</v-col>
            <v-col data-testid="scale-card-port" cols="12" v-if="isTCP || isCustom">{{ $t('port') }}: {{scale.port ?? $t('notAvailable')}}</v-col>
            <v-col data-testid="scale-card-delimiter" cols="12" v-if="isCustom">{{ $t('delimiter') }}: {{scale.delimiter}}</v-col>
            <v-col data-testid="scale-card-skip" cols="12" v-if="isCustom">{{ $t('skip') }}: {{scale.skip}}</v-col>
            <v-col data-testid="scale-card-weigh-in-motion" v-if="isTCP">
              {{ $t('weighInMotion') }}:
              <Icon
                :icon="scale.weighInMotion ? 'mdi-check' : 'mdi-cancel'"
                :iconColor="scale.weighInMotion ? 'success' : 'error'"
                :tooltipColor="scale.weighInMotion ? 'success' : 'error'"
                :tooltipText="scale.weighInMotion ? $t('weighInMotionSet') : $t('weighInMotionNotSet')"
                small
              />
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ReceiverScaleConnectionTypes } from '@/utils/Enumerations.js'
export default {
  name: 'ScaleCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    scale: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isTCP () {
      return this.scale.connectionType.value === ReceiverScaleConnectionTypes.TCP.value
    },
    isCustom () {
      return this.scale.connectionType.value === ReceiverScaleConnectionTypes.Custom.value
    }
  }
}
</script>
