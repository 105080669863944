<template>
  <v-card data-testid="ticket-form">
    <v-card-title :class="contractColor">
      <span class="headline">{{title}}</span>
      <v-spacer/>
      <span style="font-style: italic;" class="mr-2 subtitle-1" v-if="newTicketTime && hasOutWeight">*{{$t('willApplyNewDates')}}</span>
      <Icon
      v-if="propTicket"
      margin="mr-1"
      :small="false"
      icon="mdi-clock"
      iconColor="tertiary"
      :tooltipText="$t('editDates')"
      dataTestId="ticket-edit-dates"
      @icon-clicked="setDateEntry(!editTime)"/>
      <BaseDialogActions
      @close="$emit('close')"
      hideRefresh/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="ticketForm"
        testId="save-ticket"
        strongValidation
        :lazyValidation="true"
        :disabled="buttonDisabled"
        :buttonText="isEditing ? $t('saveChanges') : $t('createTicket')"
        :buttonColor="contractColor"
        @submit="saveTicket"
      >
        <template v-slot:right-action>
          <v-checkbox
            v-if="!isEditing"
            data-testid="ticket-create-another-check"
            color="primary"
            v-model="createAnother"
            label="Save and Create Another"
            class="mr-2"
          />
        </template>
        <v-container fluid>
          <v-row>
            <v-col cols="12" :lg="hasTicketImages ? 8 : 12" :xl="hasTicketImages ? 8 : 12">
              <v-row>
                <v-col cols="12" :lg="requiresLogger ? '4' : '6'">
                  <LoadableComponent :loading="loading">
                    <ContractAutocomplete
                    :includePaused="false"
                    :contractId="propTicket ? propTicket.contractId : undefined"
                    :contractMode="contractMode"
                    :contractType="restrictedContractType"
                    :tractId="propTractId"
                    :fetchContracts="false"
                    ref="firstField"
                    data-testid="ticket-contract"
                    @contract-chosen="contractChosen"
                    />
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" :lg="requiresLogger ? '4' : '6'">
                  <LoadableComponent :loading="loading">
                    <v-autocomplete v-if="!contract"
                    data-testid="ticket-product"
                    :label="$t('chooseContractForProducts')"
                    disabled/>
                    <ProductAutocomplete
                      v-else
                      :contract.sync="contract"
                      :productId="propTicket?.productID"
                      :label="contract === undefined ? $t('chooseContractForProducts') : $t('product')"
                      :includePreexistingProduct="propTicket?.contractId === contract?.contractId"
                      :propFetchProducts="false"
                      defaultToFirstProduct
                      @product-chosen="productChosen"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" lg="4" v-if="requiresLogger">
                  <LoadableComponent :loading="loading">
                    <AccountAutocomplete
                    id="ticket-logger"
                    data-testid="ticket-account"
                    v-if="loggers.length > 0 && requiresLogger"
                    :propAccounts="loggers"
                    :accountId="isEditing ? propTicket.loggerAccountId : defaultLoggerId"
                    :title="$t('logger')"
                    @account-chosen="loggerChosen"
                    :fetchTagsAndAccounts="false"/>
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="3">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    ref="truckField"
                    v-model="ticket.trailerIdentifier"
                    data-testid="ticket-truck"
                    color="black"
                    counter
                    maxlength="10"
                    :full-width="false"
                    :label="$t('trailerIdentifier')"
                    :rules="[rules.required]"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <LoadableComponent :loading="loading">
                    <AccountAutocomplete
                    id="driver"
                    data-testid="driver"
                    :accountId="propTicket ? propTicket.driverAccountId : undefined"
                    :title="$t('driver')"
                    :clearable="true"
                    userSetting="driverAccountAutocomplete"
                    @account-chosen="driverAccountChosen"
                    :fetchTagsAndAccounts="false"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3" v-if="contractMode.value === 0">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    :disabled="isFromLoader"
                    v-model="ticket.latitude"
                    @blur="onCoordFieldBlur"
                    data-testid="ticket-latitude"
                    :label="$t('latitude')"
                    color="black"
                    :persistent-hint="isFromLoader"
                    :hint="isFromLoader ? $t('ticketWasCreatedInLoader') : ''"
                    :rules="[rules.required, rules.decimal, rules.validLatitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
                    type="number"
                    hide-spin-buttons/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3" v-if="contractMode.value === 0">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    :disabled="isFromLoader"
                    v-model="ticket.longitude"
                    @blur="onCoordFieldBlur"
                    data-testid="ticket-longitude"
                    :label="$t('longitude')"
                    color="black"
                    :persistent-hint="isFromLoader"
                    :hint="isFromLoader ? $t('ticketWasCreatedInLoader') : ''"
                    :rules="[rules.required, rules.decimal, rules.validLongitudeForTextBox, rules.sixDecimalPlacesOrFewer]"
                    type="number"
                    hide-spin-buttons/>
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" lg="3" v-if="showDepartureWeight">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.departureWeight"
                    data-testid="ticket-departureweight"
                    persistent-hint
                    color="black"
                    :rules="enteringPounds ? [rules.validWeightPounds] : [rules.validWeightTons]"
                    :label="`${$t('departureWeight')} (${enteringPounds ? 'lbs' : 'tons'})`"
                    :hint="departureWeightConv"
                    type="number"
                    hide-spin-buttons
                    @keydown="preventDecimalForPounds($event)"
                    @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.inWeight"
                    data-testid="ticket-inweight"
                    persistent-hint
                    color="black"
                    :rules="enteringPounds ? [rules.validWeightPounds] : [rules.validWeightTons]"
                    ref="inWeight"
                    :label="`${$t('inWeight')} (${enteringPounds ? 'lbs' : 'tons'})`"
                    :hint="inWeightConv"
                    type="number"
                    hide-spin-buttons
                    @keydown="preventDecimalForPounds($event)"
                    @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.outWeight"
                    data-testid="ticket-outweight"
                    persistent-hint
                    color="black"
                    :rules="enteringPounds ? [rules.validWeightPounds] : [rules.validWeightTons]"
                    ref="outWeight"
                    :hint="outWeightConv"
                    :label="`${$t('outWeight')} (${enteringPounds ? 'lbs' : 'tons'})`"
                    @blur="onOutWeightBlur"
                    @change="onOutWeightChange"
                    type="number"
                    hide-spin-buttons
                    @keydown="preventDecimalForPounds($event)"
                    @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" :lg="isByproductPurchase ? '4' : '3'">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                      v-if="!isEditing"
                      v-model="ticket.defectWeight"
                      data-testid="ticket-defectweight"
                      color="black"
                      persistent-hint
                      :rules="enteringPounds ? [rules.validWeightPounds] : [rules.validWeightTons]"
                      :label="`${$t('defectWeight')} (${enteringPounds ? 'lbs' : 'tons'})`"
                      :hint="defectWeightConv"
                      type="number"
                      hide-spin-buttons
                      @keydown="preventDecimalForPounds($event)"
                      @wheel="$event.target.blur()"/>
                    <v-text-field
                      v-else-if="isEditing"
                      v-model="ticket.defectWeight"
                      :disabled="defectWeightWarning"
                      data-testid="ticket-defectweight"
                      color="black"
                      persistent-hint
                      :append-icon="showDefectIcon ? 'mdi-dots-horizontal' : undefined"
                      :rules="enteringPounds ? [rules.validWeightPounds] : [rules.validWeightTons]"
                      :hint="defectWeightWarning ? $t('willBeSetToZero') : defectWeightConv"
                      :label="`${$t('defectWeight')} (${enteringPounds ? 'lbs' : 'tons'})`"
                      @click:append="openTicketDefectForm"
                      type="number"
                      hide-spin-buttons
                      @keydown="preventDecimalForPounds($event)"
                      @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
                <v-col v-if="!isByProducts" cols="12" sm="6" lg="3">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.pieces"
                    data-testid="ticket-pieces"
                    color="black"
                    persistent-hint
                    :disabled="hasLogCounts"
                    :hint="!collectPieces ? $t('noPieceEntry') : ''"
                    :rules="!collectPieces ? [] : [rules.integer, rules.validPieces]"
                    :label="$t('pieces')"
                    type="number"
                    hide-spin-buttons
                    @keydown="preventDecimal($event, true)"
                    @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-dialog v-model="ticketDefectForm" width="750px">
                <TicketDefectsForm
                  v-if="ticketDefectForm"
                  :ticket="ticket"
                  :contract="contract"
                  :weightInPounds="enteringPounds"
                  @close="ticketDefectForm = false"
                  @refresh="updateDefectWeight"
                />
              </v-dialog>
              <v-dialog v-model="locationChangeDialog" max-width="400px">
                <ConfirmDialog
                  :title="$t('contractDestinationChange')"
                  :body="$t('ticketDestinationWarningDescription')"
                  :confirmText="$t('okay')"
                  :canCancel="false"
                  @confirm="closeLocationConfirm"
                />
              </v-dialog>
              <v-row>
                <TicketEditTime
                v-if="isEditing ? editTime : true"
                :style="!isEditing ? {visibility: hasOutWeight ? 'visible' : 'hidden'} : {}"
                :isEditing="isEditing"
                :contractType="contract?.type"
                @time-applied="applyNewTime"
                @cancel="setDateEntry(false)"
                @valid-entry="isValidEntry"
                :loadCreatedAt="ticket.loadCreatedAt || new Date().toISOString()"
                :departedAtDate="isEditing ? propTicket.departedAt : undefined"
                :weighedInDate="isEditing ? propTicket.weighedInAt : undefined"
                :weighedOutDate="isEditing ? propTicket.weighedOutAt : undefined"
                />
              </v-row>
              <v-row align="center">
                <v-col cols="auto" style="width: 154px;">
                  <LoadableComponent :loading="loading" type="button">
                    <v-btn
                    style="width: 100%"
                    :class="contractColor"
                    @click="toggleEnteringPounds">
                      <v-icon small class="mr-2" color="white">mdi-scale</v-icon>
                      <span>{{enteringPounds ? 'LBS' : 'TONS'}}</span>
                    </v-btn>
                  </LoadableComponent>
                </v-col>
                <v-col>
                  <LoadableComponent :loading="loading" invisible>
                    <span :class="netWeight < 0 ? 'title red--text' : 'title'">{{ netWeightString }}</span>
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!isByProducts" cols="12" sm="6" lg="3">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.averageLength"
                    color="black"
                    :disabled="hasLogCounts"
                    data-testid="ticket-form-avg-length"
                    :full-width="false"
                    :rules="[rules.positiveDecimal, rules.validAverageLength]"
                    :label="$t('averageLength')"
                    type="number"
                    hide-spin-buttons
                    @wheel="$event.target.blur()"/>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                      v-model="ticket.extTicketNumber1"
                      color="black"
                      maxLength="32"
                      data-testid="ticket-form-ext-1"
                      counter="32"
                      @change="validateTicketNumber(ticket.extTicketNumber1, { isExt1: true })"
                      @input="extOneValidated = false"
                      :full-width="false"
                      :rules="requiresExt1 ? [rules.required] : []"
                      :label="$t('extTicketNumber1')">
                      <template #append>
                        <Icon
                        icon="mdi-checkbox-multiple-marked-circle"
                        :iconColor="extOneValidateDisabled ? 'black' : extTicket1IconColor"
                        dataTestId="ticket-form-valid-number"
                        :small="false"
                          :tooltipText="$t('validateTicketNumber')"
                        :disabled="extOneValidateDisabled"
                        @icon-clicked="validateTicketNumber(ticket.extTicketNumber1, { isExt1: true })"/>
                      </template>
                    </v-text-field>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                  <LoadableComponent :loading="loading">
                    <v-text-field
                    v-model="ticket.extTicketNumber2"
                    color="black"
                    ref="extTwoTextField"
                    data-testid="ticket-form-ext-2"
                    maxLength="32"
                    counter="32"
                    :rules="requiresExt2 ? [rules.required] : []"
                    :full-width="false"
                    :label="$t('extTicketNumber2')"
                    @change="extTicket2IconColor = 'black'"
                    >
                      <template #append>
                        <Icon
                        icon="mdi-checkbox-multiple-marked-circle"
                        :iconColor="extTwoValidateDisabled ? 'black' : extTicket2IconColor"
                        dataTestId="ticket-form-valid-number-2"
                        :small="false"
                          :disabled="extTwoValidateDisabled"
                        :tooltipText="$t('validateTicketNumber')"
                        @icon-clicked="validateTicketNumber(ticket.extTicketNumber2, { isExt2: true })"/>
                      </template>
                    </v-text-field>
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3" v-if="requiresFromDeck && contract?.fromAccountId">
                  <LoadableComponent :loading="loading">
                    <DeckSelect
                    :locations="allLocations"
                    :label="$t('fromDeck')"
                    :accountId.sync="contract.fromAccountId"
                    :propDeckId="ticket?.fromDeckId"
                    weightBasedOnly
                    @deck-selected="fromDeckSelected"
                    :contentType="DeckContentType.Logs"
                    />
                  </LoadableComponent>
                </v-col>
                <v-col cols="12" sm="6" lg="3" v-if="requiresDeck && contract?.destinationAccountId">
                  <LoadableComponent :loading="loading">
                    <DeckSelect
                    :locations="allLocations"
                    :label="$t('destinationDeck')"
                    :accountId.sync="contract.destinationAccountId"
                    :propDeckId="ticket?.deckId"
                    :weightBasedOnly="false"
                    @deck-selected="deckSelected"
                    :contentType="deckContentType"/>
                  </LoadableComponent>
                </v-col>
              </v-row>
              <v-row v-if="alreadyExistingTickets.length > 0">
                <v-col cols="12">
                  <span class="title">{{$t('ticketsWithNumberAlreadyExist')}}</span>
                  <v-expansion-panels popout accordion tile v-model="openPanel">
                    <v-expansion-panel v-for="t in alreadyExistingTickets" :key="t.ticketId">
                      <v-expansion-panel-header>
                        <span>{{$t('ticketNumberX', { x: t.ticketNumber })}}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pt-2 mb-n4">
                        <TicketInformation
                        :ticket="t"/>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="hasTicketImages" cols="12" lg="4">
              <LoadableComponent :loading="loading || !hasTicketImageData" type="fill">
                <TicketImageViewer
                v-if="hasTicketImageData"
                :ticketNumber="ticket.ticketNumber"
                :ticketImageData="ticketImageData"
                :includeImageData="shouldApplyRecognizedFields"
                @data-selected="populateFromImageData"
                @delete="deleteTicketImage"
                />
              </LoadableComponent>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import fieldRules from '@/utils/rules.js'
import { numberWithCommas } from '@/utils/NumericMutations'
import Cookies from 'js-cookie'
import { CookieKeys } from '@/utils/constants.js'
import moment from 'moment'
import { getOriginationTicketId } from '@/utils/OriginationIdUtility.js'
import { ticketImageClient, ticketRecognitionClient } from '../../utils/TicketImages'
import { ContractMode, TicketOrigin, DefectCollectionType, DeckContentType, TicketStatus, ContractType, ErrorSource } from '@/utils/Enumerations'
import { colorClassForContractMode } from '@/utils/componentHelpers.js'
import { getFormattedCoordWithTrailingZeroes } from '../../utils/NumericMutations'
import { localToUTC } from '@/utils/DateFormatter.js'

export default {
  name: 'TicketForm',

  components: {
    ContractAutocomplete: () => import('../autocomplete/ContractAutocomplete.vue'),
    ProductAutocomplete: () => import('../autocomplete/ProductAutocomplete.vue'),
    AccountAutocomplete: () => import('../autocomplete/AccountAutocomplete.vue'),
    TicketDefectsForm: () => import('@/components/defects/TicketDefectsForm.vue'),
    TicketEditTime: () => import('./TicketEditTime.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    DeckSelect: () => import('@/components/autocomplete/DeckSelect.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    TicketInformation: () => import('@/components/ticket/ticket-details/TicketInformation.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    TicketImageViewer: () => import('@/components/ticket/TicketImageViewer.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue')
  },

  props: {
    propTicket: { type: Object },
    propTractId: { type: Number },
    contractMode: { type: Object }
  },

  computed: {
    ...mapGetters('locations', ['allLocations']),
    ...mapGetters('contract', ['allContracts']),

    title () {
      if (this.propTicket) return `Editing Ticket #${this.propTicket.ticketNumber}`

      let ticketTypeMessage = 'log'

      switch (this.contractMode.value) {
        case 1:
          ticketTypeMessage = 'byProduct'
          break
        case 2:
          ticketTypeMessage = 'transfer'
          break
        case 3:
          ticketTypeMessage = 'logYardSale'
          break
      }

      return this.$t('createTicketOfType', { type: this.$t(ticketTypeMessage) })
    },

    hasTicketImages () {
      if (!this.isEditing) return false
      return this.propTicket.hasImages && this.ticketImageData !== 'error'
    },

    hasTicketImageData () {
      return (this.ticketImageData && this.ticketImageData.length > 0) ?? false
    },

    inWeightConv () {
      return this.getConversion(this.ticket.inWeight)
    },

    extOneValidateDisabled () {
      return this.ticket.extTicketNumber1.trim().length === 0 || this.contract === undefined
    },

    extTwoValidateDisabled () {
      return this.ticket.extTicketNumber2.trim().length === 0 || this.contract === undefined
    },

    productSize () {
      return this.requiresLogger ? 6 : 3
    },

    locationChangeDialog () {
      if (this.isEditing === false) return false

      return this.shownDestinationDialog === false &&
        this.contractDestinationChange === true &&
        this.ticket?.isExternal === false &&
        this.hasDefects === true
    },

    outWeightConv () {
      return this.getConversion(this.ticket.outWeight)
    },

    defectWeightConv () {
      return this.getConversion(this.ticket.defectWeight)
    },

    departureWeightConv () {
      return this.getConversion(this.ticket.departureWeight)
    },

    netWeight () {
      return this.ticket.inWeight - this.ticket.outWeight - this.ticket.defectWeight
    },

    showDepartureWeight () {
      return this.contract?.type === ContractType.ByproductSale.value || this.contract?.type === ContractType.LogYardSale.value
    },

    netWeightString () {
      const tons = this.enteringPounds ? (this.netWeight / 2000).toFixed(4) : (this.netWeight).toFixed(4)
      const pounds = this.enteringPounds ? numberWithCommas(this.netWeight, 0) : numberWithCommas(this.netWeight * 2000, 0)
      return `${tons} tons | ${pounds} lbs`
    },

    hasOutWeight () {
      return parseFloat(this.ticket.outWeight) > 0
    },

    requiresExt1 () {
      if (this.contract) {
        return this.contract.requiresExt1
      } else {
        return false
      }
    },
    requiresExt2 () {
      if (this.contract) {
        return this.contract.requiresExt2
      } else {
        return false
      }
    },

    collectPieces () {
      if (this.product) {
        return this.product.collectPieceInfo
      } else {
        return false
      }
    },

    isEditing () {
      return this.propTicket !== undefined
    },

    requiresLogger () {
      if (this.contract) {
        return this.contract.requiresLogger
      }
      return false
    },

    requiresDeck () {
      return !this.contract?.isExternal && this.contract?.type !== ContractType.ByproductSale.value && this.contract
    },

    requiresFromDeck () {
      return this.contract?.type === ContractType.Transfer.value || this.contract?.type === ContractType.LogYardSale.value
    },

    isFromLoader () {
      return this.isEditing && this.propTicket?.origin === TicketOrigin.Loader.value
    },

    contractColor () {
      return colorClassForContractMode(this.contractMode.value)
    },

    shouldApplyRecognizedFields () {
      return (this.propTicket?.status === TicketStatus.toInt(this.$t('inTransit')) && this.ticketImageData.length > 0) ?? false
    },

    fieldSets () {
      return this.ticketImageData.map(d => d.fields).filter(d => d !== undefined)
    },

    maxConfidenceFieldSet () {
      if (this.fieldSets.length === 0) return {}
      return this.fieldSets.reduce((prev, fields) => {
        const out = {}
        Object.keys(fields).forEach(key => {
          out[key] = (prev[key] === undefined || fields[key]?.confidence > prev[key]?.confidence)
            ? fields[key]
            : prev[key]
        })
        return out
      }, {})
    },

    showDefectIcon () {
      return (
        this.allLocations.find(l => l.accountId === this.contract?.destinationAccountId)?.defectCollectionMode === DefectCollectionType.Itemized.value ||
        this.allLocations.find(l => l.accountId === this.contract?.destinationAccountId)?.defectCollectionMode === DefectCollectionType.Segmented.value
      )
    },

    deckContentType () {
      return this.isByproductPurchase
        ? DeckContentType.Byproducts
        : DeckContentType.Logs
    },

    isByproductPurchase () {
      return this.contract?.type === ContractType.ByproductPurchase.value
    },

    restrictedContractType () {
      return this.isEditing ? this.contract?.type : undefined
    }
  },

  data: () => ({
    rules: fieldRules.rules,
    buttonDisabled: false,
    contract: undefined,
    product: undefined,
    enteringPounds: true,
    createAnother: false,
    chosenLogger: undefined,
    editTime: false,
    selectedDeck: undefined,
    selectedFromDeck: undefined,
    newTicketTime: undefined,
    loggers: [],
    hasDefects: false,
    defaultLoggerId: -1,
    openPanel: 0,
    deckId: -1,
    ticketDefectForm: false,
    extOneValidated: true,
    alreadyExistingTickets: [],
    ticketImageData: [],
    contractDestinationChange: false,
    shownDestinationDialog: false,
    isByProducts: false,
    defectWeightWarning: false,
    ticket: {
      trailerIdentifier: '',
      inWeight: 0,
      outWeight: 0,
      defectWeight: 0,
      departureWeight: 0,
      deckId: undefined,
      fromDeckId: undefined,
      loadCreatedAt: moment.utc().format(),
      pieces: '',
      latitude: '',
      longitude: '',
      driverAccountId: undefined,
      proximityTrigger: false,
      extTicketNumber1: '',
      extTicketNumber2: '',
      weighedInAt: '',
      weighedOutAt: '',
      departedAt: '',
      averageLength: 0,
      rolloutStatus: 0,
      seal: undefined
    },
    hasLogCounts: false,
    extTicket1IconColor: 'black',
    extTicket2IconColor: 'black',
    DeckContentType,
    loading: false,
    outWeightChanged: false
  }),

  watch: {
    'contract.type': {
      handler (val) {
        if (val !== ContractType.ByproductSale.value && val !== ContractType.LogYardSale.value) {
          this.ticket.departureWeight = 0
          this.ticket.departedAt = null
        }
      }
    },

    loading (val) {
      if (!val) {
        let focusedRef = null
        setTimeout(_ => {
          switch (this.propTicket?.status) {
            case TicketStatus.InTransit.value:
              focusedRef = this.$refs.inWeight
              break
            case TicketStatus.WeighedIn.value:
              focusedRef = this.$refs.outWeight
              break
            default:
              focusedRef = this.$refs.firstField.$children[0]
          };
          focusedRef.focus()
        }, 0)
      }
    }
  },

  async created () {
    const locationsQuery = { includeLogsDecks: true, includeByproductDecks: true }
    this.isByProducts = this.contractMode.value === ContractMode.Byproducts.value
    this.loading = true
    try {
      await Promise.all([
        this.fetchLocations(locationsQuery),
        this.fetchAccounts(),
        this.fetchAccountTags(),
        this.fetchProducts({
          contractId: null,
          includeProduction: !this.isByProducts,
          includeByProducts: this.isByProducts
        }),
        this.fetchContracts({
          includeOpen: true,
          includeOnHold: false,
          includeClosed: false,
          includeExpired: false,
          limitToDraft: false,
          limitToInternal: false,
          limitToPaused: false,
          includeProduction: this.contractMode.value === 0,
          includeWoodsSale: this.contractMode.value === 0,
          includeByProduct: this.contractMode.value === 1,
          includeTransfer: this.contractMode.value === 2,
          includeLogYardSale: this.contractMode.value === 3,
          includeByproductPurchase: this.contractMode.value === 1
        })
      ])
      if (this.isEditing) {
        // The prop ticket is not guaranteed to have all the fields we need (EX: Posting Form)
        this.ticket = await this.getTicket(this.propTicket.ticketId)
        if (this.ticket.contractId) await this.contractChosen(this.allContracts.find(c => c.contractId === this.ticket.contractId))

        this.ticket.latitude = getFormattedCoordWithTrailingZeroes(this.ticket.latitude)
        this.ticket.longitude = getFormattedCoordWithTrailingZeroes(this.ticket.longitude)

        if (this.ticket.hasImages) {
          try {
            this.ticketImageData = await ticketRecognitionClient.getRecognitionData(this.ticket.ticketId, this.ticket.status === TicketStatus.InTransit.value)
          } catch (e) {
            this.setSnackError(this.$t('errorTicketImageData'))
            this.ticketImageData = 'error'
          }
        }

        if (!this.ticket.isExternal && this.ticket.defectWeight > 0) {
          this.fetchDefectsForTicketById(this.ticket.ticketId).then(defects => {
            if (defects.length > 0) {
              this.hasDefects = true
            }
          })
        }

        if (!this.ticket.isExternal && this.ticket.hasLogCounts) {
          this.fetchTicketLogCounts(this.ticket.ticketId).then(logCounts => {
            if (logCounts.length > 0) {
              this.hasLogCounts = true
            }
          })
        }
      }
    } finally {
      this.loading = false
    }

    this.setCookies()
  },

  beforeDestroy () {
    Cookies.set(CookieKeys.TICKET_FORM_ENTERING_POUNDS, this.enteringPounds.toString())
  },

  methods: {
    ...mapActions('ticket', ['createTicket', 'updateTicket', 'getTicketsWithExternalNumber', 'fetchTicketLogCounts', 'getTicket']),
    ...mapActions('defects', ['fetchDefectsForTicketById']),
    ...mapActions('tract', ['getLoggersForTract']),
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapActions('product', ['fetchProducts']),
    ...mapActions('contract', ['fetchContracts']),

    numberWithCommas,
    deckSelected (deck) { this.selectedDeck = deck },
    fromDeckSelected (deck) { this.selectedFromDeck = deck },
    productChosen (product) { this.product = product },
    applyNewTime (time) {
      this.newTicketTime = time
    },

    loggerChosen (logger) {
      this.chosenLogger = logger
    },

    async contractChosen (contract) {
      if (contract === undefined || this.lastContractUpdatedId === contract.contractId) {
        return
      }

      if (this.contract && contract?.destinationAccountId !== this.contract.destinationAccountId && this.propTicket?.contractId === this.contract.contractId) {
        this.contractDestinationChange = true

        if (this.hasDefects) {
          this.defectWeightWarning = true
        }
      } else if (contract?.destinationAccountId === this.propTicket?.destinationAccountId) {
        this.contractDestinationChange = false
        this.defectWeightWarning = false
      }

      this.contract = contract

      if (!this.isEditing) {
        this.ticket.latitude = this.contract.latitude.toFixed(6)
        this.ticket.longitude = this.contract.longitude.toFixed(6)
      }
      if (this.requiresLogger) {
        const response = await this.getLoggersForTract(contract.tractId)
        const defaultLogger = response.find(l => l.isDefault)
        this.defaultLoggerId = defaultLogger ? defaultLogger.accountId : -1
        this.loggers = response
        this.lastContractUpdatedId = contract.contractId
      }
    },

    populateFromFieldSet (fieldSet) {
      for (const key of Object.keys(fieldSet)) {
        if (this.ticket[key] === undefined || this.ticket[key] === null) {
          console.warn(`Recognized field ${key} does not exist on ticket, skipping`)
          continue
        }
        const newFieldValue = fieldSet[key].value
        if (newFieldValue === undefined || newFieldValue === null) {
          console.warn(`Recognized field ${key} is null, skipping`)
          continue
        }
        if (typeof this.ticket[key] !== typeof newFieldValue) {
          console.warn(`Field ${key} (${typeof newFieldValue}) in recognized field set does not match the type of the field's current value (${typeof this.ticket[key]})`)
        }
        switch (key) {
          case 'inWeight':
          case 'outWeight':
            this.ticket[key] = (this.enteringPounds)
              ? fieldSet[key].value
              : fieldSet[key].value / 2000
            break
          default:
            this.ticket[key] = fieldSet[key].value
        }
      }
    },

    populateFromImageData (imageData) {
      this.populateFromFieldSet(imageData?.fields ?? {})
    },

    toggleEnteringPounds () {
      if (this.ticket.inWeight === '') {
        this.ticket.inWeight = 0
      }
      if (this.ticket.outWeight === '') {
        this.ticket.outWeight = 0
      }
      if (this.ticket.defectWeight === '') {
        this.ticket.defectWeight = 0
      }
      if (this.ticket.departureWeight === '') {
        this.ticket.departureWeight = 0
      }

      const multiplier = this.enteringPounds ? (1 / 2000) : 2000
      const decimals = this.enteringPounds ? 4 : 0
      this.ticket.inWeight = (parseFloat(this.ticket.inWeight) * multiplier).toFixed(decimals)
      this.ticket.outWeight = (parseFloat(this.ticket.outWeight) * multiplier).toFixed(decimals)
      this.ticket.defectWeight = (parseFloat(this.ticket.defectWeight) * multiplier).toFixed(decimals)
      this.ticket.departureWeight = (parseFloat(this.ticket.departureWeight) * multiplier).toFixed(decimals)
      this.enteringPounds = !this.enteringPounds
    },

    setCookies () {
      const enteringPounds = Cookies.get(CookieKeys.TICKET_FORM_ENTERING_POUNDS)
      if (enteringPounds) {
        this.enteringPounds = enteringPounds === 'true'
        if (!this.enteringPounds) {
          this.ticket.inWeight = (this.ticket.inWeight / 2000).toFixed(4)
          this.ticket.defectWeight = (this.ticket.defectWeight / 2000).toFixed(4)
          this.ticket.departureWeight = (this.ticket.departureWeight / 2000).toFixed(4)
          this.ticket.outWeight = (this.ticket.outWeight / 2000).toFixed(4)
        }
      }
    },

    setDateEntry (isDateEntry) {
      this.editTime = isDateEntry
      if (this.editTime) this.newTicketTime = undefined
    },

    async validate () {
      if (!this.contract) {
        this.setSnackError(this.$t('missingContract'))
        return false
      }

      if (!this.product) {
        this.setSnackError(this.$t('missingProduct'))
        return false
      }

      if (this.collectPieces && !this.ticket.pieces) {
        this.setSnackError(this.$t('missingPiecesInformation'))
        return false
      }

      if (this.requiresLogger && this.chosenLogger === undefined) {
        this.setSnackError(this.$t('contractRequiresLoggerSelection'))
        return false
      }

      if (this.requiresDeck && this.selectedDeck === undefined) {
        this.setSnackError(this.$t('contractRequiresDeckSelection'))
        return false
      }

      if (this.requiresFromDeck && this.selectedFromDeck === undefined) {
        this.setSnackError(this.$t('contractRequiresDeckSelection'))
        return false
      }

      if (this.requiresExt1 && this.ticket.extTicketNumber1 === '') {
        this.setSnackError(this.$t('contractRequiresExt1'))
        return false
      }

      if (this.requiresExt2 && this.ticket.extTicketNumber2 === '') {
        this.setSnackError(this.$t('contractRequiresExt2'))
        return false
      }

      if (this.ticket.inWeight - this.ticket.outWeight - this.ticket.defectWeight < 0) {
        this.setSnackError(this.$t('negativeNetWeightNotAllowed'))
        return false
      }

      if (this.ticket.extTicketNumber1.trim().length > 0 && !this.extOneValidated) {
        await this.validateTicketNumber(this.ticket.extTicketNumber1, { isExt1: true })
        if (this.alreadyExistingTickets.length > 0) {
          return false
        }
      }

      if (this.newTicketTime && this.newTicketTime?.applyAllDates && moment(localToUTC(this.newTicketTime.weighedInAt, true)).isAfter(localToUTC(this.newTicketTime.weighedOutAt, true))) {
        this.setSnackError({
          message: this.$t('weighedInDateAfterWeighedOutDate'),
          code: 'W8WS',
          source: ErrorSource.WebClient
        })
        return false
      }

      return this.$refs.form.$refs.ticketForm.validate()
    },

    async saveTicket () {
      const isValidated = await this.validate()
      if (!isValidated) { return }

      const ticketRequestObject = this.formatTicket()
      if (this.isEditing) {
        await this.updateTicket(ticketRequestObject)
        this.$emit('ticket-changed')
      } else {
        await this.createTicket(ticketRequestObject)
        if (this.createAnother) {
          this.resetForm()
        } else {
          this.$emit('ticket-changed')
        }
      }
    },

    async validateTicketNumber (ticketNumber, { isExt1 = false, isExt2 = false }) {
      if (isExt2) this.$refs.extTwoTextField.blur()
      if (ticketNumber.trim().length === 0 || this.contract === undefined) {
        return
      }

      if (isExt1 && this.propTicket?.extTicketNumber1 === ticketNumber.trim() && this.propTicket?.destinationAccountId === this.contract?.destinationAccountId) {
        this.extTicket1IconColor = 'black'
        this.extOneValidated = true
        this.setSnack(this.$t('noTicketNumberChange'))
        return
      }

      if (isExt2 && this.propTicket?.extTicketNumber2 === ticketNumber.trim() && this.propTicket?.destinationAccountId === this.contract?.destinationAccountId) {
        this.extTicket2IconColor = 'black'
        this.setSnack(this.$t('noTicketNumberChange'))
        return
      }

      this.alreadyExistingTickets = []

      let response = await this.getTicketsWithExternalNumber({
        extTicketNumber1: isExt1 ? ticketNumber : undefined,
        extTicketNumber2: isExt2 ? ticketNumber : undefined,
        destinationAccountId: this.contract?.destinationAccountId,
        isByproduct: this.isByProducts
      })

      response = response.filter(t => t.ticketId !== this.propTicket?.ticketId)

      if (response.length > 0) {
        if (isExt1) this.extTicket1IconColor = 'error'
        if (isExt2) this.extTicket2IconColor = 'error'
        this.alreadyExistingTickets = response
        this.setSnackError(this.$t('ticketAlreadyExistsWithNumber', { ticketNumber }))
      } else {
        if (isExt1) this.extTicket1IconColor = 'success'
        if (isExt2) this.extTicket2IconColor = 'success'
        this.setSnack(this.$t('uniqueTicketNumber'))
      }

      if (isExt1) this.extOneValidated = true
    },

    resetForm () {
      this.ticket.inWeight = 0
      this.ticket.outWeight = 0
      this.ticket.defectWeight = 0
      this.ticket.departureWeight = 0
      this.ticket.pieces = '0'
      this.ticket.averageLength = '0'
      this.ticket.trailerIdentifier = ''
      this.ticket.seal = undefined
      this.ticket.extTicketNumber1 = ''
      this.ticket.extTicketNumber2 = ''
      this.extOneValidated = true
      this.alreadyExistingTickets = []

      this.$refs.truckField.focus()
      this.$refs.form.$refs.ticketForm.resetValidation()
    },

    driverAccountChosen (driverAccount) {
      this.ticket.driverAccountId = driverAccount?.accountId ?? undefined
    },

    formatTicket () {
      let ticketRequestObject = {
        ticketId: this.isEditing ? this.ticket.ticketId : null,
        originationTicketId: this.isEditing ? this.ticket.originationTicketID : getOriginationTicketId(this.contract.contractId),
        trailerIdentifier: this.ticket.trailerIdentifier,
        seal: this.ticket.seal,
        latitude: this.ticket.latitude,
        longitude: this.ticket.longitude,
        productId: this.product.productId,
        contractId: this.contract.contractId,
        extTicketNumber1: this.ticket.extTicketNumber1,
        extTicketNumber2: this.ticket.extTicketNumber2,
        inWeight: this.getFormattedWeight(this.ticket.inWeight),
        outWeight: this.getFormattedWeight(this.ticket.outWeight),
        defectWeight: this.getFormattedWeight(this.ticket.defectWeight),
        departureWeight: this.getFormattedWeight(this.ticket.departureWeight),
        departedAt: this.ticket.departedAt,
        pieces: (this.collectPieces || this.hasLogCounts || this.ticket.pieces > 0) ? this.ticket.pieces ?? 0 : 0,
        driverAccountId: this.ticket.driverAccountId,
        loadCreatedAt: this.isEditing ? this.ticket.loadCreatedAt : moment.utc().format(),
        averageLength: this.ticket.averageLength,
        isByProducts: this.isByProducts,
        rolloutStatus: this.ticket.rolloutStatus
      }

      if (this.isEditing) {
        ticketRequestObject.modifiedAt = this.ticket.modifiedAt
        ticketRequestObject.departedAt = this.ticket.departedAt
      }

      if (this.requiresDeck) {
        ticketRequestObject.deckId = this.selectedDeck.deckId
      }

      if (this.requiresFromDeck) {
        ticketRequestObject.fromDeckId = this.selectedFromDeck.deckId
      }

      if (this.newTicketTime) {
        ticketRequestObject = this.applyTicketDates(ticketRequestObject, this.newTicketTime)
      } else {
        ticketRequestObject.weighedInAt = this.ticket.weighedInAt
        ticketRequestObject.weighedOutAt = this.ticket.weighedOutAt
      }

      if (this.ticket.loggerAccountId !== null && this.ticket.loggerAccountId !== undefined) {
        ticketRequestObject.loggerAccountId = this.ticket.loggerAccountId
      }

      if (this.requiresLogger) {
        ticketRequestObject.loggerAccountId = this.chosenLogger.accountId
      }

      return ticketRequestObject
    },

    applyTicketDates (ticketRequestObject, dates) {
      const { departedAt, weighedInAt, weighedOutAt, applyAllDates } = dates

      if (applyAllDates) {
        if (!this.isEditing) {
          ticketRequestObject.loadCreatedAt = this.ticket.contractType === ContractType.ByproductSale.value ? departedAt : weighedInAt
        } else {
          const dateToCompare = this.ticket.contractType === ContractType.ByproductSale.value ? departedAt : weighedInAt
          ticketRequestObject.loadCreatedAt = this.ticket.loadCreatedAt < dateToCompare
            ? this.ticket.loadCreatedAt
            : dateToCompare
        }

        ticketRequestObject.departedAt = departedAt
        ticketRequestObject.weighedInAt = weighedInAt
        ticketRequestObject.weighedOutAt = weighedOutAt
      } else {
        ticketRequestObject.weighedOutAt = weighedOutAt

        const applyDate = (key) => {
          const existingDate = this.ticket[key]
          ticketRequestObject[key] = existingDate && weighedOutAt < existingDate
            ? weighedOutAt
            : existingDate
        }

        applyDate('loadCreatedAt')
        applyDate('departedAt')
        applyDate('weighedInAt')
      }

      if (ticketRequestObject.departureWeight === 0 || !this.ticket.contractType === ContractType.ByproductSale.value) {
        ticketRequestObject.departedAt = null
      }

      if (ticketRequestObject.inWeight === 0) {
        ticketRequestObject.weighedInAt = null
      }

      if (ticketRequestObject.outWeight === 0) {
        ticketRequestObject.weighedOutAt = null
      }

      return ticketRequestObject
    },

    getFormattedWeight (weight) {
      if (!this.enteringPounds) {
        weight = Math.round(weight * 2000)
      }
      const asFloat = parseFloat(weight)
      return isNaN(asFloat) ? 0 : asFloat
    },

    getConversion (weight) {
      const conv = this.enteringPounds ? weight / 2000 : weight * 2000
      const unit = this.enteringPounds ? 'Tons' : 'Pounds'
      const decimals = this.enteringPounds ? 4 : 0
      return (isNaN(conv) ? '0 Tons' : `${conv.toFixed(decimals)} ${unit}`)
    },

    isValidEntry (val) {
      this.buttonDisabled = !val
    },

    openTicketDefectForm () {
      if (this.propTicket?.status === TicketStatus.InTransit.value) {
        this.setSnack(this.$t('cannotAddDefectsOnInTransitTicket'))
        return
      }

      if (this.contract?.contractId !== this.propTicket?.contractId) {
        this.setSnack(this.$t('cannotAddDefectsOnUnsavedTicket'))
        return
      }

      if (this.contract?.isExternal) {
        this.setSnack(this.$t('ticketMustBeInternal'))
        return
      }

      this.ticketDefectForm = true
    },

    closeLocationConfirm () {
      this.contractDestinationChange = false
      this.shownDestinationDialog = true
    },

    deleteTicketImage (imageUrl) {
      ticketImageClient.deleteImage(imageUrl).then(() => {
        const target = this.ticketImageData.findIndex(imageData => {
          const u1 = (imageUrl instanceof URL) ? imageUrl : new URL(imageUrl)
          const u2 = (imageData.resourceUri instanceof URL) ? imageData.resourceUri : new URL(imageData.resourceUri)
          return u1.pathname === u2.pathname
        })
        if (target !== -1) {
          this.ticketImageData.splice(target, 1)
          this.ticketImageData = [
            ...this.ticketImageData.slice(0, target),
            ...this.ticketImageData.slice(target)
          ]
        }
      })
    },

    async updateDefectWeight () {
      const updatedTicket = await this.getTicket(this.propTicket.ticketId)
      const multiplier = !this.enteringPounds ? (1 / 2000) : 1
      const decimals = !this.enteringPounds ? 4 : 0
      this.ticket.modifiedAt = updatedTicket.modifiedAt
      this.ticket.defectWeight = (parseFloat(updatedTicket.defectWeight) * multiplier).toFixed(decimals)
    },

    preventDecimalForPounds (e) {
      if (this.enteringPounds && e.key === '.') {
        e.preventDefault()
      }
    },

    preventDecimal (e) {
      if (e.key === '.') {
        e.preventDefault()
      }
    },

    onCoordFieldBlur () {
      if (this.rules.validLatitudeForTextBox(this.ticket.latitude) === true || Math.abs(parseFloat(this.ticket.latitude)) <= 90) {
        this.ticket.latitude = getFormattedCoordWithTrailingZeroes(this.ticket.latitude)
      }
      if (this.rules.validLongitudeForTextBox(this.ticket.longitude) === true || Math.abs(parseFloat(this.ticket.longitude)) <= 180) {
        this.ticket.longitude = getFormattedCoordWithTrailingZeroes(this.ticket.longitude)
      }
    },

    onOutWeightBlur () {
      if (this.outWeightChanged) {
        this.outWeightChanged = false
        this.editTime = true
      }
    },

    onOutWeightChange () {
      this.outWeightChanged = true
    }
  }
}
</script>
