<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.ContractManager" @n-shortcut="newPayPeriodPressed">
  <v-container fill-height fluid data-testid="pay-period-table" style="padding-bottom:4px;">
    <Dialog :stateId="dialogId" @dialog-closing="close(false)" maxWidth="80vw">
      <FiscalYear
        v-if="fiscalYearDialog"
        @close="close(false)"
      />
      <PayPeriodForm
        v-if="dialog"
        @close="close(false)"
        @pay-period-mutated="close(true)"
        :fiscalYear.sync="selectedFiscalYear"
        :lastPayPeriod="lastPayPeriod"
        :propPayPeriod="focusedPayPeriod"
      />
    </Dialog>
    <v-row no-gutters>
      <v-col cols="12">
        <material-card
        color="primary"
        :title="$t('calendar')"
        :text="$t('calendarDescription')"
        full-width
        :offsetBottom="0">
          <DataTable
          :items.sync="sortedPayPeriods"
          :headers="headers"
          :loading.sync="payPeriodsLoading"
          :actions="tableActions"
          :customCells="customCells"
          @create="newPayPeriodPressed"
          @refresh="refreshPayPeriods"
          @manage-fiscal-years="openManageFiscalYears()"
          >
            <template #end-date="{item}">
              {{getEndDate(item.endDate)}}
            </template>
            <template #pay-date="{item}">
              {{getEndDate(item.payDate)}}
            </template>
            <template #custom-controls>

              <v-select
              ref="payPeriodFiscalYearSelect"
              v-model="selectedFiscalYear"
              :items="allFiscalYears"
              :label="$t('fiscalYear')"
              :menu-props="{ bottom: true, offsetY: true }"
              color="primary"
              class="mx-4 mt-1 mb-n5"
              return-object
              outlined
              item-value="fiscalYearId"
              style="max-width: 280px;"
              @change="switchFiscalYear"
              >
                <template #selection="{item}">
                  <span style="white-space: nowrap;">
                    <Icon
                    dataTestId="fiscal-year-status"
                    :icon="getFiscalYearStatus(item).icon"
                    :tooltipText="getFiscalYearStatus(item).tooltipText"
                    :small="false"
                    :iconColor="getFiscalYearStatus(item).color"
                    />
                    {{ `${utcToLocalDate(item.startDate)} - ${getEndDate(item.endDate)}` }}
                  </span>
                </template>
                <template #item="{item}">
                  <Icon
                  dataTestId="fiscal-year-status"
                  :icon="getFiscalYearStatus(item).icon"
                  :tooltipText="getFiscalYearStatus(item).tooltipText"
                  :small="false"
                  :iconColor="getFiscalYearStatus(item).color"
                  />
                  <span>{{ `${utcToLocalDate(item.startDate)} - ${getEndDate(item.endDate)}` }}</span>
                </template>
              </v-select>
            </template>
            <template #status="{ item }">
              <Icon
              dataTestId="pay-period-status-icon"
              :icon="getStatus(item).icon"
              :iconColor="getStatus(item).color"
              :tooltipText="getStatus(item).tooltipText"
              :small="false"/>
            </template>
            <template #actions="{ item }">
              <Icon
              :tooltipText="lastPayPeriod.payPeriodId === item.payPeriodId && !futurePayPeriodsExist? $t('edit') : $t('cantEditPayPeriod')"
              :iconColor="lastPayPeriod.payPeriodId === item.payPeriodId && !futurePayPeriodsExist? 'success' : 'grey'"
              icon="mdi-pencil"
              dataTestId="pay-period-edit-button"
              @icon-clicked="mutatePayPeriod(item, 'edit')"
              :disabled="!userAssignedClaim(UserClaims.ContractManager)"
              />
              <Icon
              :tooltipText="$t('delete')"
              iconColor="error"
              icon="mdi-delete-forever"
              dataTestId="pay-period-delete-button"
              :disabled="!userAssignedClaim(UserClaims.ContractManager) || item?.status !== PayPeriodStatus.Inactive.value"
              @icon-clicked="mutatePayPeriod(item, 'delete')"
              />
            </template>
          </DataTable>
        </material-card>
        <v-dialog width="400px" v-model="deleting">
          <ConfirmDelete
            @close="close"
            v-if="deleting"
            :title="$t('payPeriod')"
            @delete="deleteItem"
            @cancel-delete="close"/>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12">
        <PayPeriodChart
        ref="chart"
        :fiscalYear="selectedFiscalYear"
        v-if="selectedFiscalYear && !responsive"
        :payPeriods.sync="allPayPeriods"
        @next-clicked="handleChartNav"
        @prev-clicked="handleChartNav"
        />
      </v-col>
    </v-row>
  </v-container>
</ShortcutWrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PayPeriodHeaders from '@/headers/PayPeriod'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import moment from 'moment'
import { UserClaims, PayPeriodStatus, FiscalYearStatus } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { mobileCompatible } from '@/utils/DeviceUtility'

export default {
  name: 'PayPeriods',

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDelete: () => import('../../helper/ConfirmDelete.vue'),
    PayPeriodForm: () => import('./PayPeriodForm.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    PayPeriodChart: () => import('./PayPeriodChart.vue'),
    FiscalYear: () => import('../fiscal-year/FiscalYear.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue')
  },

  data: () => ({
    dialogId: 'pay-periods',
    PayPeriodStatus,
    dialog: false,
    deleting: false,
    fiscalYearDialog: false,
    responsive: false,
    selectedFiscalYear: undefined,
    focusedPayPeriod: undefined,
    lastPayPeriodPreviousYear: undefined,
    futurePayPeriodsExist: false,
    UserClaims
  }),

  created () {
    this.refreshPayPeriods(true)
  },

  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  computed: {
    ...mapGetters('pay-period', ['allPayPeriods', 'payPeriodsLoading']),
    ...mapGetters('fiscal-year', ['allFiscalYears']),

    headers () {
      return PayPeriodHeaders.payPeriodHeaders()
    },

    sortedPayPeriods () {
      return JSON.parse(JSON.stringify(this.allPayPeriods)).sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
    },

    tableActions () {
      return [
        {
          actionName: 'manage-fiscal-years',
          icon: 'mdi-calendar',
          text: this.$t('manageFiscalYears')
        },
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.ContractManager) || this.selectedFiscalYear?.status === PayPeriodStatus.Closed.value
        }
      ]
    },

    customCells () {
      return [
        {
          slotName: 'status',
          value: 'status'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'start-date',
          value: 'startDate',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'end-date',
          value: 'endDate'
        },
        {
          slotName: 'pay-date',
          value: 'payDate'
        }
      ]
    },

    lastPayPeriod () {
      if (this.allPayPeriods.length === 0) return this.lastPayPeriodPreviousYear ?? undefined

      return this.allPayPeriods.reduce((lastPayPeriod, currentPayPeriod) => {
        return moment(lastPayPeriod.endDate).isBefore(currentPayPeriod.endDate) ? currentPayPeriod : lastPayPeriod
      })
    }
  },

  methods: {
    ...mapActions('pay-period', ['fetchPayPeriods', 'fetchPayPeriodsNoMutation', 'updatePayPeriod', 'deletePayPeriod']),
    ...mapActions('fiscal-year', ['fetchFiscalYears']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    userAssignedClaim,

    async refreshPayPeriods (firstFetch) {
      const response = await this.fetchFiscalYears()
      if (response.length === 0) {
        this.selectedFiscalYear = undefined
        if (firstFetch) {
          this.setSnack(this.$t('defineFiscalYearToCreatePayPeriods'))
        }
      } else {
        if (firstFetch || this.selectedFiscalYear === undefined) {
          this.selectedFiscalYear = this.allFiscalYears.find(fy => fy.status === FiscalYearStatus.Open.value)
        }
        await this.fetchPayPeriods(this.selectedFiscalYear.fiscalYearId)
        this.futurePayPeriodsExist = false
        if (this.lastPayPeriod && this.lastPayPeriod.endDate === this.selectedFiscalYear.endDate) { // Determine whether to allow edit of last pay period in selected fiscal year
          const followingYearId = this.allFiscalYears.find(fy => fy.startDate === this.selectedFiscalYear.endDate)?.fiscalYearId
          if (followingYearId) {
            this.fetchPayPeriodsNoMutation(followingYearId).then(allPp => {
              if (allPp.length >= 1) {
                this.futurePayPeriodsExist = true
              }
            })
          }
        }
        if (this.selectedFiscalYear?.status === PayPeriodStatus.Inactive.value) { // Record pay period from previous year if in a future year
          this.fetchPayPeriodsNoMutation(
            this.allFiscalYears[this.allFiscalYears.findIndex(fy => fy.fiscalYearId === this.selectedFiscalYear.fiscalYearId) - 1].fiscalYearId
          ).then(allPp => {
            if (allPp.length >= 1) {
              this.lastPayPeriodPreviousYear = allPp.reduce((lastPayPeriod, currentPayPeriod) => {
                return moment(lastPayPeriod.endDate).isBefore(currentPayPeriod.endDate) ? currentPayPeriod : lastPayPeriod
              })
            }
          })
        }
      }
    },

    getEndDate (date) {
      return utcToLocalDate(moment.utc(date).add(-1, 'days').format(), 'MM/DD/YYYY')
    },

    switchFiscalYear (fy) {
      this.selectedFiscalYear = fy
      this.refreshPayPeriods()
      this.$refs.payPeriodFiscalYearSelect.blur()
    },

    async handleChartNav (direction) {
      let index = this.allFiscalYears.findIndex(f => f.fiscalYearId === this.selectedFiscalYear.fiscalYearId)
      index += direction
      this.selectedFiscalYear = this.allFiscalYears[index]
      await this.refreshPayPeriods()
      this.$vuetify.goTo(this.$refs.chart, {
        duration: 400,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    },

    mutatePayPeriod (payPeriod, mutationType) {
      if (mutationType === 'edit') {
        if (payPeriod.payPeriodId !== this.lastPayPeriod.payPeriodId) {
          return
        }
      }

      this.focusedPayPeriod = payPeriod

      switch (mutationType) {
        case 'delete':
          this.deleting = true
          break
        case 'edit':
          this.dialog = true
          this.openPayPeriodForm(payPeriod)
          break
        case 'create':
          if (this.selectedFiscalYear) {
            this.openPayPeriod = this.allPayPeriods.find(pp => pp.payPeriodId === this.lastPayPeriod)
            this.creating = true
            this.dialog = true
            this.openPayPeriodForm()
          } else {
            this.setSnack(this.$t('defineFiscalYearToCreatePayPeriods'))
          }
          break
        default:
          this.close()
          break
      }
    },

    getStatus (payPeriod) {
      switch (payPeriod?.status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'success',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('exported'),
            icon: 'mdi-export'
          }
      }
    },

    openManageFiscalYears () {
      this.close()
      this.fiscalYearDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '900px' })
    },

    openPayPeriodForm (payPeriod) {
      this.close()
      this.focusedPayPeriod = payPeriod
      this.dialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '900px' })
    },

    async deleteItem () {
      this.focusedPayPeriod.fiscalYearId = this.selectedFiscalYear.fiscalYearId
      await this.deletePayPeriod(this.focusedPayPeriod)
      this.close(true)
    },

    close () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.refreshPayPeriods()

      this.deleting = false
      this.dialog = false
      this.fiscalYearDialog = false
      this.focusedPayPeriod = undefined
    },

    onResponsiveInverted () {
      this.responsive = mobileCompatible()
    },

    newPayPeriodPressed () {
      switch (this.selectedFiscalYear?.status) {
        case FiscalYearStatus.Closed.value: {
          this.setSnack(this.$t('cannotCreateNewPayPeriodInPastFiscalYear'))
          return
        }
        case FiscalYearStatus.Open.value: {
          this.mutatePayPeriod(null, 'create')
          return
        }
        case FiscalYearStatus.Inactive.value:
        case undefined: {
          if (!this.lastPayPeriodPreviousYear || Math.abs(moment(this.lastPayPeriodPreviousYear.endDate).diff(moment(this.selectedFiscalYear.startDate), 'days')) > 1) {
            this.setSnack(this.$t('mustFinishCreatingPreviousYearPayPeriods'))
          } else {
            this.mutatePayPeriod(null, 'create')
          }
        }
      }
    },

    getFiscalYearStatus (fiscalYear) {
      switch (fiscalYear?.status) {
        case FiscalYearStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case FiscalYearStatus.Open.value:
          return {
            color: 'success',
            tooltipText: this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case FiscalYearStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
      }
    }
  }
}
</script>
