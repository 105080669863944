<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <span class="mr-2 subtitle-1">
          {{ $t('receiverSettings') }}
        </span>
        <Icon
          icon="mdi-plus"
          iconColor="primary"
          :tooltipText="$t('modifyReceiverSettings', { modify: $t('create') })"
          :small="false"
          dataTestId="deck-create-button"
          @icon-clicked="createReceiverSettings"
          :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"/>
      </v-col>
    </v-row>
    <v-row v-if="receiverSettings.length > 0">
      <v-col v-for="(item, index) in receiverSettings" :key="index" cols="12" lg="3">
        <v-card outlined rounded class="fill-height">
          <v-card-title>
            <v-row justify="space-between" no-gutters style="width: 100%;">
              <v-col class="mr-2 text-truncate">
                <span class="subtitle-1">
                  {{ item.name }}
                </span>
              </v-col>
              <v-col cols="auto">
                <Icon
                  icon="mdi-information-outline"
                  iconColor="primary"
                  :tooltipText="$t('showDetails')"
                  :small="false"
                  @icon-clicked="openReceiverDetail(item)"
                />
                <Icon
                  icon="mdi-pencil"
                  :tooltipText="$t('edit')"
                  iconColor="success"
                  @icon-clicked="editReceiverSettings(item)"
                  :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                />
                <Icon
                  dataTestId="scale-delete-button"
                  icon="mdi-delete"
                  iconColor="error"
                  :tooltipText="$t('delete')"
                  @icon-clicked="openDeleteDialog(item.locationReceiverSettingId)"
                  :disabled="!userAssignedClaim(UserClaims.GlobalAdmin)"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="mt-n2">
            <v-row dense>
              <v-col cols="12">{{ $t('loadCreationType') }}: {{ ReceiverLoadCreationTypes.fromInt(item.loadCreationSetting.receiverLoadCreationType) }}</v-col>
              <v-col cols="12">{{ $t('scaleCount') }}: {{ item.scaleSettings.length }}</v-col>
              <v-col cols="12">{{ $t('printerAddress') }}: {{ item.printerSetting.address.trim().length > 0 ? item.printerSetting.address : $t('notSpecified') }}</v-col>
              <v-col cols="12">{{ $t('defectCollectionSetting', {setting: $t('behavior')}) }}: {{ ReceiverDefectCollectionBehavior.fromInt(item.defectCollectionSetting.collectionBehavior) }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="auto">
        <span class="mr-2">{{ $t('noReceiverSettings') }}</span>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" maxWidth="80vw" @close="resetDialogs">
      <ReceiverSettingsDetail
        v-if="receiverSettingsDetail"
        :propReceiverSettings="chosenSettings"
        @close="resetDialogs"
        @edit-receiver-settings="editReceiverSettings"
      />
      <ReceiverSettingsForm
        v-if="receiverSettingsForm"
        :isEditing="editing"
        :propSettings="chosenSettings"
        :locationId="locationId"
        :defectCollectionMode="defectCollectionMode"
        @close="resetDialogs"
      />
      <ConfirmDialog
        v-if="confirmDeleteDialog"
        :title="$t('deleteReceiverSettingsTitle')"
        :body="$t('deleteReceiverSettingsBody')"
        @confirm="deleteReceiverSettings"
        @cancel="resetDialogs"
      />
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { UserClaims, ReceiverLoadCreationTypes, ReceiverDefectCollectionBehavior } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
export default {
  name: 'ReceiverSettings',

  props: {
    locationId: {
      type: Number,
      required: true
    },
    defectCollectionMode: {
      type: Number,
      required: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ReceiverSettingsDetail: () => import('./ReceiverSettingsDetail.vue'),
    ReceiverSettingsForm: () => import('./ReceiverSettingsForm.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('receiver-settings-panel'),
    chosenSettings: undefined,
    receiverSettingsDetail: false,
    receiverSettingsForm: false,
    confirmDeleteDialog: false,
    editing: false,
    receiverToDelete: undefined,
    UserClaims,
    ReceiverLoadCreationTypes,
    ReceiverDefectCollectionBehavior
  }),

  created () {
    this.fetchReceiverSettings(this.locationId)
  },

  computed: {
    ...mapGetters('receiver-settings', ['receiverSettings'])
  },

  methods: {
    ...mapActions('receiver-settings', ['fetchReceiverSettings', 'fetchReceiverSettingsById', 'deleteReceiverSettingsById']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    userAssignedClaim,

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.receiverSettingsDetail = false
      this.receiverSettingsForm = false
      this.editing = false
      this.confirmDeleteDialog = false
      this.receiverToDelete = undefined
    },

    createReceiverSettings () {
      this.resetDialogs()
      this.chosenSettings = undefined
      this.editing = false
      this.receiverSettingsForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    editReceiverSettings (receiver) {
      this.chosenSettings = receiver
      this.resetDialogs()
      this.editing = true
      this.receiverSettingsForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    async deleteReceiverSettings () {
      await this.deleteReceiverSettingsById({ locationId: this.locationId, receiverId: this.receiverToDelete })
      await this.fetchReceiverSettings(this.locationId)
      this.resetDialogs()
    },

    openReceiverDetail (receiver) {
      this.chosenSettings = receiver
      this.receiverSettingsDetail = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    openDeleteDialog (receiverId) {
      this.confirmDeleteDialog = true
      this.receiverToDelete = receiverId
      this.openOrUpdateDialog({ id: this.dialogId, width: '400' })
    }
  }
}
</script>
