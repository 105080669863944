<template>
  <v-card>
    <v-card-title :class="headerClass || 'primary white--text'">
      <span class="headline">{{$t('modificationHistoryForX', { x: entityName })}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text>
      <template v-if="auditEntryLoader.loading || auditEntryLoader.error">
        <div class="py-12" style="text-align: center">
          <v-progress-circular v-if="auditEntryLoader.loading" color="primary" indeterminate/>
          <span class="headline" v-if="auditEntryLoader.error">
            <v-icon class="mb-1">mdi-alert-outline</v-icon>
            {{$t('couldNotLoadModificationHistory')}}
          </span>
        </div>
      </template>
      <template v-else>
        <DataTable
        :items.sync="auditEntries"
        :headers="auditHeaders"
        :customCells="customColumns"
        tableKey="rowKey">
          <template #filters>
          <v-chip v-for="fc in filterChips" :key="`filter-chip-${fc.text}`" close @click:close="fc.remove">
            <span class="font-weight-bold pr-1">{{fc.name}}:</span>
            {{fc.text}}
          </v-chip>
          </template>
          <template #component="{ item }">
            <div v-for="(segment, i) in item.componentSegments" :key="i">
              <span :key="`${item.rowKey}-${i}-fe`" v-if="i > 0" class="grey--text px-1" style="user-select: none">{{'>'}}</span>
              <span :key="`${item.rowKey}-${i}`" @click="setSegmentFilter(segment, item)" class="filter-clickable">{{segment}}</span>
            </div>
          </template>
          <template #modified-by="{ item }">
            <span class="filter-clickable" @click="setChangedByFilter(item)">{{ item.modifiedBy }}</span>
          </template>
          <template #modified-at="{ item }">
            {{ item.modifiedAt.toLocaleString('en-US') }}
          </template>
          <template #modified-reason="{ item }">
            <span @click="setCauseFilter(item)" class="filter-clickable">{{item.modifiedReason}}</span>
          </template>
          <template #op-type="{ item }">
            <span @click="setTypeFilter(item)" class="filter-clickable">{{item.opType}}</span>
          </template>
        </DataTable>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { getAuditEntries } from '@/utils/AuditChanges.js'
import { ResourceLoader } from '../../utils/ResourceLoader.js'
import AuditHeaders from '../../headers/Audit.js'

export default {
  name: 'ModificationHistory',
  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    entityType: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    },
    entityName: {
      type: String,
      required: true
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    allAuditEntries: [],
    auditEntryLoader: ResourceLoader.empty,
    filters: {
      segment: [],
      opType: undefined,
      changedBy: undefined,
      cause: undefined
    }
  }),

  computed: {
    auditEntries () {
      const { segment, opType, changedBy, cause } = this.filters
      const noFilters = segment.length === 0 && opType === undefined && changedBy === undefined && cause === undefined
      return (noFilters)
        ? this.allAuditEntries
        : this.allAuditEntries.filter(ae =>
          (segment.length === 0 || segment.every((s, idx) => ae.componentSegments?.[idx] === s)) &&
          (!opType || ae.opType === opType) &&
          (!changedBy || ae.modifiedBy === changedBy) &&
          (!cause || ae.modifiedReason === cause))
    },
    auditHeaders () {
      return AuditHeaders.auditHeaders(this.$i18n.locale)
    },
    customColumns () {
      return [
        {
          slotName: 'component',
          value: 'componentSegments'
        },
        {
          slotName: 'modified-by',
          value: 'modifiedBy'
        },
        {
          slotName: 'modified-at',
          value: 'modifiedAt'
        },
        {
          slotName: 'modified-reason',
          value: 'modifiedReason'
        },
        {
          slotName: 'op-type',
          value: 'opType'
        }
      ]
    },
    filterChips () {
      return [
        this.filters.changedBy ? {
          name: this.$t('changedBy'),
          text: this.filters.changedBy,
          remove: this.removeChangedByFilter
        } : undefined,
        this.filters.opType ? {
          name: this.$t('type'),
          text: this.filters.opType,
          remove: this.removeTypeFilter
        } : undefined,
        this.filters.segment.length > 0 ? {
          name: this.$t('field'),
          text: this.filters.segment.map((s, idx) => idx > 0 ? ` > ${s}` : s).join(''),
          remove: this.removeSegmentFilter
        } : undefined,
        this.filters.cause ? {
          name: this.$t('cause'),
          text: this.filters.cause,
          remove: this.removeCauseFilter
        } : undefined
      ].filter(fc => fc !== undefined)
    }
  },

  async created () {
    this.auditEntryLoader = new ResourceLoader(async () => { this.allAuditEntries = await this.getAuditEntries() })
    await this.auditEntryLoader.load()
  },

  methods: {
    async getAuditEntries () {
      return await getAuditEntries(this.entityType, this.entityId)
    },

    setTypeFilter (entry) {
      this.filters.opType = entry.opType
    },

    removeTypeFilter () {
      this.filters.opType = undefined
    },

    setChangedByFilter (entry) {
      this.filters.changedBy = entry.modifiedBy
    },

    removeChangedByFilter () {
      this.filters.changedBy = undefined
    },

    setCauseFilter (entry) {
      this.filters.cause = entry.modifiedReason
    },

    removeCauseFilter () {
      this.filters.cause = undefined
    },

    setSegmentFilter (segment, auditEntry) {
      const sliceEnd = auditEntry.componentSegments.lastIndexOf(segment) + 1
      this.filters.segment = (sliceEnd !== -1)
        ? auditEntry.componentSegments.slice(0, sliceEnd)
        : []
    },

    removeSegmentFilter () {
      this.filters.segment = []
    }
  }
}
</script>

<style>
.filter-clickable:hover {
  text-decoration: underline;
}
</style>
