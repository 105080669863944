<template>
  <v-container fluid data-testid="downloads-view">
    <material-card
      color="primary"
      :title="$t('downloads')"
      :text="$t('downloadsDescription')"
      full-width
    >
      <v-tabs v-model="currentTab" class="mt-4" color="primary">
        <v-tab>{{$t('ticketsAndFinancials')}}</v-tab>
        <v-tab>{{$t('modificationHistory')}}</v-tab>
        <v-tab-item disabled>
          <TicketsAndFinancialsDownloads />
        </v-tab-item>
        <v-tab-item disabled>
          <ModificationHistoryDownloads />
        </v-tab-item>
      </v-tabs>
    </material-card>
  </v-container>
</template>

<script>
export default {
  name: 'Imports',

  components: {
    TicketsAndFinancialsDownloads: () => import('./TicketsAndFinancialsDownloads.vue'),
    ModificationHistoryDownloads: () => import('./ModificationHistoryDownloads.vue')
  },

  data: () => ({
    currentTab: 0
  })
}
</script>
