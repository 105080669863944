<template>
  <v-expansion-panels popout v-model="openPanel" accordion tile>
    <v-expansion-panel
      v-for="(receivableByAccount) in receivablesByBusinessEntity"
      :key="receivableByAccount.businessEntityName"
    >
      <v-expansion-panel-header>
        {{ receivableByAccount.businessEntityName }}
        <v-spacer/>
        <template #actions>
          {{ formatMoney(receivableByAccount.amount) }}
          <v-icon class="ml-2"
            >mdi-chevron-down</v-icon
          >
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          v-if="receivableByAccount.receivables.length > 0"
          :headers="headers"
          :items.sync="receivableByAccount.receivables"
          :hide-default-footer="receivableByAccount.receivables.length < 10"
        >
          <template v-slot:item.amount="{ item }">{{
            `${formatMoney(item.amount)}`
          }}</template>
          <template v-slot:item.rate="{ item }">
            <span>{{ formatMoney(item.computedAtRate) }}</span>
          </template>
          <template #item.payByTons="{item}">{{ getPayByTonsText(item) }}</template>
          <template #item.payOn="{item}">{{
            payOn(item.computedAtPayOn)
          }}</template>
          <template #item.payBy="{item}">{{
            payBy(item.computedAtPayBy)
          }}</template>
          <template v-slot:item.createdAt="{ item }">{{
            utcToLocalDate(item.ticketCreatedAt)
          }}</template>
          <template #item.activityWithSplit="{item}">
            <span>
              {{ item.activityName }}
            </span>
            <span v-if="item.computedAtSplit">
              ({{ formatPercentage(item.computedAtSplit) }})
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <Icon
              iconColor="black"
              icon="mdi-information-outline"
              dataTestId="receivable-details-button"
              :tooltipText="$t('details')"
              :disabled="item.recoveryMethod === 1"
              @icon-clicked="viewReceivable(item)"
            >
            </Icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { formatMoney, formatPercentage, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { ticketReceivableHeaders } from '@/headers/Receivable.js'
import { PayOn, PayBy } from '@/utils/Enumerations.js'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'ReceivableExpansionPanels',

  props: {
    receivables: {
      type: Array,
      required: true
    },
    ticket: {
      type: Object,
      required: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    openPanel: 0,
    PayBy
  }),

  computed: {
    headers () {
      return ticketReceivableHeaders(this.$i18n.locale)
    },

    formattedReceivables () {
      return this.receivables.map(r => ({
        ...r,
        payByTons: tonStringFromPounds(r.payByWeight, 3, r.computedAtPayBy === PayBy.MetricWeight.value)
      }))
    },

    receivablesByBusinessEntity () {
      const receivablesByBusinessEntity = []
      this.formattedReceivables.forEach(p => {
        const entityIndex = receivablesByBusinessEntity.findIndex(pbv => pbv.businessEntityName === p.businessEntityName)
        const mappedReceivable = {
          ...p,
          activityWithSplit: {
            name: p.activityName,
            split: p.computedAtSplit
          }
        }
        if (entityIndex === -1) {
          receivablesByBusinessEntity.push({
            businessEntityName: p.businessEntityName,
            amount: p.amount,
            receivables: [mappedReceivable]
          })
        } else {
          receivablesByBusinessEntity[entityIndex].receivables.push(mappedReceivable)
          receivablesByBusinessEntity[entityIndex].amount += p.amount
        }
      })

      return receivablesByBusinessEntity
    },

    contractTypeColor () {
      return colorClassForContractType(this.ticket?.contractType)
    }
  },

  methods: {
    formatMoney,
    formatPercentage,
    tonStringFromPounds,
    utcToLocalDate,
    payOn: (x) => PayOn.fromInt(x),
    payBy: (x) => PayBy.fromInt(x),

    getPayByTonsText (item) {
      return item.computedAtPayBy === PayBy.Load.value
        ? ''
        : item.payByTons
    }
  }
}
</script>
