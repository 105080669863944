import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async resetState (context) {
    context.commit('resetState')
  },
  async fetchPayables (context, contractMode) {
    const queryString = `byproduct=${contractMode.value === 1}&transfer=${contractMode.value === 0}&logYardSale=${contractMode.value === 0}`
    const response = await ApiServer.get(`payables?${queryString}`)
    context.commit('setPayables', response.data)
    return response.data
  },
  async fetchAllTractPayables (context) {
    const response = await ApiServer.get('tractPayables')
    context.commit('setTractPayables', response.data)
    return response.data
  },
  async fetchUnpaidAdvances (context) {
    const response = await ApiServer.get('v2/advances?limitToUnpaid=true')
    context.commit('setUnpaidAdvances', response.data)
    return response.data
  },
  async getPayable (_, payableId) {
    const path = `payables/${payableId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async getPayablesForAccount (_, accountId) {
    const path = `payables/?accountId=${accountId}`
    const response = await ApiServer.get(path)
    return response.data
  },
  async fetchUnpaidAccountPayables (context) {
    const response = await ApiServer.get('accountpayables')
    context.commit('setAccountPayables', response.data)
    return response.data
  }
}
