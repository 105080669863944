<template>
  <v-expansion-panels popout v-model="openPanel" accordion tile>
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{$t('ticketNumberDefects', { ticketNumber: ticket.ticketNumber })}}
        <span v-if="ticketHasCorrections">*</span>
        <v-spacer/>
        <template #actions>
          {{ tonStringFromPounds(totalDefectWeight) }} (t)
          <v-icon class="ml-2">mdi-chevron-down</v-icon
          >
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <DefectTable :ticketDefects="ticketDefects"/>
        <span class="font-italic" v-if="ticketHasCorrections">{{ $t('originalTicketDefectsCorrection') }}</span>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { tonStringFromPounds } from '@/utils/NumericMutations'
import { CorrectionType } from '@/utils/Enumerations'
export default {
  name: 'TicketDefectsExpansionPanels',

  components: {
    DefectTable: () => import('@/components/defects/DefectTable.vue')
  },

  props: {
    ticketDefects: {
      type: Array,
      required: true,
      default: undefined
    },

    ticket: {
      type: Object,
      required: true,
      default: undefined
    }
  },

  data: () => ({
    openPanel: 0
  }),

  computed: {
    ticketHasCorrections () {
      if (this.ticket?.correctionType !== CorrectionType.SpecifyNewTicketValues.value) return false
      return this.ticket.defectWeight !== this.totalDefectWeight
    },

    totalDefectWeight () {
      return this.ticketDefects.reduce((t, d) => t + d.totalLBS, 0)
    }
  },

  methods: {
    tonStringFromPounds
  }
}
</script>
