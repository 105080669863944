<template>
  <v-card>
    <v-card-title
    :class="`headline white--text ${!isByproduct ? 'primary' : ''}`"
    :style="isByproduct ? 'background-color: #363636' : ''">
      <span>
        {{ $t('correctionsFor', { name: payer.counterpartyName })}}
      </span>
      <v-spacer/>
      <span class="mr-3">{{ totalCorrected }}</span>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text class="mt-6">
      <DataTable
        hideHeader
        :items.sync="payer.corrections"
        :headers="headers"
        :customCells="customCells"
        :no-data-text="$t('noRecoveries')"
      >
        <template #activity="{item}">
          <Icon
          dataTestId="receivables-by-payer-corrections"
          margin="mt-n1 mr-1"
          icon="mdi-auto-fix"
          :small="true"
          :iconColor="item.correctionSide === 0 ? 'error' : 'success'"
          :tooltipColor="item.correctionSide === 0 ? 'error' : 'success'"
          :tooltipText="getCorrectionTooltipText(item)"
          />
          <span>
            {{item.activity}}
          </span>
        </template>
        <template #net-weight="{item}">
          {{tonStringFromPounds(item.netWeight)}}
        </template>
        <template #amount="{item}">
          {{getAmountText(item) }}
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td v-for="(_, i) in Array.from({length: headers.length - 1})" :key="`rbp-summaryrow-${i}`"/>
            <td class="text-right">{{totalCorrected}}</td>
          </tr>
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations'
import { CorrectionTypes, CorrectionSide } from '@/utils/Enumerations.js'
import { prCorrectionHeaders } from '@/headers/Cycles.js'

export default {
  name: 'CounterpartyCorrectionsDialog',

  props: {
    payer: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    totalCorrected () {
      return formatMoney(this.payer.corrections.reduce((total, curr) => total + curr.amount, 0))
    },
    headers () {
      return prCorrectionHeaders({ isByproduct: this.isByproduct })
    },
    customCells () {
      return [
        {
          slotName: 'activity',
          value: 'activity'
        },
        {
          slotName: 'net-weight',
          value: 'netWeight'
        },
        {
          slotName: 'amount',
          value: 'amount'
        }
      ]
    }
  },

  methods: {
    formatMoney,
    tonStringFromPounds,
    close () {
      this.dialog = false
    },

    getCorrectionTooltipText ({ correctionType, correctionSide }) {
      return `${CorrectionTypes.find(ct => ct.value === correctionType).name}
        ======
        ${CorrectionSide.find(cs => cs.value === correctionSide).name}`
    },

    getAmountText ({ amount, grossAmount }) {
      if (amount === grossAmount || this.isByproduct) {
        return formatMoney(amount)
      }

      return `${formatMoney(amount)} (${formatMoney(grossAmount)})`
    }
  }
}
</script>
