import csv from 'jquery-csv'
import { localToUTC } from '@/utils/DateFormatter.js'
import { getOriginationTicketId } from '@/utils/OriginationIdUtility.js'
import moment from 'moment'
import { generateCsvString } from '@/utils/CSVUtility'
import { ContractMode } from '../../../utils/Enumerations'
import i18n from '../../../i18n'

export const getNewTicketsFromCSV = (csvLines, productNameMap, accountNameIdMap, destinationDeckMap, fromDeckMap, { contractId, latitude, longitude }, contractMode, loggerAccountId) => {
  const ticketsToPost = []
  const validationErrors = []

  for (const [index, ticketLine] of csvLines.entries()) {
    let ticket

    try {
      ticket = getTicketFromLine(csv.toArray(ticketLine), contractMode, false)
    } catch (_) {
      throw new Error('ERROR_PARSING')
    }

    const product = productNameMap[ticket.productName.toLowerCase()]
    let driverAccountId = null

    if (contractMode.value !== ContractMode.Byproducts.value) {
      driverAccountId = accountNameIdMap[ticket.driverName.trim().toLowerCase()]?.accountId

      if (ticket.driverName.trim().length > 0 && (driverAccountId === undefined || driverAccountId === null)) {
        validationErrors.push(addValidationError('unknownDriverNameCSV', { driverName: ticket.driverName, rowNumber: index + 1 }))
        continue
      }
    }

    let fromDeckId = null
    let deckId = null

    if (contractMode.value === ContractMode.Transfers.value || contractMode.value === ContractMode.LogYardSale.value) {
      fromDeckId = fromDeckMap[ticket.fromDeck.toLowerCase()]?.deckId

      if (ticket.fromDeck.trim().length === 0 || (ticket.fromDeck.trim().length > 0 && (fromDeckId === undefined || fromDeckId === null))) {
        validationErrors.push(addValidationError('unknownFromDeckCSV', { deckName: ticket.fromDeck, rowNumber: index + 1 }))
        continue
      }

      if (contractMode.value === ContractMode.Transfers.value) {
        deckId = destinationDeckMap[ticket.deck.toLowerCase()]?.deckId

        if (ticket.deck.trim().length === 0 || (ticket.deck.trim().length > 0 && (deckId === undefined || deckId === null))) {
          validationErrors.push(addValidationError('unknownDestinationDeckCSV', { deckName: ticket.deck, rowNumber: index + 1 }))
          continue
        }
      }
    }

    // Validation
    if (product === undefined || product === null) {
      validationErrors.push(addValidationError('unknownProductNameCSV', { productName: ticket.productName, rowNumber: index + 1 }))
      continue
    }

    if (ticket.trailerIdentifier.trim().length === 0 || ticket.trailerIdentifier.trim().length > 10) {
      validationErrors.push(addValidationError('invalidTrailerIdentifierCSV', { rowNumber: index + 1 }))
      continue
    }

    const validationResult = validateNumericProperties(ticket, index, contractMode.value === ContractMode.Byproducts.value, product)
    const { textKey, args } = validationResult

    if (textKey !== undefined) {
      validationErrors.push({ textKey, args })
      continue
    }

    let loadCreatedAt = moment.utc().format()
    let weighedInAt = null
    let weighedOutAt = null
    let departedAt = null

    if (ticket.weighedOutAt !== '') {
      const dateFromCSV = localToUTC(moment.utc(ticket.weighedOutAt).hours(8).format(), true)
      loadCreatedAt = dateFromCSV

      if (validationResult.formattedDepartureWeight > 0) {
        departedAt = dateFromCSV
      }

      if (validationResult.formattedInWeight > 0) {
        weighedInAt = dateFromCSV
      }

      if (validationResult.formattedOutWeight > 0) {
        weighedOutAt = dateFromCSV
      }
    }

    ticketsToPost.push({
      originationTicketId: getOriginationTicketId(contractId),
      productId: product.productId,
      trailerIdentifier: ticket.trailerIdentifier,
      departureWeight: validationResult.formattedDepartureWeight,
      inWeight: validationResult.formattedInWeight,
      outWeight: validationResult.formattedOutWeight,
      defectWeight: validationResult.formattedDefectWeight,
      extTicketNumber1: ticket.extTicketNumber1,
      extTicketNumber2: ticket.extTicketNumber2,
      driverAccountId: driverAccountId ? Number(driverAccountId) : null,
      averageLength: validationResult.formattedAverageLength,
      pieces: validationResult.formattedPieces,
      fromDeckId: fromDeckId ? Number(fromDeckId) : null,
      deckId: deckId ? Number(deckId) : null,
      loggerAccountId,
      loadCreatedAt,
      contractId,
      departedAt,
      weighedInAt,
      weighedOutAt,
      latitude,
      longitude
    })
  }

  return {
    tickets: ticketsToPost,
    validationErrors: validationErrors
  }
}

export const getTicketNumbersFromCSV = (csvLines) => {
  const csvArrays = csvLines.map(line => csv.toArray(line))
  return csvArrays.reduce((arr, line) => {
    const [ticketNumber] = line
    arr.push(ticketNumber.replaceAll(',', ''))
    return arr
  }, [])
}

export const getUpdatedTicketsFromCSV = async (csvLines, ticketsFromServer, contractMode, productNameMap) => {
  const ticketsToUpdate = []
  const validationErrors = []
  const unupdatedTickets = []

  const csvArrays = csvLines.map(line => csv.toArray(line))
  const ticketNumberDict = getTicketDictionaryForServerTickets(ticketsFromServer)
  const missingTicketErrors = checkForMatchingTickets(new Set(csvArrays.map(l => l[0])), new Set(Object.keys(ticketNumberDict)))

  if (missingTicketErrors !== null) return missingTicketErrors

  for (const [index, ticketFromCsv] of csvArrays.entries()) {
    const ticketFromLine = getTicketFromLine(ticketFromCsv, contractMode, true)
    const ticket = ticketNumberDict[ticketFromLine.ticketNumber]
    if (ticket === undefined) {
      validationErrors.push(addValidationError('couldNotFindTicket', { ticketNumber: ticketFromLine.ticketNumber, rowNumber: index + 1 }))
      continue
    }

    ticketFromLine.departureWeight = ticket.departureWeight
    ticketFromLine.inWeight = ticketFromLine.inWeight.length > 0 ? ticketFromLine.inWeight : ticket.inWeight
    ticketFromLine.pieces = ticketFromLine.pieces.length > 0 ? ticketFromLine.pieces : ticket.pieces
    ticketFromLine.averageLength = ticketFromLine.averageLength.length > 0 ? ticketFromLine.averageLength : ticket.averageLength
    ticketFromLine.extTicketNumber1 = ticketFromLine.extTicketNumber1.length > 0 ? ticketFromLine.extTicketNumber1 : ticket.extTicketNumber1
    ticketFromLine.extTicketNumber2 = ticketFromLine.extTicketNumber2.length > 0 ? ticketFromLine.extTicketNumber2 : ticket.extTicketNumber2

    const product = productNameMap[ticket.product.toLowerCase()]

    if (product === undefined || product === null) {
      validationErrors.push(addValidationError('unknownProduct', { productName: ticket.product, rowNumber: index + 1 }))
      continue
    }

    const validationResult = validateNumericProperties(ticketFromLine, index, contractMode.value === ContractMode.Byproducts.value, product)
    if (validationResult) {
      const { textKey, args } = validationResult
      if (textKey !== undefined && args !== undefined) {
        validationErrors.push({ textKey, args })
        continue
      }
    }

    const immutableFieldsValidationResult = validateImmutableFields(ticketFromLine, ticket, contractMode, index)
    if (immutableFieldsValidationResult) {
      const { textKey, args } = immutableFieldsValidationResult
      if (textKey !== undefined && args !== undefined) {
        validationErrors.push({ textKey, args })
        continue
      }
    }

    const weighedOutAt = ticketFromLine.weighedOutAt !== ''
      ? localToUTC(moment.utc(ticketFromLine.weighedOutAt).hours(8).format(), true)
      : moment.utc().format()

    if (validationResult.formattedOutWeight > 0) {
      ticket.weighedOutAt = weighedOutAt

      if (moment(ticket.loadCreatedAt).isAfter(moment(weighedOutAt))) {
        ticket.loadCreatedAt = weighedOutAt
      }

      if (moment(ticket.weighedInAt).isAfter(moment(weighedOutAt)) || ticket.weighedInAt === null) {
        ticket.weighedInAt = weighedOutAt
      }

      if (moment(ticket.departedAt).isAfter(moment(weighedOutAt))) {
        ticket.departedAt = weighedOutAt
      }
    } else {
      ticket.weighedOutAt = null
    }

    const updatedTicket = {
      departureWeight: validationResult.formattedDepartureWeight,
      inWeight: validationResult.formattedInWeight,
      outWeight: validationResult.formattedOutWeight,
      defectWeight: validationResult.formattedDefectWeight,
      pieces: validationResult.formattedPieces,
      averageLength: validationResult.formattedAverageLength,
      extTicketNumber1: ticketFromLine.extTicketNumber1,
      extTicketNumber2: ticketFromLine.extTicketNumber2
    }

    // Do not issue a ticket update if the ticket is not changed.
    if (!isTicketUpdated(ticket, updatedTicket)) {
      unupdatedTickets.push(ticket)
      continue
    }

    ticketsToUpdate.push({
      ticketId: ticket.ticketId,
      originationTicketId: ticket.originationTicketID,
      contractId: ticket.contractId,
      productId: ticket.productID,
      trailerIdentifier: ticket.trailerIdentifier,
      departureWeight: updatedTicket.departureWeight,
      inWeight: updatedTicket.inWeight,
      outWeight: updatedTicket.outWeight,
      defectWeight: updatedTicket.defectWeight,
      extTicketNumber1: updatedTicket.extTicketNumber1,
      extTicketNumber2: updatedTicket.extTicketNumber2,
      loggerAccountId: ticket.loggerAccountId,
      loadCreatedAt: ticket.loadCreatedAt,
      departedAt: ticket.departedAt,
      weighedInAt: ticket.weighedInAt,
      weighedOutAt: ticket.weighedOutAt,
      modifiedAt: ticket.modifiedAt,
      receivedAt: ticket.receivedAt,
      averageLength: updatedTicket.averageLength,
      deckId: ticket.deckId,
      fromDeckId: ticket.fromDeckId,
      rolloutStatus: ticket.rolloutStatus,
      latitude: Number(ticket.latitude.toString().slice(0, 9)),
      longitude: Number(ticket.longitude.toString().slice(0, 9)),
      pieces: updatedTicket.pieces,
      product: ticket.product,
      driverAccountId: ticket.driverAccountId,
      ticketNumber: ticket.ticketNumber
    })
  }

  return {
    tickets: ticketsToUpdate,
    validationErrors: validationErrors,
    unupdatedTickets: unupdatedTickets
  }
}

const validateImmutableFields = (ticketLine, ticket, contractType, index) => {
  if (ticketLine.destination && ticketLine.destination !== ticket.destination) {
    return addValidationError('immutableTicketFieldChanged', { rowNumber: index + 1, field: i18n.t('destination'), original: ticket.destination, csv: ticketLine.destination })
  }
  if (ticketLine.product && ticketLine.product !== ticket.product) {
    return addValidationError('immutableTicketFieldChanged', { rowNumber: index + 1, field: i18n.t('product'), original: ticket.product, csv: ticketLine.product })
  }
  if (contractType.value === ContractMode.Logs.value) {
    if (ticketLine.tract && ticketLine.tract !== ticket.tract) {
      return addValidationError('immutableTicketFieldChanged', { rowNumber: index + 1, field: i18n.t('tract'), original: ticket.tract, csv: ticketLine.tract })
    }
  } else {
    if (ticketLine.fromAccount && ticketLine.fromAccount !== ticket.fromAccount) {
      return addValidationError('immutableTicketFieldChanged', { rowNumber: index + 1, field: i18n.t('fromAccount'), original: ticket.fromAccount, csv: ticketLine.fromAccount })
    }
  }
}

const validateNumericProperties = ({
  departureWeight = '',
  pieces = '',
  averageLength = '',
  inWeight,
  outWeight,
  defectWeight
}, index, isByProducts, product) => {
  const formattedDepartureWeight = formatIntegerFromCsv(departureWeight)
  const formattedInWeight = formatIntegerFromCsv(inWeight)
  const formattedOutWeight = formatIntegerFromCsv(outWeight)
  const formattedDefectWeight = formatIntegerFromCsv(defectWeight)
  const formattedPieces = formatIntegerFromCsv(pieces)
  const formattedAverageLength = formatIntegerFromCsv(averageLength)

  if (isNaN(formattedInWeight) || formattedInWeight < 0) {
    return addValidationError('inWeightMustBeValidCSV', { rowNumber: index + 1, inWeight: inWeight })
  }

  if (isNaN(formattedOutWeight) || formattedOutWeight < 0) {
    return addValidationError('outWeightMustBeValidCSV', { rowNumber: index + 1, outWeight: outWeight })
  }

  if (isNaN(formattedDefectWeight) || formattedDefectWeight < 0) {
    return addValidationError('defectWeightMustBeValidCSV', { rowNumber: index + 1, defectWeight: defectWeight })
  }

  // Check contract specific properties.
  if (isByProducts === false) {
    if (isNaN(formattedPieces) || formattedPieces < 0) {
      return addValidationError('invalidPieceCountCSV', { rowNumber: index + 1 })
    }

    if (product?.collectPieceInfo && formattedPieces <= 0) {
      return addValidationError('invalidPieceCountForProduct', { rowNumber: index + 1, productName: product?.name })
    }

    if (isNaN(formattedAverageLength) || formattedAverageLength < 0) {
      return addValidationError('invalidAverageLength', { rowNumber: index + 1 })
    }
  }

  if (formattedOutWeight > formattedInWeight || formattedDefectWeight > formattedInWeight - formattedOutWeight) {
    return addValidationError('weightsMustBeValidCSV', { rowNumber: index + 1 })
  }

  return {
    formattedDepartureWeight: formattedDepartureWeight,
    formattedInWeight: formattedInWeight,
    formattedOutWeight: formattedOutWeight,
    formattedDefectWeight: formattedDefectWeight,
    formattedPieces: formattedPieces,
    formattedAverageLength: formattedAverageLength
  }
}

export const getExampleCsvData = (isUpdate, contractMode) => {
  const today = new Date().toISOString().slice(0, 10)
  switch (contractMode.value) {
    case ContractMode.Logs.value:
      return isUpdate
        ? `data:text/csv;charset=utf-8,TicketNumber,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt\n1,26000,13000,0,34,9,ExampleNum1,ExampleNum2,${today}`
        : `data:text/csv;charset=utf-8,Product,TrailerIdentifier,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt,DriverName\nPine Logs,RES-2312,26000,13000,0,34,9,ExampleNum1,ExampleNum2,${today},Woody`
    case ContractMode.Byproducts.value:
      return isUpdate
        ? `data:text/csv;charset=utf-8,TicketNumber,InWeight,OutWeight,DefectWeight,ExtTicketNum1,ExtTicketNum2,WeighedOutAt\n201,30000,14500,200,ExampleNum1,ExampleNum2,${today}`
        : `data:text/csv;charset=utf-8,Product,TrailerIdentifier,DepartureWeight,InWeight,OutWeight,DefectWeight,ExtTicketNum1,ExtTicketNum2,WeighedOutAt\nChips,RES-2312,26000,26000,13000,0,ExampleNum1,ExampleNum2,${today}`
    case ContractMode.Transfers.value:
      return isUpdate
        ? `data:text/csv;charset=utf-8,TicketNumber,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt\n201,30000,14500,200,22,10,ExampleNum1,ExampleNum2,${today}`
        : `data:text/csv;charset=utf-8,Product,TrailerIdentifier,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt,DriverName,FromDeck,Deck\nChips,RES-2312,26000,13000,0,34,9,ExampleNum1,ExampleNum2,${today},Woody,OriginDeck,DestinationDeck`
    case ContractMode.LogYardSale.value:
      return isUpdate
        ? `data:text/csv;charset=utf-8,TicketNumber,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt\n201,30000,14500,200,0,22,10,ExampleNum1,ExampleNum2,${today}`
        : `data:text/csv;charset=utf-8,Product,TrailerIdentifier,DepartureWeight,InWeight,OutWeight,DefectWeight,Pieces,AverageLength,ExtTicketNum1,ExtTicketNum2,WeighedOutAt,DriverName,FromDeck\nChips,RES-2312,26000,26000,13000,0,34,9,ExampleNum1,ExampleNum2,${today},Woody,OriginDeck`
  }
}

export const getCsvWithTicketData = (tickets, contractMode) => {
  const cols = updateTicketColumns(contractMode)
  return `data:text/csv;charset=utf-8,${generateCsvString(tickets, cols)}`
}

const formatIntegerFromCsv = (value) => {
  return parseInt(String(value).replaceAll(',', '') || 0)
}

const getTicketFromLine = (csvArray, contractMode, isUpdate) => {
  if (isUpdate) {
    switch (contractMode.value) {
      case ContractMode.Logs.value:
        return {
          ticketNumber: csvArray[0].trim(),
          inWeight: csvArray[1].trim(),
          outWeight: csvArray[2].trim(),
          defectWeight: csvArray[3].trim(),
          pieces: csvArray[4].trim(),
          averageLength: csvArray[5].trim(),
          extTicketNumber1: csvArray[6].trim(),
          extTicketNumber2: csvArray[7].trim(),
          weighedOutAt: csvArray[8].trim(),
          destination: csvArray[9]?.trim(),
          tract: csvArray[10]?.trim(),
          product: csvArray[11]?.trim()
        }
      case ContractMode.Byproducts.value:
        return {
          ticketNumber: csvArray[0].trim(),
          inWeight: csvArray[1].trim(),
          outWeight: csvArray[2].trim(),
          defectWeight: csvArray[3].trim(),
          extTicketNumber1: csvArray[4].trim(),
          extTicketNumber2: csvArray[5].trim(),
          weighedOutAt: csvArray[6].trim(),
          destination: csvArray[7]?.trim(),
          fromAccount: csvArray[8]?.trim(),
          product: csvArray[9]?.trim(),
          pieces: '0',
          averageLength: '0'
        }
      case ContractMode.Transfers.value:
      case ContractMode.LogYardSale.value:
        return {
          ticketNumber: csvArray[0].trim(),
          inWeight: csvArray[1].trim(),
          outWeight: csvArray[2].trim(),
          defectWeight: csvArray[3].trim(),
          pieces: csvArray[4].trim(),
          averageLength: csvArray[5].trim(),
          extTicketNumber1: csvArray[6].trim(),
          extTicketNumber2: csvArray[7].trim(),
          weighedOutAt: csvArray[8].trim(),
          destination: csvArray[9]?.trim(),
          fromAccount: csvArray[10]?.trim(),
          product: csvArray[11]?.trim()
        }
    }
  } else {
    switch (contractMode.value) {
      case ContractMode.Logs.value:
        return {
          productName: csvArray[0].trim(),
          trailerIdentifier: csvArray[1].trim(),
          inWeight: csvArray[2].trim(),
          outWeight: csvArray[3].trim(),
          defectWeight: csvArray[4].trim(),
          pieces: csvArray[5].trim(),
          averageLength: csvArray[6].trim(),
          extTicketNumber1: csvArray[7].trim(),
          extTicketNumber2: csvArray[8].trim(),
          weighedOutAt: csvArray[9].trim(),
          driverName: csvArray[10]
        }
      case ContractMode.Byproducts.value:
        return {
          productName: csvArray[0].trim(),
          trailerIdentifier: csvArray[1].trim(),
          departureWeight: csvArray[2].trim(),
          inWeight: csvArray[3].trim(),
          outWeight: csvArray[4].trim(),
          defectWeight: csvArray[5].trim(),
          extTicketNumber1: csvArray[6].trim(),
          extTicketNumber2: csvArray[7].trim(),
          weighedOutAt: csvArray[8].trim(),
          pieces: '0',
          averageLength: '0'
        }
      case ContractMode.Transfers.value:
        return {
          productName: csvArray[0].trim(),
          trailerIdentifier: csvArray[1].trim(),
          inWeight: csvArray[2].trim(),
          outWeight: csvArray[3].trim(),
          defectWeight: csvArray[4].trim(),
          pieces: csvArray[5].trim(),
          averageLength: csvArray[6].trim(),
          extTicketNumber1: csvArray[7].trim(),
          extTicketNumber2: csvArray[8].trim(),
          weighedOutAt: csvArray[9].trim(),
          driverName: csvArray[10],
          fromDeck: csvArray[11].trim(),
          deck: csvArray[12].trim()
        }
      case ContractMode.LogYardSale.value:
        return {
          productName: csvArray[0].trim(),
          trailerIdentifier: csvArray[1].trim(),
          departureWeight: csvArray[2].trim(),
          inWeight: csvArray[3].trim(),
          outWeight: csvArray[4].trim(),
          defectWeight: csvArray[5].trim(),
          pieces: csvArray[6].trim(),
          averageLength: csvArray[7].trim(),
          extTicketNumber1: csvArray[8].trim(),
          extTicketNumber2: csvArray[9].trim(),
          weighedOutAt: csvArray[10].trim(),
          driverName: csvArray[11].trim(),
          fromDeck: csvArray[12].trim()
        }
    }
  }
}

const addValidationError = (textKey, args) => {
  return {
    textKey: textKey,
    args: args
  }
}

const getTicketDictionaryForServerTickets = (tickets) => {
  return tickets.reduce((dict, ticket) => {
    dict[String(ticket.ticketNumber)] = ticket
    return dict
  }, {})
}

const checkForMatchingTickets = (csvSet, serverSet) => {
  // Check if a ticket number was not found in the server call.
  if (csvSet.size > serverSet.size) {
    const missingTicketNumbers = [...csvSet].filter(x => !serverSet.has(x))

    return {
      tickets: [],
      validationErrors: missingTicketNumbers.map(ticketNumber => addValidationError('ticketWithNumberDoesNotExist', { ticketNumber: ticketNumber }))
    }
  }

  return null
}

const isTicketUpdated = (ticketFromServer, ticketFromCsv) => {
  return ticketFromServer.departureWeight !== ticketFromCsv.departureWeight ||
    ticketFromServer.inWeight !== ticketFromCsv.inWeight ||
    ticketFromServer.outWeight !== ticketFromCsv.outWeight ||
    ticketFromServer.defectWeight !== ticketFromCsv.defectWeight ||
    ticketFromServer.pieces !== ticketFromCsv.pieces ||
    ticketFromServer.averageLength !== ticketFromCsv.averageLength ||
    ticketFromServer.extTicketNumber1 !== ticketFromCsv.extTicketNumber1 ||
    ticketFromServer.extTicketNumber2 !== ticketFromCsv.extTicketNumber2
}

export const validateHeaders = (headers, isUpdate, contractMode) => {
  const expectedColumns = isUpdate
    ? updateTicketColumns(contractMode)
    : importTicketColumns(contractMode)

  for (let index = 0; index < expectedColumns.length; index++) {
    const currentHeader = headers[index]
    const expectedHeader = expectedColumns[index]

    if (currentHeader === undefined && expectedHeader.optional) continue
    if (currentHeader === undefined && !expectedHeader.optional) return i18n.t('missingExpectedHeader', { name: expectedHeader.text, index: index + 1 })
    if (currentHeader.trim().toLowerCase() !== expectedHeader.text.toLowerCase()) return i18n.t('incorrectColumnName', { expected: expectedHeader.text, actual: currentHeader, index: index + 1 })
  }
}

const updateTicketColumns = (contractMode) => {
  let columns = [
    { text: 'TicketNumber', value: ticket => ticket.ticketNumber },
    { text: 'InWeight', value: _ => '' },
    { text: 'OutWeight', value: _ => '' },
    { text: 'DefectWeight', value: _ => '' }
  ]
  if (contractMode.value !== ContractMode.Byproducts.value) {
    columns = columns.concat([
      { text: 'Pieces', value: ticket => ticket.pieces > 0 ? ticket.pieces : '' },
      { text: 'AverageLength', value: _ => '' }
    ])
  }
  columns = columns.concat([
    { text: 'ExtTicketNum1', value: ticket => ticket.extTicketNumber1 ?? '' },
    { text: 'ExtTicketNum2', value: ticket => ticket.extTicketNumber2 ?? '' },
    { text: 'WeighedOutAt', value: _ => '' },
    { text: 'Destination', value: ticket => ticket.destination, optional: true }
  ])
  if (contractMode.value === ContractMode.Logs.value) {
    columns = columns.concat([
      { text: 'TractName', value: ticket => ticket.tract, optional: true }
    ])
  } else {
    columns = columns.concat([
      { text: 'FromAccount', value: ticket => ticket.fromAccount, optional: true }
    ])
  }
  columns = columns.concat([
    { text: 'Product', value: ticket => ticket.product, optional: true }
  ])

  return columns
}

const importTicketColumns = (contractMode) => {
  let columns = [
    { text: 'Product' },
    { text: 'TrailerIdentifier' }
  ]
  if (contractMode.value === ContractMode.Byproducts.value || contractMode.value === ContractMode.LogYardSale.value) {
    columns = columns.concat({ text: 'DepartureWeight' })
  }
  columns = columns.concat([
    { text: 'InWeight' },
    { text: 'OutWeight' },
    { text: 'DefectWeight' }
  ])
  if (contractMode.value !== ContractMode.Byproducts.value) {
    columns = columns.concat([
      { text: 'Pieces' },
      { text: 'AverageLength' }
    ])
  }
  columns = columns.concat([
    { text: 'ExtTicketNum1' },
    { text: 'ExtTicketNum2' },
    { text: 'WeighedOutAt' }
  ])
  if (contractMode.value !== ContractMode.Byproducts.value) {
    columns = columns.concat({ text: 'DriverName' })
  }

  if (contractMode.value === ContractMode.Transfers.value || contractMode.value === ContractMode.LogYardSale.value) {
    columns = columns.concat([
      { text: 'FromDeck', value: ticket => ticket.fromDeckName, optional: true }
    ])

    if (contractMode.value === ContractMode.Transfers) {
      columns = columns.concat([
        { text: 'Deck', value: ticket => ticket.deckName, optional: true }
      ])
    }
  }

  return columns
}
