// Legna colors source: https://dev.azure.com/legnasoftware/_git/GraphicsAssets?path=/Legna-Font-Colors-20190827.pdf
// Some colors from: https://v2.vuetifyjs.com/en/styles/colors/#material-colors
import colors from 'vuetify/lib/util/colors'

export default {
  primary: '#D15F27', // Heartwood Orange
  secondary: '#191410', // Darkened Walnut
  tertiary: '#F9F6DF', // Sapwood White
  accent: colors.blue.accent1,
  error: colors.red.darken1,
  info: colors.indigo.lighten1,
  success: colors.green.darken1,
  warning: colors.orange.darken1,
  byproduct: '#363636',
  transfer: colors.grey.base,
  logyardsale: colors.brown.base
}
