<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="9" xl="10">
        <ProductSummaryTable :productSummary="productionSummaryObject.productSummary || []"/>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <ProductSummaryChart :productSummary="productionSummaryObject.productSummary || []"/>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels popout v-model="openPanel" accordion tile>
          <v-expansion-panel v-for="(production, index) in productionSummaryObject.production" :key="`production-${index}`">
            <v-expansion-panel-header>
              <v-row dense>
                <v-col cols="auto">
                  <v-icon
                    small
                    color="rgba(0,0,0,0.6)"
                  >
                    mdi-account
                  </v-icon>
                  {{addNameWithCode(production.name, production.code)}}
                </v-col>
              </v-row>
              <template #actions>
                <v-container>
                  <v-row justify="end">
                    {{getTotalAmountForProductionPanel(production)}}
                  </v-row>
                  <v-row justify="end">
                    {{getTotalWeightForProductionPanel(production)}}
                  </v-row>
                  <v-row justify="end">
                    {{getTotalLoadsForProductionPanel(production)}}
                  </v-row>
                </v-container>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="lg_expansion-panel_wrapper">
                <ProductionCard
                :production="production"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'PayableProductionSummary',

  props: {
    productionSummaryObject: {
      type: Object,
      required: true
    }
  },

  components: {
    ProductSummaryTable: () => import('@/components/settlements/accounts-payable-module/production-summary/ProductSummaryTable.vue'),
    ProductSummaryChart: () => import('@/components/settlements/accounts-payable-module/production-summary/ProductSummaryChart.vue'),
    ProductionCard: () => import('@/components/settlements/accounts-payable-module/production-summary/ProductionCard.vue')
  },

  data: () => ({
    openPanel: -1
  }),

  methods: {
    getTitleForProductionPanel ({ name, code }) {
      return this.addNameWithCode(name, code)
    },

    getTotalWeightForProductionPanel ({ productionDetails }) {
      let totalWeight = 0

      productionDetails.forEach((productionDetail) => {
        totalWeight += productionDetail.tractProduction.reduce((a, b) => a + b.weight, 0)
      })

      return `${tonStringFromPounds(totalWeight)} tons`
    },

    getTotalLoadsForProductionPanel ({ productionDetails }) {
      let totalLoads = 0

      productionDetails.forEach((productionDetail) => {
        totalLoads += productionDetail.tractProduction.reduce((a, b) => a + b.loads, 0)
      })

      const loadText = totalLoads > 1 ? 'loads' : 'load'
      return `${totalLoads} ${loadText}`
    },

    getTotalAmountForProductionPanel ({ productionDetails }) {
      let totalAmount = 0

      productionDetails.forEach((productionDetail) => {
        totalAmount += productionDetail.tractProduction.reduce((a, b) => a + b.amount, 0)
      })

      return formatMoney(totalAmount)
    }
  }
}
</script>
