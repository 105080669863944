<template>
  <v-container fluid v-if="!loading">
    <v-row dense v-if="!isByproducts">
      <v-col>
        <v-text-field
        v-model="search"
        :label="$t('searchTicketNumberOrLabel')"
        color="black"
        append-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch(true)"
        @keydown.backspace="clearSearch(false)"
        />
      </v-col>
      <v-col>
        <v-row justify="end">
          <VerticalAggregateTotalTable
          v-if="!emptyList"
          :aggregateTotal="batchTotals.allBatchTotals"
          :isByproducts="isByproducts"
          :isWeightBased="containsWeightBasedBatch"
          class="mr-10"/>
          <TimeframeSelector @item-selected="dateSelected" :timeLabelValue="timeLabel" immediate :timeframes="timeframes" :max="45" class="ml-4 mb-1"/>
        </v-row>
        <v-row justify="end" dense no-gutters>
          <v-col cols="auto">
            <p class="mt-n4 pa-0">{{dateString}}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels popout v-model="openDatePanel" class="mt-6" accordion tile>
          <v-expansion-panel v-for="(list, dateIndex) in groupedConsumptionBatches" :key="dateIndex">
            <v-expansion-panel-header
            dense hide-actions active-class="primary white--text">
              <v-container fluid class="ml-n3">
                <v-row dense>
                  <span class="headline">{{ getHeaderForDateGroup(list) }}</span>
                </v-row>
              </v-container>
              <v-container fluid style="font-size: 20px;">
                <v-row dense justify="end" v-if="!$vuetify.breakpoint.xs">
                  <AggregateTotalTable
                  :isActive="dateIndex === openDatePanel"
                  :aggregateTotal="batchTotals.groupedTotals[dateIndex]"
                  :isByproducts="isByproducts"
                  :isWeightBased="groupIsWeightBased(list)"/>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels popout v-model="panels" multiple class="mt-6" flat accordion tile>
                <v-expansion-panel v-for="(consumptionBatch, index) in list" :key="index">
                  <v-expansion-panel-header
                    dense hide-actions active-class="primary white--text"
                    v-slot:default="{ open }"
                    :disabled="consumptionBatch.consumptionMode === ConsumptionMode.WeightBased.value"
                    :style="`cursor: ${consumptionBatch.consumptionMode === ConsumptionMode.TicketBased.value ? 'pointer' : 'default'};${consumptionBatch.consumptionMode === ConsumptionMode.WeightBased.value ? 'background-color: #f7f7f7' : ''}`">
                    <v-container fluid class="ml-n3" >
                      <v-row dense class="text-truncate">
                        <span class="headline">{{getHeaderForBatch(consumptionBatch)}}</span>
                      </v-row>
                      <v-row dense>
                        <span class="subtitle-1">{{getConsumedOnText(consumptionBatch)}}</span>
                      </v-row>
                    </v-container>
                    <v-spacer></v-spacer>
                    <v-container fluid v-if="batchTotals" style="font-size: 20px;">
                      <v-row dense justify="end" v-if="!$vuetify.breakpoint.xs">
                        <AggregateTotalTable
                        :isActive="open"
                        :aggregateTotal="batchTotals.batchTotals[consumptionBatch.consumptionBatchId]"
                        :isByproducts="isByproducts"
                        :isWeightBased="consumptionBatch.consumptionMode === ConsumptionMode.WeightBased.value"/>
                      </v-row>
                    </v-container>
                    <Icon
                    icon="mdi-backup-restore"
                    :tooltipText="getRevertText(index)"
                    :disabled="shouldBeDisabled(index)"
                    :small="false"
                    iconColor="error"
                    @icon-clicked="showConfirmDeleteDialogForUnposting(consumptionBatch, index)"/>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fluid>
                      <v-row dense justify="end">
                        <v-col cols="auto" class="mt-4">
                          <Icon
                          v-if="consumptionBatch.consumptionMode === ConsumptionMode.TicketBased.value"
                          icon="mdi-file-delimited-outline"
                          dataTestId="download-inventory-csv-button"
                          :tooltipText="$t('csv')"
                          :small="false"
                          elementId="consumption-batch-csv-button"
                          margin="mr-2"
                          @icon-clicked="downloadCsv(consumptionBatch)" />
                        </v-col>
                      </v-row>
                      <v-row v-if="consumptionBatch.consumptionMode === ConsumptionMode.TicketBased.value">
                        <v-col cols="12">
                          <v-data-table
                          class="mt-4"
                          :items="mappedConsumptionBatchTickets(consumptionBatch)"
                          item-key="ticketId"
                          show-select
                          dense
                          :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
                          :items-per-page="25"
                          v-model="selected[index]"
                          :search="tableSearch"
                          :custom-filter="filterMappedTickets"
                          :headers="headers">
                            <template #item.ticketNumber="{ item }">
                              <Icon
                              v-if="item.correctionType !== null"
                              :tooltipText="getCorrectionText(item.correctionType)"
                              dataTestId="consume-ticket-correction-icon"
                              icon="mdi-auto-fix"
                              iconColor="black"
                              margin="mt-n1"
                              />
                              <span>{{item.ticketNumber}}</span>
                            </template>
                            <template #item.extTicketNumber1="{item}">
                              <span>
                                {{ item.extTicketNumber1 !== '' ? item.extTicketNumber1 : '-' }}
                              </span>
                            </template>
                            <template #item.extTicketNumber2="{item}">
                              <span>
                                {{ item.extTicketNumber2 !== '' ? item.extTicketNumber2 : '-' }}
                              </span>
                            </template>
                            <template #item.netWeight="{item}">
                              <span>{{ tonStringFromPounds(item.netWeight) }}</span>
                            </template>
                            <template #item.weighedOutAt="{ item }">{{
                              utcToLocalDate(item.weighedOutAt, 'L - LT')
                            }}</template>
                            <template #item.product="{ item }">{{
                              item.product
                            }}</template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <Dialog :stateId="dialogId">
          <ConfirmDialog
          v-if="confirmDialog"
          :title="dialogTitle"
          :body="dialogBody"
          :confirmText="$t('revert')"
          color="primary"
          @cancel="close"
          @confirm="deleteConfirmed"/>
        </Dialog>
      </v-col>
      <v-row align="center" justify="center" class="pa-6 ma-6" v-if="emptyList">
      <span class="headline black--text">
        {{$t('noConsumedTickets')}}
      </span>
    </v-row>
    </v-row>
  </v-container>
  <v-container class="fill-height" v-else>
    <Loading/>
  </v-container>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { numberWithCommas, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { CorrectionTypes, ContractMode, DeckContentType, ConsumptionMode } from '@/utils/Enumerations.js'
import TicketHeaders from '@/headers/Ticket'
import { mapActions, mapGetters } from 'vuex'
import { LocalStorageKeys } from '@/utils/constants.js'
import { timeframesInLocalTime as tf, timeframeToUtcIsoString } from '@/utils/DateFormatter'
import moment from 'moment'
export default {
  name: 'ConsumedTickets',

  props: {
    productIdDictionary: {
      type: Object,
      required: true
    },
    refreshFlag: {
      type: Boolean
    },
    contractMode: {
      type: Object,
      default: undefined
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    AggregateTotalTable: () => import('@/components/ticket/ticket-consumption/AggregateTotalTable.vue'),
    Loading: () => import('@/components/core/Loading.vue'),
    TimeframeSelector: () => import('@/components/dashboard/TimeframeSelector.vue'),
    VerticalAggregateTotalTable: () => import('@/components/ticket/ticket-consumption/VerticalAggregateTotalTable.vue')
  },

  data: () => ({
    dialogId: 'consumed-tickets',
    openDatePanel: undefined,
    panels: [],
    confirmDialog: false,
    loading: false,
    selected: [],
    search: '',
    focusedBatch: undefined,
    focusedIndex: undefined,
    ConsumptionMode,
    timeLabel: 'today',
    sinceTime: '',
    untilTime: '',
    timeframe: undefined
  }),

  created () {
    if (!localStorage.getItem(LocalStorageKeys.CONSUMPTION_BATCHES_TIME_LABEL)) localStorage.setItem(LocalStorageKeys.CONSUMPTION_BATCHES_TIME_LABEL, this.timeLabel)
  },

  computed: {
    ...mapGetters('consumption', ['consumptionBatches']),
    ...mapGetters('product', ['allProducts']),

    timeframes () {
      const timeframes = [
        tf.today,
        tf.yesterday,
        tf.last7Days,
        tf.last14Days,
        tf.last30Days,
        tf.thisWeek,
        tf.lastWeek,
        tf.thisMonth,
        tf.lastMonth
      ].map(timeframeToUtcIsoString)
      return timeframes
    },

    emptyList () {
      return this.filteredConsumptionBatches.length === 0
    },

    tableSearch () {
      if (!isNaN(parseInt(this.search))) {
        return this.search
      }
      return ''
    },

    headers () { return TicketHeaders.consumedTicketHeaders() },

    isByproducts () {
      return this.contractMode.value === ContractMode.Byproducts.value
    },

    containsWeightBasedBatch () {
      return this.filteredConsumptionBatches.some(b => b.consumptionMode === ConsumptionMode.WeightBased.value)
    },

    dateString () {
      return `${utcToLocalDate(this.sinceTime)}-${utcToLocalDate(this.untilTime)}`
    },

    batchTotals () {
      if (this.productIdDictionary === undefined) { return undefined }

      let collectTotalAverageRpi = true
      const unchangedTotals = {}
      const returnTotals = {}
      let totalWeight = 0
      let totalRpi = 0
      let totalMbf = 0
      const batchTotals = {}
      this.filteredConsumptionBatches.forEach(batch => {
        let collectAverageRpi = true
        const totalsForBatch = batch.consumptionMode === ConsumptionMode.TicketBased.value
          ? batch.tickets.reduce((totals, ticket) => {
            const ticketWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
            totals.weightTotal += ticketWeight
            totals.mbfTotal += ticketWeight * this.productIdDictionary[ticket.product].conversionRateW2V
            if (collectAverageRpi) {
              if (ticket.rpi === 0) {
                collectAverageRpi = false
                collectTotalAverageRpi = false
                totals.rpiTotal = 0
              } else {
                totals.rpiTotal += (ticket.rpi * ticketWeight)
              }
            }
            return totals
          }, { weightTotal: 0, mbfTotal: 0, rpiTotal: 0 })
          : { weightTotal: batch.consumedWeight }

        totalWeight += totalsForBatch.weightTotal
        totalMbf += batch.consumptionMode === ConsumptionMode.TicketBased.value ? Math.ceil(totalsForBatch.mbfTotal) : 0
        totalRpi += parseFloat(totalsForBatch.rpiTotal / totalsForBatch.weightTotal)

        batchTotals[batch.consumptionBatchId] = {
          weightTons: tonStringFromPounds(totalsForBatch.weightTotal),
          mbf: batch.consumptionMode === ConsumptionMode.TicketBased.value
            ? numberWithCommas(Math.ceil(totalsForBatch.mbfTotal))
            : 0,
          rpi: batch.consumptionMode === ConsumptionMode.TicketBased.value && collectAverageRpi && totalsForBatch.rpiTotal !== 0
            ? numberWithCommas((totalsForBatch.rpiTotal / totalsForBatch.weightTotal), 2) : 0
        }
        unchangedTotals[batch.consumptionBatchId] = totalsForBatch
      })
      const allBatchTotals = {
        weightTons: tonStringFromPounds(totalWeight),
        mbf: numberWithCommas(Math.ceil(totalMbf)) ?? 0,
        rpi: collectTotalAverageRpi && totalRpi !== 0
          ? numberWithCommas((totalRpi / this.filteredConsumptionBatches.length), 2) : 0
      }

      returnTotals.groupedTotals = this.groupBatchTotals(unchangedTotals)
      returnTotals.allBatchTotals = allBatchTotals
      returnTotals.batchTotals = batchTotals
      return returnTotals
    },

    dialogBody () {
      if (this.focusedIndex === undefined || this.focusedBatch === undefined) {
        return ''
      }

      if (this.focusedBatch.consumptionMode === ConsumptionMode.WeightBased.value) {
        return this.$t('revertConsumptionBody', {
          weight: tonStringFromPounds(this.focusedBatch.consumedWeight)
        })
      }

      const selectedLength = this.selected[this.focusedIndex].length
      const batchLength = this.focusedBatch.tickets.length

      return selectedLength === batchLength || selectedLength === 0 ? this.$t('ticketBatchConsumptionBody') : this.$t('ticketConsumptionBody')
    },

    dialogTitle () {
      return this.$t('revertConsumption')
    },

    filteredConsumptionBatches () {
      const initialFilter = this.consumptionBatches.filter(cb => cb.deckContentType === (this.isByproducts ? DeckContentType.Byproducts.value : DeckContentType.Logs.value))

      if (this.search) {
        return initialFilter.filter((v) => {
          if (v.label.toLowerCase().includes(this.search.toLowerCase())) {
            return true
          } else {
            if (v.tickets.find(t => String(t.ticketNumber).includes(String(this.search)))) {
              return true
            } else {
              return false
            }
          }
        })
      } else {
        return initialFilter
      }
    },

    groupedConsumptionBatches () {
      const startingBatchList = this.filteredConsumptionBatches
      if (startingBatchList === []) { return [] }
      const groupedBatches = []
      let dateGroup
      let date

      startingBatchList.forEach((batch, index) => {
        if (index === 0) {
          date = utcToLocalDate(batch.consumedOn)
          dateGroup = [batch]
        } else {
          if (moment(date).date() !== moment(utcToLocalDate(batch.consumedOn)).date() || moment(date).month() !== moment(utcToLocalDate(batch.consumedOn)).month()) {
            groupedBatches.push(dateGroup)
            dateGroup = [batch]
          } else {
            dateGroup.push(batch)
          }
          date = utcToLocalDate(batch.consumedOn)
        }
      })
      if (dateGroup !== undefined) {
        groupedBatches.push(dateGroup)
      }
      return groupedBatches
    }
  },

  watch: {
    filteredConsumptionBatches: {
      handler () {
        this.addArraysForTables()
      },
      deep: true,
      immediate: true
    },

    groupedConsumptionBatches: {
      handler () {
        this.addArraysForTables()
      },
      deep: true,
      immediate: true
    },

    refreshFlag (val) {
      if (val) {
        this.refresh()
      }
    },

    openDatePanel (_, oldVal) {
      this.panels = []
    }
  },

  methods: {
    ...mapActions('consumption', ['downloadConsumptionBatchExport', 'fetchConsumptionBatches', 'revertTickets']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    utcToLocalDate,
    tonStringFromPounds,

    refresh () {
      this.loading = true
      this.fetchConsumptionBatches({ sinceTime: this.sinceTime, untilTime: this.untilTime })
        .finally(() => {
          this.search = ''
          this.loading = false
          this.timeLabel = localStorage.getItem(LocalStorageKeys.CONSUMPTION_BATCHES_TIME_LABEL)
          this.sinceTime = localStorage.getItem(LocalStorageKeys.CONSUMPTION_BATCHES_SINCE_TIME)
          this.untilTime = localStorage.getItem(LocalStorageKeys.CONSUMPTION_BATCHES_UNTIL_TIME)
        })
    },

    getConsumedOnText ({ consumedByUser, consumedOn }) {
      return this.$t('consumedOnText', {
        consumedByUser,
        consumedOn: utcToLocalDate(consumedOn)
      })
    },

    showConfirmDeleteDialogForUnposting (batch, index) {
      this.close(false)
      this.focusedBatch = batch
      this.focusedIndex = index
      this.openOrUpdateDialog({ id: this.dialogId, width: '400px', allowFullscreen: false })
      this.confirmDialog = true
    },

    addArraysForTables () {
      this.selected = this.filteredConsumptionBatches.map(_ => [])
    },

    getHeaderForBatch (batch) {
      const ticketCount = batch.tickets.length
      const deck = batch.deck
      const labelText = batch.label ? `- ${batch.label}` : '- N/A'
      return batch.consumptionMode === ConsumptionMode.TicketBased.value
        ? `${deck} ${labelText}: ${ticketCount} Ticket${ticketCount > 1 ? 's' : ''}`
        : `${deck} ${labelText}`
    },

    getHeaderForDateGroup (batches) {
      if (this.$vuetify.breakpoint.xs) {
        return utcToLocalDate(batches[0].consumedOn)
      }
      return moment(utcToLocalDate(batches[0].consumedOn)).format('dddd, MMMM D, YYYY')
    },

    getCorrectionText (correctionType) {
      return `CORRECTED
      ${CorrectionTypes.find(ct => ct.value === correctionType).name}`
    },

    async downloadCsv (consumptionBatch) {
      await this.downloadConsumptionBatchExport(consumptionBatch)
    },

    getRevertText (index) {
      const batch = this.filteredConsumptionBatches[index]

      if (batch.consumptionMode === ConsumptionMode.WeightBased.value) {
        return this.$t('revert')
      } else {
        return this.selected[index].length > 0 ? this.$t('revertSelected') : this.$t('revertAll')
      }
    },

    clearSearch (val) {
      if (val) {
        this.search = ''
      }

      this.searches = ''
    },

    shouldBeDisabled (index) {
      if (index === 0) return false
      const batch = this.filteredConsumptionBatches[index]

      if (batch.consumptionMode === ConsumptionMode.TicketBased.value) return false

      const previousBatchIndex = this.consumptionBatches.findIndex(b => b.deckId === batch.deckId && b.consumptionBatchId !== batch.consumptionBatchId)
      return previousBatchIndex < index && previousBatchIndex !== -1
    },

    close (shouldRefresh = false) {
      this.confirmDialog = false
      this.closeDialogsAtOrAbove(this.dialogId)

      if (shouldRefresh) {
        this.$emit('trigger-refresh')
      }
    },

    async deleteConfirmed () {
      let ticketIds

      const selectedLength = this.selected[this.focusedIndex].length

      if (selectedLength > 0 && this.focusedBatch.tickets.length !== selectedLength) {
        ticketIds = this.selected[this.focusedIndex].map(t => t.ticketId)
      }

      const results = await this.revertTickets({
        ticketIds: ticketIds,
        consumptionBatchId: this.focusedBatch.consumptionBatchId
      })

      if (this.focusedBatch.consumptionMode === ConsumptionMode.WeightBased.value) {
        this.setSnack(this.$t('consumptionBatchReverted'))
      } else {
        if (results?.successes && results?.failures) {
          this.setSnack(`Successfully reverted ${results.successes} tickets. ${results.failures} failures.`)
        } else {
          this.setSnack('Successfully reverted tickets.')
        }
      }

      this.close(true)
    },

    async dateSelected (dates) {
      this.timeframe = dates
      this.timeLabel = dates.timeLabel
      this.sinceTime = dates.sinceTime
      this.untilTime = dates.untilTime
      this.panels = []
      if (this.timeLabel === 'custom') {
        localStorage.setItem(LocalStorageKeys.CONSUMPTION_BATCHES_TIME_LABEL, 'today')
      } else {
        localStorage.setItem(LocalStorageKeys.CONSUMPTION_BATCHES_TIME_LABEL, dates.timeLabel)
        localStorage.setItem(LocalStorageKeys.CONSUMPTION_BATCHES_SINCE_TIME, dates.sinceTime)
        localStorage.setItem(LocalStorageKeys.CONSUMPTION_BATCHES_UNTIL_TIME, dates.untilTime)
      }
      const params = {
        sinceTime: this.sinceTime,
        untilTime: this.untilTime
      }
      await this.fetchConsumptionBatches(params)
    },

    groupIsWeightBased (list) {
      return list.some((cb) => cb.consumptionMode === ConsumptionMode.WeightBased.value)
    },

    groupBatchTotals (totals) {
      if (totals === undefined || totals === null || totals === {}) { return undefined }
      if (this.groupedConsumptionBatches === undefined || this.groupedConsumptionBatches === []) { return [] }
      let collectAverageRpi = true
      const groupedTotals = {}
      let groupWeight = 0
      let groupMbf = 0
      let groupRpi = 0
      let currentBatch
      for (let i = 0; i < this.groupedConsumptionBatches.length; i++) {
        collectAverageRpi = true
        for (let j = 0; j < this.groupedConsumptionBatches[i].length; j++) {
          currentBatch = totals[this.groupedConsumptionBatches[i][j].consumptionBatchId]
          groupWeight += currentBatch.weightTotal
          groupMbf += currentBatch.mbfTotal
          if (collectAverageRpi) {
            if (currentBatch.rpiTotal !== 0) {
              groupRpi += currentBatch.rpiTotal / currentBatch.weightTotal
            } else {
              collectAverageRpi = false
              groupRpi = 0
            }
          }
        }
        groupedTotals[i] = {
          weightTons: tonStringFromPounds(groupWeight),
          mbf: numberWithCommas(Math.ceil(groupMbf)) ?? 0,
          rpi: groupRpi !== 0
            ? numberWithCommas((groupRpi / this.groupedConsumptionBatches[i].length), 2) : 0
        }
        groupWeight = 0
        groupMbf = 0
        groupRpi = 0
      }
      return groupedTotals
    },

    mappedConsumptionBatchTickets (cb) {
      return cb.tickets.map(t => ({
        ...t,
        netWeight: (t.inWeight - t.outWeight - t.defectWeight)
      }))
    },

    filterMappedTickets (value, search, item) {
      return Object.values(item).some(prop => {
        return String(prop).includes(search)
      })
    }
  }
}
</script>
