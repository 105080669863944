<template>
  <div>
    <v-card>
      <v-card-title :class="`primary white--text headline`">
        <span>
          {{ title }}
        </span>
        <v-spacer/>
        <Icon
        @icon-clicked="$emit('cancel')"
        :tooltipText="$t('cancel')"
        dataTestId="contract-expirdate-cancel-button"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        large
        />
      </v-card-title>
      <v-card-text class="mt-6">
        <FormWrapper @submit="trySaveChanges" :hideButton="readonly">
          <v-container fluid>
            <v-row v-if="contract" class="black--text" style="font-size: 14px;">
              <v-col cols="12">
                <span class="subtitle" style="font-size: 16px;">
                  {{ $t('contractInformation')}}
                </span>
              </v-col>
              <v-col cols="6" xs="12" sm="12" md="6" v-if="contract.tract">
                <v-icon small>mdi-crosshairs-gps</v-icon>
                <span>
                  {{contract.tract}}
                </span>
              </v-col>
              <v-col cols="6" xs="12" sm="12" md="6">
                <v-icon small>mdi-domain</v-icon>
                <span>
                  {{contract.destination}}
                </span>
              </v-col>
              <v-col cols="6" xs="12" sm="12" md="6" v-if="contract.setting">
                <v-icon small>mdi-map-marker-circle</v-icon>
                <span>
                  {{contract.setting}}
                </span>
              </v-col>
              <v-col cols="6" xs="12" sm="12" md="6">
                <v-icon small>mdi-account</v-icon>
                <span>
                  {{contract.account}}
                </span>
              </v-col>
              <v-col cols="6" xs="12" sm="12" md="6" v-if="contract.fromAccount">
                <v-icon small>mdi-account</v-icon>
                <span>
                  {{contract.fromAccount}}
                </span>
              </v-col>
              <v-col cols="12" class="mt-6">
                <DatePicker
                :dateLabel="$t('expiresOn')"
                dataTestId="contract-expirdate"
                :startDate="expirationDate"
                :mustBeAfter="expirationDate"
                @date-picked="expirationDateChosen"
                :disabled="readonly"/>
              </v-col>
            </v-row>
            <v-row v-else justify="center" align="center">
              <v-progress-circular class="pa-12 ma-12" indeterminate color="primary"/>
            </v-row>
          </v-container>
          <template #left-action>
            <v-btn v-if="contract?.type < 2" color="primary" link outlined @click="jumpToTract">{{$t('goToTract')}}</v-btn>
          </template>
        </FormWrapper>
      </v-card-text>
    </v-card>
    <v-dialog
      v-if="confirmUpdateDialog"
      v-model="confirmUpdateDialog"
      width="400px"
    >
      <ConfirmDialog
        :title="$t('contractEditConfirmTitle')"
        :body="$t('contractEditConfirmMessage')"
        color="primary"
        :confirmText="$t('edit')"
        @confirm="saveChangesWithApproval"
        @cancel="$emit('cancel')"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import RouterJump from '@/model/RouterJump'
import { UserClaims, ContractApprovalStatus } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'UpdateExpirationDate',
  props: {
    contractId: {
      type: Number,
      required: true
    }
  },
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },
  data: () => ({
    contract: undefined,
    expirationDate: undefined,
    confirmUpdateDialog: false
  }),

  computed: {
    ...mapGetters('user', ['companyInfo']),
    readonly () {
      return !userAssignedClaim(UserClaims.ContractManager) ||
        (this.companyInfo.requireApprovalForContractModifications &&
        [ContractApprovalStatus.PendingModification.value, ContractApprovalStatus.PendingReview.value]
          .includes(this.contract?.approvalStatus))
    },
    title () {
      return this.readonly ? this.$t('expirationDate') : this.$t('updateExpirationDate')
    }
  },

  async created () {
    const contract = await this.getContract(this.contractId)
    this.expirationDate = utcToLocalDate(contract.expirationDate)
    this.contract = contract
  },
  methods: {
    ...mapActions('contract', ['getContract', 'updateContract']),
    ...mapActions('contract-approvals', ['createContractApproval', 'updateContractApproval']),
    ...mapMutations('global', ['setRouterJump']),
    expirationDateChosen (date) {
      this.expirationDate = date
    },

    jumpToTract () {
      const sourceObject = { tractId: this.contract.tractId }
      const options = { readonly: this.readonly }
      this.setRouterJump(new RouterJump('UpdateExpirationDate', 'Tracts', sourceObject, options))
      if (this.$router.currentRoute.path !== '/tracts') this.$router.push('tracts')
      this.$emit('jump')
    },

    trySaveChanges () {
      if (this.companyInfo.requireApprovalForContractModifications && !this.contract.isDraft) {
        this.confirmUpdateDialog = true
      } else {
        this.saveChangesWithoutApproval()
      }
    },

    async saveChangesWithoutApproval () {
      const updateRequest = this.makeUpdateRequest()
      await this.updateContract(updateRequest)
      this.$emit('contract-updated')
    },

    async saveChangesWithApproval () {
      const createApprovalResponse = await this.createContractApproval({ contractId: this.contractId })
      if (!createApprovalResponse) {
        this.confirmUpdateDialog = false
        return
      }
      const updateRequest = this.makeUpdateRequest()
      await this.updateContract(updateRequest)
      const requestObj = {
        contractApprovalId: createApprovalResponse.contractApprovalId,
        contractId: this.contractId,
        status: ContractApprovalStatus.PendingReview.value
      }
      this.updateContractApproval(requestObj)
      this.$emit('contract-updated')
    },

    makeUpdateRequest () {
      const updateRequest = {
        contractId: this.contract.contractId,
        accountId: this.contract.accountId,
        tractId: this.contract.tractId,
        settingId: this.contract.settingId,
        fromAccountId: this.contract.fromAccountId,
        destinationAccountId: this.contract.destinationAccountId,
        requiresLogger: this.contract.requiresLogger,
        requiresExt1: this.contract.requiresExt1,
        requiresExt2: this.contract.requiresExt2,
        acceptsAnyLoad: this.contract.acceptsAnyLoad,
        effectiveDate: this.contract.effectiveDate,
        isDraft: this.contract.isDraft,
        type: this.contract.type,
        expirationDate: localToUTC(this.expirationDate)
      }

      if (this.contract.type === 3) {
        updateRequest.tractId = null
        updateRequest.settingId = null
      } else {
        updateRequest.fromAccountId = null
      }

      return updateRequest
    }
  }
}
</script>
