<template>
  <v-card min-height="70vh">
    <v-card-title :class="headerClass">
      <span class="headline">{{title}}</span>
      <Icon
      v-if="!loading"
      iconColor="tertiary"
      margin="mx-1"
      dataTestId="contract-detail-status"
      :icon="getStatusIcon(contract).iconName"
      :small="false"
      :tooltipText="getStatusIcon(contract).msg"
      />
      <Icon
      v-if="contract.paused"
      icon="mdi-pause-circle"
      dataTestId="contract-detail-pau"
      iconColor="tertiary"
      margin="mx-1"
      :small="false"
      :tooltipText="$t('contractOnPause')"
      />
      <v-spacer/>
      <v-menu bottom left>
        <template #activator="{ on }">
          <v-icon v-on="on" color="white">mdi-dots-vertical</v-icon>
        </template>
        <v-list>
          <v-list-item v-for="(ha, index) in headerActions" :key="`${ha.text}-${index}`" @click="ha.action()" :disabled="ha.disabled">
            <v-avatar left>
              <v-icon color="black" :disabled="ha.disabled">{{ha.icon}}</v-icon>
            </v-avatar>
            <v-list-item-title class="subtitle-1">{{ $t(ha.text) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <BaseDialogActions
      hideRefresh
      />
    </v-card-title>
    <v-card-text class="subtitle-1 black--text px-8 pt-2 pb-8">
      <v-container fluid fill-height v-if="loading">
        <v-row justify="center" align="center">
          <v-progress-circular
          indeterminate
          color="primary"/>
        </v-row>
      </v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="mb-n6">
          <ApprovalStatusIndicator
          v-if="showApprovalStatus"
          :contractId="contract.contractId"
          :approvalStatus="contract.approvalStatus"
          @review="review"
        />
        </v-col>
          <v-col cols="12">
            <ContractInformation
            v-if="!loading"
            :contract="contract"
            :allActivities="allActivities"
            :tractLandowners="tractLandowners"
            />
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="auto">
                <span>
                  {{ activityTitle }}
                </span>
              </v-col>
              <v-col cols="auto">
                <Icon
                v-if="!contract.acceptsAnyLoad && !someActivityDetailExists"
                icon="mdi-alert-circle-outline"
                iconColor="error"
                :small="false"
                :large="false"
                :tooltipText="$t('noDetails')"
                />
              </v-col>
              <v-spacer/>
              <v-col cols="auto">
                <Icon
                icon="mdi-pencil"
                iconColor="success"
                dataTestId="contract-activities-edit-button"
                :small="false"
                :tooltipText="$t('editActivities')"
                @icon-clicked="editActivities"
                :disabled="!canEdit"
              />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-n2">
            <ContractDetailActivities
            :showActions="false"
            :allActivities="allActivities"
            :entities="activitiesByEntity"
            :contract="contract"
          />
          </v-col>
        </v-row>
      </v-card-text>
    <Dialog :stateId="dialogId" @close="resetDialogs" maxWidth="75vw">
      <ModificationHistory
      v-if="auditChanges"
      entityType="contract"
      :entityId="contractId"
      :entityName="$t('contract')"
      :headerClass="headerClass"
      />
      <ContractQrCode
      v-if="qrCode"
      :propContract="contractId"
      />
      <DownloadContractDialog
      v-if="downloadContractDialogOpen"
      :contract="contract"
      :counterparties="counterparties"
      @download-contract="downloadContract"
      />
    </Dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { ContractStatusReason, ContractMode, ContractType, UserClaims, ContractApprovalStatus, RuntimeCounterparty } from '@/utils/Enumerations.js'
import Cookies from 'js-cookie'
import { CookieKeys } from '@/utils/constants.js'
import RouterJump from '@/model/RouterJump.js'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { generateLogsContract } from '@/utils/pdf-formatters/ContractDownload'

export default {
  name: 'ContractDetail',
  components: {
    ContractDetailActivities: () => import('@/components/contract/contract-detail/ContractDetailActivities.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue'),
    ContractInformation: () => import('@/components/contract/contract-detail/ContractInformation.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    ContractQrCode: () => import('@/components/contract/ContractQrCode.vue'),
    DownloadContractDialog: () => import('@/components/contract/DownloadContractDialog.vue'),
    ApprovalStatusIndicator: () => import('@/components/contract/contract-detail/ApprovalStatusIndicator')
  },

  props: {
    contractId: {
      type: Number,
      required: true
    },
    hasPendingTickets: {
      type: Boolean,
      default: false
    },
    jumpsToEdit: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    loading: true,
    auditChanges: false,
    qrCode: false,
    downloadContractDialogOpen: false,
    products: [],
    contract: {},
    dialogId: uniqueDialogId('contract-audit-entries'),
    UserClaims,
    tract: {},
    counterparties: [],
    tractLandowners: []
  }),

  computed: {
    ...mapGetters('activity', ['allActivities', 'activitiesByEntity', 'activitiesLoading']),
    ...mapGetters('user', ['companyInfo', 'userInfo']),

    activityTitle () {
      return this.allActivities.length > 0 ? this.$t('activities') : this.$t('noActivities')
    },

    someActivityDetailExists () {
      return this.allActivities.reduce((p, c) => p || c.activityDetails.length > 0, false)
    },

    contractMode () {
      switch (this.contract.type) {
        case ContractType.LogYardSale.value:
          return ContractMode.LogYardSale
        case ContractType.ByproductSale.value:
        case ContractType.ByproductPurchase.value:
          return ContractMode.Byproducts
        case ContractType.Transfer.value:
          return ContractMode.Transfers
        case ContractType.Production.value:
        case ContractType.WoodsSale.value:
        default:
          return ContractMode.Logs
      }
    },

    title () {
      return this.$t('contractDetails')
    },

    headerClass () {
      return `${this.contractMode.details.color} white--text`
    },

    headerActions () {
      return (this.contract && this.tractsFetched) ? [
        {
          text: 'viewModifications',
          icon: 'mdi-history',
          action: this.viewModificationHistory,
          disabled: false
        },
        {
          text: 'edit',
          icon: 'mdi-pencil',
          action: this.editContract,
          disabled: !this.canEdit
        },
        {
          text: 'showQrCode',
          icon: 'mdi-qrcode',
          action: this.showQrCode,
          disabled: this.showQrCodeDisabled
        },
        {
          text: 'downloadContract',
          icon: 'mdi-download',
          action: this.openDownloadContractDialog,
          disabled: this.downloadContractDisabled
        }
      ] : []
    },

    showQrCodeDisabled () {
      return this.contract.isExternal ||
        this.contract.type === ContractType.Transfer.value ||
        this.contract.type === ContractType.ByproductPurchase.value ||
        this.contract.type === ContractType.ByproductSale.value
    },

    downloadContractDisabled () {
      return this.allActivities.every(a => a.contractor === null && a.runtimeCounterparty === RuntimeCounterparty.Driver.value)
    },

    tractsFetched () {
      return !this.contractHasTract || this.tract.type !== undefined
    },

    showApprovalStatus () {
      return this.companyInfo.requireApprovalForContractModifications &&
        [
          ContractApprovalStatus.PendingModification.value,
          ContractApprovalStatus.PendingReview.value,
          ContractApprovalStatus.Returned.value
        ].includes(this.contract.approvalStatus)
    },

    canEdit () {
      if (!userAssignedClaim(UserClaims.ContractManager)) return false

      if (!this.companyInfo.requireApprovalForContractModifications) return true

      if (this.approvalStateAllowsModification) return true

      return this.previousModificationByUser
    },

    approvalStateAllowsModification () {
      return [
        ContractApprovalStatus.Approved.value,
        ContractApprovalStatus.Returned.value,
        null
      ].includes(this.contract.approvalStatus)
    },

    previousModificationByUser () {
      return this.contract.approvalStatus === ContractApprovalStatus.PendingModification.value
        ? this.contract.approvalRequestedByUserId === this.userInfo.applicationUserId
        : false
    },

    contractHasTract () {
      return !(this.contract.type === ContractType.ByproductSale.value ||
              this.contract.type === ContractType.Transfer.value ||
              this.contract.type === ContractType.LogYardSale.value ||
              (this.contract.type === ContractType.ByproductPurchase.value && !this.contract.tractId))
    }
  },

  async created () {
    this.setOpenPanels([])
    await this.getContractById(this.contractId)
    this.tractLandowners = await this.fetchTractLandowners(this.contract.tractId)
    this.tractLandowners.sort((a, b) => b.ownership - a.ownership)
  },

  methods: {
    ...mapMutations('global', ['setRouterJump']),
    ...mapMutations('activity', ['setLoading', 'setOpenPanels']),
    ...mapActions('activity', ['fetchActivities', 'fetchActivitiesByEntity']),
    ...mapActions('contract', ['getContract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('tract', ['fetchTract', 'fetchTractLandowners']),
    ...mapActions('account', ['fetchAccountById']),
    ...mapMutations('snackbar', ['setSnack']),
    userAssignedClaim,

    async getContractById (contractId) {
      this.loading = true
      try {
        const contract = await this.getContract(contractId)
        contract.hasPendingTickets = this.hasPendingTickets
        this.contract = contract

        if (this.contractHasTract) {
          const tract = await this.fetchTract(contract.tractId)
          this.tract = tract
        }

        await this.fetchActivitiesByEntity(contract.contractId)
      } finally {
        this.loading = false
      }
    },

    editActivities () {
      this.edit(true)
    },

    editContract () {
      this.edit(false)
    },

    edit (editActivities) {
      const currentPath = this.$router.app._route.path
      if (!this.jumpsToEdit && (
        currentPath === '/contracts' || currentPath === '/settlements' || currentPath === '/tracts'
      )) {
        this.$emit(editActivities ? 'edit-activities' : 'edit-contract', this.contract)
      } else {
        this.jumpToContract(editActivities)
      }
    },

    jumpToContract (editActivities) {
      Cookies.set(CookieKeys.CONTRACT_GRID_TICKET_MODE, JSON.stringify(this.contractMode))
      const routerJump = new RouterJump('Tickets', 'Contracts', {
        contract: this.contract,
        editActivities
      })
      this.setRouterJump(routerJump)
      this.$router.push('contracts')
    },

    viewModificationHistory () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '75vw' })
      this.auditChanges = true
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.auditChanges = false
      this.qrCode = false
      this.downloadContractDialogOpen = false
    },

    closeDownloadContract () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.resetDialogs()
    },

    getStatusIcon (contract) {
      const status = contract.status
      let statusReason = ContractStatusReason.fromInt(contract.statusReason)
      if (statusReason !== 'None') {
        statusReason = `${statusReason}`
      } else {
        statusReason = ''
      }
      switch (status) {
        case 0:
          return {
            iconName: 'mdi-checkbox-marked-circle',
            color: 'primary',
            msg: this.$t('open')
          }
        case 1:
          return {
            iconName: 'mdi-close',
            color: 'error',
            msg: `${this.$t('closed')}
                  ${statusReason}`
          }
        case 2:
          return {
            iconName: 'mdi-minus-circle',
            color: 'warning',
            msg: `${this.$t('onHold')}
                  ${statusReason}`
          }
        case 3:
          return {
            iconName: 'mdi-close-circle',
            color: 'error',
            msg: `${this.$t('expired')}
                  ${statusReason}`
          }
        default:
          return {
            iconName: 'mdi-alert',
            color: 'error',
            msg: this.$t('exception')
          }
      }
    },

    showQrCode (contract) {
      this.openOrUpdateDialog({ id: this.dialogId, width: '75vw' })
      this.qrCode = true
    },

    review (contractId) {
      this.getContractById(contractId)
      this.$emit('review')
    },

    async openDownloadContractDialog () {
      if (this.contractMode.value !== ContractMode.Logs.value) {
        this.setSnack(this.$t('logsContractsOnly'))
        return
      }

      const activities = this.allActivities
      const mappedLandowners = this.tractLandowners.map(tlo => ({ accountId: tlo.accountId, name: tlo.accountName }))
      const accountDict = {}
      activities.forEach(activity => {
        if (activity.runtimeCounterparty === RuntimeCounterparty.Driver.value) return
        const isLandowner = activity.runtimeCounterparty === RuntimeCounterparty.Landowner.value
        const accounts = isLandowner ? mappedLandowners : [activity.contractor]
        accounts.forEach(account => {
          accountDict[account.accountId] ??= { name: account.name, activities: [], accountId: account.accountId }
          accountDict[account.accountId].activities.push(activity.activityId)
        })
      })

      this.counterparties = Object.values(accountDict)

      if (this.counterparties.length === 1) {
        this.downloadContract(this.counterparties)
      } else {
        this.openOrUpdateDialog({ id: this.dialogId, width: '40vw' })
        this.downloadContractDialogOpen = true
      }
    },

    async downloadContract (selectedCounterparties) {
      const activities = this.allActivities.filter(a => {
        return selectedCounterparties.some(scp => scp.activities.includes(a.activityId))
      })

      const accounts = await this.getAccountsForAllPdf(selectedCounterparties)

      await generateLogsContract(this.contract, this.tract, this.companyInfo, accounts, activities, this.tractLandowners)
      this.closeDownloadContract()
    },

    async getAccountsForAllPdf (counterparties) {
      const accounts = []
      for (const counterparty of counterparties) {
        const completeCounterparty = await this.fetchAccountById(counterparty.accountId)
        accounts.push({
          ...completeCounterparty,
          ...counterparty
        })
      }
      return accounts
    }
  }
}
</script>
