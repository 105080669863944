<template>
  <span>
    <v-row>
      <v-spacer/>
      <v-col align="right">
        <v-menu
        v-model="groupingConfigMenu"
        :close-on-content-click="false"
        left>
          <template v-slot:activator="{on: menu, attrs}">
            <v-tooltip bottom color="primary">
              <template #activator="{on: tooltip}">
                <v-icon color="primary" class="mt-4 mr-n2" v-bind="attrs" v-on="{...tooltip, ...menu}">mdi-filter-cog</v-icon>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">
                {{ $t('selectGroup') }}
              </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="primary white--text">
              {{ $t('selectGroup') }}
            </v-card-title>
            <v-card-text class="px-0" fluid>
              <v-container>
                <v-radio-group v-model="radioSelection">
                  <v-radio
                  v-for="(header, i) in headers"
                  :key="i"
                  color="primary"
                  :value="header"
                  :label="header.text"
                  @click="closeGroupings()"/>
                </v-radio-group>
              </v-container>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
      <v-data-table
      :headers="headers"
      :items="items"
      :group-by="subGroupValues"
      dense
      :show-expand="expandToContract"
      single-expand
      :style="`cursor: ${expandToContract ? 'pointer' : 'default'}`"
      :expanded="expanded"
      @click:row="rowClicked"
      item-key="index"
      :items-per-page="-1"
      hide-default-footer
      ref="table"
      >
        <template #group.header="item">
          <th :colspan="headers.length + 1" @click="item.toggle" style="cursor: pointer">
            <v-row>
              <v-col cols="10" align="left">
                <v-icon :ref="item.group" class="mt-n1">
                  {{ item.isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="subtitle-1">
                  {{ groupTitle(item) }}
                </span>
              </v-col>
              <v-spacer/>
              <v-col cols="2" align="right" v-if="showRateGroupedColumn" :ratesInline="rates = ratesForGroupHeader(item)">
                <v-row>
                  <v-col class="mt-1">
                    <Icon
                    v-if="rates.length > 1"
                    icon="mdi-dots-horizontal"
                    icon-color="black"
                    :tooltipText="$t('multipleDistinctRates')"/>
                    <span v-else>
                      {{ rates[0] ? formatMoney(rates[0]) : formatMoney(0) }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </th>
        </template>
        <template #item.rate="{item}">
          {{ formatMoney(item.rate) }}
        </template>
        <template #expanded-item="{ headers, item }">
          <td class="grey lighten-5" :colspan="headers.length+1">
            <v-row>
              <v-col cols="12" md="6" xl="4" v-for="(contract, i) of getContracts(item)" :key="i">
                <RateVerificationContractCard
                :contract="contract"
                @unposted-tickets="unpostedTickets"/>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
  </span>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations.js'
export default {
  name: 'RateVerificationTable',

  components: {
    RateVerificationContractCard: () => import('./RateVerificationContractCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    groupingKeys: {
      type: Array,
      required: true
    },
    expandToContract: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    headers: [],
    expanded: [],
    groupingConfigMenu: false,
    radioSelection: undefined,
    defaultExpandedGroup: undefined
  }),

  watch: {
    radioSelection () {
      this.groupingConfigMenu = false
      this.$nextTick(_ => {
        this.closeGroupings(this.defaultExpandedGroup)
      })
    }
  },

  computed: {
    subGroupValues () {
      return this.radioSelection
        ? [this.radioSelection.value]
        : []
    },

    showRateGroupedColumn () {
      return this.radioSelection.value !== 'rate'
    }
  },

  created () {
    this.headers = this.groupingKeys
    this.radioSelection = this.headers[0]
  },

  mounted () {
    this.closeGroupings(this.defaultExpandedGroup)
  },

  methods: {
    formatMoney,

    rowClicked (item) {
      if (!this.expandToContract) return
      this.$nextTick(() => {
        this.expanded = (this.expanded.includes(item)) ? [] : [item]
      })
    },

    groupTitle (item) {
      const group = this.$t(item.groupBy[0])
      const value = item.groupBy[0] === 'rate'
        ? formatMoney(item.group)
        : item.group
      return `${group}: ${value}`
    },

    closeGroupings (singleExpand) {
      Object.keys(this.$refs.table.openCache).forEach(g => {
        this.$refs.table.openCache[g] = g === singleExpand?.group
      })
      this.defaultExpandedGroup = undefined
    },

    ratesForGroupHeader (group) {
      const rates = [...new Set(group.items.map(item => item.rate))]
      if (rates.length > 1) this.defaultExpandedGroup ??= group
      return rates
    },

    getContracts (item) {
      return Array.from(item.contracts.values())
    },

    unpostedTickets (contractId) {
      this.expanded[0].contracts.delete(contractId)
      if (this.expanded[0].contracts.size === 0) this.expanded = []
      this.$emit('refresh-batch')
    }
  }
}
</script>
