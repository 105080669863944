<template>
  <v-row>
    <v-col cols="12">
      <BasicFinancialTable
      :entries="miscPayments"
      @aggregate-selected="miscPaymentSelected"/>
    </v-col>
    <v-dialog v-model="miscPaymentDialogOpen" width="900px">
      <v-card v-if="focusedPayment">
        <v-card-title class="headline py-4 primary white--text">
          <v-row>
            <v-col cols="10">
              <span style="overflow-wrap: anywhere; word-wrap: break-word; word-break: normal;">
                {{ title }}
              </span>
            </v-col>
            <v-spacer/>
            <v-col align="right">
              <Icon
              icon="mdi-close"
              icon-color="white"
              large
              :small="false"
              :tooltipText="$t('close')"
              @icon-clicked="closeDialog()"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
              :items="focusedPayment.payments"
              :headers="breakdownHeaders"
              :hide-default-footer="focusedPayment.payments.length <= 10">
              <template #item.amount="{item}">
                {{ formatMoney(item.amount) }}
              </template>
              <template #item.dateIssued="{item}">
                {{ utcToLocalDate(item.dateIssued, 'L - LT') }}
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { formatMoney } from '@/utils/NumericMutations'
import { MiscPaymentType } from '../../../../utils/Enumerations'
export default {
  name: 'MiscPaymentsAggregation',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    BasicFinancialTable: () => import('@/components/accounting/adjusting-entries/v1/BasicFinancialTable.vue')
  },

  props: {
    miscPayments: {
      type: Array,
      default: () => []
    },
    miscPaymentType: {
      type: Object,
      requried: true
    }
  },

  data: () => ({
    focusedPayment: undefined,
    miscPaymentDialogOpen: false
  }),

  computed: {
    aggregateHeaders () {
      return AdjustingEntriesHeaders.miscPayments()
    },

    breakdownHeaders () {
      return this.miscPaymentType.value === MiscPaymentType.TractPayment.value
        ? AdjustingEntriesHeaders.tractPaymentBreakdown()
        : AdjustingEntriesHeaders.accountPaymentBreakdown()
    },

    title () {
      return this.miscPaymentType.value === MiscPaymentType.TractPayment.value
        ? this.$t('tractPaymentsByEntityAndActivity', { entity: this.focusedPayment.businessEntity, activity: this.focusedPayment.activity })
        : this.$t('accountPaymentsByEntityAndActivity', { entity: this.focusedPayment.businessEntity, activity: this.focusedPayment.activity })
    }
  },

  methods: {
    utcToLocalDate,
    formatMoney,
    miscPaymentSelected (payment) {
      this.focusedPayment = payment
      this.miscPaymentDialogOpen = true
    },

    closeDialog () {
      this.miscPaymentDialogOpen = false
      this.focusedPayment = undefined
    }
  }
}
</script>
