<template>
  <v-card v-if="journalEntry">
    <v-card-title class="primary">
      <span class="white--text headline">{{titleText}}</span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      dataTestId="entry-detail-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <JournalEntryDetailContent :propJournalEntry="journalEntry"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'JournalEntryDetail',

  props: {
    propJournalEntry: Object,
    journalEntryId: Number
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    JournalEntryDetailContent: () => import('./JournalEntryDetailContent.vue')
  },

  computed: {
    titleText () {
      return `${this.journalEntry.accountName}`
    }
  },

  data: () => ({
    journalEntry: undefined
  }),

  async created () {
    if (this.journalEntryId) {
      const response = await this.getJournalEntry(this.journalEntryId)
      this.journalEntry = response
    } else {
      this.journalEntry = this.propJournalEntry
    }
  },

  methods: {
    ...mapActions('journalEntry', ['getJournalEntry'])
  }
}
</script>
