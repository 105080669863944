<template>
  <v-card>
    <v-card-title class="primary">
      <span class="white--text headline">
        {{title}}
      </span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      dataTestId="tag-close-button"
      :tooltipText="$t('close')"
      :small="false"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper @submit="saveChanges">
        <v-container grid-list-sm>
          <v-row dense>
            <v-col cols="12" md="12" xs="12">
              <v-select
                :readonly="editing"
                v-model="selectedArea"
                color="black"
                item-text="name"
                return-object
                item-color="primary"
                data-testId="tag-area"
                :label="$t('area')"
                :items="tagAreas"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-text-field
                v-model="tag.value"
                color="black"
                counter
                maxlength="16"
                ref="name"
                data-testId="tag-value"
                :label="$t('name')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { TagArea } from '@/utils/Enumerations.js'
export default {
  name: 'TagForm',

  props: {
    propTag: {
      type: Object,
      required: false,
      default: undefined
    },
    propTagArea: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  computed: {
    editing () {
      return this.propTag !== undefined
    },

    title () {
      return this.editing ? 'Edit Tag' : 'Create Tag'
    },
    tagAreas () {
      return TagArea.enums
    }
  },

  data: () => ({
    selectedArea: TagArea.Accounts,
    tag: {
      tagArea: 0,
      value: ''
    }
  }),

  watch: {
    selectedArea (val) {
      this.tag.tagArea = val.value
    }
  },

  created () {
    if (this.propTag) {
      this.tag = JSON.parse(JSON.stringify(this.propTag))
      this.selectedArea = TagArea.forInt(this.tag.tagArea)
    } else if (this.propTagArea) {
      this.selectedArea = TagArea.forInt(this.propTagArea.value)
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.name.focus()
    }, 0)
  },

  methods: {
    ...mapActions('tags', ['createTag', 'updateTag']),
    async saveChanges () {
      if (this.tag.value.length <= 0) {
        this.setSnackError(this.$t('tagNoNameError'))
        return
      }

      if (this.editing) {
        const updateRequest = {
          tag: this.tag,
          tagId: this.tag.tagId
        }

        await this.updateTag(updateRequest)
        this.$emit('tag-mutated')
      } else {
        await this.createTag(this.tag)
        this.$emit('tag-mutated')
      }
    }
  }
}
</script>
