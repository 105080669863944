<template>
  <v-card>
    <v-card-title class="primary headline white--text">
      <span>
        {{titleText}}
      </span>
      <v-spacer></v-spacer>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      strongValidation
      :disabled="consumeBtnDisabled"
      :buttonText="titleText"
      @submit="consumeTickets">
        <v-container fluid>
          <v-row v-if="consumptionMode === ConsumptionMode.TicketBased.value">
            <v-col cols="12">
              <p class="black--text subtitle-1">
                <span v-if="ticketsForConsumption.length === 1">
                  {{$t('consumptionDetailSingleText', { count: ticketsForConsumption.length })}}
                </span>
                <span v-else>
                  {{$t('consumptionDetailText', { count: ticketsForConsumption.length })}}
                </span>
              </p>
              <p>
                {{$t('toBeConsumedLabel')}}
              </p>
              <AggregateTotalTable
              style="color: black;"
              :aggregateTotal="aggregateTotal"/>
            </v-col>
          </v-row>
          <v-row v-if="consumptionMode === ConsumptionMode.WeightBased.value" dense>
            <v-col cols="12">
              <p class="black--text subtitle-1" v-if="weightToConsume === 1">
                {{ $t('consumptionDetailWeightSingleText', { weight: weightToConsume, deck: deck.name }) }}
              </p>
              <p class="black--text subtitle-1" v-else>
                {{ $t('consumptionDetailWeightText', { weight: weightToConsume, deck: deck.name }) }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
              :rules="[s => s.length <= 32 || $t('stringLengthMustBeLE', { object: 'Label', length: 32 })]"
              v-model="label"
              color="black"
              ref="firstField"
              :label="$t('label')"/>
            </v-col>
            <v-col cols="12">
            </v-col>
            <v-col cols="12" class="mt-n12">
              <v-checkbox v-model="applyConsumedOnDate" :label="$t('specifyConsumedOn')" color="primary"></v-checkbox>
              <DatePicker
              v-if="applyConsumedOnDate"
              dataTestId="consumption-consumed-on"
              :dateLabel="$t('consumedOn')"
              :startDate="consumedOn"
              :allowFuture="false"
              :min.sync="latestDeckAssignment"
              @valid-date="(val) => { isValidDate = val }"
              @date-picked="consumedOnChosen"/>
            </v-col>
            <v-col v-show="invalidDateText" cols="12">
              <span class="error--text">{{invalidDateText}}</span>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { utcToLocalDate, localToCompanyOffsetEndOfDay } from '@/utils/DateFormatter.js'
import { tonStringFromPounds, numberWithCommas } from '@/utils/NumericMutations.js'
import { ConsumptionMode } from '@/utils/Enumerations.js'
export default {
  name: 'ConfirmConsumption',

  props: {
    deck: Object,
    ticketsForConsumption: Array,
    weightToConsume: Number,
    productMap: Object
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    AggregateTotalTable: () => import('@/components/ticket/ticket-consumption/AggregateTotalTable.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue')
  },

  data: () => ({
    label: '',
    consumedOn: new Date().toISOString(),
    isValidDate: true,
    applyConsumedOnDate: false,
    ConsumptionMode
  }),

  mounted () {
    setTimeout(() => {
      this.$refs.firstField.focus()
    }, 500)
  },

  computed: {
    aggregateTotal () {
      let collectAverageRpi = true
      const totals = this.ticketsForConsumption.reduce((t, ticket) => {
        t.weightTotal += ticket.inWeight - ticket.outWeight - ticket.defectWeight
        t.mbfTotal += (ticket.inWeight - ticket.outWeight - ticket.defectWeight) * this.productMap[ticket.product].conversionRateW2V
        if (collectAverageRpi) {
          if (ticket.rpi === 0) {
            collectAverageRpi = false
          } else {
            t.rpiTotal += (ticket.rpi * (ticket.inWeight - ticket.outWeight - ticket.defectWeight))
          }
        }
        return t
      }, { weightTotal: 0, mbfTotal: 0, rpiTotal: 0 })

      return {
        weightTons: tonStringFromPounds(totals.weightTotal),
        mbf: numberWithCommas(Math.ceil(totals.mbfTotal)),
        rpi: collectAverageRpi ? numberWithCommas((totals.rpiTotal / totals.weightTotal), 2) : 0
      }
    },

    consumedOnEndOfDay () {
      return moment(this.consumedOn).endOf('day').format()
    },

    consumptionMode () {
      return this.deck.consumptionMode
    },

    consumeBtnDisabled () {
      return this.applyConsumedOnDate && (!this.isValidDate || this.invalidDateText !== undefined)
    },

    titleText () {
      switch (this.consumptionMode) {
        case ConsumptionMode.TicketBased.value:
          return this.$t('consumeTickets')
        case ConsumptionMode.WeightBased.value:
          return this.$t('consumeWeight')
        default:
          return ''
      }
    },

    invalidDateText () {
      if (this.applyConsumedOnDate === false) {
        return undefined
      }

      const isAfterToday = moment(this.consumedOn).isAfter(moment().endOf('day'))
      if (isAfterToday) {
        return this.$t('consumedOnCannotBeFutureDate')
      }

      if (moment(this.latestDeckAssignment).isAfter(moment.utc(this.consumedOnEndOfDay))) {
        return this.$t('ticketsAssignedToDeckAfterConsumedOn', { date: utcToLocalDate(this.latestDeckAssignment) })
      }

      return undefined
    },

    latestDeckAssignment () {
      if (this.ticketsForConsumption.length === 0) { return '' }

      return moment.max(this.ticketsForConsumption.map(t => moment.utc(t.deckAssignedAt))).format()
    }
  },

  methods: {
    ...mapActions('consumption', ['postConsumptionBatch']),

    consumedOnChosen (date) {
      this.consumedOn = date
    },

    close () {
      this.$emit('close')
    },

    async consumeTickets () {
      const ticketIds = this.ticketsForConsumption.map(t => t.ticketId)
      const weightToConsume = Math.round(this.weightToConsume * 2000)
      const notSameDay = !moment(this.consumedOn).isSame(moment(), 'day')

      await this.postConsumptionBatch({
        ticketIds: this.consumptionMode === ConsumptionMode.TicketBased.value ? ticketIds : [],
        weightToConsume: this.consumptionMode === ConsumptionMode.WeightBased.value ? weightToConsume : 0,
        label: this.label ?? null,
        consumedOn: (this.applyConsumedOnDate && notSameDay) ? localToCompanyOffsetEndOfDay(this.consumedOn) : null,
        deckId: this.deck.deckId
      })

      this.$emit('tickets-consumed')
    }
  }
}
</script>
