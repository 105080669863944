<template>
  <v-menu offset-y rounded>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        color="grey"
        @click="notificationCount = 0"
        v-on="on"
      >
        <v-badge
        :content="notificationCount"
        :value="notificationCount"
        color="error"
        overlap
        small
        >
          <v-icon small>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-list dense v-if="userNotifications.length > 0">
        <v-list-item
          v-for="(notification, index) in notifications"
          :key="`notification-key-${index}`"
          @click="notificationClicked(notification)"
        >
          <v-list-item-icon>
            <v-icon :color="getNotificationIconColor(notification)">
              {{getNotificationIcon(notification.identifier)}}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="ml-n6 my-3">
            <span style="text-wrap: wrap;">
              {{ getNotificationString(notification) }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-card-title v-else>
        <span class="subtitle-2">{{$t('noNotifications')}}</span>
      </v-card-title>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { NotificationIdentifier } from '@/utils/Enumerations.js'
import moment from 'moment'
export default {
  name: 'Notifications',

  data: () => ({
    notifications: [],
    notificationCount: 0
  }),

  computed: {
    ...mapGetters('notifications', ['userNotifications'])
  },

  watch: {
    notifications: {
      handler (val) {
        this.notificationCount = val.length
      },
      immediate: true
    },

    userNotifications: {
      handler (val) {
        this.notifications = []
        val.forEach(notification => {
          notification.entityDetails.forEach((entityDetails) => {
            this.notifications.push({
              details: JSON.parse(entityDetails),
              identifier: notification.identifier
            })
          })
        })
      },
      immediate: true
    }
  },

  methods: {
    getNotificationIcon (identifier) {
      return NotificationIdentifier.forInt(identifier).icon
    },

    getNotificationIconColor (notification) {
      switch (notification.identifier) {
        case NotificationIdentifier.ContractExpiration.value:
          return 'primary'
        case NotificationIdentifier.InsuranceExpiration.value:
          return 'brown'
        case NotificationIdentifier.AccountCertificationExpiration.value:
          return 'black'
        case NotificationIdentifier.DeviceRegistrationExpiration.value:
          return 'black'
        case NotificationIdentifier.ContractModificationPendingApproval.value:
        case NotificationIdentifier.ContractModificationReturned.value:
          return 'primary'
        default:
          console.error('Unexpected notification identifier')
      }
    },

    getNotificationString (notification) {
      switch (notification.identifier) {
        case NotificationIdentifier.ContractExpiration.value:
          return this.getContractExpirationString(notification.details)
        case NotificationIdentifier.InsuranceExpiration.value:
          return this.getInsuranceExpirationString(notification.details)
        case NotificationIdentifier.AccountCertificationExpiration.value:
          return this.getAccountCertificationExpirationString(notification.details)
        case NotificationIdentifier.DeviceRegistrationExpiration.value:
          return this.getDeviceExpirationString(notification.details)
        case NotificationIdentifier.ContractModificationPendingApproval.value:
          return this.getPendingContractApprovalString(notification.details)
        case NotificationIdentifier.ContractModificationReturned.value:
          return this.getReturnedContractApprovalString(notification.details)
        default:
          console.error('Unexpected notification identifier')
      }
    },

    getContractExpirationString (contract) {
      if (contract.Tract === null || contract.Tract === undefined) {
        return `${contract.FromAccount} || ${contract.Account} || ${contract.Destination} expires ${this.getDaysBetween(contract.ExpirationDate)}`
      } else {
        return `${contract.Tract} || ${contract.Account} || ${contract.Destination} || ${contract.Setting} expires ${this.getDaysBetween(contract.ExpirationDate)}`
      }
    },

    getInsuranceExpirationString (account) {
      const today = moment.utc()
      const expirationDate = moment.utc(account.InsuranceExpiryDate)

      return expirationDate.isBefore(today)
        ? this.$t('insuranceExpiredNotification', { accountName: account.Name, expirationDate: utcToLocalDate(account.InsuranceExpiryDate) })
        : this.$t('insuranceExpiringNotification', { accountName: account.Name, expirationDate: utcToLocalDate(account.InsuranceExpiryDate) })
    },

    getAccountCertificationExpirationString (accountCertification) {
      const today = moment.utc()
      const expirationDate = moment.utc(accountCertification.ExpirationDate)

      var expirationString = ''
      if (expirationDate.isSame(today, 'day')) {
        expirationString = 'accountCertificationExpiredTodayNotification'
      } else if (expirationDate.isBefore(today)) {
        expirationString = 'accountCertificationExpiredNotification'
      } else {
        expirationString = 'accountCertificationExpiringNotification'
      }

      return this.$t(expirationString, {
        certificationName: accountCertification.CertificationName,
        accountName: accountCertification.AccountName,
        expirationDate: utcToLocalDate(accountCertification.ExpirationDate)
      })
    },

    getDeviceExpirationString (device) {
      const today = moment.utc()
      const expirationDate = moment.utc(device.ExpiresOn)
      let truncatedId = ''
      let idType = ''
      if (device.DeviceID.length <= 16) {
        truncatedId = device.DeviceID
        idType = 'androidId'
      } else {
        truncatedId = device.DeviceID.slice(-17)
        idType = 'udid'
      }
      return expirationDate.isBefore(today)
        ? this.$t(idType + 'ExpiredNotification', { account: device.Account, deviceId: truncatedId, expirationDate: utcToLocalDate(device.ExpiresOn) })
        : this.$t(idType + 'ExpirationNotification', { account: device.Account, deviceId: truncatedId, expirationDate: utcToLocalDate(device.ExpiresOn) })
    },

    getPendingContractApprovalString (pendingContractApproval) {
      return pendingContractApproval.Tract
        ? this.$t('pendingContractApprovalNotificationWithTract', { tract: pendingContractApproval.Tract })
        : this.$t('pendingContractApprovalNotification')
    },

    getReturnedContractApprovalString (returnedContractApproval) {
      return returnedContractApproval.Tract
        ? this.$t('returnedContractApprovalNotificationWithTract', { tract: returnedContractApproval.Tract })
        : this.$t('returnedContractApprovalNotification')
    },

    getDaysBetween (expirationDate) {
      const today = moment.utc()
      const endDate = moment.utc(expirationDate)
      const deltaDays = (moment.duration(endDate.diff(today)).asDays())
      let expirationText = ''
      if (deltaDays < 1) expirationText = this.$t('hoursExpirationSuffix', { hours: 24 })
      else if (deltaDays < 1.5) expirationText = this.$t('hoursExpirationSuffix', { hours: 36 })
      else expirationText = this.$t('daysExpirationSuffix', { days: deltaDays.toFixed(0) })

      return expirationText
    },

    notificationClicked (notification) {
      switch (notification.identifier) {
        case NotificationIdentifier.ContractExpiration.value:
          this.$emit('contract-notification', notification.details.ContractId)
          break
        case NotificationIdentifier.InsuranceExpiration.value:
          this.$emit('insurance-notification', notification.details.AccountId)
          break
        case NotificationIdentifier.AccountCertificationExpiration.value:
          this.$emit('account-certification-notification', notification.details)
          break
        case NotificationIdentifier.DeviceRegistrationExpiration.value:
          this.$emit('device-notification', notification.details.DeviceRegistrationId)
          break
        case NotificationIdentifier.ContractModificationPendingApproval.value:
          this.$emit('pending-approval-notification', notification.details.ContractId)
          break
        case NotificationIdentifier.ContractModificationReturned.value:
          this.$emit('returned-approval-notification', notification.details.ContractId)
          break
        default:
          console.error('Unexpected notification identifier')
      }
    }
  }
}
</script>
