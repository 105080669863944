<template>
  <div class="fill-height">
    <GridChartCard
      v-if="data.length > 0"
      :title="title"
      @draw="createChart"
      :hasData="redrawFlag"
      v-bind="{tooltip}"
    >
      <template #actions>
        <slot name="actions"/>
      </template>
    </GridChartCard>
  </div>
</template>

<script>
import { Chart } from 'chart.js'
import { numberWithCommas, formatMoney } from '@/utils/NumericMutations.js'
import { chartColorForContractMode } from './ChartColors'
import { mapGetters } from 'vuex'
export default {
  name: 'LineChartCard',

  components: {
    GridChartCard: () => import('./GridChartCard.vue')
  },

  props: {
    data: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    yFormatType: {
      type: String,
      default: 'USD'
    },
    tooltip: {
      type: String,
      default: '',
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    pointStyles: [
      'circle',
      'triangle',
      'rect',
      'star',
      'cross',
      'line',
      'crossRot',
      'dash',
      'rectRounded',
      'rectRot'
    ],
    redrawFlag: false
  }),

  watch: {
    data () {
      this.redrawFlag = false
      this.$nextTick(() => {
        this.redrawFlag = true
      })
    }
  },

  computed: {
    ...mapGetters('ticket', ['contractMode'])
  },

  mounted () {
    if (this.data.length > 0) this.redrawFlag = true
  },

  methods: {
    numberWithCommas,
    formatMoney,

    isOneLocation (data) {
      let isOneLocation = true
      const locationName = data[0].locationName
      for (let i = 0; i < data.length; i++) {
        const isSame = data[i].locationName === locationName
        if (!isSame) {
          isOneLocation = false
          break
        }
      }
      return isOneLocation
    },

    getDatasets (isOneLocation) {
      const datasets = []
      let labels = []
      if (this.data.length > 0) {
        labels = this.data[0].dates
      } else {
        return
      }
      const styleMap = {}
      let pointsUsed = 0

      const getLabel = (locationName, deckName) => {
        if (isOneLocation) return deckName

        return `${locationName}-${deckName}`
      }

      this.data.forEach((deck, i) => {
        let color = ''
        let pointStyle = ''
        if (styleMap[deck.locationName] === undefined) {
          styleMap[deck.locationName] = { pointStyle: '', colorsUsed: 0 }
          styleMap[deck.locationName].pointStyle = this.pointStyles[pointsUsed % this.pointStyles.length]
          pointsUsed++
        }
        pointStyle = styleMap[deck.locationName].pointStyle
        color = chartColorForContractMode(i, this.data.length, this.contractMode.value)
        styleMap[deck.locationName].colorsUsed++

        const dataSet = {
          data: deck[this.valueKey],
          label: getLabel(deck.locationName, deck.name),
          fill: false,
          borderColor: color,
          pointBackgroundColor: color,
          pointStyle: pointStyle,
          pointRadius: 5
        }
        datasets.push(dataSet)
      })

      return {
        datasets: datasets,
        labels: labels
      }
    },

    createChart (canvas) {
      const isOneLocation = this.isOneLocation(this.data)
      const chartData = this.getDatasets(isOneLocation)
      const format = this.yFormatType

      const chartConfig = {
        type: 'line',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 500,
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItem) => {
                  const value = format === 'USD'
                    ? formatMoney(tooltipItem.parsed.y)
                    : numberWithCommas(tooltipItem.parsed.y, 3)

                  return `${tooltipItem.dataset.label}: ${value}`
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: true
              }
            },
            y: {
              display: true,
              grid: {
                display: true
              },
              ticks: {
                callback: function (val, index) {
                  if (format === 'USD') {
                    return formatMoney(val)
                  } else {
                    return numberWithCommas(val, 3)
                  }
                }
              }
            }
          }
        }
      }
      this.deckWeightChart = new Chart(canvas, chartConfig)
    }
  }
}
</script>
