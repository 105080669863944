<template>
  <v-container fluid>
    <v-col v-if="filterTypes" cols="12" md="auto" class="px-0">
      <v-row dense>
        <v-col cols="12" md="6">
          <div>
            <v-select
              v-if="Object.keys(filterItems).length > 0"
              clearable
              :items="filterTypes.filter(ft => this.filterItems[ft?.key]?.length > 0)"
              return-object
              :value="filterType"
              @input="setFilterType"
              :label="$t('filterBy')"
              outlined
              rounded
              dense
              class="mb-n6 mr-2"
            >
              <template #item="{ item, on }">
                <v-list-item v-on="on">
                  <v-icon class="mr-2">{{IconHelpers.getIconForObjectType(item.key)}}</v-icon>
                  <span>{{item.name}}</span>
                </v-list-item>
              </template>
              <template #selection="{ item } ">
                <template>
                  <v-icon class="mr-2">{{IconHelpers.getIconForObjectType(item.key)}}</v-icon>
                  <span>{{item.name}}</span>
                </template>
              </template>
            </v-select>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <v-select
              v-if="filterItems"
              v-show="filterType?.name"
              clearable
              :items="filterItems[filterType?.key]"
              return-object
              :value="selectedFilter"
              @input="setFilter"
              outlined
              rounded
              dense
              :label="filterType?.name"
              class="mb-n6 mr-2"
              :disabled="filterItems[filterType?.key]?.length <= 1"
            >
              <template #item="{ item, on }">
                <v-list-item v-on="on">
                  <span>{{item}}</span>
                </v-list-item>
              </template>
              <template #selection="{ item } ">
                <template>
                  <span>{{item}}</span>
                </template>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="pa-0">
      <DataTable
        :items="itemsFiltered || items"
        :headers="headers"
        :selected.sync="selectedItemsModel"
        :tableKey="tableKey"
        :showSelect="showSelect"
        :keepSelectionWhenItemsChange="filterTypes != undefined"
      />
    </v-col>
  </v-container>
</template>

<script>
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'EBTractPaymentSelection',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue')
  },

  data: () => ({
    IconHelpers,
    filterType: undefined,
    selectedFilter: undefined,
    filterItems: {},
    itemsFiltered: undefined
  }),

  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Array,
      required: false,
      default: () => []
    },
    headers: {
      type: Array,
      required: true
    },
    tableKey: {
      type: String,
      required: true
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    filterTypes: {
      type: Array,
      required: false,
      default: undefined
    },
    filterTypeFromStorage: {
      type: String,
      required: false,
      default: undefined
    }
  },

  async created () {
    if (this.filterTypes) {
      if (this.filterTypeFromStorage) this.filterType = this.filterTypes.find(ft => ft.key === this.filterTypeFromStorage)
      this.setFilterItems()
      this.setFilterType(this.filterType)
    }
  },

  watch: {
    selectedFilter: {
      deep: true,
      handler (val) {
        this.setFilter(val)
      }
    }
  },

  computed: {
    selectedItemsModel: {
      get () { return this.selectedItems },
      set (value) { this.$emit('update:selectedItems', value) }
    }
  },

  methods: {
    setFilterType (ft) {
      this.filterType = ft
      this.selectedFilter = undefined
      this.$emit('filter-type-set', ft?.key)
      if (this.filterItems[this.filterType?.key]?.length === 1) this.setFilter(this.filterItems[this.filterType?.key][0])
    },

    setFilter (f) {
      this.selectedFilter = f
      this.itemsFiltered = f
        ? this.items.filter(t => t[this.filterType?.key] === this.selectedFilter)
        : this.items
    },

    setFilterItems () {
      this.filterTypes.forEach(ft => {
        this.filterItems[ft.key] = [...new Set(this.items.map(t => t[ft.key]))].filter(i => i !== null && i.length > 0).sort((a, b) => a.localeCompare(b))
      })
      this.setFilter(undefined)
      if (this.filterItems[this.filterType?.key]?.length < 1) this.setFilterType(undefined)
      if (this.filterItems[this.filterType?.key]?.length === 1) this.setFilter(this.filterItems[this.filterType?.key][0])
    }
  }
}
</script>
