<template>
  <div>
    <v-autocomplete
    v-model="chosenTeam"
    color="black"
    :label="$t('team')"
    :items="teams"
    @change="teamChosen"
    :loading="teamsLoading"
    item-text="name"
    return-object
    clearable
    autocomplete="chrome-off"
    >
      <template #prepend-inner>
        <v-icon>mdi-account-group</v-icon>
      </template>
      <template #append-outer>
        <v-icon @click="refreshTeams" color="primary" tabindex="-1">mdi-refresh</v-icon>
      </template>
      <template #item="{ item }">
        <v-row no-gutters>
          <v-col>
            {{ item.name }}
          </v-col>
          <v-col cols="auto">
            <Icon v-if="!!item.description"
            :small="false"
            iconColor="info"
            icon="mdi-information-outline"
            :tooltipText="item.description"
            margin="mx-1"
            />
          </v-col>
          <v-col cols="auto">
            <Icon
            iconColor="info"
            :small="false"
            icon="mdi-dots-horizontal"
            :tooltipText="getTeamTooltip(item)"
            margin=""
            />
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TeamAutocomplete',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  created () {
    if (this.propTeamId) this.chosenTeam = this.allTeams.find(t => t.teamId === this.propTeamId)
  },

  props: {
    propTeamId: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    chosenTeam: undefined,
    team: undefined,
    initializing: true
  }),

  computed: {
    ...mapGetters('team', ['allTeams', 'teamsLoading']),

    teams () {
      return this.allTeams.filter(t => t.isActive)
    }
  },

  methods: {
    ...mapActions('team', ['fetchTeams']),

    async refreshTeams () {
      await this.fetchTeams()
    },

    getTeamTooltip (team) {
      return team.users
        .map((u) => u.name)
        .toString()
        .replaceAll(',', '\n')
    },

    teamChosen (team) {
      this.$emit('team-chosen', team)
    }
  }
}
</script>
