<template>
  <Icon
    :icon="statusIcons[status]"
    :iconColor="isHeader ? 'tertiary' : statusColors[status]"
    :tooltipColor="isHeader ? 'primary' : statusColors[status]"
    :tooltipText="statusLabels[status]"
    :small="!isHeader"
  />
</template>

<script>
import { TractStatus } from '@/utils/Enumerations.js'
export default {
  name: 'TractStatusIcon',

  props: {
    status: {
      type: Number
    },
    isHeader: {
      type: Boolean
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    statusIcons: [
      'mdi-pencil-plus-outline',
      'mdi-forest',
      'mdi-check-circle-outline',
      'mdi-check-circle',
      'mdi-close-circle',
      'mdi-archive'
    ],
    statusColors: [
      'black',
      'success',
      'grey',
      'success',
      'error',
      'grey'
    ]
  }),

  computed: {
    statusLabels () {
      return TractStatus.map(ts => ts.name)
    }
  }
}
</script>
