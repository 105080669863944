<template>
  <v-card>
    <v-card-title class="headline white--text primary">
      <span>
        {{ $t('advances')}}
      </span>
      <v-spacer/>
      <span class="mr-3">{{ formatMoney(total) }}</span>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text class="mt-6">
      <DataTable
        hideHeader
        :items.sync="payee.advances"
        :headers="headers"
        :customCells="customCells"
        :no-data-text="$t('noAdvances')"
      >
        <template #remainingBalance="{ item }">
          <span>{{formatMoney(item.remainingBalance)}}</span>
        </template>
        <template #amount="{ item }">
          <span>{{formatMoney(item.amount)}}</span>
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td class="text-left">{{ $t('totals') }}</td>
            <td></td>
            <td class="text-right">{{formatMoney(total)}}</td>
            <td class="text-right">{{formatMoney(balanceTotal)}}</td>
          </tr>
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import { advanceDialogHeaders } from '@/headers/Cycles.js'

export default {
  name: 'SBPAdvanceDialog',

  props: {
    payee: {
      type: Object,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    balanceTotal () {
      return this.payee.advances.map(recovery => recovery.remainingBalance).reduce((prev, curr) => prev + curr, 0)
    },
    total () {
      return this.payee.advances.map(recovery => recovery.amount).reduce((prev, curr) => prev + curr, 0)
    },
    headers () {
      return advanceDialogHeaders()
    },
    customCells () {
      return [
        {
          slotName: 'remainingBalance',
          value: 'remainingBalance'
        },
        {
          slotName: 'amount',
          value: 'amount'
        }
      ]
    }
  },

  methods: {
    formatMoney,
    close () {
      this.dialog = false
    }
  }
}
</script>
