import ApiServer from '@/utils/api/ApiServer.js'

export default {
  async fetchPostBatches (context, contractMode) {
    const url = ApiServer.urlFor('postBatches', {
      version: 2,
      params: {
        logs: contractMode === 0,
        byproduct: contractMode === 1,
        transfer: contractMode === 2,
        logYardSale: contractMode === 3
      }
    })
    const { data: postBatches } = await ApiServer.get(url)
    context.commit('setPostBatches', postBatches)
    return postBatches
  },

  async fetchTicketsForPostBatch (_, postBatchId) {
    const { data: tickets } = await ApiServer.get(`v2/postBatches/${postBatchId}/tickets`)
    return tickets
  },

  async fetchPostingGroups (context, contractMode) {
    const url = ApiServer.urlFor('tickets/forPosting', {
      version: 2,
      params: {
        logs: contractMode === 0,
        byproduct: contractMode === 1,
        transfer: contractMode === 2,
        logYardSale: contractMode === 3
      }
    })
    const { data: postingGroups } = await ApiServer.get(url)
    context.commit('setPostingGroups', postingGroups)
    return postingGroups
  },

  async postTickets (_, request) {
    const { data: response } = await ApiServer.post('postBatches', request)
    return response
  },

  async unpostTickets (_, { postBatchId, ticketIds }) {
    if (ticketIds) {
      const results = { successes: 0, failures: 0 }
      for (const ticketId of ticketIds) {
        try {
          await ApiServer.delete(`postBatches/${postBatchId}/tickets/${ticketId}`)
          results.successes++
        } catch (_) {
          results.failures++
        }
      }

      return results
    }

    await ApiServer.delete(`postBatches/${postBatchId}`)
  },

  async unpostTicket (_, { postBatchId, ticketId }) {
    try {
      await ApiServer.delete(`postBatches/${postBatchId}/tickets/${ticketId}`)
      return true
    } catch {
      return false
    }
  },

  async unpostContractTickets (_, tickets) {
    const results = { successes: 0, failures: 0 }
    for (const ticket of tickets) {
      try {
        await ApiServer.delete(`postBatches/${ticket.postBatchId}/tickets/${ticket.ticketId}`)
        results.successes++
      } catch (_) {
        results.errors++
      }
    }

    return results
  }
}
