<template>
  <v-container fluid class="mt-n6">
    <v-row no-gutters align="center" justify="end" :class="mobileCompatible ? 'mt-2' : ''">
      <v-col v-if="(parseFloat(distanceFromTract) > 0.5 && !distanceCleared) || asOfDate && !dateFilterCleared">
        <v-row dense justify="end" align="center">
          <v-col cols="auto" v-if="asOfDate && !dateFilterCleared">
            <v-chip close @click:close="clearDateFilter" color="info" class="my-n1">
              <span>{{$t('showingDataAsOf')}}: {{utcToLocalDate(asOfDate)}}</span>
            </v-chip>
          </v-col>
          <v-col cols="auto" v-if="parseFloat(distanceFromTract) > 0.5 && !distanceCleared">
            <v-chip close @click:close="clearDistance" color="info" class="my-n1">
              <span>{{$t('youAreMilesFromThisTract', {distance: this.distanceFromTract})}}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-row
          justify="end"
          align="center"
          class="ml-4"
          dense
          v-show="!mobileCompatible">
          <v-col cols="auto">
            <TabbedColumnAdjuster
            :propColumnSet="harvestHeaders"
            :propTabs="columnAdjusterTabs"
            :isOpen="columnAdjusterOpen"
            :dynamicSize="mobileCompatible"
            @closed="columnAdjusterOpen = false"
            @new-columns-selected="newColumnsSelected"
            v-show="!mobileCompatible"/>
          </v-col>
          <v-col cols="auto">
            <Icon v-if="!mobileCompatible"
            :small="false"
            :icon="tableView ? 'mdi-graph' : 'mdi-table'"
            iconColor="primary"
            dataTestId="tract-harvest-chart-view"
            :tooltipText="tableView ? $t('viewCharts') : $t('viewTable')"
            @icon-clicked="toggleTableView"
            />
          </v-col>
          <v-col cols="auto">
            <Icon v-if="!mobileCompatible"
            :small="false"
            :icon="units === HarvestUnits.MBF ? 'mdi-scale' : 'mdi-cube'"
            iconColor="primary"
            :tooltipText="units === HarvestUnits.MBF ? $t('viewWeight') : $t('viewVolume')"
            @icon-clicked="toggleVolumeView"
            />
          </v-col>
          <v-col cols="auto">
            <v-menu v-if="!mobileCompatible" left>
              <template #activator="{ on }">
                <v-icon v-on="on" color="primary">mdi-dots-horizontal</v-icon>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in contextMenuOptions"
                :key="`context-menu-${i}`"
                @click="item.action"
                :dataTestId="item.dataTestId">
                  <v-avatar left>
                    <v-icon color="black">{{item.icon}}</v-icon>
                  </v-avatar>
                  <v-list-item-title class="subtitle-1">{{ $t(item.label) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="auto">
            <Icon
            icon="mdi-refresh"
            @icon-clicked="refreshTractHarvest"
            :tooltipText="$t('refresh')"
            :small="false"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="tableView">
      <v-col cols="12" :class="mobileCompatible ? 'pa-0 mt-n4' : ''">
        <DataTable
        v-if="tractAnalysis"
        :items.sync="tractHarvest"
        :headers.sync="headers"
        :sumHeaders="sumHeaders"
        hideHeader
        :loading="loading"
        :showSearch="false"
        showTotals
        groupBy="woodType"
        :abbreviateHeaders="mobileCompatible"
        >
          <template #group.header="{ group }">
            <span class="title">{{ woodTypeName(group) }}</span>
          </template>
          <template #group-total.product="{ group }">
            {{ `${woodTypeName(group.group)} Totals` }}
          </template>
          <template #total.product>
          {{ $t('totals') }}
          </template>
          <template #group.summary.prepend="{ group }">
            <p v-if="$vuetify.breakpoint.xs" class="text-subtitle-2 ml-4 mt-4 mb-2 font-weight-black"> {{woodTypeName(group)}} Totals </p>
          </template>
        </DataTable>
      </v-col>
       <v-col cols="12" class="me-24">
        <v-row justify="end">
          <v-col cols="10" xs="10" sm="8" md="6" lg="4" xl="2">
            <v-simple-table>
              <template v-for="(section, i) in totalRows">
                <tr :key="`analysistotal-spacer-${i}`" v-if="i > 0"><td class="pt-6"/><td/></tr>
                <tr v-for="totalRow in section" :key="`analysis-totals-${totalRow.label}`" :style="`border: ${totalRow.topBorder ? '1px solid black' : 'none'}`">
                  <td :class="totalRow.keyRight ? '' : 'font-weight-bold'">{{$t(totalRow.label)}}</td>
                  <td :style="`text-align: right; ${totalRow.topBorder ? 'border-top: 1px solid black;' : '' }`">{{formatMoney(totalRow.value)}}</td>
                </tr>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-row justify="end" no-gutters>
          <span class="font-italic">{{$t('tractHarvestTicketStatusDenotation')}}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-show="!tableView" dense>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4">
        <canvas id="prod-chart"/>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4">
        <canvas id="stumpage-chart"/>
      </v-col>
      <v-col cols="12" xs="12" sm="6" md="6" lg="4">
        <canvas id="loads-chart"/>
      </v-col>
    </v-row>
    <v-row v-show="!tableView" class="mt-12" dense justify="center">
      <v-col cols="12">
        <canvas id="harvested-chart" style="min-height: 350px"/>
      </v-col>
    </v-row>
    <v-dialog v-model="includePlInPdfDialog" width="400">
      <ConfirmDialog
      :title="$t('downloadOptions')"
      :confirmText=" $t('downloadPDF')"
      color="primary"
      body=""
      @confirm="downloadPDF"
      @cancel="includePlInPdfClose"
      >
      <template #custom-body>
        <v-checkbox
        v-model="includePlInPdf"
        color="primary"
        :label="$t('includeTractSummaryBody')"></v-checkbox>
      </template>
      </ConfirmDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { Chart } from 'chart.js'
import { saveAs } from 'file-saver'
import { tonStringFromPounds, formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { generateCruiseAnalysisReport } from '@/utils/pdf-formatters/CruiseAnalysisReport.js'
import { tractHarvestHeaders } from '@/headers/Tract.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { TractTypeCategory, WoodType, HarvestUnits } from '@/utils/Enumerations'
import { generateCsvString } from '../../../utils/CSVUtility'
import { LocalStorageKeys } from '@/utils/constants.js'
import { getLogChartColors, logChartColorFor } from '@/components/dashboard/ChartColors.js'

export default {
  name: 'TractHarvest',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    TabbedColumnAdjuster: () => import('@/components/helper/TabbedColumnAdjuster.vue')
  },

  props: {
    tract: {
      type: Object,
      required: true
    },
    asOfDate: {
      type: String,
      default: null
    },
    shouldRefresh: {
      type: Boolean,
      default: false
    },
    mobileCompatible: {
      type: Boolean,
      default: false
    },
    distanceFromTract: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    HarvestUnits,
    tractAnalysis: undefined,
    tableView: true,
    units: HarvestUnits.forInt(parseInt(localStorage.getItem(LocalStorageKeys.TRACT_CRUISE_UNITS))) ?? HarvestUnits.Tons,
    loadsChart: undefined,
    productionCostsChart: undefined,
    cruiseValueChart: undefined,
    harvestedChart: undefined,
    loading: false,
    dateFilterCleared: false,
    distanceCleared: false,
    columnSet: null,
    includePlInPdfDialog: false,
    includePlInPdf: localStorage.getItem(LocalStorageKeys.INCLUDE_TRACT_SUMMARY_PDF),
    columnAdjusterOpen: false,
    newColumnSet: null
  }),

  computed: {
    harvestHeaders () {
      return tractHarvestHeaders(this.tractTypeCategory, this.units)
    },
    columnAdjusterTabs () {
      return ['cruise', 'operational', 'financial']
    },
    tractHarvest () {
      return this.tractAnalysis?.tractHarvestAnalysis ?? []
    },
    totals () {
      return this.tractHarvest.reduce((totals, th) => {
        totals.harvestedTons += th.harvestedTons
        totals.totalCost += th.totalCost
        totals.totalTractPayments = th.totalTractPayments
        totals.total = totals.totalCost + totals.totalTractPayments
        return totals
      }, {
        harvestedTons: 0,
        totalCost: 0,
        totalTractPayments: 0,
        total: 0
      })
    },

    tractTypeCategory () {
      const t = TractTypeCategory.forInt(this.tract.type.category)
      return t
    },

    sumHeaders () {
      return (this.columnSet) ? this.columnSet.getSummableHeaders() : []
    },

    totalRows () {
      if (!this.tractAnalysis) return []
      if (this.tractTypeCategory === TractTypeCategory.Delivered) return [[{ label: 'tractPayments', value: this.tractAnalysis.totalTractPayments }]]
      return [
        [
          { label: 'revenue', value: this.tractAnalysis.totalRevenue },
          { label: 'stumpageCost', value: this.tractAnalysis.totalStumpageCost, keyRight: true },
          { label: 'productionCost', value: this.tractAnalysis.totalProductionCost, keyRight: true },
          { label: 'totalCost', value: this.tractAnalysis.totalCost },
          { label: 'grossProfit', value: this.tractAnalysis.totalGrossProfit },
          { label: 'tractPayments', value: this.tractAnalysis.totalTractPayments },
          { label: 'tractPL', value: this.tractAnalysis.tractProfitLoss, topBorder: true }
        ],
        [
          { label: 'purchaseCost', value: this.tractAnalysis.purchaseCost },
          { label: 'contractStumpageCost', value: this.tractAnalysis.totalStumpageCost, keyRight: true },
          { label: 'balance', value: this.tractAnalysis.balance }
        ]
      ].slice(0, 1)
    },

    headers () {
      if (!this.columnSet) return []
      return this.columnSet.getColumns()
    },

    contextMenuOptions () {
      return [
        { label: 'downloadPDF', icon: 'mdi-file-pdf-box', dataTestId: 'tract-harvest-pdf-download', action: this.includePlInPdfOpen },
        { label: 'downloadCSV', icon: 'mdi-file-delimited-outline', dataTestId: 'tract-harvest-download', action: this.downloadCSV }
      ]
    },

    mobileMenuOptions () {
      return {
        tab: this.$t('tractCostAnalysis'),
        actions: [
          {
            id: 'refresh',
            action: this.refreshTractHarvest
          },
          {
            icon: 'mdi-application-edit',
            tooltipText: this.$t('adjustColumns'),
            action: this.openColumnAdjuster,
            divider: true
          },
          {
            icon: this.isTons ? 'mdi-cube' : 'mdi-scale',
            tooltipText: this.isTons ? this.$t('viewVolume') : this.$t('viewWeight'),
            action: this.toggleVolumeView
          },
          {
            icon: this.tableView ? 'mdi-graph' : 'mdi-table',
            tooltipText: this.tableView ? this.$t('viewCharts') : this.$t('viewTable'),
            action: this.toggleTableView
          },
          ...this.contextMenuOptions.map(({ label, icon, action }) => ({
            icon,
            tooltipText: this.$t(label),
            action
          }))
        ]
      }
    },

    isTons () {
      return this.units === HarvestUnits.Tons
    }
  },

  watch: {
    shouldRefresh (val) {
      if (val) {
        this.refreshTractHarvest()
      }
    },

    units () {
      this.columnSet = this.harvestHeaders
      this.createHarvestedChart()
    }
  },

  created () {
    this.columnSet = this.harvestHeaders
    this.includePlInPdf = localStorage.getItem(LocalStorageKeys.INCLUDE_TRACT_SUMMARY_PDF) === 'true'
    this.$emit('extra-menu-options', this.mobileMenuOptions)
  },

  mounted () {
    this.refreshTractHarvest()
  },

  methods: {
    ...mapActions('tract', ['getTractHarvest', 'fetchTractLandowners']),
    ...mapActions('notes', ['fetchTractNotes']),
    tonStringFromPounds,
    formatMoney,
    numberWithCommas,
    generateCruiseAnalysisReport,
    utcToLocalDate,

    woodTypeName (e) {
      return WoodType.forInt(e).name
    },

    async refreshTractHarvest () {
      if (!this.loading) {
        this.loading = true
        this.tractAnalysis = await this.getTractHarvest({
          tractId: this.tract.tractId,
          asOfDate: this.dateFilterCleared ? null : this.asOfDate
        })
        this.loading = false
        this.setupCharts()
      }
    },

    includePlInPdfOpen () {
      this.includePlInPdfDialog = true
    },

    includePlInPdfClose () {
      this.includePlInPdfDialog = false
    },

    async downloadPDF () {
      const tractNotes = await this.fetchTractNotes(this.tract.tractId)
      const landowners = await this.fetchTractLandowners(this.tract.tractId)
      this.generateCruiseAnalysisReport({
        tractAnalysis: this.tractAnalysis,
        tract: this.tract,
        tractNotes: tractNotes,
        headers: this.headers,
        sumHeaders: this.sumHeaders,
        includeTotalsTable: this.includePlInPdf,
        landowners: landowners
      })
      localStorage.setItem(LocalStorageKeys.INCLUDE_TRACT_SUMMARY_PDF, this.includePlInPdf)
      this.includePlInPdfDialog = false
    },

    clearDateFilter () {
      this.dateFilterCleared = true
      this.refreshTractHarvest()
    },

    clearDistance () {
      this.distanceCleared = true
    },

    isNoData (data) {
      return data.length === 0 || data.every(i => i === 0)
    },

    setupCharts () {
      this.createLoadsChart()
      this.createHarvestedChart()
      this.createProdCostsChart()
      this.createRemainingCruiseValueChart()
    },

    createHarvestedChart () {
      if (this.harvestedChart) this.harvestedChart.destroy()
      const cvx = document.getElementById('harvested-chart')
      this.harvestedChart = new Chart(cvx, this.getHarvestedChartConfig())
    },

    shouldShowTotalColumn (columnId) {
      return !this.columnSet.isColumnHidden(columnId)
    },

    getChartTitle (text) {
      return {
        font: {
          family: 'Roboto, sans-serif',
          size: 16
        },
        display: true,
        text: text
      }
    },

    getChartSubtitle (isDisplayed) {
      return {
        display: isDisplayed,
        text: this.$t('noDataAvailableForChart'),
        color: 'black',
        font: {
          size: 16,
          family: 'Roboto, sans-serif',
          weight: 400
        },
        padding: {
          top: 20
        }
      }
    },

    createLoadsChart () {
      if (this.loadsChart) this.loadsChart.destroy()
      const data = this.tractHarvest.map(th => th.loads)
      const labels = !this.isNoData(data) ? this.tractHarvest.map(th => th.product) : []
      const dataSet = {
        labels: labels,
        datasets: [{
          data: data,
          hoverOffset: 4,
          backgroundColor: getLogChartColors(labels.length)
        }]
      }

      const config = {
        type: 'pie',
        data: dataSet,
        options: {
          plugins: {
            title: this.getChartTitle(this.$t('loadsByProduct')),
            subtitle: this.getChartSubtitle(this.isNoData(data))
          }
        }
      }

      const cvx = document.getElementById('loads-chart')
      this.loadsChart = new Chart(cvx, config)
    },

    getCutoutPercentage ({ harvestedTons, expectedTons }) {
      if (expectedTons === 0) return this.$t('notAvailable')

      const cutoutPercentage = (harvestedTons / expectedTons) * 100

      if (cutoutPercentage > 100) return '100.00%'

      return `${cutoutPercentage.toFixed(2)}%`
    },

    createProdCostsChart () {
      if (this.productionCostsChart) this.productionCostsChart.destroy()
      const data = this.tractHarvest.map(th => th.totalProductionCost)
      const labels = !this.isNoData(data) ? this.tractHarvest.map(th => th.product) : []
      const dataSet = {
        labels: labels,
        datasets: [{
          data: data,
          hoverOffset: 4,
          backgroundColor: getLogChartColors(labels.length)
        }]
      }

      const config = {
        type: 'pie',
        data: dataSet,
        options: {
          plugins: {
            title: this.getChartTitle(this.$t('productionCostByProduct')),
            subtitle: this.getChartSubtitle(this.isNoData(data))
          }
        }
      }

      const cvx = document.getElementById('prod-chart')

      this.productionCostsChart = new Chart(cvx, config)
    },

    createRemainingCruiseValueChart () {
      if (this.cruiseValueChart) this.cruiseValueChart.destroy()
      const data = this.tractHarvest.map(th => th.remainingCruiseValue)
      const labels = !this.isNoData(data) ? this.tractHarvest.map(th => th.product) : []
      const dataSet = {
        labels: labels,
        datasets: [{
          data: data,
          hoverOffset: 4,
          backgroundColor: getLogChartColors(labels.length)
        }]
      }

      const config = {
        type: 'pie',
        data: dataSet,
        options: {
          plugins: {
            title: this.getChartTitle(this.$t('remainingCruiseValueByProduct')),
            subtitle: this.getChartSubtitle(this.isNoData(data))
          }
        }
      }

      const cvx = document.getElementById('stumpage-chart')
      this.cruiseValueChart = new Chart(cvx, config)
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    getHarvestedData (tractHarvest) {
      if (!tractHarvest) {
        return
      }

      const labels = tractHarvest.map(cd => cd.product)
      const harvestedData = tractHarvest.map(cd => this.isTons ? this.poundsToTons(cd.harvestedLBS) : cd.harvestedMBF)
      const expectedData = tractHarvest.map(cd => this.isTons ? this.poundsToTons(cd.expectedLBS) : cd.expectedMBF)

      const dataSets = [
        {
          label: this.isTons ? this.$t('harvestedTons') : this.$t('harvestedMBF'),
          backgroundColor: logChartColorFor(0, 2),
          data: harvestedData
        },
        {
          label: this.isTons ? this.$t('expectedTons') : this.$t('expectedMBF'),
          backgroundColor: logChartColorFor(1, 2),
          data: expectedData
        }
      ]

      return {
        labels: labels,
        datasets: dataSets
      }
    },

    getHarvestedChartConfig () {
      const noData = this.getHarvestedData(this.tractHarvest).datasets?.every(ds => this.isNoData(ds.data))
      return {
        type: 'bar',
        data: noData ? [] : this.getHarvestedData(this.tractHarvest),
        options: {
          barValueSpacing: 20,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              font: {
                family: 'Roboto, sans-serif',
                size: 16
              },
              display: true,
              text: this.isTons ? this.$t('harvestedVersusExpectedTons') : this.$t('harvestedVersusExpectedMBF')
            },
            subtitle: this.getChartSubtitle(noData)
          },
          labels: {
            defaultFontSize: 16
          }
        }
      }
    },

    poundsToTons (pounds) {
      const tons = (pounds / 2000).toFixed(3)
      return parseFloat(tons)
    },

    generateCsvString,

    downloadCSV () {
      const csvString = this.generateCsvString(this.tractHarvest, [
        { label: 'woodType', value: th => WoodType.forInt(th.woodType).name },
        ...this.columnSet.columns.filter(c => !!c.text).map(c => ({
          text: c.text,
          value: i => i[c.value]
        }))
      ])
      const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `Cruise_Analysis_${this.tract.name.replaceAll(' ', '_')}_${moment().format('MM_DD_YY')}.csv`)
    },

    toggleTableView () {
      this.tableView = !this.tableView
      this.$emit('extra-menu-options', this.mobileMenuOptions)
    },

    toggleVolumeView () {
      switch (this.units) {
        case HarvestUnits.Tons: this.units = HarvestUnits.MBF; break
        default: this.units = HarvestUnits.Tons
      }
      this.$emit('extra-menu-options', this.mobileMenuOptions)
      localStorage.setItem(LocalStorageKeys.TRACT_CRUISE_UNITS, this.units.value)
    },

    openColumnAdjuster () {
      this.columnAdjusterOpen = true
    }
  }
}
</script>
