<template>
    <v-autocomplete
    :data-testid="this.dataTestid"
    color="black"
    item-color="primary"
    v-model="chosenLocationName"
    :label="$t('location')"
    :loading="locationsLoading"
    :items="locationNames"
    :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
  >
    <template #append-outer>
      <v-icon @click="refreshLocations" color="primary">mdi-refresh</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LocationAutocomplete',

  props: {
    locationName: { String, default: undefined },
    locationId: {
      type: Number,
      default: undefined
    },
    fetchDeckHistory: {
      type: Boolean,
      default: false
    },
    selectFirst: {
      type: Boolean,
      default: false
    },
    filterEmptyDecks: {
      type: Boolean,
      default: false
    },
    showAllLocations: {
      type: Boolean,
      default: false
    },
    dataTestid: String
  },

  watch: {
    chosenLocationName (name) {
      this.parseLocationName(name)
    }
  },

  computed: {
    ...mapGetters('locations', ['allLocations', 'locationsLoading'])
  },

  data: () => ({
    locationNames: [],
    chosenLocationName: ''
  }),

  created () {
    this.refreshLocations()
  },

  methods: {
    ...mapActions('locations', ['fetchLocations']),

    async refreshLocations () {
      await this.fetchLocations(this.fetchDeckHistory)
      this.addLocationNames()
    },

    addLocationNames () {
      this.locationNames = []
      this.allLocations.forEach((location) => {
        if ((this.filterEmptyDecks && location.decks.length > 0) || !this.filterEmptyDecks) {
          this.locationNames.push(location.name)
        }

        if (this.locationName === location.name || this.locationId === location.locationId) {
          this.chosenLocationName = location.name
        }
      })

      if (this.showAllLocations && this.locationNames.length > 1) {
        this.locationNames.unshift(this.$t('allLocations'))
      }

      if (this.locationName) {
        this.chosenLocationName = this.locationName
        return
      }

      if (this.locationNames.length === 1 || (this.selectFirst && this.locationNames.length > 0)) {
        this.chosenLocationName = this.locationNames[0]
      }
    },

    parseLocationName () {
      const location = this.allLocations.find(l => l.name === this.chosenLocationName)
      if (location) {
        this.$emit('location-chosen', location)
      } else if (this.chosenLocationName === this.$t('allLocations')) {
        this.$emit('location-chosen', this.$t('allLocations'))
      }
    }
  }
}
</script>
