<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <Selector
        v-if="payPeriodSelectorKey"
        :disabled="noPostingGroups"
        data-testid="post-payperiod-selector"
        :items="postingGroups"
        :label="$t('payPeriods')"
        @item-selected="postingGroupChosen"
        :initialItem="selectedPostingGroup"
        itemValue="period.payPeriodId"
        useCustomSlots>
          <template #selection="{item}">
            {{getPayPeriodString(item, true)}}
          </template>
          <template #item="{item}">
            <span>
              {{getPayPeriodString(item)}}
            </span>
          </template>
        </Selector>
      </v-col>
      <v-col>
        <v-text-field
        v-model="search"
        data-testid="post-search"
        single-line
        color="primary"
        append-icon="mdi-magnify"
        clearable
        class="mr-6"
        :label="$t('search')"
        style="min-width:120px;"/>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" v-if="noPostingGroups">
      <v-col cols="12">
        <NoData>{{$t('noTicketAvailableForPosting')}}</NoData>
      </v-col>
    </v-row>
    <v-row v-if="selectedPostingGroupFiltered !== undefined">
      <v-col cols="12">
        <v-data-table
        v-if="!noPostingGroups"
        v-model="selected"
        ref="postDataTable"
        :items="selectedPostingGroupFiltered?.tickets ?? []"
        :search="search"
        :items-per-page="25"
        dense
        :headers="headers"
        :footer-props="{ 'items-per-page-options': [5, 25, 50, 100, -1] }"
        item-key="ticketId"
        show-select
        >
          <template #item.ticketNumber="{item}">
            {{ item.ticketNumber }}
            <Icon
            v-if="selectedPostingGroupFiltered.tickets.length >= 5 && checkTicketForWarning(item)"
            icon="mdi-alert"
            icon-color="warning"
            :tooltipText="getWarningTooltip(item)"
            />
          </template>
          <template #item.netWeight="{item}">
            <span class="mr-2">{{ `${tonStringFromPounds(item.inWeight - item.outWeight - item.defectWeight)}` }}</span>
          </template>
          <template #item.weighedOutAt="{ item }">{{
            utcToLocalDate(item.weighedOutAt, 'L - LT')
          }}</template>
          <template #item.pieces="{item}">
            <span class="mr-2">{{item.pieces === 0 ? $t('notAvailable') : item.pieces}}</span>
          </template>
          <template #item.product="{ item }">{{
            item.product
          }}</template>
          <template #item.actions="{ item }">
            <Icon
              icon="mdi-pencil"
              iconColor="success"
              :tooltipText="$t('edit')"
              @icon-clicked="editTicket(item)"
              small
              :disabled="!userAssignedClaim(UserClaims.TicketManager)"
            />
          </template>
          <template #item.tract="{item}">
            {{ item.tract ?? $t('notAvailable') }}
          </template>
          <template #item.fromAccount="{item}">
            {{ item.fromAccount ?? $t('notAvailable') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" max-width="1800px">
      <TicketForm
        :propTicket="focusedTicket"
        :contractMode="contractMode"
        @ticket-changed="ticketUpdated"
        @hook:mounted="emitPersist(true)"
        @hook:beforeDestroy="emitPersist(false)"
      />
    </Dialog>
  </v-container>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapGetters, mapActions } from 'vuex'
import TicketHeaders from '@/headers/Ticket'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
import { ContractMode, UserClaims } from '../../../utils/Enumerations'
import { uniqueDialogId } from '../../../utils/componentHelpers'
export default {
  name: 'PostTickets',

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    TicketForm: () => import('@/components/ticket/TicketForm.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    contractMode: {
      type: Object
    },
    postingGroupFilter: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    dialogId: uniqueDialogId('post-tickets'),
    selectedPostingGroup: undefined,
    selectedPostingGroupFiltered: {
      period: undefined,
      tickets: undefined
    },
    search: '',
    selected: [],
    ticketMedians: {
      inboundWeight: 0,
      outboundWeight: 0
    },
    focusedTicket: undefined,
    payPeriodSelectorKey: true,
    UserClaims
  }),

  computed: {
    ...mapGetters('posting', ['postingGroups']),

    headers () {
      return this.isLogsMode
        ? TicketHeaders.ticketPostingHeaders()
        : TicketHeaders.ticketByproductPostingHeaders()
    },

    isLogsMode () {
      return this.contractMode.value === ContractMode.Logs.value
    },

    noPostingGroups () {
      return !(this.postingGroups.length > 0)
    },

    postingGroupFiltered () {
      return this.selectedPostingGroup.tickets
        .filter((t) => Object.keys(this.postingGroupFilter)
          .filter(k => this.postingGroupFilter[k] !== undefined)
          .every(k => t[k] === this.postingGroupFilter[k])) ??
        []
    },

    usePostingGroupFilter () {
      return Object.keys(this.postingGroupFilter).some(k => this.postingGroupFilter[k])
    }
  },

  watch: {
    postingGroups: {
      handler (newGroups) {
        if (this.selectedPostingGroup) {
          const selectedGroupInNewCollection = newGroups.find(g => g.period.payPeriodId === this.selectedPostingGroup.period.payPeriodId)
          this.postingGroupChosen(selectedGroupInNewCollection)
        }
      },
      deep: true
    },

    selectedPostingGroup (group) {
      if (group === undefined) {
        this.postingGroupChosen(this.postingGroups?.[0])

        this.payPeriodSelectorKey = false
        this.$nextTick(() => { this.payPeriodSelectorKey = true })
      }
    },

    selected: {
      handler (val) {
        this.$emit('tickets-selected', val)
      },
      deep: true
    },

    postingGroupFilter: {
      handler (_) {
        this.filterPostingGroup()
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    tonStringFromPounds,
    userAssignedClaim,

    postingGroupChosen (postingGroup) {
      this.selected = []
      this.selectedPostingGroup = postingGroup
      this.search = ''
      this.$emit('posting-group-selected', postingGroup)
      this.calculateTicketMedians()
      this.filterPostingGroup()
    },

    emitPersist (persist) {
      this.$emit('persist', persist)
    },

    getPayPeriodString (postingGroup, selected) {
      const { startDate, endDate } = postingGroup.period
      const convertedEndDate = new Date(endDate)
      convertedEndDate.setDate(convertedEndDate.getDate() - 1)
      const filteredPostingGroupLength = this.$refs.postDataTable && selected ? this.$refs.postDataTable.$children[0].filteredItems.length : postingGroup.tickets.length
      return `${utcToLocalDate(startDate)} - ${utcToLocalDate(convertedEndDate)} (${filteredPostingGroupLength} Tickets)`
    },

    calculateTicketMedians () {
      if (this.selectedPostingGroup && this.selectedPostingGroup.tickets) {
        let tickets = [...this.selectedPostingGroup.tickets]
        tickets.sort((a, b) => a.outWeight - b.outWeight)
        let midpoint = Math.floor(tickets.length / 2)
        const outboundMedian = tickets.length % 2 === 1
          ? tickets[midpoint]?.outWeight
          : (tickets[midpoint - 1]?.outWeight + tickets[midpoint]?.outWeight) / 2

        tickets = [...this.selectedPostingGroup.tickets]
        tickets.sort((a, b) => a.inWeight - b.inWeight)
        midpoint = Math.floor(tickets.length / 2)
        const inboundMedian = tickets.length % 2 === 1
          ? tickets[midpoint]?.inWeight
          : (tickets[midpoint - 1]?.inWeight + tickets[midpoint]?.inWeight) / 2

        this.ticketMedians.outboundWeight = outboundMedian
        this.ticketMedians.inboundWeight = inboundMedian
      }
    },

    checkTicketForWarning (ticket) {
      const inWeightDeltaPercentage = ticket.inWeight > 0
        ? Math.abs(ticket.inWeight - this.ticketMedians.inboundWeight) / this.ticketMedians.inboundWeight
        : 0

      const outWeightDeltaPercentage = ticket.outWeight > 0
        ? Math.abs(ticket.outWeight - this.ticketMedians.outboundWeight) / this.ticketMedians.outboundWeight
        : 0

      return inWeightDeltaPercentage >= 0.25 || outWeightDeltaPercentage > 0.25
    },

    getWarningTooltip (ticket) {
      let tooltip = ''
      const inWeightDeltaPercentage = ticket.inWeight > 0
        ? Math.abs(ticket.inWeight - this.ticketMedians.inboundWeight) / this.ticketMedians.inboundWeight
        : 0

      const outWeightDeltaPercentage = ticket.outWeight > 0
        ? Math.abs(ticket.outWeight - this.ticketMedians.outboundWeight) / this.ticketMedians.outboundWeight
        : 0

      if (inWeightDeltaPercentage >= 0.25) tooltip += 'inbound'
      if (outWeightDeltaPercentage >= 0.25) tooltip === '' ? tooltip += 'outbound' : tooltip += ', outbound'

      return this.$t('ticketWeightError', { typeString: tooltip })
    },

    editTicket (ticket) {
      this.focusedTicket = ticket
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    ticketUpdated () {
      this.$emit('refresh-single-ticket', this.focusedTicket.ticketId)
      this.focusedTicket = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    filterPostingGroup () {
      if (this.selectedPostingGroup) {
        this.selectedPostingGroupFiltered.period = this.selectedPostingGroup.period
        this.selectedPostingGroupFiltered.tickets = this.usePostingGroupFilter
          ? this.postingGroupFiltered
          : this.selectedPostingGroup.tickets
      }
    }
  }
}
</script>
