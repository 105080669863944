<template>
  <div>
    <v-autocomplete
      v-model="selectedLoggers"
      :label="$t('loggers')"
      :items="accounts"
      data-testid="logger-complete"
      item-text="name"
      value="name"
      dense
      chips
      color="black"
      item-color="primary"
      return-object
      multiple
    >
      <template #append>
        <v-chip v-for="tag in selectedTags" small :key="tag.tagId" close @click:close="tagClicked(tag)">
          {{tag.value}}
        </v-chip>
      </template>
      <template #append-outer>
        <v-icon @click="refreshAccounts" color="primary">mdi-refresh</v-icon>
        <v-menu v-model="showMenu"
        offset-y
        :close-on-content-click="false"
        style="max-width: 600px">
          <template #activator="{on}">
            <v-icon color="primary" v-on="on">mdi-filter</v-icon>
          </template>
          <v-list>
            <v-list-item @click="tagClicked(tag)" v-for="tag in accountTags" :key="tag.tagId">
              <v-list-item-title>
                {{tag.value}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { SETTING_TYPES, SETTING_KEYS } from '@/utils/UserSettings.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'LoggerAutocomplete',

  props: {
    userSetting: { type: String, default: undefined },
    loggers: {
      type: Array
    },
    fetchTagsAndAccounts: { type: Boolean, default: true }
  },

  data: () => ({
    selectedLoggers: [],
    showMenu: false,
    selectedTags: [],
    tags: []
  }),

  watch: {
    selectedLoggers (val) {
      this.$emit('loggers-mutated', val)
    },

    selectedTags (_) {
      this.setUserSettings()
    }
  },

  computed: {
    ...mapGetters('user-settings', ['userSettings']),
    ...mapGetters('account', ['allAccounts', 'accountsLoading']),
    ...mapGetters('tags', ['accountTags']),

    accounts () {
      const accountsFiltered = this.selectedTags.length
        ? this.allAccounts.filter(a => a.accountTags.some(at => this.selectedTags.some(st => st.value === at)))
        : this.allAccounts
      return this.loggers
        ? [...new Set([...accountsFiltered, ...this.loggers])]
        : this.allAccounts
    }
  },

  created () {
    this.getTags()
    this.initializeAccounts()
  },

  methods: {
    ...mapActions('account', ['fetchAccounts', 'getAccountsWithTags']),
    ...mapActions('tags', ['fetchTags']),
    ...mapMutations('user-settings', ['mutateUserSetting']),

    async refreshAccounts () {
      if (!this.accountsLoading) await this.fetchAccounts()
    },

    async getTags () {
      if (this.fetchTagsAndAccounts) await this.fetchTags(0)
      this.checkUserSettings()
    },

    async initializeAccounts () {
      if (this.fetchTagsAndAccounts) await this.fetchAccounts()
    },

    tagClicked (tag) {
      const tagIndex = this.selectedTags.findIndex(t => t.tagId === tag.tagId)
      if (tagIndex === -1) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags.splice(tagIndex, 1)
      }
    },

    checkUserSettings () {
      if (this.userSetting === 'accountLoggerAutocomplete') {
        this.selectedTags = this.tagsFromIds(this.userSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.ACCOUNT_AUTOCOMPLETE])
        if (this.loggers) {
          this.selectedLoggers = this.loggers
        } else {
          this.refreshAccounts()
        }
      } else {
        this.refreshAccounts()
      }
    },

    setUserSettings () {
      this.mutateUserSetting({
        type: SETTING_TYPES.TRACT_FORM,
        key: SETTING_KEYS.ACCOUNT_AUTOCOMPLETE,
        value: this.selectedTags.map(t => t.tagId)
      })
    },

    tagsFromIds (ids) {
      return (ids ?? []).map(id => this.accountTags.find(t => t.tagId === id)).filter(t => t !== undefined)
    }
  }
}
</script>
