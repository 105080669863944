<template>
  <v-container fill-height fluid grid-list-xl>
    <v-row dense>
      <v-col>
        <material-card
          color="primary"
          :title="$t('userInfo')"
          :text="$t('infoForContactInformation')"
          full-width
        >
          <UserInfoFormDialog :hideTitle="true"/>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'UserInfo',

  components: {
    UserInfoFormDialog: () => import('@/components/settings-components/UserInfoFormDialog.vue')
  }
}
</script>
