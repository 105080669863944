<template>
  <v-card data-testid="by-product-form">
    <v-card-title style="background-color: #363636; color: white;">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      data-testid="by-product-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="productForm"
        testId="save-product"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveProduct"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="product.name"
                data-testid="by-product-name"
                ref="firstField"
                color="black"
                counter="64"
                maxlength="64"
                :rules="[rules.required]"
                :label="$t('name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                v-model="product.code"
                data-testid="by-product-code"
                color="black"
                counter="10"
                maxlength="10"
                :label="$t('code')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
              :items="woodTypes"
              :rules="[rules.required]"
              :label="$t('woodType')"
              item-color="primary"
              color="black"
              data-testid="by-product-wood-type"
              v-model="product.woodType"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
              :items="timberTypes"
              :rules="[rules.required]"
              item-color="primary"
              color="black"
              :label="$t('timberType')"
              data-testid="by-product-timber-type"
              v-model="product.timberType"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions } from 'vuex'
import { WoodType, TimberType } from '@/utils/Enumerations.js'
export default {
  name: 'ByProductForm',

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    isEditing: Boolean,
    propProduct: Object
  },

  data: () => ({
    rules: fieldRules.rules,
    product: {
      productId: -1,
      code: '',
      name: '',
      timberType: '',
      woodType: '',
      conversionRateW2V: 1.0,
      collectPieceInfo: false,
      isByProduct: true
    }
  }),

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  created () {
    if (this.isEditing) {
      this.product = JSON.parse(JSON.stringify(this.propProduct))
      this.product.woodType = this.woodTypeFromInt(this.propProduct.woodType)
      this.product.timberType = this.timberTypeFromInt(this.propProduct.timberType)
    }
  },

  computed: {
    title () {
      return this.isEditing ? this.$t('updatingByProduct') : this.$t('creatingByProduct')
    },

    saveBtnText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('createNewByProduct')
    },

    timberTypes () {
      return TimberType.names
    },

    woodTypes () {
      return WoodType.names
    }
  },

  methods: {
    ...mapActions('product', ['createProduct', 'updateProduct']),
    woodTypeFromInt: (x) => WoodType.fromInt(x),
    timberTypeFromInt: (x) => TimberType.fromInt(x),

    async saveProduct () {
      if (!this.$refs.form.$refs.productForm.validate()) {
        return
      }

      const requestObj = JSON.parse(JSON.stringify(this.product))
      requestObj.woodType = WoodType.toInt(requestObj.woodType)
      requestObj.timberType = TimberType.toInt(requestObj.timberType)

      if (this.isEditing) {
        await this.updateProduct(requestObj)
        this.$emit('product-mutated')
      } else {
        await this.createProduct(requestObj)
        this.$emit('product-mutated')
      }
    }
  }
}
</script>
