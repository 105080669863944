<template>
  <v-card>
    <v-card-title class="headline white--text primary">
      <span>
        {{ resourceName }}
      </span>
      <v-spacer/>
      <span class="mr-3">{{ formatMoney(total) }}</span>
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text class="mt-6">
      <DataTable
        hideHeader
        :items.sync="payables"
        :headers="headers"
        :customCells="customCells"
        :no-data-text="$t('noX', { x: resourceName })"
      >
        <template #amount="{ item }">
          <span>{{formatMoney(item.amount)}}</span>
        </template>
        <template #body.append>
          <tr class="font-weight-bold">
            <td class="text-left">{{ $t('totals') }}</td>
            <td v-for="i in blankColumns" :key="i"/>
            <td class="text-right">{{formatMoney(total)}}</td>
          </tr>
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
export default {
  name: 'SBPMiscPaymentDialog',

  props: {
    payables: {
      type: Array,
      default: () => []
    },
    resourceName: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    total () {
      return this.payables.reduce((total, curr) => total + curr.amount, 0)
    },
    customCells () {
      return [
        {
          slotName: 'amount',
          value: 'amount'
        }
      ]
    },
    blankColumns () {
      return this.headers.length - 2
    }
  },

  methods: {
    formatMoney,
    close () {
      this.dialog = false
    }
  }
}
</script>
