<template>
  <v-card>
    <v-card-title class="headline white--text primary">
      <span>
        {{ titleText }}
      </span>
      <v-spacer/>
      <Icon
      icon="mdi-close"
      iconColor="white"
      dataTestId="recovery-close-button"
      :large="true"
      margin="0"
      :small="false"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"/>
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper
        :buttonText="$t('applyRecovery')"
        :disabled="applyDisabled"
        @submit="applyRecovery"
      >
        <MoneyTextField
        ref="firstField"
        data-testid="recovery-amount"
        :initialValue="recovery.amount"
        @val-changed="recoveryEntered"/>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'RecoveryForm',

  props: {
    advanceId: Number,
    propRecovery: { Object, default: undefined }
  },

  components: {
    MoneyTextField: () => import('../../helper/MoneyTextField.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    recoveryAmount: 0,
    recovery: {
      amount: 0
    }
  }),

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.$children[0].focus()
    }, 0)
  },

  created () {
    if (this.propRecovery) {
      this.recovery = JSON.parse(JSON.stringify(this.propRecovery))
    }
  },

  computed: {
    titleText () {
      return this.propRecovery ? this.$t('editRecovery') : this.$t('newRecovery')
    },

    applyDisabled () {
      return this.recoveryAmount === 0
    }
  },

  methods: {
    ...mapActions('advances', ['postRecovery']),
    ...mapActions('recovery', ['updateRecovery']),
    recoveryEntered (amount) {
      this.recoveryAmount = amount
    },

    async applyRecovery () {
      const recoveryRequestObject = {
        recovery: {
          amount: this.recoveryAmount
        },
        advanceId: this.advanceId
      }

      if (this.propRecovery) {
        recoveryRequestObject.recovery.recoveryId = this.propRecovery.recoveryId
        await this.updateRecovery(recoveryRequestObject)
        this.$emit('recovery-mutated')
      } else {
        await this.postRecovery(recoveryRequestObject)
        this.$emit('recovery-mutated')
      }
    }
  }
}
</script>
