<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
      testId="deck-form-save-btn"
      :buttonText="$t('reset')"
      :disabled="saveButtonDisabled"
      @submit="saveChanges">
        <v-container>
          <v-row dense>
            <span class="font-italic">
              {{$t('deckResetInformation')}}
            </span>
          </v-row>
          <v-row dense>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
              ref="firstField"
              color="black"
              data-testid="deck-tons"
              type="number"
              :label="$t('onDeckTons')"
              :hint="$t('onDeckPreviousTonnage', { onDeck: tonStringFromPounds(propDeck.onDeck) })"
              persistentHint
              :rules="[rules.positiveDecimal, rules.validOnDeckTons]"
              v-model="onDeckTons"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <MoneyTextField
                data-testid="deck-value"
                :initialValue="onDeckValue"
                :label="$t('value')"
                :hint="$t('onDeckPreviousValue', { value: formatMoney(propDeck.value) })"
                @val-changed="deckValueEntered"
              />
            </v-col>
          </v-row>
          <v-row dense class="mt-6">
            <v-col cols="12">
              <v-tabs>
                <v-tab>
                  {{$t('pastResets')}}
                </v-tab>
                <v-tab-item disabled>
                  <v-data-table
                    :items="deckAdjustments"
                    :headers="headers"
                    :hide-default-footer="deckAdjustments.length <= 10"
                    class="mt-4"
                    sort-by="createdAt"
                    sort-desc
                    dense
                  >
                    <template #item.createdAt="{item}">
                      <span>{{utcToLocalDate(item.createdAt, 'L - LT')}}</span>
                    </template>
                    <template #item.valueAt="{item}">
                      <span>{{formatMoney(item.valueAt)}}</span>
                    </template>
                    <template #item.adjustmentTons="{ item }">
                      <span>
                        {{ getTonStringForAdjustment(item) }}
                      </span>
                    </template>
                    <template #item.adjustmentValue="{ item }">
                      <span>
                        {{ getValueStringForAdjustment(item) }}
                      </span>
                    </template>
                    <template #item.averageValuePerTonAt="{item}">
                      <span>{{formatMoney(item.averageValuePerTonAt)}}</span>
                    </template>
                    <template #item.onDeckAt="{item}">
                      <span>{{tonStringFromPounds(item.onDeckAt)}}</span>
                    </template>
                    <template #item.onDeck="{item}">
                      <span>{{tonStringFromPounds(item.onDeck)}}</span>
                    </template>
                    <template #item.value="{item}">
                      <span>{{formatMoney(item.value)}}</span>
                    </template>
                    <template #item.actions="{item}">
                      <Icon
                      icon="mdi-delete"
                      iconColor="error"
                      dataTestId="deck-asdjustment-delete-button"
                      :disabled="item.deckAdjustmentId !== deckAdjustments[deckAdjustments.length - 1].deckAdjustmentId"
                      :tooltipText="$t('delete')"
                      @icon-clicked="confirmDeleteAdjustment(item.deckAdjustmentId)"/>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
      <Dialog :stateId="dialogId" max-width="400px">
        <ConfirmDialog
        :title="$t('deleteDeckAdjustment')"
        :body="$t('deleteDeckAdjustmentBody')"
        @confirm="removeDeckAdjustment"
        @cancel="closeDialog"/>
      </Dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import { deckAdjustmentHeaders } from '@/headers/Deck.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { mapActions } from 'vuex'
export default {
  name: 'DeckResetForm',

  props: {
    propDeck: {
      type: Object,
      required: true
    },
    location: {
      type: Object,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    MoneyTextField: () => import('../../helper/MoneyTextField.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    dialogId: 'delete-adjustment-dialog',
    rules: fieldRules.rules,
    deckAdjustments: [],
    adjustmentsLoading: false,
    focusedDeckAdjustmentId: null,
    deck: null,
    onDeckTons: 0,
    onDeckValue: 0,
    locationId: null
  }),

  computed: {
    title () {
      return this.$t('resettingDeck', { deckName: this.propDeck.name })
    },

    headers () {
      return deckAdjustmentHeaders(this.$i18n.locale)
    },

    saveButtonDisabled () {
      return Number(this.onDeckTons) === Number((this.propDeck.onDeck / 2000).toFixed(3)) &&
        Number(this.onDeckValue) === Number(this.propDeck.value)
    }
  },

  created () {
    this.deck = { ...this.propDeck }
    this.onDeckTons = (this.propDeck.onDeck / 2000).toFixed(3)
    this.onDeckValue = this.propDeck.value
    this.locationId = this.location.locationId
    this.refreshAdjustments(this.deck)
  },

  methods: {
    ...mapActions('decks', ['createDeckAdjustment', 'fetchDeckAdjustments', 'deleteDeckAdjustment']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    utcToLocalDate,
    formatMoney,
    tonStringFromPounds,
    deckValueEntered (value) { this.onDeckValue = value },

    refreshAdjustments (deck) {
      this.adjustmentsLoading = true

      this.fetchDeckAdjustments({ locationId: this.locationId, deckId: deck.deckId })
        .then(adjustments => {
          this.deckAdjustments = adjustments.map(a => ({
            ...a,
            adjustmentTons: -1 * (a.onDeckAt - a.onDeck),
            adjustmentValue: -1 * (a.valueAt - a.value)
          }))
        })
        .finally(() => {
          this.adjustmentsLoading = false
        })
    },

    closeDialog () {
      this.focusedDeckAdjustmentId = false
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    async saveChanges () {
      const adjustmentRequest = {
        deckId: this.deck.deckId,
        locationId: this.locationId,
        deckAdjustment: {
          onDeck: Number(Math.round(this.onDeckTons * 2000)),
          value: Number((this.onDeckValue))
        }
      }

      await this.createDeckAdjustment(adjustmentRequest)

      this.refreshAdjustments(this.deck)
      this.$emit('deck-mutated')
    },

    getTonStringForAdjustment ({ adjustmentTons }) {
      if (adjustmentTons === 0) return '-'
      return adjustmentTons < 0 ? `(${tonStringFromPounds(Math.abs(adjustmentTons))})` : tonStringFromPounds(adjustmentTons)
    },

    getValueStringForAdjustment ({ adjustmentValue }) {
      if (adjustmentValue === 0) return '-'
      return adjustmentValue < 0 ? `(${formatMoney(Math.abs(adjustmentValue))})` : formatMoney(adjustmentValue)
    },

    closeForm () {
      this.$emit('close')
    },

    confirmDeleteAdjustment (deckAdjustmentId) {
      this.focusedDeckAdjustmentId = deckAdjustmentId
      this.openOrUpdateDialog({ id: this.dialogId, width: '900' })
    },

    async removeDeckAdjustment () {
      await this.deleteDeckAdjustment({
        locationId: this.locationId,
        deckId: this.deck.deckId,
        deckAdjustmentId: this.focusedDeckAdjustmentId
      })
      this.closeDialog()
      this.$emit('deck-mutated')
    }
  }
}
</script>
