import ApiServer from '@/utils/api/ApiServer.js'
import Ticket from '@/model/Ticket.js'
import moment from 'moment'
import { ContractMode } from '../../../utils/Enumerations'
import store from '@/store'
import i18n from '../../../i18n'

const DASHBOARD_MAX_TICKET_FETCH = 100

function formatTicket (ticket, isUpdate) {
  const productId = ticket.productId ?? ticket.productID
  const originationTicketId = ticket.originationTicketId ?? ticket.originationTicketID
  const postableTicket = {
    originationTicketId: (originationTicketId).toString(),
    productId: productId ? parseInt(productId) : undefined,
    contractId: parseInt(ticket.contractId),
    trailerIdentifier: (ticket.trailerIdentifier).toString(),
    inWeight: parseInt(ticket.inWeight),
    outWeight: parseInt(ticket.outWeight),
    defectWeight: parseInt(ticket.defectWeight),
    departureWeight: parseInt(ticket.departureWeight),
    pieces: parseInt(ticket.pieces),
    latitude: parseFloat(ticket.latitude),
    longitude: parseFloat(ticket.longitude),
    extTicketNumber1: ticket.extTicketNumber1,
    extTicketNumber2: ticket.extTicketNumber2,
    seal: ticket.seal,
    loadCreatedAt: ticket.loadCreatedAt,
    loggerAccountId: ticket.loggerAccountId,
    weighedInAt: ticket.weighedInAt,
    weighedOutAt: ticket.weighedOutAt,
    departedAt: ticket.departedAt,
    averageLength: parseFloat((parseFloat(ticket.averageLength)).toFixed(2)),
    rolloutStatus: parseInt(ticket.rolloutStatus),
    driverAccountId: ticket.driverAccountId,
    deckId: ticket.deckId,
    fromDeckId: ticket.fromDeckId
  }

  if (isUpdate) {
    postableTicket.modifiedAt = ticket.modifiedAt
    postableTicket.ticketId = ticket.ticketId
    postableTicket.loadCreatedAt = ticket.loadCreatedAt
    postableTicket.receivedAt = ticket.receivedAt
    postableTicket.proximityTriggered = ticket.proximityTriggered
  }

  return postableTicket
}

export default {
  applyFilter (context, filterType) {
    context.commit('applyFilter', filterType)
  },

  removeFilter (context, filterName) {
    const filter = {
      name: filterName
    }
    context.commit('removeFilter', filter)
  },

  async fetchTicketById (_, ticketId) {
    const path = `v2/tickets/${ticketId}`
    const response = await ApiServer.get(path)
    return new Ticket(response.data)
  },

  async fetchTickets (context, filter) {
    try {
      context.commit('setLoading', true)
      let path = 'v2/tickets'
      const headers = {}

      if (filter) {
        path = `${path}${filter.buildQueryString()}`
      }

      if (filter.ticketNumbers) {
        headers.ticketNumbers = JSON.stringify(filter.ticketNumbers).replaceAll('[', '').replaceAll(']', '').replaceAll('"', '')
      }

      if (filter.ticketIds) {
        if (filter.ticketIds.length >= DASHBOARD_MAX_TICKET_FETCH) {
          store.dispatch('snackbar/setSnack', i18n.t('ticketResultsLimitedTo100'))
        }
        headers.ticketIds = JSON.stringify(
          filter.ticketIds.sort((a, b) => b - a).slice(0, DASHBOARD_MAX_TICKET_FETCH) // Only use newest ticket IDs up to maximum allowed number
        ).replaceAll('[', '').replaceAll(']', '').replaceAll('"', '')
      }

      const response = await ApiServer.get(path, { headers: headers })
      const tickets = response.data.map(t => new Ticket(t))
      context.commit('setTickets', tickets)

      context.commit('setLoading', false)
      return tickets
    } finally {
      context.commit('setLoading', false)
    }
  },

  setContractMode (context, contractMode) {
    context.commit('setContractMode', contractMode)
  },

  async fetchTicketsForConsumption (_) {
    const path = 'v2/tickets?IncludePosted=true&IncludeExported=true&limitToInternal=true&ExcludeConsumed=true'
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchTicketsForReconciliation (_, { isByproduct = false }) {
    const url = ApiServer.urlFor('tickets', {
      version: 2,
      params: {
        includeInTransit: true,
        hasImages: true,
        production: !isByproduct,
        woodsSale: !isByproduct,
        byProduct: isByproduct
      }
    })
    const response = await ApiServer.get(url)
    return response.data.map(t => new Ticket(t))
  },

  async getTicket (_, ticketId) {
    const path = `v2/tickets/${ticketId}`
    const response = await ApiServer.get(path)
    return response.data
  },

  async getPostedTickets (context) {
    try {
      context.commit('setLoading', true)
      const path = 'tickets?IncludePosted=true'
      const response = await ApiServer.get(path)
      context.commit('setPostedTickets', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },

  async getTicketPayables (_, ticketId) {
    const path = `tickets/${ticketId}/payables`
    const response = await ApiServer.get(path)
    return response.data
  },

  async deleteTicket (context, ticketId) {
    const response = await ApiServer.delete(`tickets/${ticketId}`)
    context.commit('removeTicket', ticketId)
    return response.data
  },
  async createTicket (_, ticket) {
    const postableTicket = formatTicket(ticket, false)
    const url = ApiServer.urlFor('tickets', { version: 2 })
    const response = await ApiServer.post(url, postableTicket)
    return response.data
  },
  async updateTicket (context, ticket) {
    const postableTicket = formatTicket(ticket, true)
    const url = ApiServer.urlFor(`tickets/${ticket.ticketId}`, { version: 3 })
    const response = await ApiServer.put(url, postableTicket)
    context.commit('updateTicket', response.data)
    return response.data
  },

  async triggerTicketExportEmail (_, requestObject) {
    const path = 'exports/ticketExport'
    const response = await ApiServer.post(path, requestObject)
    return response.data
  },

  async getCorrectionsForTicket (_, ticketId) {
    const path = `v2/tickets/${ticketId}/corrections`
    const response = await ApiServer.get(path)
    return response.data
  },

  async fetchCorrectionById (_, { ticketId, correctionId }) {
    const { data: correction } = await ApiServer.get(`v2/tickets/${ticketId}/corrections/${correctionId}`)
    return correction
  },

  async getCorrectionsForPeriod (_, period) {
    const { payPeriodId, fiscalYearId } = period
    const path = `v2/fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/corrections`
    const response = await ApiServer.get(path)
    return response.data
  },

  async postTicketCorrection (_, ticketRequestObj) {
    const path = `v2/tickets/${ticketRequestObj.ticketId}/corrections`
    const response = await ApiServer.post(path, ticketRequestObj)
    return response.data
  },

  async deleteTicketCorrection (_, ticketRequestObj) {
    const path = `v2/tickets/${ticketRequestObj.ticketId}/corrections/${ticketRequestObj.ticketCorrectionId}`
    const response = await ApiServer.delete(path)
    return response.data
  },

  async getTicketsWithNumbers (_, { ticketNumbers, contractMode, destinationAccountId, includeImmutableTickets = true }) {
    ticketNumbers = Array.isArray(ticketNumbers) ? ticketNumbers : [ticketNumbers]
    const headers = {
      ticketNumbers: ticketNumbers.join(', ')
    }
    const url = ApiServer.urlFor('tickets', {
      version: 2,
      params: {
        includeInTransit: true,
        includeWeighedIn: true,
        includeWeighedOut: true,
        includeReadyForPayment: true,
        includePosted: includeImmutableTickets,
        includeExported: includeImmutableTickets,
        byProduct: contractMode.value === ContractMode.Byproducts.value,
        transfer: contractMode.value === ContractMode.Transfers.value,
        logYardSale: contractMode.value === ContractMode.LogYardSale.value,
        destinationAccountId
      }
    })
    const response = await ApiServer.get(url, {
      headers: headers
    })
    return response.data
  },

  async getTicketsWithExternalNumber (_, {
    extTicketNumber1,
    extTicketNumber2,
    destinationAccountId,
    isByproduct,
    lowerbound = moment.utc().subtract(90, 'days').add(1, 'hour').toISOString()
  }) {
    const url = ApiServer.urlFor('tickets', {
      version: 2,
      params: {
        includePosted: true,
        includeInTransit: true,
        includeReadyForPayment: true,
        includeWeighedIn: true,
        includeWeighedOut: true,
        includeExported: true,

        byproduct: isByproduct,

        extTicketNumber1,
        extTicketNumber2,
        destinationAccountId,
        lowerbound
      }
    })

    return (await ApiServer.get(url)).data
  },

  async fetchTicketLogCounts (_, ticketId) {
    const path = `v2/tickets/${ticketId}/logcounts`
    const response = await ApiServer.get(path)
    return response.data
  },

  async createTicketLogCounts (_, { ticketId, logCounts }) {
    const url = ApiServer.urlFor(`tickets/${ticketId}/logcounts`, { version: 2 })
    const response = await ApiServer.post(url, { logCounts })
    return response.data
  }
}
