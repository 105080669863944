<template>
  <v-container fluid>
    <Dialog
      :stateId="dialogId"
      :enforcePersistence="requireEditApproval"
      maxWidth="80vw"
      @dialog-closing="resetDialogs"
    >
      <ContractEditor
        v-if="editing"
        :shouldAddActivity="shouldAddActivity"
        :contract="focusedContract"
        @contract-changed="save"
        @close="resetDialogs"
        :contractId="focusedContract.contractId"
        :contractMode="logContractMode"
        :companyRequiresEditApproval="companyInfo.requireApprovalForContractModifications"
        :currentDraftMode="focusedContract.isDraft"
      />
      <ContractDetail
        @edit-activities="editActivities"
        @edit-contract="editContract"
        v-if="contractDetail"
        :contractId="focusedContract.contractId"
        :hasPendingTickets="focusedContract ? focusedContract.hasPendingTickets : false"
        @review="refreshContracts"
      />
    </Dialog>
    <v-dialog v-model="deleting" width="400">
      <ConfirmDelete
        v-if="deleting"
        title="Contract"
        @delete="deleteItem"
        @cancel-delete="resetDialogs"/>
    </v-dialog>
    <ContractTable
    :contracts="contracts"
    :isTractDetail="true"
    :isByProducts="false"
    :loading.sync="loading"
    @refresh-contracts="refreshContracts"
    @contract-action="parseTableAction"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ContractMode } from '../../../utils/Enumerations'
export default {
  name: 'TractContracts',

  props: {
    tractId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    dialogId: 'tract-contracts',
    contracts: [],
    loading: false,
    deleting: false,
    shouldAddActivity: false,
    contractDetail: false,
    editing: false,
    focusedContract: {}
  }),

  components: {
    Dialog: () => import('@/components/Dialog.vue'),
    ContractTable: () => import('@/components/contract/ContractTable.vue'),
    ContractDetail: () => import('@/components/contract/contract-detail/ContractDetail.vue'),
    ContractEditor: () => import('@/components/contract/ContractEditor.vue'),
    ConfirmDelete: () => import('@/components/helper/ConfirmDelete.vue')
  },

  computed: {
    ...mapGetters('dialog', ['topDialogId']),
    ...mapGetters('user', ['companyInfo']),

    logContractMode () {
      return ContractMode.Logs
    },

    requireEditApproval () {
      return this.companyInfo.requireApprovalForContractModifications && !this.focusedContract.isDraft && this.editing
    }
  },

  created () {
    this.refreshContracts()
  },

  methods: {
    ...mapActions('tract', ['fetchContractsForTract']),
    ...mapActions('contract', ['deleteContract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),

    async refreshContracts () {
      this.loading = true
      const contracts = await this.fetchContractsForTract(this.tractId)
      this.contracts = contracts
      this.loading = false
    },

    parseTableAction (action) {
      switch (action.actionType) {
        case 'edit-contract':
          this.editContract(action.contract)
          break
        case 'contract-detail':
          this.viewDetails(action.contract)
          break
        case 'delete-contract':
          this.viewConfirmDelete(action.contract)
      }
    },

    viewDetails (contract) {
      this.closeSubdialogs()
      this.focusedContract = contract
      this.contractDetail = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
    },

    viewConfirmDelete (contract) {
      this.focusedContract = contract
      this.deleting = true
    },

    editContract (contract) {
      this.closeSubdialogs()
      this.focusedContract = contract
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
      this.editing = true
    },

    editActivities (contract) {
      this.closeSubdialogs()
      this.focusedContract = Object.assign({}, contract)
      this.shouldAddActivity = true
      this.editing = true
    },

    async deleteItem () {
      await this.deleteContract(this.focusedContract.contractId)
      this.resetDialogs()
      await this.refreshContracts()
    },

    closeSubdialogs () {
      this.editing = false
      this.deleting = false
      this.contractDetail = false
    },

    resetDialogs () {
      this.closeSubdialogs()
      this.focusedContract = {}
      this.closeDialogsAtOrAbove('tract-contracts')
    },

    async save (createdContract) {
      await this.refreshContracts()
      this.resetDialogs()
      await this.refreshContracts()
    }
  }
}
</script>
