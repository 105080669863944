<template>
  <v-card data-testid="location-form">
    <v-card-title class="primary">
      <span class="headline white--text">
        {{title}}
      </span>
      <v-spacer/>
      <BaseDialogActions hideRefresh/>
    </v-card-title>
    <v-card-text class="mt-3">
      <FormWrapper
        testId="save-location"
        @submit="saveChanges"
      >
        <v-container fluid grid-list-md>
          <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4">
                <v-text-field
                v-model="location.name"
                ref="name"
                maxLength="32"
                counter="32"
                autocomplete="chrome-off"
                data-testid="name"
                color="black"
                :label="$t('name')"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4">
                <v-select
                v-model="selectedTimezone"
                item-text="name"
                item-value="name"
                data-testid="location-timezone"
                return-object
                item-color="primary"
                color="black"
                :items="timezones"
                label="Company Timezone"
                />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="4">
                <v-select
                v-model="location.consumptionMode"
                :items="consumptionModes"
                :label="$t('consumptionMode')">
                </v-select>
              </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <span class="subtitle-1">
                {{$t('logyardConfiguration')}}
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto">
              <v-select
                :label="$t('defectCollectionMode')"
                :items="defectTypes"
                v-model="selectedDefectType"
                color="primary"
                style="width: 200px;"
              />
            </v-col>
            <v-col cols="auto" v-if="showSegmentedConfiguration">
              <v-text-field
              type="number"
              hide-spin-buttons
              :label="$t('segmentLength')"
              v-model="location.segmentLength"
              :rules="[rules.decimalTwoPlacesBetweenOrBlank(4, 24, 'validRangeForSegmentLength'), lessThanLogLengthRule]"
              clearable
              />
            </v-col>
            <v-col cols="auto" class="pr-8">
              <v-switch
              v-model="location.collectLogCounts"
              :label="$t('collectLogCounts')"
              />
            </v-col>
            <v-col cols="auto" class="pr-8">
              <v-switch
              v-model="location.ntepCompliant"
              :label="$t('ntepCompliant')"/>
            </v-col>
            <v-col cols="auto" class="pr-8">
              <v-switch
              v-model="transporterByproductTickets"
              :label="$t('transporterByproductTicketCreation')"/>
            </v-col>
            <v-col cols="auto" v-if="transporterByproductTickets">
              <v-switch
              v-model="location.allowTransporterDepartureWeightEntry"
              :label="$t('transporterDepartureWeightEntry')"
              />
            </v-col>
            <v-col cols="12" v-if="location.collectLogCounts">
              <v-row>
                <v-col cols="auto">
                  <span class="subtitle-1">
                    {{ $t('logLengths') }}
                  </span>
                  <v-menu
                  v-model="addLogLengthOpen"
                  :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                      v-on="on"
                      v-bind="attrs"
                      medium
                      color="primary"
                      class="mr-2"
                      :disabled="location.logLengths.length >= 6">
                      mdi-plus
                      </v-icon>
                    </template>
                    <v-card width="200px">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                            type="number"
                            hide-spin-buttons
                            :label="$t('logLength')"
                            v-model="newLogLength"
                            @keydown.enter.prevent="addLogLength()"
                            :rules="[rules.decimalTwoPlacesBetweenOrBlank(8, 64, 'validRangeForLogLength'), greaterThanSegmentLengthRule]"
                            ref="logLengthField">
                              <template #append>
                                <Icon
                                :tooltipText="$t('addLogLength')"
                                :small="false"
                                icon="mdi-check-circle"
                                icon-color="success"
                                @icon-clicked="addLogLength()"
                                :disabled="!currentNewLogLengthIsValid"/>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-chip v-for="(l, index) in sortedLogLengths"
                :key="index"
                color="black"
                class="white--text ml-2 mb-6"
                close @click:close="removeLength(l)">
                  <span>{{ `${l.length.toFixed(2)}'` }}</span>
                </v-chip>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <LocationContacts
              :mainContact="location.mainContact"
              :ticketContact="location.ticketContact"
              @deleteTicketContact="location.ticketContact = { firstName: '', lastName: '', phoneNumber: '', email: '' }"
              @mainContactSet="location.mainContact = $event"
              @ticketContactSet="location.ticketContact = $event"/>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn v-if="propLocation === undefined" data-testid="location-autofill-button" class="primary mt-n2" @click="autofillForm">
                {{$t('autofillFromCompanyInfo')}}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-6"></v-divider>
          <v-row>
            <v-col cols="12" xs="12" class="mt-3">
              <span class="subtitle-1">
                {{$t('address')}}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model="location.address.addressLine1"
              data-testid="address-address-line-1"
              color="black"
              maxLength="64"
              counter="64"
              :label="$t('addressLine1')"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
              v-model="location.address.addressLine2"
              data-testid="address-address-line-2"
              color="black"
              maxLength="64"
              counter="64"
              :label="$t('addressLine2')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
              v-model="location.address.city"
              data-testid="address-city"
              color="black"
              maxLength="32"
              counter="32"
              :label="$t('city')"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-autocomplete
                :items="statesWithLabels"
                item-value="value"
                item-text="value"
                v-model="location.address.state"
                :label="$t('state')"
                data-testid="address-state"
                color="black"
                item-color="primary"
                @blur="autoselectState($event)">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
              v-model="location.address.postalCode"
              data-testid="address-postal-code"
              color="black"
              maxLength="5"
              counter="5"
              :label="$t('zipCode')"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" xs="12" class="mt-3"><span class="subtitle-1">Ticket Note</span></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" class="mt-n1">
              <v-textarea
              v-model="location.ticketNote"
              label="Notes that will be printed on tickets at the weight station."
              data-testid="ticket-note"
              counter="256"
              max
              color="black"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { statesWithLabels } from '@/utils/constants.js'
import timezones from './timezones.json'
import { mask } from 'vue-the-mask'
import FormRules from '@/utils/rules.js'
import { mapActions } from 'vuex'
import { DefectCollectionType, ConsumptionMode, TransporterTicketCategory, ErrorSource } from '@/utils/Enumerations.js'
export default {
  name: 'LocationForm',

  props: {
    propLocation: {
      type: Object,
      default: undefined
    },
    companyInfo: {
      type: Object
    },
    businessEntityId: {
      type: Number,
      default: -1
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    LocationContacts: () => import('@/components/settings-components/company-config/LocationContacts.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  watch: {
    collectsLogCounts (val) {
      if (!val) {
        this.location.logLengths = []
        this.newLogLength = 0
      }
    },
    addLogLengthOpen (val) {
      if (val) {
        setTimeout(() => {
          this.$refs.logLengthField.focus()
        }, 50)
      }
    },
    transporterByproductTickets (transporterByproductTicketCreation) {
      this.location.transporterTicketCategory = transporterByproductTicketCreation ? TransporterTicketCategory.Byproducts.value : TransporterTicketCategory.None.value
      this.location.allowTransporterDepartureWeightEntry = transporterByproductTicketCreation && this.location.allowTransporterDepartureWeightEntry
    }
  },

  data: () => ({
    selectedTimezone: '',
    createdLocation: undefined,
    addDeckNowDialog: false,
    rules: FormRules.rules,
    defectTypes: DefectCollectionType.names,
    consumptionModes: ConsumptionMode.names,
    selectedDefectType: DefectCollectionType.names[0],
    phoneMask: '(###)-###-####',
    transporterByproductTickets: false,
    location: {
      name: '',
      segmentLength: '',
      defectCollectionMode: 0,
      collectLogCounts: false,
      ntepCompliant: false,
      transporterTicketCategory: TransporterTicketCategory.None.value,
      allowTransporterDepartureWeightEntry: false,
      consumptionMode: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        state: '',
        postalCode: '',
        city: ''
      },
      timeZoneOffset: '',
      mainContact: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
      },
      ticketContact: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
      },
      ticketNote: '',
      businessEntityId: -1,
      logLengths: []
    },
    timezones,
    statesWithLabels,
    specifyLogCountsDialog: false,
    addLogLengthOpen: false,
    newLogLength: 0
  }),

  directives: {
    mask
  },

  computed: {
    title () {
      if (this.propLocation) {
        return `Editing ${this.propLocation.name}`
      } else {
        return 'Creating New Location'
      }
    },

    showSegmentedConfiguration () {
      return DefectCollectionType.toInt(this.selectedDefectType) === 2 || this.location.collectLogCounts
    },

    collectsLogCounts () {
      return this.location.collectLogCounts
    },

    currentNewLogLengthIsValid () {
      const newLengthParsedFixed = Number(parseFloat(this.newLogLength).toFixed(2))
      return this.logLengthRule(this.newLogLength) &&
        this.location.logLengths.every(l => l.length !== newLengthParsedFixed) &&
        Number(this.location.segmentLength) <= newLengthParsedFixed
    },

    sortedLogLengths () {
      const logLengths = this.location.logLengths
      return logLengths.sort((a, b) => a.length - b.length)
    }
  },

  created () {
    if (this.propLocation) {
      const location = JSON.parse(JSON.stringify(this.propLocation))
      this.transporterByproductTickets = this.propLocation.transporterTicketCategory === TransporterTicketCategory.Byproducts.value
      if (location.ticketContact === null) {
        location.ticketContact = JSON.parse(JSON.stringify(this.location.ticketContact))
      }
      if (location.mainContact === null) {
        location.mainContact = JSON.parse(JSON.stringify(this.location.mainContact))
      }
      if (location.address === null) {
        location.address = JSON.parse(JSON.stringify(this.location.address))
      }
      this.location = JSON.parse(JSON.stringify(location))
      this.selectedDefectType = DefectCollectionType.fromInt(location.defectCollectionMode)
      this.location.consumptionMode = ConsumptionMode.fromInt(location.consumptionMode)
    } else {
      this.location.consumptionMode = ConsumptionMode.names[0]
    }
    this.location.businessEntityId = this.businessEntityId
    this.parseTimezone()
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.name.focus()
    }, 0)
  },

  methods: {
    ...mapActions('locations', ['createLocation', 'updateLocation']),
    logLengthRule: FormRules.rules.decimalTwoPlacesBetweenOrBlank(8, 64, false, false),
    greaterThanSegmentLengthRule (value) {
      return !value || Number(this.location.segmentLength) <= value || this.$t('logLengthsMustBeGreaterThanSegmentLength')
    },
    lessThanLogLengthRule (value) {
      return this.location.logLengths.every(l => l.length >= Number(value)) || this.$t('logLengthsMustBeGreaterThanSegmentLength')
    },

    parseTimezone () {
      let tz
      if (!this.location) {
        tz = this.timezones.find(tz => Number(tz.offset) === -5)
      } else {
        tz = (this.timezones.find(tz => Number(tz.offset) === this.location.timeZoneOffset))
        if (!tz) {
          tz = this.timezones.find(tz => Number(tz.offset) === -5)
        }
      }
      this.selectedTimezone = tz
    },

    async saveChanges () {
      if (!this.verifyRequest()) {
        return
      }

      const locationRequest = JSON.parse(JSON.stringify(this.location))
      locationRequest.timeZoneOffset = parseFloat(this.selectedTimezone.offset)
      locationRequest.ticketContact.phoneNumber = locationRequest.ticketContact.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace('-', '')
      locationRequest.mainContact.phoneNumber = locationRequest.mainContact.phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace('-', '')
      locationRequest.consumptionMode = ConsumptionMode.toInt(this.location.consumptionMode)

      // Add defect configuration.
      locationRequest.defectCollectionMode = DefectCollectionType.toInt(this.selectedDefectType)

      if (locationRequest.defectCollectionMode === 2 || locationRequest.collectLogCounts) {
        locationRequest.segmentLength = Number(locationRequest.segmentLength)
      } else {
        locationRequest.segmentLength = null
      }

      if (this.propLocation) {
        const response = await this.updateLocation(locationRequest)
        this.$emit('location-mutated', response)
      } else {
        const response = await this.createLocation(locationRequest)
        this.$emit('location-mutated', response)
      }
    },

    verifyRequest () {
      if (this.location.name === '') {
        this.setSnackError('Enter a name for the location.')
        return false
      }
      if (this.location.mainContact.firstName === '' ||
      this.location.mainContact.lastName === '' ||
      this.location.mainContact.phoneNumber === '' ||
      this.location.mainContact.email === '') {
        this.setSnackError(this.$t('fillMainContact'))
        return false
      }
      if (this.location.ticketContact.firstName === '' ||
      this.location.ticketContact.lastName === '' ||
      this.location.ticketContact.phoneNumber === '' ||
      this.location.ticketContact.email === '') {
        this.setSnackError(this.$t('fillTicketContacts'))
        return false
      }

      if (this.location.address.addressLine1 === '' ||
      this.location.address.city === '' ||
      this.location.address.postalCode === '' ||
      this.location.address.state === '') {
        this.setSnackError(this.$t('fillAddress'))
        return false
      }

      // Verify defect configuration for segmented defects.
      if (DefectCollectionType.toInt(this.selectedDefectType) === 2 || this.location.collectLogCounts) {
        const parsedSegLength = Number(this.location.segmentLength)
        if (isNaN(parsedSegLength) || parsedSegLength < 4 || parsedSegLength > 24) {
          this.setSnackError({
            message: this.$t('validRangeForSegmentLength'),
            code: 'FORM_ERROR',
            source: ErrorSource.WebClient
          })
          return false
        }
      }

      return true
    },

    autoselectState (event) {
      const input = event.srcElement._value
      this.statesWithLabels.forEach(s => {
        if (input.toUpperCase() === s.value || input.split(' ').map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ') === s.label) {
          this.location.address.state = s.value
        }
      })
    },

    close () {
      this.$emit('close')
    },
    autofillForm () {
      this.location.name = this.companyInfo.name
      this.location.mainContact = JSON.parse(JSON.stringify(this.companyInfo.mainContact))
      this.location.address = JSON.parse(JSON.stringify(this.companyInfo.address))
      this.location.ticketContact = JSON.parse(JSON.stringify(this.companyInfo.mainContact))
    },
    addLogLength () {
      if (!this.currentNewLogLengthIsValid) return

      this.addLogLengthOpen = false
      this.location.logLengths.push({
        length: Number(this.newLogLength)
      })
      this.newLogLength = undefined
    },
    removeLength (length) {
      const index = this.location.logLengths.findIndex(l => l.length === Number(length.length))
      this.location.logLengths.splice(index, 1)
    }
  }
}
</script>
