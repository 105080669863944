<template>
  <div v-if="!loading && certifications?.length > 0">
    <v-row>
      <v-spacer/>
      <Icon
        margin="mr-3 ml-1"
        :small="false"
        :icon="tableView ? 'mdi-card-multiple' : 'mdi-table'"
        iconColor="primary"
        :tooltipText="tableView ? $t('viewCards') : $t('viewTable')"
        @icon-clicked="tableView = !tableView"
      />
    </v-row>
    <AccountCertificationsTable
      v-if="tableView"
      :certifications="certifications"
      :files="files"
    />
    <AccountCertificationCardCollection
      v-else
      :certifications="certifications"
      :editMode="false"
      :files="files"
    />
  </div>
  <span v-else>{{ $t('noneSpecified') }}</span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'AccountCertifications',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    AccountCertificationsTable: () => import('@/components/account/certifications/AccountCertificationsTable'),
    AccountCertificationCardCollection: () => import('@/components/account/certifications/AccountCertificationCardCollection.vue')
  },

  props: {
    accountId: {
      type: Number,
      required: true
    },
    files: {
      type: Array,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    loading: undefined,
    certifications: [],
    tableView: false
  }),

  created () {
    this.refreshCertifications()
  },

  methods: {
    ...mapActions('certification', ['fetchAccountCertificationsByAccountId']),

    async refreshCertifications () {
      this.loading = true
      try {
        this.certifications = await this.fetchAccountCertificationsByAccountId(this.accountId)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
