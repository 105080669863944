<template>
<ShortcutWrapper :nShortcutRequiredClaim="UserClaims.TemplateManager" @n-shortcut="createActivityTemplate()">
  <DataTable
    :items.sync="filteredTemplates"
    :loading.sync="templatesLoading"
    :headers="headers"
    :actions="tableActions"
    :activityTemplate="true"
    :customCells="customTableCells"
    @new-template="createActivityTemplate"
    @refresh="refreshActivityTemplates"
  >
    <template #prepend-search>
      <div class="d-flex mt-1 mb-n6">
        <Selector
          :items="categories"
          :initialItem="selectedCategory"
          :label="$t('category')"
          item-text="name"
          item-value="value"
          width="220px"
          class="mr-2"
          @item-selected="setSelectedCategory"
        />
        <Selector
          v-if="entities.length > 0"
          :items="entities"
          :initialItem="selectedEntity"
          :label="$t('entity')"
          item-text="name"
          item-value="businessEntityId"
          width="300px"
          class="mr-2"
          @item-selected="entitySelected"
        />
      </div>
    </template>
    <template #custom-controls>
      <v-checkbox
        v-model="showInactive"
        class="mr-2"
        color="primary"
        data-testid="activity-show-inactive-checkbox"
        :label="$t('showInactive')"
      />
    </template>
    <template #status="{ item }">
      <Icon
      dataTestId="activity-status-icon"
      :icon="!isTemplateActive(item) ? 'mdi-cancel' : 'mdi-checkbox-marked-circle'"
      :iconColor="!isTemplateActive(item) ? 'black' : 'success'"
      :tooltipColor="!isTemplateActive(item) ? 'black' : 'success'"
      :tooltipText="!isTemplateActive(item) ? $t('inactive') : $t('active')"
      :small="false"
      />
      <Icon
      v-if="item.tractLevelCapture"
      icon="mdi-crosshairs-gps"
      iconColor="black"
      :tooltipText="$t('tractLevelCapture')"
      :small="false"
      />
      <Icon
      v-if="item.specialization === TemplateSpecialization.Transfer.value"
      dataTestId="activity-transfer-icon"
      icon="mdi-transfer"
      iconColor="grey"
      :tooltipText="$t('transferActivity')"
      :small="false"
      />
      <Icon
      v-if="item.specialization === TemplateSpecialization.Depletion.value"
      icon="mdi-wallet-bifold"
      :tooltipText="$t('depletionActivity')"
      iconColor="black"
      :small="false"/>
    </template>
    <template #pay-on="{ item }">
      <span>{{ item.baseCost ? payOnFromInt(item.baseCost.payOn) : $t('notAvailable') }}</span>
    </template>
    <template #gl-offset="{ item }">
      <span>{{ item.glOffset ? item.glOffset : $t('notAvailable') }}</span>
    </template>
    <template #pay-by="{ item }">
      <span>{{ item.baseCost ? payByFromInt(item.baseCost.payBy) : $t('notAvailable') }}</span>
    </template>
    <template #base-cost-rate="slotProps">
      <span v-if="slotProps.item.baseCost">{{ formatMoney(slotProps.item.baseCost.rate) }}</span>
      <span v-else>{{$t('notAvailable')}}</span>
    </template>
    <template #related="slotProps">
      <Icon
      icon="mdi-history"
      iconColor="primary"
      dataTestId="template-modification-history-button"
      :tooltipText="$t('history')"
      @icon-clicked="openTemplateModificationHistory(slotProps.item)"/>
    </template>
    <template #actions="slotProps">
      <Icon
      icon="mdi-pencil"
      iconColor="success"
      dataTestId="tempalate-edit-button"
      :tooltipText="$t('edit')"
      @icon-clicked="editTemplate(slotProps.item)"
      :disabled="!userAssignedClaim(UserClaims.TemplateManager)"/>
      <Icon
      icon="mdi-delete-forever"
      iconColor="error"
      dataTestId="template-delete-button"
      :tooltipText="$t('delete')"
      @icon-clicked="confirmDeleteTemplate(slotProps.item)"
      :disabled="!userAssignedClaim(UserClaims.TemplateManager)"/>
    </template>
  </DataTable>
  <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
    <ModificationHistory entityType="activityTemplate" :entityName="selectedTemplate.name" v-if="selectedTemplate" :entityId="selectedTemplate.activityTemplateId"/>
  </Dialog>
  <Dialog :stateId="templateFormDialogId" @dialog-closing="resetDialogs">
    <TemplateForm
      v-if="templateForm"
      :propTemplate="selectedTemplate"
      :chosenCategory="selectedCategory.value"
      :initialEntity="selectedEntity"
      @close="resetDialogs"
      @template-mutated="refreshActivityTemplates"
    />
  </Dialog>
</ShortcutWrapper>
</template>

<script>
import { UserClaims, PayOn, PayBy, AccountingCategory, ActivityStatus, TemplateSpecialization } from '@/utils/Enumerations'
import { mapGetters, mapActions } from 'vuex'
import { uniqueDialogId } from '@/utils/componentHelpers'
import { formatMoney } from '@/utils/NumericMutations'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { LocalStorageKeys } from '@/utils/constants.js'
import ActivityTemplateHeaders from '@/headers/Activity'
export default {
  name: 'TemplatesTab',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ShortcutWrapper: () => import('@/components/core/ShortcutWrapper.vue'),
    Selector: () => import('@/components/core/Selector.vue'),
    TemplateForm: () => import('@/components/settings-components/activity-template/TemplateForm.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue')
  },

  data: () => ({
    TemplateSpecialization,
    dialogId: uniqueDialogId('activity-template-tab'),
    templateFormDialogId: uniqueDialogId('activity-template-form'),
    showInactive: false,
    templateForm: false,
    ActivityStatus,
    entities: [],
    selectedEntity: null,
    selectedTemplate: null,
    selectedCategory: AccountingCategory.Payable,
    tableActions: [
      {
        icon: 'mdi-refresh',
        text: 'Refresh',
        actionName: 'refresh'
      },
      {
        icon: 'mdi-plus',
        text: 'New Template',
        actionName: 'new-template',
        disabled: !userAssignedClaim(UserClaims.TemplateManager)
      }
    ],
    UserClaims
  }),

  computed: {
    ...mapGetters('activity-templates', ['allActivityTemplates', 'templatesLoading']),

    headers () {
      return this.selectedCategory.value === AccountingCategory.Receivable.value
        ? ActivityTemplateHeaders.activityTemplateHeaders().filter(header => header.value !== 'costType')
        : ActivityTemplateHeaders.activityTemplateHeaders()
    },

    categories () {
      return AccountingCategory.enums
    },

    customTableCells () {
      return [
        {
          slotName: 'status',
          value: 'activityStatus'
        },
        {
          slotName: 'pay-by',
          value: 'payBy'
        },
        {
          slotName: 'gl-offset',
          value: 'glOffset'
        },
        {
          slotName: 'pay-on',
          value: 'payOn'
        },
        {
          slotName: 'related',
          value: 'related'
        },
        {
          slotName: 'actions',
          value: 'actions'
        },
        {
          slotName: 'base-cost-rate',
          value: 'rate'
        }
      ]
    },

    filteredTemplates () {
      return this.allActivityTemplates
        .filter(template => template.category === this.selectedCategory.value &&
          template.businessEntityId === this.selectedEntity?.businessEntityId &&
          (this.showInactive || this.isTemplateActive(template))
        )
    }
  },

  async created () {
    const entities = await this.fetchAllBusinessEntities()
    await this.refreshActivityTemplates()

    const savedTemplateId = localStorage.getItem(LocalStorageKeys.TEMPLATE_ENTITY_ID)
    if (savedTemplateId) {
      this.selectedEntity = entities.find(e => e.businessEntityId === parseInt(savedTemplateId))
    } else {
      this.selectedEntity = entities[0]
    }

    this.entities = entities
  },

  methods: {
    ...mapActions('activity-templates', ['fetchActivityTemplates', 'fetchTemplateBundles']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    userAssignedClaim,

    entitySelected (entity) {
      localStorage.setItem(LocalStorageKeys.TEMPLATE_ENTITY_ID, entity?.businessEntityId)
      this.selectedEntity = entity
    },

    async refreshActivityTemplates () {
      this.resetDialogs()
      await this.fetchActivityTemplates()
      await this.fetchTemplateBundles()
    },

    createActivityTemplate () {
      this.resetDialogs()
      this.templateForm = true
      this.openOrUpdateDialog({ id: this.templateFormDialogId, width: '80vw' })
    },

    editTemplate (template) {
      this.resetDialogs()
      this.selectedTemplate = template
      this.templateForm = true
      this.openOrUpdateDialog({ id: this.templateFormDialogId, width: '80vw' })
    },

    confirmDeleteTemplate (template) {
      this.$emit('confirm-delete-template', template)
    },

    isTemplateActive (template) {
      return template.activityStatus === ActivityStatus.Active.value
    },

    payByFromInt (val) {
      return PayBy.fromInt(val)
    },

    payOnFromInt (val) {
      return PayOn.fromInt(val)
    },

    openTemplateModificationHistory (template) {
      this.selectedTemplate = template
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    resetDialogs () {
      this.templateForm = false
      this.selectedTemplate = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
      this.closeDialogsAtOrAbove(this.templateFormDialogId)
    },

    setSelectedCategory (category) {
      this.selectedCategory = category
      this.$emit('category-selected', category)
    }
  }
}
</script>
