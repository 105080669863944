<template>
  <v-card>
      <v-card-title :style="headerStyle">
          <span class="headline">Defect Images</span>
          <v-spacer/>
          <Icon
            icon="mdi-close"
            iconColor="tertiary"
            dataTestId="ticket-defect-edit-close-button"
            :small="false"
            :large="true"
            margin=""
            :tooltipText="$t('close')"
            @icon-clicked="$emit('close')"
          />
      </v-card-title>
      <v-card-text>
        <ImageViewer
        :title="$t('defectImagesTitle')"
        :imageData="imageUrls"
        :propImagePage="1"
        :scrollZoom="{ mode: 'cursor', increment: 0.3 }"
        draggable
        />
      </v-card-text>
  </v-card>
</template>

<script>
import { defectImageClient } from '../../utils/TicketImages.js'

export default {
  name: 'DefectImageViewer',

  components: {
    Icon: () => import('../helper/Icon.vue'),
    ImageViewer: () => import('@/components/helper/ImageViewer.vue')
  },

  props: ['ticketId', 'ticketDefectId'],

  data: () => ({
    loading: false,
    imageUrls: []
  }),

  async created () {
    this.fetchDefectImages(this.ticketId, this.ticketDefectId)
  },

  computed: {
    headerStyle () {
      return 'background-color: #d15f27; color: white;'
    }
  },

  methods: {
    async fetchDefectImages () {
      defectImageClient.getImageUrls(this.ticketId, this.ticketDefectId).then(urls => {
        this.imageUrls = urls.map(url => url.toString())
      })
    }
  }
}
</script>
