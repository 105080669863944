<template>
  <v-card outlined rounded>
    <v-card-title>
      <v-icon class="mr-2" :color="iconColor">mdi-certificate-outline</v-icon>
      <v-tooltip v-if="getCertificationStatus === CertificationStatus.Expiring.value" bottom color="black">
        <template #activator="{on}">
          <v-icon v-on="on" color="warning" style="padding-right: 0.25em">mdi-alert</v-icon>
        </template>
        <span class="subtitle-1 white--text">{{ $t('expiringSoon') }}</span>
      </v-tooltip>
      <span :class="titleStyle">{{ certification.name }}</span>
      <v-spacer/>
      <Icon
        v-if="file"
        icon="mdi-file-download-outline"
        iconColor="black"
        :tooltipText="downloadFileTooltip"
        @icon-clicked="downloadFile"
        tabindex="0"
      />
      <Icon
        v-if="showCrud"
        icon="mdi-pencil"
        iconColor="success"
        :tooltipText="$t('editCertification')"
        @icon-clicked="edit"
        tabindex="0"
      />
      <Icon
        v-if="showCrud"
        icon="mdi-delete-forever"
        iconColor="error"
        :tooltipText="$t('delete')"
        @icon-clicked="confirmRemoveDialog = true"
        tabindex="0"
      />
    </v-card-title>
    <v-card-text class="mt-n5">
      <v-container fluid grid-list-sm>
        <v-row no-gutters class="ml-n2">
          <v-col cols="12">
            {{ effectiveDate }}
          </v-col>
        </v-row>
        <v-row no-gutters class="ml-n2">
          <v-col cols="12">
            {{ expirationDate }}
          </v-col>
        </v-row>
        <v-row no-gutters class="ml-n2">
          <v-col cols="12">
            {{ certificateNumber }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog width="400px" v-model="confirmRemoveDialog">
      <ConfirmDialog
        v-if="confirmRemoveDialog"
        :title="$t('removeCertification')"
        :body="$t('removeCertificationFromAccountBody', { certification: certification.name })"
        @confirm="remove"
        @cancel="confirmRemoveDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { CERTIFICATION_EXPIRY_WARNING_DAYS } from '@/utils/constants.js'
import { CertificationStatus } from '@/utils/Enumerations.js'
export default {
  name: 'AccountCertificationCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    certification: {
      type: Object,
      required: true
    },
    showCrud: {
      type: Boolean,
      default: true
    },
    file: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    confirmRemoveDialog: false,
    CertificationStatus
  }),

  computed: {
    expiredString () {
      return this.getCertificationStatus === CertificationStatus.Expired.value
        ? this.$t('expiredOn')
        : this.$t('expiresOnVerbatim')
    },

    expirationDate () {
      if (!this.certification) return ''
      return this.certification.expirationDate
        ? `${this.expiredString} ${utcToLocalDate(this.certification.expirationDate)}`
        : this.$t('expirationDateNotSpecified')
    },

    effectiveDate () {
      if (!this.certification) return ''
      return this.certification.effectiveDate
        ? `${this.$t('effective')} ${utcToLocalDate(this.certification.effectiveDate)}`
        : this.$t('effectiveDateNotSpecified')
    },

    certificateNumber () {
      if (!this.certification) return ''
      const certificateString = this.certification.certificateNumber ?? this.$t('notApplicable')
      return `${this.$t('certificateNumber')}: ${certificateString}`
    },

    downloadFileTooltip () {
      return `${this.$t('downloadFile')}
      (${this.file.name})`
    },

    isExpired () {
      if (!this.certification.expirationDate) return false
      const today = moment.utc()
      const expirationDate = moment.utc(this.certification.expirationDate)
      return expirationDate.isBefore(today)
    },

    isExpiring () {
      if (!this.certification.expirationDate) return false
      const today = moment.utc()
      const criticalDate = moment.utc().add(CERTIFICATION_EXPIRY_WARNING_DAYS, 'd')
      const expirationDate = moment.utc(this.certification.expirationDate)
      return expirationDate.isBetween(today, criticalDate)
    },

    titleStyle () {
      switch (this.getCertificationStatus) {
        case CertificationStatus.Inactive.value:
          return 'subtitle-1 grey--text'
        case CertificationStatus.Active.value:
          return 'subtitle-1'
        case CertificationStatus.Expiring.value:
          return 'subtitle-1'
        case CertificationStatus.Expired.value:
          return 'subtitle-1 grey--text'
        default:
          return 'subtitle-1'
      }
    },

    iconColor () {
      switch (this.getCertificationStatus) {
        case CertificationStatus.Inactive.value:
          return 'grey'
        case CertificationStatus.Active.value:
          return 'black'
        case CertificationStatus.Expiring.value:
          return 'black'
        case CertificationStatus.Expired.value:
          return 'grey'
        default:
          return 'black'
      }
    },

    getCertificationStatus () {
      const now = moment.utc()
      const criticalDate = moment.utc().add(CERTIFICATION_EXPIRY_WARNING_DAYS, 'd')
      const effectiveDate = this.certification.effectiveDate ? moment.utc(this.certification.effectiveDate) : undefined
      const expirationDate = this.certification.expirationDate ? moment.utc(this.certification.expirationDate) : undefined
      if (effectiveDate?.isAfter(now)) {
        return CertificationStatus.Inactive.value
      }
      if (expirationDate?.isBetween(now, criticalDate)) {
        return CertificationStatus.Expiring.value
      }
      if (expirationDate?.isBefore(now)) {
        return CertificationStatus.Expired.value
      }
      return CertificationStatus.Active.value
    }
  },

  methods: {
    edit () {
      this.$emit('certification-edit-initiated', this.certification)
    },

    remove () {
      this.confirmRemoveDialog = false
      this.$emit('certification-removed', this.certification)
    },

    async downloadFile () {
      const fetchedFile = await fetch(this.file.ref)
      const blob = await fetchedFile.blob()
      saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
    }
  }
}
</script>
