<template>
  <v-row dense>
    <v-col cols="12" xs="12">
      <v-btn
      data-testid="correction-enteringpounds"
      @click="enteringPounds = !enteringPounds"
      class="primary white--text mb-2"
      min-width="130px"
      >
        <v-icon small class="mr-2" color="white">mdi-scale</v-icon>
        <span>{{enteringPounds ? 'LBS' : 'TONS'}}</span>
      </v-btn>
    </v-col>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="originalWeights.inWeight"
        data-testid="correction-original-inweight"
        :label="$t('originalInWeight')"
        :hint="getConvertedHint(originalWeights.inWeight)"
        color="black"
        disabled
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="newWeights.inWeight"
        data-testid="correction-inweight"
        :label="$t('inWeight')"
        :hint="getConvertedHint(newWeights.inWeight)"
        color="black"
        persistent-hint
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="originalWeights.outWeight"
        data-testid="correction-original-outweight"
        :label="$t('originalOutWeight')"
        :hint="getConvertedHint(originalWeights.outWeight)"
        color="black"
        disabled
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="newWeights.outWeight"
        data-testid="correction-outweight"
        :label="$t('outWeight')"
        :hint="getConvertedHint(newWeights.outWeight)"
        color="black"
        persistent-hint
        />
      </v-col>
    </v-row>
    <v-row dense v-if="isSimpleDefect">
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="originalWeights.defectWeight"
        data-testid="correction-original-defectweight"
        :label="$t('originalDefectWeight')"
        :hint="getConvertedHint(originalWeights.defectWeight)"
        color="black"
        disabled
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="newWeights.defectWeight"
        data-testid="correction-defectweight"
        :label="$t('defectWeight')"
        :hint="getConvertedHint(newWeights.defectWeight)"
        color="black"
        persistent-hint
        />
      </v-col>
    </v-row>
    <v-row dense v-else>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="originalWeights.natureDefectWeight"
        data-testid="correction-original-nature-defectweight"
        :label="$t('originalNatureDefectWeight')"
        :hint="getConvertedHint(originalWeights.natureDefectWeight)"
        color="black"
        disabled
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="newWeights.natureDefectWeight"
        data-testid="correction-nature-defectweight"
        :label="$t('natureDefectWeight')"
        :hint="getConvertedHint(newWeights.natureDefectWeight)"
        color="black"
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="originalWeights.mismanufactureDefectWeight"
        data-testid="correction-original-mismanufacture-defect-weight"
        :label="$t('originalMismanufacturedDefectWeight')"
        :hint="getConvertedHint(originalWeights.mismanufactureDefectWeight)"
        color="black"
        disabled
        persistent-hint
        />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6">
        <v-text-field
        v-model="newWeights.mismanufactureDefectWeight"
        data-testid="correction-mismanufacture-defect-weight"
        :label="$t('mismanufacturedDefectWeight')"
        :hint="getConvertedHint(newWeights.mismanufactureDefectWeight)"
        color="black"
        persistent-hint
        />
      </v-col>
    </v-row>
    <v-col cols="12" xs="12" sm="12" md="6">
      <v-text-field
      v-model="netWeights.old"
      data-testid="correction-original-netweight"
      :label="$t('originalNetWeight')"
      color="black"
      disabled
      persistent-hint
      />
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="6">
      <v-text-field
      data-testid="correction-netweight"
      v-model="netWeights.new"
      :label="$t('net')"
      color="black"
      readonly
      persistent-hint
      />
    </v-col>
  </v-row>
</template>

<script>
import { DefectCategory } from '@/utils/Enumerations.js'
const weightKeys = ['inWeight', 'outWeight', 'defectWeight', 'natureDefectWeight', 'mismanufactureDefectWeight']
export default {
  props: {
    originalTicket: {
      type: Object,
      required: true
    },
    defects: {
      type: Array,
      required: true
    },
    isSimpleDefect: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    enteringPounds: true,
    originalWeights: {},
    newWeights: {
      inWeight: 0,
      outWeight: 0,
      defectWeight: 0,
      natureDefectWeight: 0,
      mismanufactureDefectWeight: 0
    }
  }),

  computed: {
    netWeights () {
      const decimals = this.enteringPounds ? 0 : 3
      const weightLabel = this.enteringPounds ? 'lbs' : 'tons'
      const defectWeight = this.isSimpleDefect
        ? this.newWeights.defectWeight
        : parseFloat(this.newWeights.natureDefectWeight) + parseFloat(this.newWeights.mismanufactureDefectWeight)
      const newNet = this.newWeights.inWeight - this.newWeights.outWeight - defectWeight
      const oldNet = this.originalWeights.inWeight - this.originalWeights.outWeight - this.originalWeights.defectWeight

      let delta = (newNet - oldNet).toFixed(decimals)
      if (!delta.includes('-')) {
        delta = `+${delta}`
      }

      return {
        new: `${newNet.toFixed(decimals)} (${delta}) ${weightLabel}`,
        old: `${oldNet.toFixed(decimals)} ${weightLabel}`
      }
    }
  },

  watch: {
    enteringPounds: {
      handler (val) {
        const convert = this.getConversionFunction(val)
        weightKeys.forEach(key => {
          this.originalWeights[key] = this.originalWeights[key] !== undefined ? convert(this.originalWeights[key]) : this.originalWeights[key]
          this.newWeights[key] = this.newWeights[key] !== undefined ? convert(this.newWeights[key]) : this.newWeights[key]
        })
      }
    },

    newWeights: {
      handler (val) {
        this.emitFormattedWeights(val)
      },
      deep: true
    }
  },

  created () {
    this.setInitialWeights(this.originalTicket)
  },

  methods: {
    setInitialWeights (originalTicket) {
      weightKeys.forEach(key => {
        this.originalWeights[key] = originalTicket[key]
        this.newWeights[key] = originalTicket[key]
      })

      if (this.isSimpleDefect === false) {
        const originalNatureDefectWeight = this.defects.filter(d => d.category === DefectCategory.Nature.value).reduce((tot, d) => tot + d.totalLBS, 0)
        const originalMismanufacturedDefectWeight = this.defects.filter(d => d.category === DefectCategory.Mismanufactured.value).reduce((tot, d) => tot + d.totalLBS, 0)
        this.originalWeights.mismanufactureDefectWeight = originalMismanufacturedDefectWeight
        this.originalWeights.natureDefectWeight = originalNatureDefectWeight
        this.newWeights.mismanufactureDefectWeight = originalMismanufacturedDefectWeight
        this.newWeights.natureDefectWeight = originalNatureDefectWeight
      }
    },

    getConvertedHint (weight) {
      const convert = this.getConversionFunction(!this.enteringPounds)
      return `${convert(weight)} ${!this.enteringPounds ? 'lbs' : 'tons'}`
    },

    getConversionFunction (toPounds) {
      if (toPounds) { return (x) => Math.floor(parseFloat(x) * 2000) }
      return (x) => parseFloat((parseFloat(x) / 2000).toFixed(3))
    },

    emitFormattedWeights (cWeights) {
      const weights = JSON.parse(JSON.stringify(cWeights))
      Object.keys(weights).forEach(key => {
        weights[key] = parseInt(!this.enteringPounds ? Math.floor(parseFloat(weights[key]) * 2000) : weights[key])
      })

      this.$emit('weights-entered', weights)
    }
  }
}
</script>
