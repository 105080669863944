<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="currentTab" class="mt-4">
          <v-tab>{{ $t('payables') }}</v-tab>
          <v-tab>{{ $t('receivables') }}</v-tab>
          <v-tab>{{ $t('accruals') }}</v-tab>
          <v-spacer/>
          <Icon
          v-if="hasIntegrations"
          :small="false"
          large
          :tooltipText="$t('integrations')"
          icon="mdi-cloud-outline"
          @icon-clicked="openIntegrationDialog"
          />
          <Icon
          :small="false"
          large
          :tooltipText="$t('downloadCsv')"
          icon="mdi-download"
          @icon-clicked="openCsvDialog"/>
          <v-tab-item disabled>
            <PayablesAggregations
            :payables="!isByproducts ? brokenDownSnapshot.payables : brokenDownSnapshot.byproducts.payables"
            :isByproducts="isByproducts"/>
          </v-tab-item>
          <v-tab-item disabled>
            <TicketFinancialsAggregations
            :financials="!isByproducts ? brokenDownSnapshot.receivables : brokenDownSnapshot.byproducts.receivables"
            :isByproducts="isByproducts"
            :title="$t('ticketReceivables')"/>
          </v-tab-item>
          <v-tab-item disabled>
            <TicketFinancialsAggregations
            :financials="!isByproducts ? brokenDownSnapshot.accruals : brokenDownSnapshot.byproducts.accruals"
            :isByproducts="isByproducts"
            :title="$t('ticketAccruals')"/>
          </v-tab-item>
        </v-tabs>
        </v-col>
      </v-row>
    </v-col>
    <Dialog :stateId="dialogId" maxWidth="800" @close="closeDialogs">
      <CSVExport
      v-if="csvDialog"
      isAdjustingEntries
      :isByProducts="isByproducts"
      @downloadCsv="downloadCsv"
      @close-csv-export="closeDialogs"
      />
      <SnapshotIntegrationDialog
      v-if="integrationDialog"
      :snapshotRecord="snapshotRecord"
      @click:retry="retrySnapshotIntegration"
      />
    </Dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { uniqueDialogId } from '../../../../utils/componentHelpers'
import { AdjustingEntriesSnapshotIntegrationStatus, FinancialIntegrationType } from '../../../../utils/Enumerations'
export default {
  name: 'SnapshotBreakdown',

  components: {
    PayablesAggregations: () => import('@/components/accounting/adjusting-entries/v1/PayablesAggregations.vue'),
    TicketFinancialsAggregations: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsAggregations.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    SnapshotIntegrationDialog: () => import('@/components/accounting/adjusting-entries/v1/SnapshotIntegrationDialog.vue'),
    CSVExport: () => import('@/components/settlements/CSVExport.vue')
  },

  props: {
    snapshot: {
      type: Object,
      required: true
    },
    snapshotRecord: {
      type: Object,
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: true
    },
    snapshotPeriodEndDate: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    brokenDownSnapshot: {},
    currentTab: 0,
    csvDialog: false,
    integrationDialog: false,
    dialogId: uniqueDialogId('snapshot-breakdown'),
    delayedRefreshTimeout: undefined
  }),

  created () {
    this.breakDownSnapshot(this.snapshot)
  },

  watch: {
    snapshot (ss) {
      this.breakDownSnapshot(ss)
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),

    hasIntegrations () { return this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value }
  },

  methods: {
    ...mapActions('adjusting-entries', ['fetchAdjustingEntriesCSV', 'patchSnapshotRecord']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    closeDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.csvDialog = false
      this.integrationDialog = false
    },

    openCsvDialog () {
      this.csvDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: 400 })
    },

    openIntegrationDialog () {
      this.integrationDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: 800 })
    },

    async downloadCsv (requestType) {
      const requestObj = {
        snapshotId: this.snapshotRecord.adjustingEntriesSnapshotRecordId,
        periodEndDate: this.snapshotPeriodEndDate
      }
      if (requestType !== 3) {
        requestObj.type = this.getRequestType(requestType)
        await this.fetchAdjustingEntriesCSV(requestObj)
        return
      }
      requestObj.type = 'apcsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
      requestObj.type = 'arcsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
      requestObj.type = 'jecsvexport'
      await this.fetchAdjustingEntriesCSV(requestObj)
    },

    async retrySnapshotIntegration () {
      const { adjustingEntriesSnapshotRecordId } = this.snapshotRecord
      const body = [
        {
          op: 'add',
          path: '/integrationStatus',
          value: AdjustingEntriesSnapshotIntegrationStatus.Retry.value
        }
      ]
      await this.patchSnapshotRecord({ snapshotRecordId: adjustingEntriesSnapshotRecordId, body })
      this.closeDialogs()
      this.triggerDelayedRefresh()
    },

    triggerDelayedRefresh () {
      this.delayedRefreshTimeout = setTimeout(() => {
        this.delayedRefreshTimeout = undefined
        this.$emit('refresh-snapshot')
      }, 5000)
    },

    getRequestType (type) {
      switch (type) {
        case 0:
          return 'apcsvexport'
        case 1:
          return 'arcsvexport'
        case 2:
          return 'jecsvexport'
        default:
          return undefined
      }
    },
    breakDownSnapshot (snapshot) {
      this.brokenDownSnapshot = this.aggregateTicketsFinancials(snapshot.tickets ?? [], snapshot.ticketCorrections ?? [])

      this.brokenDownSnapshot.byproducts = this.aggregateTicketsFinancials(snapshot.byproductsTickets ?? [], snapshot.byproductsCorrections ?? [])

      this.brokenDownSnapshot.payables.transferPayables = new Map()
      snapshot.transferTickets.forEach(t => {
        t.payables.forEach(p => {
          this.brokenDownSnapshot.payables.transferPayables = this.aggregateFinancialToMap(t.ticketId, t.netWeight, t.ticketNumber, t.date, p, this.brokenDownSnapshot.payables.transferPayables)
        })
      })

      this.brokenDownSnapshot.payables.tractPayments = new Map()
      snapshot.tractPayments.forEach(tp => {
        const pair = [tp.businessEntityId, tp.activityTemplateId].join(',')
        const currentEntry = this.brokenDownSnapshot.payables.tractPayments.get(pair)
        const payableSummary = {
          title: tp.title,
          tractName: tp.tractName,
          dateIssued: tp.dateIssued,
          amount: tp.amount
        }
        this.brokenDownSnapshot.payables.tractPayments.set(pair, {
          businessEntity: tp.businessEntity,
          activity: tp.activity,
          glCode: tp.glCode,
          glOffset: tp.glOffset,
          amount: (currentEntry?.amount ?? 0) + tp.amount,
          payments: (currentEntry?.payments ?? []).concat(payableSummary)
        })
      })

      this.brokenDownSnapshot.payables.advances = new Map()
      snapshot.advances.forEach(a => {
        const pair = [a.businessEntityId, a.activityTemplateId].join(',')
        const currentEntry = this.brokenDownSnapshot.payables.advances.get(pair)
        const advanceSummary = {
          account: a.account,
          title: a.title,
          effective: a.effective,
          amount: a.amount,
          balance: a.balance
        }
        this.brokenDownSnapshot.payables.advances.set(pair, {
          businessEntity: a.businessEntity,
          activity: a.activity,
          glCode: a.glCode,
          glOffset: a.glOffset,
          amount: (currentEntry?.amount ?? 0) + a.amount,
          advances: (currentEntry?.advances ?? []).concat(advanceSummary)
        })
      })

      this.brokenDownSnapshot.payables.accountPayments = new Map()
      const accountPaymentsRoot = snapshot.accountPayments ?? []
      accountPaymentsRoot.forEach(ap => {
        const pair = [ap.businessEntityId, ap.activityTemplateId].join(',')
        const currentEntry = this.brokenDownSnapshot.payables.accountPayments.get(pair)
        const payableSummary = {
          title: ap.title,
          account: ap.account,
          dateIssued: ap.dateIssued,
          amount: ap.amount
        }
        this.brokenDownSnapshot.payables.accountPayments.set(pair, {
          businessEntity: ap.businessEntity,
          activity: ap.activity,
          glCode: ap.glCode,
          glOffset: ap.glOffset,
          amount: (currentEntry?.amount ?? 0) + ap.amount,
          payments: (currentEntry?.payments ?? []).concat(payableSummary)
        })
      })
    },

    aggregateRecoveriesByTitle (newRecovery, existingRecoveries) {
      const recoveryByAdvanceIndex = existingRecoveries.findIndex(r => r.advanceId === newRecovery.advanceId)
      if (recoveryByAdvanceIndex < 0) return existingRecoveries.concat(newRecovery)
      existingRecoveries[recoveryByAdvanceIndex].amount += newRecovery.amount
      return existingRecoveries
    },

    aggregateFinancialToMap (ticketId, weight, number, date, financial, entityActivityMap) {
      const aggregatePair = [financial.businessEntityId, financial.activityTemplateId].join(',')
      const ticketIdOrNumber = ticketId === 0 ? number : ticketId // Used to preserve funcionality for snapshots that did not include ticketId
      const ticketIdPair = [ticketIdOrNumber, financial.financialCorrectionSide].join(',')

      const currentAggregate = entityActivityMap.get(aggregatePair)

      const currentAggregateTickets = currentAggregate?.tickets ?? new Map()
      const currentTicket = currentAggregateTickets.get(ticketIdPair)

      const ticketSummary = {
        ticketId: ticketId,
        financialCorrectionSide: financial.financialCorrectionSide,
        ticketNumber: number,
        date: date,
        gross: (financial.grossAmount ?? financial.amount) + (currentTicket?.gross ?? 0),
        netWeight: weight,
        recovered: financial.recoveries?.reduce((a, b) => a + b.amount, 0) + (currentTicket?.recovered ?? 0)
      }

      currentAggregateTickets.set(ticketIdPair, ticketSummary)
      entityActivityMap.set(aggregatePair, {
        businessEntity: financial.businessEntity,
        activity: financial.activity,
        glCode: financial.glCode,
        glOffset: financial.glOffset,
        grossAmount: (currentAggregate?.grossAmount ?? 0) + (financial.grossAmount ?? financial.amount),
        tickets: currentAggregateTickets
      })
      return entityActivityMap
    },

    aggregateTicketsFinancials (tickets, corrections) {
      let recoveries = []
      const returnObject = {
        payables: {
          ticketPayables: {
            contractPayments: new Map(),
            recoveries: new Map(),
            corrections: new Map()
          }
        },
        receivables: {
          contractPayments: new Map(),
          corrections: new Map()
        },
        accruals: {
          contractPayments: new Map(),
          corrections: new Map()
        }
      }
      // Tickets
      tickets.forEach(t => {
        t.payables.forEach(p => {
          returnObject.payables.ticketPayables.contractPayments = this.aggregateFinancialToMap(t.ticketId, t.netWeight, t.ticketNumber, t.date, p, returnObject.payables.ticketPayables.contractPayments)
          recoveries = recoveries.concat(p.recoveries)
        })
        t.receivables.forEach(r => {
          returnObject.receivables.contractPayments = this.aggregateFinancialToMap(t.ticketId, t.netWeight, t.ticketNumber, t.date, r, returnObject.receivables.contractPayments)
        })
        t.journalEntries.forEach(je => {
          returnObject.accruals.contractPayments = this.aggregateFinancialToMap(t.ticketId, t.netWeight, t.ticketNumber, t.date, je, returnObject.accruals.contractPayments)
        })
      })
      // Corrections
      corrections.forEach(c => {
        c.payables.forEach(p => {
          const weight = p.financialCorrectionSide === 0 ? c.originalWeight * -1 : c.correctedWeight
          returnObject.payables.ticketPayables.corrections = this.aggregateFinancialToMap(c.ticketId, weight, c.ticketNumber, c.createdAt, p, returnObject.payables.ticketPayables.corrections)
          recoveries = recoveries.concat(p.recoveries)
        })
        c.receivables.forEach(r => {
          const weight = r.financialCorrectionSide === 0 ? c.originalWeight * -1 : c.correctedWeight
          returnObject.receivables.corrections = this.aggregateFinancialToMap(c.ticketId, weight, c.ticketNumber, c.createdAt, r, returnObject.receivables.corrections)
        })
        c.journalEntries.forEach(je => {
          const weight = je.financialCorrectionSide === 0 ? c.originalWeight * -1 : c.correctedWeight
          returnObject.accruals.corrections = this.aggregateFinancialToMap(c.ticketId, weight, c.ticketNumber, c.createdAt, je, returnObject.accruals.corrections)
        })
      })
      // Recoveries (Ticket payable only)
      recoveries.forEach(r => {
        const pair = [r.businessEntityId, r.activityTemplateId].join(',')
        const existingRecovery = returnObject.payables.ticketPayables.recoveries.get(pair)
        const recoverySummary = {
          advance: r.advance,
          advanceId: r.advanceId,
          amount: r.amount * -1,
          advanceBalance: r.advanceBalance
        }
        returnObject.payables.ticketPayables.recoveries.set(pair, {
          businessEntity: r.businessEntity,
          activity: r.activity,
          glCode: r.glCode,
          glOffset: r.glOffset,
          amount: (existingRecovery?.amount ?? 0) + (r.amount * -1),
          recoveries: this.aggregateRecoveriesByTitle(recoverySummary, existingRecovery?.recoveries ?? [])
        })
      })
      return returnObject
    }
  }
}
</script>
