<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row class="mt-6" dense>
          <v-col
          cols="12">
            <v-file-input
              v-model="csvFile"
              accept=".csv"
              outlined
              color="primary"
              :label="`Upload ${entityName} CSV`"
              @click="clearFile"
              :disabled="disabled"
            >
              <template #prepend-inner>
                <Icon
                v-if="exampleData"
                margin=""
                icon="mdi-information-outline"
                tooltipText="Download Example"
                :small="false"
                color="black"
                @icon-clicked="downloadExampleData"></Icon>
                <a href=""/>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="hasParseError" cols="12">
            <v-row>
              <v-col cols="12">
                <v-row align="end" class="ma-0">
                  <span class="title">{{$t('oneOrMoreErrorsExistWithThisFile')}}</span>
                  <v-spacer/>
                  <span>{{this.displayedFileName}}</span>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-for="(csvError, i) in errors.csv" :key="`csv-error-${i}`" dense>
              <v-col cols="12">
                <span class="subtitle-2">{{csvError.message}}</span>
              </v-col>
            </v-row>

            <v-row v-for="(lineErrors, index) in errors.lines" :key="`line-error-${index}`">
              <v-col cols="12" v-for="(lineError, li) in lineErrors" :key="`line-${index}-error-${li}`">
                <span class="subtitle-2">{{ $t('lineN', { n: lineError.lineNumber }) }}: {{lineError.message}}</span>
              </v-col>
            </v-row>
          </v-col>

          <template v-if="csvData && showPreview">
            <v-col cols="12">
              <v-row align="end" class="ma-0">
                <span class="headline">{{this.$t('preview')}}</span>
                <v-spacer/>
                <span>{{this.displayedFileName}}</span>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :items="preview.map ? csvData.map(preview.map) : csvData"
                :headers="preview.headers"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-for="header in preview.headers" v-slot:[`item.${header.value}`]="{ item }">
                  <slot :name="`preview-item.${header.value}`" :item="item" :value="item[header.value]">
                    <span :key="`header-tmp-${header.value}`">{{ header.format ? header.format(item[header.value]) : item[header.value] }}</span>
                  </slot>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
              cols="12"
              class="primary mt-n2"
              :disabled="!(csvData?.length)"
              @click="onClickImport">
              {{$t('import')}}
              </v-btn>
            </v-col>
          </template>

          <v-col cols="12" v-if="result.length > 0">
            <v-row align="end" class="ma-0">
              <span class="headline">{{this.$t('results')}}</span>
              <v-spacer/>
              <span>{{this.displayedFileName}}</span>
            </v-row>
          </v-col>
          <v-col v-if="result.length > 0" cols="12">
            <v-data-table
            :items="result"
            :headers="resultTableHeaders"
            :items-per-page="-1"
            hide-default-footer
            dense>
            <template #item.name="{item}">
              {{getRowName(item)}}
            </template>
            <template #item.success="{item}">
              <v-icon :color="item.success ? 'success' : 'error'">
                {{item.success ? 'mdi-checkbox-marked-circle' : 'mdi-close'}}
              </v-icon>
            </template>
            <template #item.error="{item}">
              {{parseServerError(item.error)}}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver'
import { parseCsv } from '../../../utils/csv/parse.js'
export default {
  name: 'CsvImport',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    parse: {
      type: Object,
      required: true,
      validator: ({ schema, context, map, validate }) => schema
    },
    preview: {
      type: Object,
      required: true,
      validator: ({ headers, map = undefined }) => headers && (!map || typeof map === 'function')
    },
    upload: {
      type: Object,
      required: true,
      validator: ({ map, action }) => action && (map === undefined || typeof map === 'function' || (map.mapAll && map.equality))
    },
    exampleData: {
      type: String,
      required: false,
      default: ''
    },
    entityName: {
      type: String,
      required: true
    },
    rowName: {
      type: [String, Function],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data: () => ({
    csvFile: undefined,
    errors: {
      csv: [],
      lines: {}
    },
    result: [],
    csvData: undefined,
    displayedFileName: undefined,
    showPreview: false
  }),

  watch: {
    csvFile (val) {
      if (val) {
        const extension = val.name.split('.').pop()
        if (extension !== 'csv') {
          this.setSnackError(this.$t('fileMustBeValidCSV'))
          this.activityTemplateCSV = null
        }
        this.handleCsvFile(val)
      } else {
        this.csvData = undefined
        this.showPreview = false
        this.errors.csv = []
        this.errors.lines = {}
      }
    }
  },

  computed: {
    hasParseError () {
      return this.errors.csv.length > 0 || (Object.keys(this.errors.lines)).length > 0
    },

    resultTableHeaders () {
      return [
        {
          text: this.$t('success'),
          value: 'success',
          align: 'left'
        },
        {
          text: this.$t('entityName', { entity: this.entityName }),
          value: 'name'
        },
        {
          text: this.$t('error'),
          value: 'error'
        }
      ]
    },

    lineErrors () {
      return Object.keys(this.errors.lines).map(lineNumber => ({
        blah: 0,
        lineNumber,
        errors: this.errors.lines[lineNumber]
      }))
    }
  },

  methods: {
    async getCsvSchema () {
      const { schema } = this.parse
      if (typeof schema === 'object') return schema
      if (typeof schema !== 'function') throw new Error('Invalid CSV schema type')
      return await schema()
    },

    getRowName (row) {
      const name = (typeof this.rowName === 'string')
        ? row?.[this.rowName]
        : this.rowName(row)

      return name || this.$t('unknown')
    },

    async onClickImport () {
      this.showPreview = false
      if (this.upload.map) {
        await this.uploadMapped(this.csvData, this.upload.map)
      } else {
        await this.uploadNonmapped(this.csvData)
      }
      this.result = this.csvData
      this.$emit('data-uploaded')
    },

    async uploadMapped (rows, mapImport) {
      if (typeof mapImport === 'function') {
        let index = 0
        for (const row of rows) {
          const rowToUpload = mapImport(row, index, rows)
          const { success, error } = await this.uploadData(rowToUpload)
          row.success = success
          row.error = error
          index += 1
        }
      } else {
        const { mapAll, equality } = mapImport
        const dataToUpload = await mapAll(rows)
        for (const dtu of dataToUpload) {
          const { success, error } = await this.uploadData(dtu)
          for (const row of rows.filter(r => equality(r, dtu))) {
            row.success = success
            row.error = error
          }
        }
      }
    },

    async uploadNonmapped (rows) {
      for (const row of rows) {
        const { success, error } = await this.uploadData(row)
        row.success = success
        row.error = error
      }
    },

    async uploadData (data) {
      try {
        await this.upload.action(data)
        return { success: true, error: undefined }
      } catch (error) {
        return { success: false, error }
      }
    },

    async handleCsvFile () {
      this.showPreview = false
      this.errors.csv = []
      this.errors.lines = {}
      this.result = []
      this.displayedFileName = undefined

      const fileContent = await this.readFileAsString(this.csvFile)
      let csvSchema
      try {
        csvSchema = await this.getCsvSchema()
      } catch (e) {
        console.error(e)
        return
      }

      const parsed = parseCsv(fileContent, csvSchema)

      let parseResult = parsed

      if (!parsed.isError) {
        const { context, map, validate } = this.parse
        const ctx = typeof context === 'function' ? await context(parsed.data) : context

        if (map) {
          parseResult = await parseResult.mapAsync(map, ctx)
        }
        if (validate) {
          parseResult = await parseResult.validateAsync(validate, ctx)
        }
      }

      this.displayedFileName = this.csvFile.name
      if (parseResult.isError) {
        this.errors.csv = parseResult.errors
        this.errors.lines = parseResult.lineErrors
      } else {
        this.csvData = parseResult.data
        this.showPreview = true
        this.$emit('parse-result', parseResult.data)
      }
    },

    async readFileAsString (file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('error', reject)
        reader.addEventListener('load', () => { resolve(reader.result) })
        reader.readAsText(file)
      })
    },

    downloadExampleData () {
      saveAs(this.exampleData, `Example${this.entityName.replace(/\s+/, '')}Import.csv`)
    },

    parseServerError (errorData) {
      const errorMessageData = errorData?.response?.data

      if (errorMessageData === undefined) {
        return this.$t('notAvailable')
      }

      const { message, code } = errorMessageData

      if (message === undefined || code === undefined) {
        return this.$t('notAvailable')
      }

      return `${message} | ${this.$t('code')}: ${code}`
    },

    // This allows users to upload the same file after changing its contents.
    // Without this, the file input component will not update the data from the same file.
    clearFile () {
      this.csvFile = undefined
    }
  }
}

</script>
