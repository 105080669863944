<template>
  <v-container fluid id="timber-portfolio">
    <v-row>
      <v-col cols="12">
        <v-row justify="end" align="center" dense>
          <v-col id="timber-portfolio-filter-chips">
            <v-chip v-for="fk in activeFilters" :key="fk.key" color="secondary" class="white--text mr-1 mb-1" close @click:close="removeFilterField(fk.key)">
              <span style="font-weight: bold;">{{$t(fk.label)}}</span>
              <span v-if="!!fk.text">{{`: ${fk.text}`}}</span>
            </v-chip>
          </v-col>
          <v-col cols="auto">
            <Icon
            :small="false"
            icon="mdi-account-circle"
            margin=""
            :tooltipText="timberPortfolioFilter.myTracts ? $t('allTracts') : $t('myTracts')"
            elementId="my-tracts-timber-portfolio"
            @icon-clicked="myTractsClicked"
            />
          </v-col>
          <v-col cols="auto">
            <TimberPortfolioFilter
            @input="applyFilters"
            />
          </v-col>
          <v-col cols="auto">
            <Icon
            icon="mdi-refresh"
            :small="false"
            @icon-clicked="refreshTracts"
            :tooltipText="$t('refresh')"
            margin="ml-n2"/>
          </v-col>
          <v-col cols="auto">
            <ColumnAdjuster
            :propColumnSet.sync="tractsByProductHeaders"
            @new-columns-selected="columnsSelected" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-progress-linear rounded indeterminate class="mb-n4" v-if="loading"></v-progress-linear>
      </v-col>
      <v-col cols="12" lg="" class="fill-width timber-portfolio-product-list" style="min-width: 0; max-height: calc(100vh - 368px); overflow: auto;">
        <v-expansion-panels popout accordion tile v-model="panel" active-class="whiteStroke" class="secondaryStroke">
          <v-expansion-panel v-for="product in timberPortfolio.products" :key="product.name">
            <v-expansion-panel-header :style="$vuetify.breakpoint.xs ? '' : 'height: 64px'">
              <v-container fluid class="pl-0">
                <v-row align="center" no-gutters>
                  <v-col cols="12" sm="" class="pr-2">
                    {{ product.name }}
                  </v-col>
                  <v-col cols="auto" :style="!$vuetify.breakpoint.xs ? { width: '180px' } : {}" class="body-2 py-1">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-tooltip left color="black" nudge-top="10">
                          <template #activator="{ on }">
                            <v-row no-gutters v-on="on">
                              <v-col>
                                <span >{{ $t('available') }}</span>
                              </v-col>
                              <v-col cols="auto" class="pl-2">
                                {{ numberWithCommas(product.available, 3) }}
                              </v-col>
                            </v-row>
                          </template>
                          <span class="subtitle-1 white--text">{{ $t('remainingTons') }}</span>
                        </v-tooltip>
                        <v-tooltip left color="black" nudge-bottom="20">
                          <template #activator="{ on }">
                            <v-row no-gutters v-on="on">
                              <v-col>
                                <span>{{ $t('allocated') }}</span>
                              </v-col>
                              <v-col cols="auto" class="pl-2">
                                {{ numberWithCommas(product.allocated, 3) }}
                              </v-col>
                            </v-row>
                          </template>
                          <span class="subtitle-1 white--text">{{ $t('timberPortfolioAllocatedExplanation') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto" style="width: 40px;">
                    <v-tooltip bottom color="black">
                      <template #activator="{ on }">
                        <v-progress-circular
                        :rotate="-90"
                        class="ml-2"
                        width="14"
                        size="40"
                        color="success"
                        :value="product.allocated / product.available * 100 || 0"
                        v-on="on"
                        />
                      </template>
                      <span class="subtitle-1 white--text">{{ product.available ? convertToPercentage(product.allocated / product.available, 0) : $t('notApplicable') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                dense
                class="pt-2"
                :items="product.tracts"
                :headers="columnSet"
                item-key="name"
                :hide-default-footer="product.tracts.length <= 10"
                :items-per-page="15"
                :footer-props="{ itemsPerPageOptions: [10, 15, 25, -1] }"
              >
                <template #item.status="{item}">
                  <TractStatusIcon :status="item.status"/>
                </template>
                <template #item.remainingTons="{item}">
                  {{ numberWithCommas(item.remainingTons, 3) }}
                </template>
                <template #item.daysRemaining="{item}">
                  {{ numberWithCommas(item.daysRemaining, 0) }}
                </template>
                <template #item.related="{item}">
                  <v-row no-gutters justify="center">
                    <v-col cols="auto">
                      <Icon
                      :small="false"
                      icon="mdi-information-outline"
                      dataTestId="tract-detail"
                      :tooltipText="$t('tractDetail')"
                      @icon-clicked="openTractDetail(item.tractId)"/>
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip color="black" bottom>
                        <template #activator="{on}">
                          <div @mouseleave="resetTooltipData" v-on="on">
                            <v-icon color="black" @click="destinationIconClicked(product.productId, item.tractId)">
                              mdi-domain
                            </v-icon>
                          </div>
                        </template>
                        <p v-for="destination in destinationTooltip" :key="destination" class="subtitle-1 white--text my-0">{{ destination }}</p>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
                <template v-for="h in columnSet.filter(h => h.tooltip)" v-slot:[`header.${h.value}`]>
                  <v-tooltip :key="h.value" color="primary" top>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ h.text }}</span>
                    </template>
                    <span class="subtitle-1 white--text" style="white-space: pre-line">{{ h.tooltip }}</span>
                  </v-tooltip>
                </template>
                <template #item.loggerAccountName="{item}">
                  <AccountAutocomplete
                  v-if="!item.loggerAccountName && !loading"
                  hideRefresh
                  :fetchTagsAndAccounts="false"
                  :title="$t('logger')"
                  :accountId="loggerSelectionByTractId.get(item.tractId)"
                  :propSelectedTags="selectedTags"
                  @account-chosen="accountChosen(item.tractId, $event)"
                  :key="item.tractId"
                  singleLine
                  class="ma-n1 mb-n2"
                  style="min-width: 250px;"
                  isQuickAssign
                  >
                  <template v-slot:append>
                    <v-chip v-for="tag in selectedTags" x-small :key="tag.tagId" close @click:close="tagClicked(tag)">
                      {{tag.value}}
                    </v-chip>
                  </template>
                  <template v-slot:append-outer>
                    <v-menu
                    offset-y
                    style="max-width: 600px"
                    :close-on-content-click="false"
                    >
                      <template #activator="{on}">
                        <v-btn
                        icon
                        v-on="on"
                        class="my-n2 ml-n4 mr-n2"
                        color="primary"
                        tabindex="-1"
                        :disabled="tags.length < 1"
                      >
                        <v-icon>mdi-filter</v-icon>
                      </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="tagClicked(tag)" v-for="tag in tags" :key="tag.tagId">
                          <v-list-item-title>
                            {{tag.value}}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn
                      icon
                      class="my-n2 mr-n2"
                      color="primary"
                      tabindex="0"
                      @click="saveTract(item.tractId)"
                      :disabled="!loggerSelectionByTractId.get(item.tractId)"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                </AccountAutocomplete>
                <span v-else-if="item.loggerAccountName">{{ item.loggerAccountName }}</span>
                <v-skeleton-loader v-else type="text" class="pt-6 pb-3" width="242"/>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-skeleton-loader type="image" height="64" v-if="!timberPortfolio.products"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" lg="4" xl="3">
        <v-data-table
        id="timber-portfolio-logger-table"
        mobile-breakpoint="400"
        v-if="timberPortfolio.loggers"
        dense
        :hide-default-footer="loggers.length <= 10"
        :items-per-page="15"
        item-key="name"
        :items="loggers"
        :headers="loggerAllocationHeaders"
        :footer-props="{ itemsPerPageOptions: [10, 15, 25, -1] }"
        @click:row="loggerRowClicked">
          <template #item.name="{item}">
            <span id="table-shortcut">{{item.name}}</span>
          </template>
          <template #item.totalAllocated="{item}">
            {{ numberWithCommas(item.totalAllocated, 3) }}
          </template>
          <template #item.allocation="{item}">
            <v-tooltip bottom color="black">
              <template #activator="{ on }">
                <v-progress-linear :value="item.allocation" v-on="on" height="16" rounded color="success" background-color="rgba(0,0,0,0.1)" style="box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.1);"/>
              </template>
              <p class="subtitle-1 white--text my-0">{{ `${numberWithCommas(item.active, 3)} ${$t('active')}\n` }}</p>
              <span class="subtitle-1 white--text">{{ `${numberWithCommas(item.totalAllocated - item.active, 3)} ${$t('planned')}` }}</span>
            </v-tooltip>
          </template>
          <template v-for="h in loggerAllocationHeaders.filter(h => h.tooltip)" v-slot:[`header.${h.value}`]>
            <v-tooltip :key="h.value" color="primary" top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ h.text }}</span>
              </template>
              <span class="subtitle-1 white--text" style="white-space: pre-line">{{ h.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-container fluid class="ma-0 pa-0" v-else>
          <v-row dense>
            <v-col cols="12">
              <v-skeleton-loader type="heading"/>
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader type="text"/>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId"
    maxWidth="2500"
    @click:outside="closeDialog"
    @dialog-closing="closeDialog"
    @close="closeDialog">
      <TractDetail
      v-if="focusedTractId"
      @edit-tract="editTract"
      :tractId="focusedTractId"
      :tabToOpen="1"/>
      <LoggerAllocation
      v-if="focusedLoggerId"
      :timberPortfolio="timberPortfolio"
      :focusedLoggerId="focusedLoggerId"
      @tract-detail-clicked="tractDetailClicked"
      />
    </Dialog>
  </v-container>
</template>

<script>

import { uniqueDialogId } from '@/utils/componentHelpers'
import { numberWithCommas, convertToPercentage } from '@/utils/NumericMutations.js'
import tractHeaders from '@/headers/Tract.js'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { TractStatus } from '@/utils/Enumerations'
import { DataTableColumn, ColumnSet } from '@/model/DataTableColumn.js'
import { SETTING_TYPES, SETTING_KEYS } from '@/utils/UserSettings.js'
import RouterJump from '@/model/RouterJump.js'

export default {
  name: 'TimberPortfolio',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    TractStatusIcon: () => import('@/components/tract/TractStatusIcon.vue'),
    TimberPortfolioFilter: () => import('@/components/tract/tract-analysis/TimberPortfolioFilter.vue'),
    TractDetail: () => import('@/components/tract/tract-detail/TractDetail.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    AccountAutocomplete: () => import('@/components/autocomplete/AccountAutocomplete.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    LoggerAllocation: () => import('@/components/tract/tract-analysis/LoggerAllocation.vue')
  },

  data: () => ({
    loadingTags: true,
    dialogId: uniqueDialogId('timber-portfolio'),
    focusedTractId: null,
    timberPortfolio: {},
    filters: {
      status: [],
      tractForester: [],
      tractType: [],
      team: [],
      myTracts: false
    },
    columnSet: null,
    panel: undefined,
    loggerSelectionByTractId: new Map(),
    tags: [],
    selectedTags: [],
    showTagMenu: false,
    filteredAccounts: undefined,
    destinationTooltipByTractId: new Map(),
    destinationTooltip: undefined,
    focusedLoggerId: undefined
  }),

  computed: {
    ...mapGetters('tract', ['timberPortfolioFilter', 'tractsLoading']),
    ...mapGetters('account', ['allAccounts']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),

    loading () {
      return this.tractsLoading || this.loadingTags
    },

    tractsByProductHeaders () {
      return tractHeaders.timberPortfolioTractForProductHeaders()
    },

    activeFilters () {
      const keys = Object.keys(this.timberPortfolioFilter)
      return keys.map(key => ({
        key,
        label: key === 'tractForester' ? 'forester' : key,
        filter: this.timberPortfolioFilter[key],
        text: this.getFilterChipText(key, this.timberPortfolioFilter[key])
      }))
        .filter(kf => {
          switch (kf.label) {
            case 'myTracts':
              return kf.filter === true
            case 'forester':
            case 'tractType':
            case 'team':
            case 'status':
            default:
              return kf.filter.length > 0
          }
        })
    },

    loggers () {
      if (this.timberPortfolio?.loggers) return this.timberPortfolio.loggers.filter(l => l.accountId !== 0 && l.name.length > 0)
      return []
    },

    loggerAllocationHeaders () {
      const columns = [new DataTableColumn({
        id: 0,
        text: this.$t('logger'),
        value: 'name',
        tooltip: this.$t('logger')
      }),
      new DataTableColumn({
        id: 1,
        text: this.$t('allocated'),
        value: 'totalAllocated',
        tooltip: this.$t('totalAllocatedTons'),
        align: 'right'
      }),
      new DataTableColumn({
        id: 2,
        text: this.$t('allocation'),
        value: 'allocation',
        tooltip: this.$t('allocation')
      })]

      return new ColumnSet({
        columns: columns
      })
        .columns
    }
  },

  watch: {
    timberPortfolioFilter: {
      handler () {
        this.refreshTracts()
      },
      deep: true
    },

    selectedTags () {
      const createMutatedSetting = (type, key, value) => ({ type, key, value })

      this.mutateUserSetting(
        createMutatedSetting(
          SETTING_TYPES.TRACT_FORM,
          SETTING_KEYS.DEFAULT_ACCOUNT_AUTOCOMPLETE,
          this.selectedTags.map(tag => tag.tagId))
      )

      this.updateUserSettings(this.mutatedUserSettings)
    }
  },

  async created () {
    this.resetTooltipData()
    const [tags] = await Promise.all([
      this.fetchTags(0),
      this.fetchAccounts()
    ])
    this.tags = tags
    this.getLoggerAutocompleteTags()
    this.columnSet = this.tractsByProductHeaders.getColumns()
    this.loadingTags = false
  },

  methods: {
    numberWithCommas,
    convertToPercentage,

    ...mapActions('tract', ['fetchTimberPortfolio', 'setTimberPortfolioFilter', 'fetchTract', 'updateTract']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tags', ['fetchTags']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapActions('tract-destination', ['fetchTractDestinations']),

    ...mapMutations('global', ['setRouterJump']),
    ...mapMutations('user-settings', ['mutateUserSetting']),

    async refreshTracts () {
      this.timberPortfolio = await this.fetchTimberPortfolio({
        statuses: this.timberPortfolioFilter.status,
        tractTypes: this.timberPortfolioFilter.tractType,
        tractForesters: this.timberPortfolioFilter.tractForester,
        teams: this.timberPortfolioFilter.team,
        myTracts: this.timberPortfolioFilter.myTracts
      })
      this.generateFields()
    },

    applyFilters (filters) {
      this.filters = filters
    },

    generateFields () {
      if (this.timberPortfolio?.loggers) {
        this.timberPortfolio.loggers = this.timberPortfolio.loggers.map(l => ({
          ...l,
          allocation: l.active / l.totalAllocated * 100
        }))
      }
    },

    getFilterChipText (key, filter) {
      switch (key) {
        case 'status':
          return filter.map(f => TractStatus[f].name).join(', ')
        case 'tractType':
        case 'tractForester':
        case 'team':
          return filter.map(val => val.name).join(', ')
        case 'myTracts':
        default:
          return ''
      }
    },

    async myTractsClicked () {
      this.setTimberPortfolioFilter({
        ...this.timberPortfolioFilter,
        myTracts: !this.timberPortfolioFilter.myTracts
      })
    },

    async removeFilterField (fieldKey) {
      let removedValue = []
      switch (fieldKey) {
        case 'myTracts':
          removedValue = false
          break
        case 'status':
        case 'tractType':
        case 'tractForester':
        case 'team':
        default:
          removedValue = []
          break
      }

      const f = {
        ...this.timberPortfolioFilter,
        [fieldKey]: removedValue
      }

      this.setTimberPortfolioFilter(f)
    },

    getLoggerAutocompleteTags () {
      this.selectedTags = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.DEFAULT_ACCOUNT_AUTOCOMPLETE]
        .map(tagId => this.tags.find(t => t.tagId === tagId))
        .filter(t => t)
      this.filterAccounts()
    },

    openTractDetail (id) {
      this.focusedTractId = id
      this.openOrUpdateDialog(this.dialogId)
    },

    closeDialog () {
      this.focusedTractId = undefined
      this.focusedLoggerId = undefined
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    editTract () {
      const routerJump = new RouterJump('TimberPortfolio', 'Tracts', {
        tractId: this.focusedTractId
      })
      this.setRouterJump(routerJump)
      this.$router.push('tracts')
    },

    accountChosen (tractId, account) {
      if (account) {
        this.loggerSelectionByTractId.set(tractId, account.accountId)
      } else {
        this.loggerSelectionByTractId.delete(tractId)
      }
    },

    filterAccounts () {
      this.filteredAccounts = this.allAccounts.filter(a => {
        if (this.selectedTags.length > 0) return a.accountTags.some(at => this.selectedTags.map(t => t.value).includes(at))
        return true
      })
      this.loggerSelectionByTractId.entries().forEach(e => {
        if (!this.filteredAccounts.map(a => a.accountId).includes(e[1])) {
          this.loggerSelectionByTractId.delete(e[0])
        }
      })
    },

    tagClicked (tag) {
      const tagIndex = this.selectedTags.findIndex(t => t.tagId === tag.tagId)
      if (tagIndex === -1) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags.splice(tagIndex, 1)
      }
      this.filterAccounts()
    },

    async saveTract (tractId) {
      const tract = await this.fetchTract(tractId)
      try {
        await this.updateTract({
          ...tract,
          tractTypeId: tract.type.tractTypeId,
          userIds: tract.users?.map(u => u.applicationUserId),
          loggerIds: tract.loggers?.map(l => l.accountId),
          certificationIds: tract.certifications?.map(c => c.certificationId),
          loggerAccountId: this.loggerSelectionByTractId.get(tractId)
        })
      } finally {
        await this.refreshTracts()
      }
    },

    async destinationIconClicked (productId, tractId) {
      const response = await this.fetchTractDestinations(tractId)
      const result = response
        .filter(d => d.products.some(p => p.productId === productId))
        .map(d => d.destinationAccountName)

      if (result.length === 0) {
        this.destinationTooltip = [this.$t('none')]
      } else {
        this.destinationTooltip = result
      }
    },

    resetTooltipData () {
      this.destinationTooltip = [this.$t('clickFor'), this.$t('destinations')]
    },

    columnsSelected (c) {
      this.columnSet = c.getColumns()
    },

    loggerRowClicked ({ loggerAccountId }) {
      this.openLoggerDialog(loggerAccountId)
    },

    openLoggerDialog (loggerAccountId) {
      this.focusedLoggerId = loggerAccountId
      this.openOrUpdateDialog(this.dialogId)
    },

    tractDetailClicked (tractId) {
      this.focusedLoggerId = undefined
      this.focusedTractId = tractId
    }
  }

}
</script>
