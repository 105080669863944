import { DataTableColumn, ColumnSet } from '../model/DataTableColumn'
import { SETTING_KEYS } from '@/utils/UserSettings.js'
import i18n from '@/i18n'

export default {
  logsContractHeaders () {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: false,
        text: 'Status',
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        hidable: false
      }),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('tract'),
        value: 'tract',
        hidable: false
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('tractType'),
        value: 'tractType'
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('logger'),
        value: 'tractDefaultLogger'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        hidable: false
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('setting'),
        value: 'setting',
        hidable: false
      }),
      new DataTableColumn({
        id: 7,
        sortable: true,
        text: i18n.t('type'),
        value: 'type'
      }),
      new DataTableColumn({
        id: 8,
        sortable: true,
        text: i18n.t('effective'),
        value: 'effectiveDate',
        align: 'center'
      }),
      new DataTableColumn({
        id: 9,
        sortable: true,
        text: i18n.t('expires'),
        value: 'expirationDate',
        align: 'center'
      }),
      new DataTableColumn({
        id: 10,
        sortable: false,
        text: i18n.t('related'),
        value: 'details',
        width: '10',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 11,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ]

    return new ColumnSet({
      columns: columns,
      setName: 'ContractHeaders',
      userSettingKey: SETTING_KEYS.CONTRACT_GRID_HIDDEN_COLUMNS
    })
  },

  transfersAndByproductsColumns (isByproduct) {
    const columns = [
      new DataTableColumn({
        id: 0,
        sortable: false,
        text: 'Status',
        value: 'status',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 1,
        sortable: true,
        text: i18n.t('fromAccount'),
        value: 'fromAccount',
        hidable: false
      }),
      // TODO: Restore tract after TSBP
      // ifn(isByproduct, new DataTableColumn({
      //   id: 9,
      //   sortable: true,
      //   text: i18n.t('tract'),
      //   value: 'tract',
      //   hidable: false
      // })),
      new DataTableColumn({
        id: 2,
        sortable: true,
        text: i18n.t('account'),
        value: 'account',
        hidable: false
      }),
      new DataTableColumn({
        id: 3,
        sortable: true,
        text: i18n.t('destination'),
        value: 'destination',
        hidable: false
      }),
      new DataTableColumn({
        id: 4,
        sortable: true,
        text: i18n.t('type'),
        value: 'type'
      }),
      new DataTableColumn({
        id: 5,
        sortable: true,
        text: i18n.t('effective'),
        value: 'effectiveDate',
        align: 'center'
      }),
      new DataTableColumn({
        id: 6,
        sortable: true,
        text: i18n.t('expires'),
        value: 'expirationDate',
        align: 'center'
      }),
      new DataTableColumn({
        id: 7,
        sortable: false,
        text: i18n.t('related'),
        value: 'details',
        width: '10',
        align: 'center',
        hidable: false
      }),
      new DataTableColumn({
        id: 8,
        sortable: false,
        text: i18n.t('actions'),
        value: 'actions',
        align: 'center',
        hidable: false
      })
    ].filter(h => h !== undefined)
    return columns
  },

  bpContractHeaders () {
    return new ColumnSet({
      columns: this.transfersAndByproductsColumns(true),
      setName: 'ContractHeaders',
      userSettingKey: SETTING_KEYS.BP_CONTRACT_GRID_HIDDEN_COLUMNS
    })
  },

  transferAndLysContractHeaders () {
    return new ColumnSet({
      columns: this.transfersAndByproductsColumns(false),
      setName: 'ContractHeaders',
      userSettingKey: SETTING_KEYS.TRANSFERS_CONTRACT_GRID_HIDDEN_COLUMNS
    })
  },

  ticketImportHeaders (isByproduct, isUpdate) {
    return [
      {
        text: i18n.t('ticketNumber'),
        value: 'ticketNumber'
      },
      {
        text: i18n.t('product'),
        value: 'product',
        align: 'center'
      },
      {
        text: i18n.t('departureWeight'),
        value: 'departureWeight',
        align: 'right',
        require: isByproduct
      },
      {
        text: i18n.t('inWeight'),
        value: 'inWeight',
        align: 'right'
      },
      {
        text: i18n.t('outWeight'),
        value: 'outWeight',
        align: 'right'
      },
      {
        text: i18n.t('defectWeight'),
        value: 'defectWeight',
        align: 'right'
      },
      {
        text: i18n.t('pieces'),
        value: 'pieces',
        align: 'right',
        require: !isByproduct
      },
      {
        text: i18n.t('averageLength'),
        value: 'averageLength',
        align: 'right',
        require: !isByproduct
      },
      {
        text: i18n.t('extTicketNum1'),
        value: 'extTicketNumber1',
        align: 'center'
      },
      {
        text: i18n.t('extTicketNum2'),
        value: 'extTicketNumber2',
        align: 'center'
      },
      {
        text: i18n.t('driver'),
        value: 'driver',
        align: 'center',
        require: !isByproduct && !isUpdate
      }
    ].filter(c => c.require ?? true)
  },
  csvHeaders (locale, isLogsMode) {
    const headers = [
      {
        text: i18n.t('status'),
        value: 'status'
      },
      {
        text: i18n.t('externalOrInternal'),
        value: 'isExternal'
      },
      {
        text: i18n.t('requiresLogger'),
        value: 'requiresLogger'
      },
      {
        text: i18n.t('acceptsAnyLoad'),
        value: 'acceptsAnyLoad'
      },
      {
        text: i18n.t('draft'),
        value: 'isDraft'
      },
      {
        text: i18n.t('account'),
        value: 'account'
      },
      {
        text: i18n.t('destination'),
        value: 'destination'
      },
      {
        text: i18n.t('type'),
        value: 'type'
      },
      {
        text: i18n.t('effectiveDate'),
        value: 'effectiveDate'
      },
      {
        text: i18n.t('expirationDate'),
        value: 'expirationDate'
      }
    ]
    if (isLogsMode === true) {
      headers.splice(6, 0, ...[
        {
          text: i18n.t('tract'),
          value: 'tract'
        },
        {
          text: i18n.t('tractType'),
          value: 'tractType'
        }
      ])
    } else {
      headers.splice(6, 0,
        {
          text: i18n.t('fromAccount'),
          value: 'fromAccount'
        }
      )
    }

    return headers
  }
}
