<template>
  <v-card flat>
    <v-container fluid>
      <!-- Activity Payments -->
      <v-row v-if="payment.activities.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('contractPayments')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
          :items="payment.activities"
          :headers="headers"
          :propDense="true"
          :hideHeader="true"
          :customCells="customPayableCells"
          :actions="[]"
          >
            <template #weight="{item}">
              {{ tonStringFromPounds(item.weight) }}
            </template>
            <template #payout="{item}">
              {{ getPayoutString(item) }}
            </template>
            <template #footer>
              <tr></tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>

      <!-- Recoveries -->
      <v-row v-if="payment.recoveries.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('recoveries')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
          :items="payment.recoveries"
          :headers="recoveryHeaders"
          :propDense="true"
          :hideHeader="true"
          :customCells="customRecoveryCells"
          :actions="[]"
        >
          <template #balance="{item}">
            {{ formatMoney(item.balance) }}
          </template>
          <template #recovered="{item}">
            {{ formatMoney(item.recovered) }}
          </template>
          <template #footer>
          </template>
        </DataTable>
        </v-col>
      </v-row>

      <!-- Tract Payments -->
      <v-row v-if="payment.tractPayments.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('tractPayments')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
          :items="payment.tractPayments"
          :headers="tractPaymentHeaders"
          :actions="[]"
          :propDense="true"
          :hideHeader="true"
          :customCells="customMiscPaymentCells"
          >
            <template #amount="{item}">
              <span>{{formatMoney(item.amount)}}</span>
            </template>
            <template #footer>
            </template>
          </DataTable>
        </v-col>
      </v-row>

      <!-- Account Payments -->
      <v-row v-if="payment.accountPayments.length > 0">
        <v-col cols="12">
          <span class="title">
            {{ $t('accountPayments') }}
          </span>
        </v-col>
        <v-col class="mt-n6">
          <DataTable
          :items="payment.accountPayments"
          :headers="accountPaymentRegisterHeaders()"
          :actions="[]"
          propDense
          hideHeader
          :customCells="customMiscPaymentCells"
          >
            <template #amount="{item}">
              <span>{{formatMoney(item.amount)}}</span>
            </template>
          </DataTable>
        </v-col>
      </v-row>

      <!-- Advances -->
      <v-row v-if="payment.advances.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('advances')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
            :items.sync="payment.advances"
            :headers="advanceHeaders"
            :propDense="true"
            :hideHeader="true"
            :customCells="customAdvanceCells"
            :actions="[]"
          >
            <template #amount="{item}">
              {{ formatMoney(item.amount) }}
            </template>
            <template #footer>
            </template>
          </DataTable>
        </v-col>
      </v-row>

      <!-- Corrections -->
      <v-row v-if="payment.correctionFinancials.length > 0">
        <v-col cols="12">
          <span class="title">
            {{$t('corrections')}}
          </span>
        </v-col>
        <v-col cols="12" class="mt-n6">
          <DataTable
            :items.sync="payment.correctionFinancials"
            :headers="correctionHeaders"
            :propDense="true"
            :hideHeader="true"
            :customCells="customCorrectionCells"
            :actions="[]"
          >
            <template #activity="{item}">
              <Icon
              dataTestId="payment-register-corrections"
              margin="mt-n1 mr-1"
              icon="mdi-auto-fix"
              :small="true"
              :iconColor="item.correctionSide === 0 ? 'error' : 'success'"
              :tooltipColor="item.correctionSide === 0 ? 'error' : 'success'"
              :tooltipText="getCorrectionTooltipText(item)"
              />
              <span>
                {{item.activity}}
              </span>
            </template>
            <template #net-weight="{item}">
              {{tonStringFromPounds(item.netWeight)}}
            </template>
            <template #body.append>
              <tr class="font-weight-bold">
                <td v-for="(_, i) in Array.from({length: correctionHeaders.length - 1})" :key="`${payment.payeeName}-${i}`"/>
                <td class="text-right">{{totalCorrected}}</td>
              </tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { tonStringFromPounds, formatMoney } from '@/utils/NumericMutations'
import { CorrectionTypes, CorrectionSide } from '@/utils/Enumerations.js'
import TractHeaders from '@/headers/Tract.js'
import { accountPaymentRegisterHeaders } from '@/headers/Account.js'
import { paymentRegisterHeaders, prRecoveryHeaders, prAdvanceHeaders, prCorrectionHeaders } from '@/headers/Cycles.js'
import { customPayableCells, customMiscPaymentCells, customRecoveryCells, customAdvanceCells, customCorrectionCells } from './CustomPaymentRegisterCells.js'
export default {
  name: 'PaymentRegisterCard',

  props: {
    payment: {
      type: Object,
      required: true
    },
    isByproduct: {
      type: Boolean,
      required: true
    },
    isExportBatch: {
      type: Boolean,
      default: false
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    customPayableCells,
    customMiscPaymentCells,
    customRecoveryCells,
    customAdvanceCells,
    customCorrectionCells
  }),

  computed: {
    headers () {
      return paymentRegisterHeaders(this.isByproduct)
    },

    recoveryHeaders () {
      return prRecoveryHeaders(this.isExportBatch)
    },

    advanceHeaders () {
      return prAdvanceHeaders()
    },

    correctionHeaders () {
      return prCorrectionHeaders({
        isByproduct: this.isByproduct,
        includeGross: true
      })
    },

    tractPaymentHeaders () {
      return TractHeaders.tractPaymentRegisterHeaders()
    },

    totalCorrected () {
      return formatMoney(this.payment.correctionFinancials.reduce((total, curr) => total + curr.amount, 0))
    }
  },

  methods: {
    tonStringFromPounds,
    formatMoney,
    accountPaymentRegisterHeaders,

    getPayoutString ({ payout, grossPayout }) {
      if (payout === grossPayout) {
        return formatMoney(payout)
      } else {
        return `${formatMoney(payout)} (${formatMoney(grossPayout)})`
      }
    },

    getCorrectionTooltipText ({ correctionType, correctionSide }) {
      return `${CorrectionTypes.find(ct => ct.value === correctionType).name}
        ======
        ${CorrectionSide.find(cs => cs.value === correctionSide).name}`
    },

    getAmountText ({ amount, grossAmount }) {
      if (amount === grossAmount) {
        return formatMoney(amount)
      }

      return `${formatMoney(amount)} (${formatMoney(grossAmount)})`
    }
  }
}
</script>
