<template>
  <v-card height="90vh" id="ticket-posting">
     <v-card-title
      :class="headerClass"
     >
     <v-row no-gutters align="center" justify="space-between">
      <v-col cols="auto">
        <span class="headline" style="min-width:153px">
          {{$t('ticketPosting')}}
        </span>
      </v-col>
      <v-col
        cols="auto"
        order-xl="last"
      >
        <BaseDialogActions
        @close="$emit('close')"
        hideRefresh
        @refresh="refreshData(currentTab)"/>
      </v-col>
      <v-col cols="12" xl="" justify="end">
        <v-row dense justify="end">

          <v-col cols="12" md="6" xl="auto" v-for="f in filterTypes.map((ft) => ({key: ft.key, val: selectedFilters[ft.key]}))" :key="f.key">
            <div>
              <v-select
                :prepend-inner-icon="IconHelpers.getIconForObjectType(f.key)"
                clearable
                :items="filterItems[f.key]"
                :value="filterItems[f.key]?.length === 1 ? filterItems[f.key][0] : f.val"
                return-object
                @input="setFilter(f.key, $event)"
                outlined
                rounded
                dense
                dark
                color="white"
                :label="$t(f.key)"
                class="mb-n6 mr-2"
                style="min-width:260px"
                v-show="currentTab === 0"
                :id="`ticketPostingFilter${$t(f.key)}`"
              >
                <template #item="{ item, on }">
                  <v-list-item v-on="on">
                    <span>{{item}}</span>
                  </v-list-item>
                </template>
                <template #selection="{ item } ">
                  <template>
                    <span>{{item}}</span>
                  </template>
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-col>
     </v-row>
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="confirmPostingDialog" v-if="confirmPostingDialog" width="400px">
        <ConfirmDialog
          :title="$t('postTickets')"
          body=""
          :confirmText="$t('postTickets')"
          :color="headerClass"
          @confirm="savePostedTickets"
          @cancel="handlePersist(false); confirmPostingDialog = false"
          @hook:created="handlePersist(true)"
          @hook:destroyed="handlePersist(false)"
        >
          <template #custom-body>
            <p class="black--text subtitle-1">{{ confirmPostingDialogBody }}</p>
          </template>
        </ConfirmDialog>
      </v-dialog>
      <v-dialog v-model="confirmUnpostingDialog" v-if="confirmUnpostingDialog" width="400px">
        <ConfirmDialog
          :title="$t('unpostTickets')"
          body=""
          :confirmText="$t('unpostTickets')"
          :color="headerClass"
          @confirm="unpostSelectedTickets"
          @cancel="handlePersist(false); confirmUnpostingDialog = false"
          @hook:created="handlePersist(true)"
          @hook:destroyed="handlePersist(false)"
        >
          <template #custom-body>
            <p class="black--text subtitle-1">{{ confirmUnpostingDialogBody }}</p>
          </template>
        </ConfirmDialog>
      </v-dialog>
      <v-tabs
      class="mt-4"
      v-model="currentTab">
        <v-tab>
          <span>
            {{$t('postTickets')}}
          </span>
        </v-tab>
        <v-tab>
          <v-tooltip bottom color="primary">
            <template #activator="{on}">
              <span v-on="on" style="height: 100%; align-content: center">
                {{$t('postedTickets')}}
              </span>
            </template>
            <span class="subtitle-1 white--text" style="white-space: pre-line">{{$t('ticketsPostedInLast30Days')}}</span>
          </v-tooltip>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" v-show="!postBatchLoader.loading" class="mt-4" touchless>
        <v-tab-item class="fill-height">
          <PostTickets
          :contractMode="contractMode"
          @tickets-selected="ticketsSelectedForPosting"
          @posting-group-selected="postingGroupSelected"
          :postingGroupFilter="selectedFilters"
          @refresh="refreshData(currentTab)"
          @refresh-single-ticket="refreshSingleTicket"
          @persist="handlePersist"
          />
        </v-tab-item>
        <v-tab-item>
          <UnpostTickets
          v-if="!postBatchLoader.loading"
          :contractMode="contractMode"
          @tickets-selected="ticketsSelectedForUnposting"
          @refresh="refreshData(currentTab, true)"
          @post-batch-selected="postBatchSelected"/>
        </v-tab-item>
      </v-tabs-items>
      <v-container fluid v-show="postBatchLoader.loading" class="fill-height">
        <v-row align="center" justify="center">
          <v-progress-circular indeterminate color="primary"/>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row v-if="currentTab === 0" dense align="center" justify="end">
       <v-col cols="auto">
          <span>{{ selectedForPostingText }}</span>
        </v-col>
        <v-col cols="auto" v-if="showSelectAllFiltered">
          <v-checkbox v-model="postAllFiltered" color="primary" :label="$t('postAllFiltered')" :disabled="filteredTickets.length === 0"/>
        </v-col>
        <v-col cols="auto" v-else>
          <v-checkbox v-model="postAllInPeriod" color="primary" :label="$t('postAllInPeriod')"/>
        </v-col>
        <v-col cols="auto">
          <v-btn
          data-testid="post-post"
          :class="headerClass"
          :disabled="selectedTicketsForPosting.length === 0 && !postAllInPeriod && !postAllFiltered"
          @click="confirmPostingDialog = true">
            {{postBtnText}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="currentTab === 1" dense align="center" justify="end">
        <v-col cols="auto">
          <v-checkbox data-testid="post-all-check" v-model="unpostAllInPeriod" color="primary" :label="$t('unpostAllInPeriod')"/>
        </v-col>
        <v-col cols="auto">
          <v-btn
          data-testid="post-unpost"
          :class="headerClass"
          :disabled="selectedTicketsForUnposting.length === 0 && !unpostAllInPeriod"
          @click="confirmUnpostingDialog = true">
            {{unpostBtnText}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ResourceLoader } from '@/utils/ResourceLoader'
import { ticketFiltersForPosting } from '@/utils/TicketFiltering'
import IconHelpers from '@/utils/IconHelpers'
import { colorClassForContractMode } from '@/utils/componentHelpers'

export default {
  name: 'Posting',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    PostTickets: () => import('@/components/ticket/ticket-posting/PostTickets.vue'),
    UnpostTickets: () => import('@/components/ticket/ticket-posting/UnpostTickets.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    contractMode: {
      type: Object
    }
  },

  data: () => ({
    IconHelpers,
    currentTab: 0,
    postAllInPeriod: false,
    postAllFiltered: false,
    unpostAllInPeriod: false,
    confirmPostingDialog: false,
    confirmUnpostingDialog: false,
    shouldRefreshOtherTab: false,
    selectedTicketsForPosting: [],
    selectedTicketsForUnposting: [],
    postingGroup: undefined,
    postBatch: undefined,
    postBatchLoader: ResourceLoader.empty,
    selectedFilters: {},
    filterItems: {}
  }),

  computed: {
    ...mapGetters('locations', ['allLocations']),

    postBtnText () {
      return this.$t('postTickets')
    },

    unpostBtnText () {
      return this.$t('unpostTickets')
    },

    selectedForPostingText () {
      if (!this.postingGroup) return ''

      let selected
      if (this.postAllInPeriod) selected = this.postingGroup.tickets.length
      else if (this.postAllFiltered) selected = this.filteredTickets.length
      else selected = this.selectedTicketsForPosting.length

      const config = {
        totalTickets: this.postingGroup.tickets.length,
        selectedTickets: selected
      }

      return this.$t('selectedForPosting', config)
    },

    confirmPostingDialogBody () {
      if (!this.postingGroup) return ''
      const allTicketsSelected = (this.postingGroup.tickets.length === this.selectedTicketsForPosting.length) || this.postAllInPeriod
      return allTicketsSelected ? this.$t('postingAllInPeriodBody') : this.$t('postingSelectedInPeriodBody')
    },

    confirmUnpostingDialogBody () {
      if (!this.postBatch) return ''
      const allTicketsSelected = (this.postBatch.ticketCount === this.selectedTicketsForUnposting.length) || this.unpostAllInPeriod
      return allTicketsSelected ? this.$t('unpostingAllInPeriodBody') : this.$t('unpostingSelectedInPeriodBody')
    },

    headerClass () {
      return colorClassForContractMode(this.contractMode.value)
    },

    filterTypes () {
      return ticketFiltersForPosting(this.contractMode.value === 0)
    },

    filteredTickets () {
      return this.postingGroup.tickets.filter((t) => Object.keys(this.selectedFilters)
        .filter(filterKey => this.selectedFilters[filterKey])
        .every(filterKey => t[filterKey] === this.selectedFilters[filterKey]))
    },

    showSelectAllFiltered () {
      return Object.keys(this.selectedFilters).some(filterKey => this.selectedFilters[filterKey])
    }
  },

  watch: {
    currentTab (val) {
      this.postAllInPeriod = false
      this.unpostAllInPeriod = false
      this.postAllFiltered = false

      if (this.shouldRefreshOtherTab) {
        this.refreshData(val)
        this.shouldRefreshOtherTab = false
      }
    },

    postAllInPeriod (val) {
      if (val) this.postAllFiltered = false
    },

    postAllFiltered (val) {
      if (val) this.postAllInPeriod = false
    },

    selectedFilter (val) {
      if (!val) {
        this.postAllFiltered = false
      } else {
        this.postAllInPeriod = false
      }
    }

  },

  async created () {
    this.refreshData(0, true)
  },

  methods: {
    ...mapActions('posting', ['fetchPostBatches', 'fetchPostingGroups', 'postTickets', 'unpostTickets']),
    ...mapActions('ticket', ['fetchTicketById']),
    ...mapActions('account', ['fetchAccounts']),
    ...mapActions('tract', ['fetchTractsOnce']),

    ticketsSelectedForPosting (tickets) { this.selectedTicketsForPosting = tickets },
    postingGroupSelected (postingGroup) {
      this.postingGroup = postingGroup
      this.setFilterItems()
      this.postAllInPeriod = false
      this.postAllFiltered = false
    },

    ticketsSelectedForUnposting (tickets) { this.selectedTicketsForUnposting = tickets },
    postBatchSelected (postBatch) {
      this.postBatch = postBatch
      this.unpostAllInPeriod = false
    },

    handlePersist (persist) {
      this.$emit('persist', persist)
    },

    async refreshData (tab, initialRefresh) {
      this.postBatchLoader = new ResourceLoader(async () => {
        if (initialRefresh) {
          this.shouldRefreshOtherTab = true
        }
        if (tab === 0) {
          await this.fetchPostingGroups(this.contractMode.value)
        } else if (tab === 1) {
          await this.fetchPostBatches(this.contractMode.value)
        }
      })

      this.postBatchLoader.load()
    },

    async refreshSingleTicket (ticketId) {
      const ticket = await this.fetchTicketById(ticketId)
      const ticketIndex = this.postingGroup?.tickets.findIndex(t => t.ticketId === ticketId)

      if (ticketIndex !== -1) {
        this.postingGroup.tickets.splice(ticketIndex, 1, ticket)
      }
    },

    async savePostedTickets () {
      let ticketsToPost

      if (this.postAllInPeriod) ticketsToPost = this.postingGroup.tickets
      else if (this.postAllFiltered) ticketsToPost = this.filteredTickets
      else ticketsToPost = this.selectedTicketsForPosting

      if (ticketsToPost.length === 0) { return }

      const payload = {
        ticketIds: ticketsToPost.map(t => t.ticketId),
        payPeriodId: this.postingGroup.period.payPeriodId
      }

      await this.postTickets(payload)
      this.confirmPostingDialog = false
      this.selectedTicketsForPosting = []
      this.shouldRefreshOtherTab = true

      this.refreshData(this.currentTab)
    },

    async unpostSelectedTickets () {
      const consumedTickets = this.selectedTicketsForUnposting.filter(t => t.consumed)
      if (consumedTickets.length !== 0) {
        this.setSnackError(this.$t('consumedTicketNumbersUnposting', { ticketNumbers: consumedTickets.map(t => t.ticketNumber).join(', ') }))
        return
      }

      const allTicketsSelected = (this.postBatch.ticketCount === this.selectedTicketsForUnposting.length) || this.unpostAllInPeriod

      const unpostObj = {
        postBatchId: this.postBatch.postBatchId,
        ticketIds: allTicketsSelected ? undefined : this.selectedTicketsForUnposting.map(t => t.ticketId)
      }

      const results = await this.unpostTickets(unpostObj)

      if (results) {
        this.setSnack(this.$t('ticketsUnposted', { successes: results.successes, failures: results.failures }))
      }

      this.confirmUnpostingDialog = false
      this.selectedTicketsForUnposting = []
      this.shouldRefreshOtherTab = true

      this.refreshData(this.currentTab)
    },

    setFilter (filterTypeKey, filterValue) {
      if (!filterValue) {
        delete this.selectedFilters[filterTypeKey]
        this.selectedFilters = JSON.parse(JSON.stringify(this.selectedFilters)) // Watcher in PostTickets requires that the variable be reassigned
      } else {
        this.selectedFilters = { ...this.selectedFilters, [filterTypeKey]: filterValue }
      }
    },

    setFilterItems () {
      this.filterTypes.forEach(ft => {
        this.filterItems[ft.key] = [...new Set(this.postingGroup?.tickets.map(t => t[ft.key]))].filter(i => i !== null).sort((a, b) => a.localeCompare(b))
        if (this.filterItems[ft.key]?.length < 1 || !this.filterItems[ft.key].includes(this.selectedFilters[ft.key])) {
          this.setFilter(ft.key, undefined)
        }
      })
    }
  }
}
</script>
