<template>
  <v-container class="pa-0">
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">{{ title }}</span>
        <v-spacer/>
        <Icon
        icon="mdi-close"
        iconColor="white"
        :small="false"
        large
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"/>
      </v-card-title>
      <v-card-text>
        <FormWrapper :buttonText="submitText" @submit="submitInsurancePolicy()">
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                v-if="showTextField"
                v-model="insurancePolicy.name"
                maxLength="32"
                counter="32"
                color="black"
                :label="$t('insurancePolicyName')"
                ref="textField">
                  <template #append>
                    <v-icon
                    :small="false"
                    style="cursor: pointer"
                    @click="clearPolicyName()">
                      mdi-close
                    </v-icon>
                  </template>
                </v-text-field>
                <v-autocomplete
                v-else
                :label="$t('insurancePolicyName')"
                v-model="insurancePolicy.name"
                ref="autocomplete"
                @blur="autocompleteBlur"
                :items="recentlySpecifiedPolicyNames"
                @update:search-input="updateSearchInput"
                >
                  <template #append>
                    <Icon
                    icon="mdi-plus"
                    :small="false"
                    :tooltipText="$t('specifyNewInsurancePolicyName')"
                    @icon-clicked="clickNewPolicyName()"/>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <CompactDatePicker
                  :initialDate="insurancePolicy.expirationDate"
                  :label="$t('insuranceExpiryDate')"
                @date-picked="insuranceChosen"
              />
              </v-col>
              <v-col cols="12" v-if="!fileInputLoading && isEditing && !isAccountCreation">
                <v-file-input
                v-model="insuranceFile"
                underlined
                color="primary"
                :label="$t('uploadFile')"
                :disabled="importedInsuranceFile !== null"
                @change="onFileChanged"
                :hint="insuranceFileHint ?? ''"
                persistent-hint
                >
                  <template #append-outer>
                    <Icon
                    :disabled="!insuranceFile || importedInsuranceFile !== null"
                    icon="mdi-upload"
                    iconColor="primary"
                    large
                    :small="false"
                    :tooltipText="$t('importFile')"
                    @icon-clicked="uploadToBlob"
                    />
                    <Icon
                    v-if="importedInsuranceFile !== null"
                    iconColor="primary"
                    :small="false"
                    large
                    icon="mdi-download"
                    :tooltipText="$t('downloadFile')"
                    @icon-clicked="downloadFile()"
                    />
                    <Icon
                    v-if="importedInsuranceFile !== null"
                    icon="mdi-file-document-remove-outline"
                    iconColor="primary"
                    large
                    :small="false"
                    :tooltipText="$t('deleteFile')"
                    @icon-clicked="deleteInsuranceFileConfirmation = true"
                    />
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </FormWrapper>
      </v-card-text>
    </v-card>
    <v-dialog v-if="this.importedInsuranceFile" v-model="deleteInsuranceFileConfirmation" width="400px">
      <ConfirmDialog
      :title="$t('deleteFile')"
      :body="$t('deleteInsuranceFileBody', {fileName: this.importedInsuranceFile.name, insuranceName: propInsurance.name})"
      :confirmText="$t('deleteFile')"
      @confirm="confirmDeleteFile"
      @cancel="closeDialog">
      </ConfirmDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { accountFilesClient } from '../../../utils/AccountFiles'
import { saveAs } from 'file-saver'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { AccountFileCategory } from '../../../utils/Enumerations'
import { localToUTC } from '../../../utils/DateFormatter'

export default {
  name: 'InsuranceForm',

  props: {
    propInsurance: {
      type: Object,
      required: false,
      default: undefined
    },
    file: {
      type: Object,
      required: false,
      default: undefined
    },
    isAccountCreation: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    CompactDatePicker: () => import('@/components/helper/CompactDatePicker.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    insurancePolicy: {
      name: '',
      expirationDate: undefined
    },
    users: undefined,
    showTextField: false,
    recentlySpecifiedPolicyNames: [],
    autocompleteLazySearch: undefined,
    insuranceFile: null,
    importedInsuranceFile: null,
    deleteInsuranceFileConfirmation: false,
    allowedFileMimes: undefined,
    fileInputLoading: true,
    insuranceFileHint: undefined
  }),

  computed: {
    isEditing () {
      return this.propInsurance !== undefined
    },

    submitText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('addInsurancePolicy')
    },

    title () {
      return this.isEditing ? this.$t('editingInsurancePolicy') : this.$t('creatingInsurancePolicy')
    },

    insuranceFileCategory () {
      return AccountFileCategory.Insurance
    }
  },

  async created () {
    if (this.propInsurance) {
      this.insurancePolicy = {
        name: this.propInsurance.name,
        expirationDate: this.propInsurance.expirationDate
      }
      this.autocompleteLazySearch = this.insurancePolicy.name
    }

    this.recentlySpecifiedPolicyNames = await this.fetchRecentInsurancePolicyNames()
    this.showTextField = this.propInsurance || this.recentlySpecifiedPolicyNames.length === 0
    this.users = await this.getAllUsers()
    this.setFileMimes()
    this.loadFiles()
  },

  methods: {
    ...mapActions('account-insurance-policies', ['fetchRecentInsurancePolicyNames']),
    ...mapActions('user', ['getAllUsers']),

    submitInsurancePolicy () {
      if (this.insurancePolicy.name === '' || this.insurancePolicy.expirationDate === undefined) {
        this.setSnackError(this.$t('fillInsurancePolicy'))
      } else {
        this.policyValidated()
      }
    },

    insuranceChosen (date) {
      this.insurancePolicy.expirationDate = date
    },

    policyValidated () {
      const policyRequest = {
        name: this.insurancePolicy.name,
        expirationDate: localToUTC(this.insurancePolicy.expirationDate)
      }

      if (this.isEditing) {
        policyRequest.accountId = this.propInsurance.accountId
        policyRequest.accountInsurancePolicyId = this.propInsurance.accountInsurancePolicyId
        this.$emit('policy-edited', policyRequest)
      } else {
        this.$emit('policy-created', policyRequest)
      }
    },

    clickNewPolicyName () {
      this.showTextField = true
      this.insurancePolicy.name = undefined
      setTimeout(() => {
        this.$refs.textField.focus()
      }, 50)
    },

    clearPolicyName () {
      this.insurancePolicy.name = undefined
      this.autocompleteLazySearch = undefined
      if (this.recentlySpecifiedPolicyNames.length > 0) {
        this.showTextField = false
        setTimeout(() => {
          this.$refs.autocomplete.focus()
        }, 50)
      }
    },

    autocompleteBlur () {
      if (this.autocompleteLazySearch) {
        this.insurancePolicy.name = this.autocompleteLazySearch
        this.showTextField = true
      }
    },

    updateSearchInput (input) {
      if (input) {
        this.autocompleteLazySearch = input
      }
    },

    confirmDeleteFile () {
      this.deleteFile(this.importedInsuranceFile)
      this.importedInsuranceFile = null
      this.insuranceFile = null
      this.closeDialog()
    },

    closeDialog () {
      this.deleteInsuranceFileConfirmation = false
    },

    async loadFiles () {
      const loadedFiles = await accountFilesClient.getFileByPolicy(this.propInsurance, this.insuranceFileCategory)
      const loadedFile = loadedFiles && loadedFiles.length > 0 ? loadedFiles[0] : null
      if (loadedFile) {
        this.setFile(loadedFile)
      }
      this.fileInputLoading = false
    },

    setFile (file) {
      this.insuranceFile = file
      this.importedInsuranceFile = file
      this.insuranceFileHint = this.$t('uploadedDateByUser', { date: this.dateFromMilliseconds(this.importedInsuranceFile.date), user: this.userFromId(this.importedInsuranceFile.user) })
    },

    async uploadToBlob () {
      if (!this.insuranceFile) return
      if (!this.validateUpload(this.insuranceFile)) return
      await accountFilesClient.uploadAccountFile(this.insuranceFile, this.propInsurance, this.insuranceFileCategory)
      this.loadFiles()
      this.$emit('file-edited')
    },

    async deleteFile (file) {
      await accountFilesClient.deleteFile(file.ref).then(async () => {
        this.insuranceFile = null
        this.insuranceFileHint = undefined
        await this.loadFiles()
        this.deleteConfirmation = false
        this.$emit('file-edited')
      })
    },

    async downloadFile () {
      const fetchedFile = await fetch(this.importedInsuranceFile.ref)
      const blob = await fetchedFile.blob()
      saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
    },

    onFileChanged (e) {
      this.insuranceFile = e
    },

    validateUpload (file) {
      const limit = 1000 * 1000
      if (file.size > limit) { // Exceeds 1MB
        this.setSnackError(this.$t('fileTooLargeSnack', { limit: '1MB' }))
        this.insuranceFile = null
        return false
      }
      if (!this.allowedFileMimes.has(file.type)) {
        this.setSnackError(this.$t('fileMimeNotAllowedSnack'))
        this.insuranceFile = null
        return false
      }
      return true
    },

    dateFromMilliseconds (milliseconds) {
      const d = new Date(+milliseconds)
      return utcToLocalDate(d, 'MM/DD/YYYY - LT')
    },

    userFromId (id) {
      return this.users.find(u => u.applicationUserId?.toString() === id.toString())?.name
    },

    setFileMimes () {
      this.allowedFileMimes = new Set([
        'application/pdf',
        'application/msword',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'image/png',
        'image/jpeg',
        'image/heic',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.ms-word'
      ])
    }
  }
}
</script>
