<template>
  <v-card data-testid="fiscal-year-form">
    <v-card-title class="primary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('close')"
      dataTestId="fiscal-year-close-button"
      :tooltipText="$t('close')"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      :large="true"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="fiscalYearForm"
        testId="save-fiscal-year"
        :lazyValidation="true"
        :disabled="isButtonDisabled"
        :buttonText="saveBtnText"
        @submit="saveFiscalYear"
      >
        <v-container grid-list-lg class="mt-6">
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <DatePicker
              dataTestId="fiscal-year-start-date"
              :dateLabel="$t('startDate')"
              :startDate="fiscalYear.startDate"
              :disabled="this.allFiscalYears.length > 0"
              :mustBeAfter.sync="fiscalYear.startDate"
              @valid-date="(val) => {isValidStartDate = val}"
              @date-picked="startDateChosen"/>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <DatePicker
              dataTestId="fiscal-year-end-date"
              :dateLabel="$t('endDate')"
              :startDate="fiscalYear.endDate"
              :mustBeAfter.sync="fiscalYear.startDate"
              @valid-date="(val) => {isValidEndDate = val}"
              @date-picked="endDateChosen"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { mapActions, mapGetters } from 'vuex'
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import moment from 'moment'

export default {
  name: 'FiscalYearForm',

  components: {
    DatePicker: () => import('../../helper/DatePicker.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    propFiscalYear: Object,
    lastFiscalYear: Object
  },

  data: () => ({
    rules: fieldRules.rules,
    isValidStartDate: true,
    isValidEndDate: true,
    fiscalYear: {
      fiscalYearId: -1,
      startDate: '',
      endDate: ''
    }
  }),

  created () {
    if (this.propFiscalYear) {
      this.fiscalYear = {
        fiscalYearId: this.propFiscalYear.fiscalYearId,
        startDate: utcToLocalDate(this.propFiscalYear.startDate),
        endDate: utcToLocalDate(this.propFiscalYear.endDate)
      }
      return
    }
    let startDate
    if (this.lastFiscalYear) {
      startDate = utcToLocalDate(this.lastFiscalYear.endDate)
    } else {
      startDate = moment().startOf('year').format()
    }

    const endDate = moment(startDate).add(364, 'days').format()

    this.fiscalYear = {
      startDate: startDate,
      endDate: endDate
    }
  },

  computed: {
    ...mapGetters('fiscal-year', ['allFiscalYears']),

    title () {
      return this.propFiscalYear ? this.$t('updatingFiscalYear') : this.$t('creatingFiscalYear')
    },

    saveBtnText () {
      return this.propFiscalYear ? this.$t('saveChanges') : this.$t('createNewFiscalYear')
    },

    isButtonDisabled () {
      return !(this.isValidStartDate && this.isValidEndDate)
    }
  },

  methods: {
    ...mapActions('fiscal-year', ['createFiscalYear', 'updateFiscalYear']),

    startDateChosen (date) {
      this.fiscalYear.startDate = localToUTC(date)
    },
    endDateChosen (date) {
      this.fiscalYear.endDate = localToUTC(date)
    },

    validate () {
      return this.fiscalYear.startDate < this.fiscalYear.endDate
    },

    async saveFiscalYear () {
      if (!this.validate()) {
        this.setSnackError(this.$t('startDateBeforeEndDate'))
        return
      }

      const fiscalYearReqObj = {
        startDate: this.fiscalYear.startDate,
        endDate: moment.utc(this.fiscalYear.endDate).add(1, 'days').format()
      }

      if (this.propFiscalYear) {
        fiscalYearReqObj.fiscalYearId = this.propFiscalYear.fiscalYearId
        await this.updateFiscalYear(fiscalYearReqObj)
        this.$emit('fiscal-year-mutated')
      } else {
        await this.createFiscalYear(fiscalYearReqObj)
        this.$emit('fiscal-year-mutated')
      }
    }
  }
}
</script>
