<template>
  <v-card>
    <v-card-title class="primary headline white--text">
      <span class="ml-2">{{$t('integrationDetails')}}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12">
            <v-card hover>
              <v-card-title>
                <span>{{integrationStatus}}</span>
              </v-card-title>
              <v-card-text>
                <div>
                  <pre class="black--text" :style="{ fontSize: '16px', fontWeight: 'bold', whiteSpace: 'pre-wrap' }">{{detailString}}</pre>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="primary" :disabled="!canRetryIntegration" @click="$emit('click:retry')">{{$t('retry')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ExportBatchIntegrationStatus } from '@/utils/Enumerations'
import { mapGetters } from 'vuex'
import { FinancialIntegrationType, UserClaims } from '../../../utils/Enumerations'
import { userAssignedClaim } from '../../../utils/ClaimUtility'
export default {
  name: 'BatchIntegrationDetailDialog',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue')
  },

  props: {
    exportBatch: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('user', ['companyInfo']),
    integrationStatus () {
      return ExportBatchIntegrationStatus.fromInt(this.exportBatch.integrationStatus)
    },

    canRetryIntegration () {
      return !(this.integrationPending || this.integrationSuccess) && userAssignedClaim(UserClaims.GlobalAdmin)
    },

    integrationPending () {
      return this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Pending.value ||
        this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Retry.value
    },

    integrationSuccess () {
      return this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Success.value
    },

    integrationInterrupted () {
      return this.exportBatch.integrationStatus === ExportBatchIntegrationStatus.Interrupted.value
    },

    detailString () {
      if (this.integrationSuccess && this.companyInfo.financialIntegrationType === FinancialIntegrationType.CsvDrop.value && this.exportBatch.integrationDetail) {
        return this.$t('csvDataStoredInContainerPath', { container: 'angel-exports', path: this.exportBatch.integrationDetail })
      }
      if (this.integrationPending) {
        return this.$t('integrationWaitingOrInProgress')
      }
      if (this.integrationInterrupted) {
        return this.$t('integrationInterruptedWhileProcessingBatch')
      }
      try {
        const ds = JSON.stringify(JSON.parse(this.exportBatch.integrationDetail || undefined), null, 2).trim()
        return ds
      } catch (e) {
        if (e instanceof SyntaxError) {
          return this.exportBatch.integrationDetail || undefined
        }
        console.error(e)
        return undefined
      }
    }
  }
}
</script>
