<template>
  <v-card :class="cardClass" @click="onClick" :ripple="interactive">
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <v-card :color="color">
            <v-card-text>
              <v-icon color="white" x-large>{{ icon }}</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="text-right">
          <v-row no-gutters justify="end">
            <v-col cols="12" v-if="title">
              <p class="grey--text mb-0">{{ title }}</p>
            </v-col>
            <v-col cols="12" v-for="content in contentArray" :key="content.value" class="text-right">
              <v-row no-gutters align="center" justify="end">
                <v-col cols="auto">
                  <LoadableComponent :loading="loading" :width="80" type="stats-card-value">
                    <v-row no-gutters justify="end" align="center">
                      <v-col>
                        <h3 class="mb-0">{{ content.value }}</h3>
                      </v-col>
                      <v-col cols="auto" v-if="content.icon">
                        <Icon
                        :iconColor="content.icon.color ?? color"
                        :tooltipColor="content.icon.color ?? color"
                        :tooltipText="content.icon.tooltip"
                        :icon="content.icon.icon ?? icon"
                        :small="false"
                        margin="ml-2"/>
                      </v-col>
                    </v-row>
                  </LoadableComponent>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StatsCard',

  components: {
    LoadableComponent: () => import('@/components/helper/LoadableComponent.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    color: {
      type: String,
      required: false,
      default: 'secondary'
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-update'
    },
    title: {
      type: String,
      required: false,
      default: undefined
    },
    content: {
      type: [String, Number, Array, Object],
      required: false,
      default: '' /* Optional structure:
      {
        value: <String>,
        icon: {
          icon: <mdi-icon>,
          color: <color>,
          tooltip: <String>
        }
      } */
    },
    interactive: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    contentArray () {
      if (Array.isArray(this.content)) return this.content
      if (['string', 'number'].includes(typeof this.content)) {
        return [this.content].map(c => ({ value: c }))
      } else if (typeof this.content === 'object') {
        return [this.content]
      } else {
        return undefined
      }
    },

    cardClass () {
      let cardClass = 'fill-height'
      if (this.interactive) cardClass = cardClass.concat(' interactive')
      return cardClass
    }
  },

  methods: {
    onClick () {
      if (this.interactive) this.$emit('click')
    }
  }
}
</script>

<style scoped>
.v-card--link:not(.interactive) {
  cursor: unset;
}

.v-card--link:focus:before { /* This prevents clicked cards from staying gray indefinitely */
  opacity: 0;
}

.v-card--link.interactive:hover { /* Vuetify's native "hover" class keeps the element hovered even after you click and move your cursor away. This uses Vuetify elevation-6's style but avoids that bug */
  box-shadow: 0px 3px 5px -1px
    var(--v-shadow-key-umbra-opacity,
    rgba(0, 0, 0, 0.2)), 0px 6px 10px 0px
    var(--v-shadow-key-penumbra-opacity,
    rgba(0, 0, 0, 0.14)), 0px 1px 18px 0px
    var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12)) !important;
  transition: all 0.2s;
}

.v-card--link {
  transition: all 0.2s;
}
</style>
