<template>
    <v-select
      color="black"
      :menu-props="{ bottom: true, offsetY: true }"
      return-object
      item-value="advanceId"
      v-model="chosenAdvance"
      select-first
      persistent-placeholder
      :label="$t('advance')"
      :loading="advancesLoading"
      :items="accountAdvances"
    >
    <template #item="{item}">
      <v-container>
        <v-row dense justify="center">
          <v-col cols="8">
            <span>{{$t('title')}}: {{item.note || 'N/A'}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Effective: ${utcToLocalDate(item.effectiveDate)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Amount: ${formatMoney(item.amount)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Balance: ${formatMoney(item.balance)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Recovery Start Date: ${utcToLocalDate(item.recoveryStartDate)}`}}</span>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #selection="{item}">
      <v-container>
        <v-row dense justify="center">
          <v-col cols="8">
            <span>{{$t('title')}}: {{item.note || 'N/A'}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Effective: ${utcToLocalDate(item.effectiveDate)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Amount: ${formatMoney(item.amount)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Balance: ${formatMoney(item.balance)}`}}</span>
          </v-col>
          <v-col cols="4">
            <span>{{`Recovery Start Date: ${utcToLocalDate(item.recoveryStartDate)}`}}</span>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #append-outer>
      <v-icon data-testid="advance-complete-refresh-button" @click="refreshAdvances" color="primary">mdi-refresh</v-icon>
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatMoney } from '@/utils/NumericMutations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
export default {
  name: 'AdvanceAutocomplete',

  props: {
    advanceId: {
      type: Number,
      default: undefined
    },
    accountId: {
      type: Number,
      required: true
    }
  },

  watch: {
    chosenAdvance (advance) {
      this.$emit('advance-chosen', advance)
    },

    async accountId () {
      this.chosenAdvance = null
      await this.refreshAdvances()
    }
  },

  computed: {
    ...mapGetters('advances', ['allAdvancesForAccount', 'advancesLoading'])
  },

  data: () => ({
    chosenAdvance: undefined,
    accountAdvances: []
  }),

  async created () {
    await this.refreshAdvances()
  },

  methods: {
    ...mapActions('advances', ['fetchAdvancesForAccount']),
    formatMoney,
    utcToLocalDate,

    async refreshAdvances () {
      this.accountAdvances = await this.fetchAdvancesForAccount(this.accountId)
      this.chosenAdvance = this.advanceId ? this.accountAdvances.find(a => a.advanceId === this.advanceId) : this.accountAdvances[0]
    }
  }
}
</script>
