<template>
  <v-card data-testid="tract-type-form">
    <v-card-title class="primary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        testId="save-entity"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveEntity"
      >
      <v-container grid-list-lg>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="entity.name"
              ref="name"
              color="black"
              counter="64"
              maxlength="64"
              data-testid="entity-name"
              :rules="[rules.required]"
              :label="$t('name')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              v-model="entity.exportCode"
              data-testid="entity-code"
              :label="$t('exportCode')"
              :rules="[rules.required]"
              color="black"
              counter="10"
              maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="isEditing">
            <v-autocomplete
              v-model="selectedAccount"
              clearable
              color="black"
              item-color="primary"
              :loading="accountsLoader.loading"
              :error="accountsLoader.error"
              :no-data-text="accountsLoader.error ? $t('couldNotLoadAccounts') : $t('noAccountsAvailable')"
              data-testid="entity-form-account-name"
              :items="accounts"
              :item-text="a => a.name"
              return-object
              :label="$t('account')"
              @change="a => setEntityAccount(a)"
              @click:clear="setEntityAccount(undefined)">
              <template #append-outer>
                <v-icon color="primary" @click="openEntityAccountForm">mdi-plus</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      </FormWrapper>
    </v-card-text>
    <Dialog :stateId="dialogId">
      <AccountForm @account-id="id => onCreateAccount(id)"/>
    </Dialog>
  </v-card>
</template>

<script>
import { rules } from '@/utils/FormRules.js'
import { mapActions } from 'vuex'
import { uniqueDialogId, tickTock } from '../../../utils/componentHelpers'
import { ResourceLoader } from '../../../utils/ResourceLoader'
export default {
  name: 'EntityForm',

  components: {
    AccountForm: () => import('@/components/account/AccountForm.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    propEntity: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    dialogId: uniqueDialogId('entity-form'),
    rules,
    refreshAccountFlag: true,
    selectedAccount: undefined,
    entity: {
      name: '',
      exportCode: '',
      businessEntityId: undefined,
      accountId: undefined
    },
    accounts: [],
    accountsLoader: ResourceLoader.empty
  }),

  computed: {
    saveBtnText () {
      if (this.propEntity) {
        return this.$t('updateEntity')
      }
      return this.$t('createEntity')
    },

    title () {
      return this.propEntity ? this.$t('editEntity') : this.$t('createEntity')
    },

    isEditing () {
      return this.propEntity !== undefined
    }
  },

  async created () {
    if (this.propEntity) {
      this.entity.name = this.propEntity.name
      this.entity.exportCode = this.propEntity.exportCode
      this.entity.businessEntityId = this.propEntity.businessEntityId
      this.entity.accountId = this.propEntity.accountId
    }
    if (this.isEditing) {
      this.accountsLoader = new ResourceLoader(async () => {
        this.accounts = await this.fetchAccounts()
      })
      await this.accountsLoader.load()
      if (this.entity.accountId > 0) {
        this.selectedAccount = this.accounts.find(a => a.accountId === this.entity.accountId)
        this.setEntityAccount(this.selectedAccount)
      }
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.name.focus()
    }, 0)
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('user', ['createBusinessEntity', 'updateBusinessEntity']),
    ...mapActions('account', ['fetchAccounts']),
    tickTock,
    async saveEntity () {
      const requestObj = JSON.parse(JSON.stringify(this.entity))
      if (this.propEntity) {
        await this.updateBusinessEntity(requestObj)
        this.$emit('entity-updated', this.entity)
      } else {
        const response = await this.createBusinessEntity(requestObj)
        this.$emit('entity-created', response)
      }
    },
    openEntityAccountForm () {
      this.openOrUpdateDialog({ id: this.dialogId, width: '70%' })
    },
    setEntityAccount (account) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.entity.accountId = account?.accountId
    },

    async onCreateAccount (account) {
      if (!account) return
      await this.accountsLoader.load()
      this.selectedAccount = this.accounts.find(a => a.accountId === account.accountId)
      this.setEntityAccount(this.selectedAccount)
    }
  }
}
</script>
