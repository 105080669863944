<template>
  <v-card height="90vh">
    <v-card-title :class="headerStyle">
      <span class="headline">
        {{$t('inventoryOperations')}}
      </span>
      <v-spacer />
      <BaseDialogActions
        @close="$emit('close')"
        @refresh="triggerRefresh"
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <v-tabs
      v-model="currentTab">
        <v-tab>
          <span>
            {{$t('consumeOrTransfer')}}
          </span>
        </v-tab>
        <v-tab>
          <span>
            {{$t('consumed')}}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab" class="mt-4" touchless>
        <v-tab-item class="fill-height">
          <ConsumeTickets
            v-if="!loading"
            :refreshFlag="refreshFlag"
            :productIdDictionary="productIdDictionary"
            :contractMode="contractMode"
            @trigger-refresh="triggerRefresh"
          />
          <Loading
          v-if="loading"/>
        </v-tab-item>
        <v-tab-item>
          <ConsumedTickets
            v-if="!loading"
            :productIdDictionary="productIdDictionary"
            :refreshFlag="refreshFlag"
            :contractMode="contractMode"
            @trigger-refresh="triggerRefresh"
          />
          <Loading
          v-if="loading"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { colorClassForContractMode } from '../../../utils/componentHelpers'
export default {
  name: 'Consumption',

  components: {
    ConsumeTickets: () => import('@/components/ticket/ticket-consumption/ConsumeTickets.vue'),
    ConsumedTickets: () => import('@/components/ticket/ticket-consumption/ConsumedTickets.vue'),
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    Loading: () => import('@/components/core/Loading.vue')
  },

  props: {
    contractMode: {
      type: Object,
      default: undefined
    }
  },

  data: () => ({
    dialogId: 'consumption',
    currentTab: 0,
    loading: true,
    refreshFlag: false,
    productIdDictionary: {}
  }),

  computed: {
    headerStyle () {
      return colorClassForContractMode(this.contractMode.value)
    }
  },

  created () {
    this.createProductDictionary()
  },

  methods: {
    ...mapActions('product', ['fetchProducts']),

    async createProductDictionary () {
      this.loading = true

      const requestObj = {
        contractId: undefined,
        includedByProduct: true,
        includeProduction: true
      }

      const products = await this.fetchProducts(requestObj)
      products.forEach(p => {
        this.productIdDictionary[p.name] = p
      })

      this.loading = false
    },

    triggerRefresh () {
      this.refreshFlag = true

      this.$nextTick(() => {
        this.refreshFlag = false
      })
    }
  }
}
</script>
