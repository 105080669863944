<template>
  <v-menu
  bottom
  :close-on-content-click="false"
  v-model="open"
  left>
    <template #activator="{on}">
      <v-icon v-on="on" class="mr-2" color="primary">{{!filterIsFalsity ? 'mdi-filter' : 'mdi-filter-outline'}}</v-icon>
    </template>

    <v-card width="600px">
      <v-card-title class="primary white--text">
        <span>{{title ?? $t('filterItem', { item: $t('tracts') })}}</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-container fluid>

          <v-row dense no-gutters>
            <v-col cols="12" class="mb-2 mt-n2">
              <span v-if="unsavedFilterIsFalsity" class="font-italic">
                {{$t('selectOneOrMoreTractStatuses')}}
              </span>
            </v-col>

            <v-col v-for="ts in tractStatuses" :key="`status-${ts.name}`" cols="12" sm="4">
              <v-checkbox
              :label="ts.name"
              v-model="filter.status"
              :value="ts.value"
              class="my-0"/>
            </v-col>

            <v-col cols="10">
              <v-select
              :label="$t('forester')"
              v-if="foresterUsers"
              :items="foresterUsers"
              v-model="filter.tractForester"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="tf => tf.name">
              <template #prepend-inner>
                <v-icon>mdi-forest</v-icon>
              </template>
              </v-select>
            </v-col>

            <v-col cols="10">
              <v-select
              :label="$t('tractType')"
              v-if="selectableTractTypes?.length > 0"
              :items="selectableTractTypes"
              v-model="filter.tractType"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="tt => tt.name">
              <template #prepend-inner>
                <v-icon>mdi-target-variant</v-icon>
              </template>
              </v-select>
            </v-col>

            <v-col cols="10">
              <v-select
              :items="allTeams"
              :label="$t('team')"
              v-model="filter.team"
              multiple
              chips
              deletable-chips
              return-object
              :item-text="t => t.name">
              <template #prepend-inner>
                <v-icon>mdi-account-group</v-icon>
              </template>
              </v-select>
            </v-col>
          </v-row>

        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-container fluid class="mt-n4">
          <v-row no-gutters>
            <v-col>
              <v-btn color="primary" outlined @click="resetFilter">
                {{$t('reset')}}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" class="text--white" :disabled="!(changed?.size > 0) || unsavedFilterIsFalsity" @click="applyFilter">{{$t('apply')}}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-menu>
</template>

<script>
import { TractStatus, TractTypeCategory } from '@/utils/Enumerations'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'StandingTimberFilter',

  props: {
    title: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    allForesters: [],
    filter: {
      status: [],
      tractType: [],
      tractForester: [],
      team: []
    },
    changed: new Set(),
    open: false
  }),

  watch: {
    'filter.status' (ss) {
      this.trackChangesFor('status', ss)
    },
    'filter.tractType' (tt) {
      this.trackChangesFor('tractType', tt)
    },
    'filter.tractForester' (tf) {
      this.trackChangesFor('tractForester', tf)
    },
    'filter.team' (t) {
      this.trackChangesFor('team', t)
    },

    open (isOpen) {
      if (isOpen) {
        this.filter = JSON.parse(JSON.stringify(this.standingTimberFilter))
        if (this.unsavedFilterIsFalsity) this.hint = true
      }
    }
  },

  computed: {
    ...mapGetters('tract', ['standingTimberFilter']),
    ...mapGetters('tract-type', ['allTractTypes']),
    ...mapGetters('team', ['allTeams']),
    ...mapGetters('user', ['foresterUsers']),

    tractStatuses () {
      return [
        TractStatus[2],
        TractStatus[3],
        TractStatus[4]
      ]
    },

    selectableTractTypes () {
      return this.allTractTypes.filter(tt => tt.category === TractTypeCategory.Stumpage.value)
    },

    stumpageCategory () {
      return TractTypeCategory.Stumpage.value
    },

    filterIsFalsity () {
      return this.standingTimberFilter.status.length === 0
    },

    unsavedFilterIsFalsity () {
      return this.filter.status.length === 0
    }
  },

  async created () {
    await Promise.all([
      this.getAllForesterUsers(),
      this.fetchTractTypes(),
      this.fetchTeams()
    ])
    this.readStandingTimberFilter() // This filter read relies on tract types & teams having already been fetched
    if (this.filterIsFalsity) {
      setTimeout(() => { this.open = true }, 250)
    }
  },

  methods: {
    ...mapActions('tract', ['setStandingTimberFilter', 'readStandingTimberFilter']),
    ...mapActions('tract-type', ['fetchTractTypes']),
    ...mapActions('user', ['getAllForesterUsers']),
    ...mapActions('team', ['fetchTeams']),

    trackChangesFor (key, items) {
      const currentSet = new Set(this.standingTimberFilter[key])
      if (currentSet.size !== items.length || items.some(i => !currentSet.has(i))) {
        this.changed.add(key)
      } else {
        this.changed.delete(key)
      }
      this.filter[key] = items
    },

    resetFilter () {
      this.filter.status = []
      this.filter.tractType = []
      this.filter.tractForester = []
      this.filter.team = []
      this.updateFilter()
      this.$emit('input', JSON.parse(JSON.stringify(this.filter)))
    },

    updateFilter () {
      this.setStandingTimberFilter(JSON.parse(JSON.stringify(this.filter)))
      this.changed = new Set()
    },

    applyFilter () {
      this.updateFilter()
      this.$emit('input', JSON.parse(JSON.stringify(this.filter)))
      this.open = false
    }
  }
}
</script>
