<template>
  <v-row>
    <v-col>
      <v-row class="mt-1">
        <v-col cols="12">
          <v-container fluid>
            <v-expansion-panels popout multiple accordion tile v-model="openPanels" v-if="!showNoData">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{title}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="paymentsEntries.length > 0">
                    <p class="subtitle-1 mt-3 mb-n2">{{$t('contractPayments')}}</p>
                    <TicketFinancialsTable
                    :entries="paymentsEntries"
                    @aggregateSelected="paymentSelected"/>
                  </span>
                  <span v-if="correctionEntries.length > 0">
                    <p class="subtitle-1 mt-6 mb-n2">{{$t('corrections')}}</p>
                    <TicketFinancialsTable
                    :entries="correctionEntries"
                    isCorrections
                    @aggregateSelected="correctionSelected"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-col>
        <v-col v-if="showNoData">
          <NoData class="mb-4 mt-n6">
            {{ $t('noDataForSelection') }}
          </NoData>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="detailDialogOpen" width="900px">
      <TicketwiseFinancialBreakdown
      v-if="detailDialogOpen"
      :isCorrection="focusedSelectionIsCorrection"
      :payableSummary="focusedSelection"
      :includeRecoveries="false"
      :isByproducts="isByproducts"
      @close="closeDialog"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { formatMoney } from '@/utils/NumericMutations'
import AdjustingEntriesHeaders from '@/headers/AdjustingEntries.js'
export default {
  name: 'TicketFinancials',

  components: {
    TicketwiseFinancialBreakdown: () => import('@/components/accounting/adjusting-entries/v1/TicketwiseFinancialBreakdown.vue'),
    TicketFinancialsTable: () => import('@/components/accounting/adjusting-entries/v1/TicketFinancialsTable.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  props: {
    financials: {
      type: Object,
      required: true
    },
    title: {
      required: true
    },
    isByproducts: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    openPanels: [0],
    detailDialogOpen: false,
    focusedSelection: undefined,
    focusedSelectionIsCorrection: false
  }),

  computed: {
    contractPaymentHeaders () {
      return AdjustingEntriesHeaders.ticketContractPayments()
    },
    paymentsEntries () {
      return Array.from(this.financials.contractPayments.values()) ?? []
    },
    correctionEntries () {
      return Array.from(this.financials.corrections.values()) ?? []
    },
    showNoData () {
      return this.paymentsEntries.length === 0 && this.correctionEntries.length === 0
    },
    contractColor () {
      return this.isByproducts ? 'byproduct' : 'primary'
    },
    borderColor () {
      return `border: 3px solid ${this.isByproducts ? '#363636' : '#D15F27'}`
    }
  },

  methods: {
    formatMoney,

    paymentSelected (payment) {
      this.focusedSelection = payment
      this.focusedSelectionIsCorrection = false
      this.detailDialogOpen = true
    },

    correctionSelected (correction) {
      this.focusedSelection = correction
      this.focusedSelectionIsCorrection = true
      this.detailDialogOpen = true
    },

    closeDialog () {
      this.detailDialogOpen = false
      this.focusedSelection = undefined
      this.focusedSelectionIsCorrection = false
    }
  }
}
</script>
