<template>
<div>
  <div v-show="activitiesLoading">
    <v-row class="mt-5" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
  </div>
  <div v-show="!activitiesLoading">
    <v-expansion-panels
      v-model="openPanels"
      multiple
      accordion
      tile
      popout
    >
      <v-expansion-panel
        v-for="(entity,index) in entities"
        :key="index"
        :data-testid="`contract-detail-activity-${entity.name.replaceAll(' ', '')}`"
      >
        <v-expansion-panel-header class="subtitle-1">
          {{ entity.name }}
          <v-spacer/>
          <template #actions>
            <v-icon>mdi-chevron-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <EntityActivityTable
            :showActions="showActions"
            :contract="contract"
            :allActivities="allActivities"
            :businessEntityId="entity.businessEntityId"
            :loading="activitiesLoading"
            :multiEntity="entities.length > 0 ? true : false"
            @refresh="$emit('refresh-activities')"
            @table-event="tableEvent"
            @new-detail="$emit('refresh-activities')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-if="entities.length === 0 && !activitiesLoading">
    <v-row class="mt-5" justify="center" align="center">
        <span class="headline black--text">{{ $t('noActivitiesPuncuated') }}</span>
    </v-row>
  </div>
</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { colorClassForContractType } from '@/utils/componentHelpers.js'
export default {
  name: 'ContractDetailActivities',
  components: {
    EntityActivityTable: () => import('@/components/activity/EntityActivityTable.vue')
  },

  props: {
    contract: {
      type: Object,
      default: undefined,
      required: true
    },
    showActions: {
      type: Boolean,
      default: true,
      required: false
    },
    entities: {
      type: Array,
      default: undefined,
      required: true
    },
    allActivities: {
      type: Array,
      default: undefined,
      required: true
    }
  },

  data: () => ({
    products: [],
    openPanels: [],
    initBusinessEntities: undefined
  }),

  computed: {
    ...mapGetters('activity', ['savedOpenPanels', 'activitiesLoading'])
  },

  created () {
    this.openPanels = this.entities.length === 1 ? [0] : this.savedOpenPanels
  },

  methods: {
    ...mapMutations('activity', ['setOpenPanels']),
    isOpenPanel (index) {
      return this.openPanels.some(panelIndex => panelIndex === index)
    },

    contractTypeColor () {
      return colorClassForContractType(this.contract.type)
    },

    tableEvent (event) {
      this.setOpenPanels(this.openPanels)
      this.$emit('table-event', event)
    },

    refreshActivities () {
      this.setOpenPanels(this.openPanels)
      this.$emit('refresh-activities')
    }
  }
}
</script>
