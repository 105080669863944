<template>
  <v-row>
    <v-col cols="12" md="6" :lg="isByproductsContract ? 3 : 4">
      <DatePicker
      @date-picked="setStartDate"
      dataTestId="contract-effective-date"
      :startDate="contractInfo.effectiveDate"
      @valid-date="(val) => {
        isValidStartDate = val
      }"
      v-if="showStartDate"
      dateLabel="Effective"
      ></DatePicker>
    </v-col>
    <v-col cols="12" md="6" :lg="isByproductsContract ? 3 : 4">
      <DatePicker
      @date-picked="endDatePicked"
      dataTestId="contract-expiration-date"
      :startDate="contractInfo.expirationDate"
      @valid-date="(val) => {
        isValidEndDate = val
      }"
      dateLabel="Expiration"
      v-if="showEndDate"
      ></DatePicker>
    </v-col>
    <v-col cols="12" :lg="isByproductsContract ? 3 : 4">
      <v-text-field
        data-testid="contract-distance"
        v-model="contractInfo.distance"
        :label="$t('distance')"
        color="black"
        :rules="[rules.positiveIntegerOrBlank, rules.validDistance]"
      ></v-text-field>
    </v-col>
    <v-col v-if="isByproductsContract" cols="12" :lg="isByproductsContract ? 3 : 4">
      <v-text-field
        v-if="!isByproductPurchase"
        v-model="contractInfo.tareWeight"
        data-testid="contract-tare-weight"
        color="black"
        :label="$t('tareWeightLBS')"
        :rules="[rules.positiveIntegerOrBlank, rules.validWeightPounds]"
      ></v-text-field>
    </v-col>
    <v-col v-if="isLogsContract" cols="12" sm="4" lg="auto">
      <v-checkbox
        data-testid="contract-accepts-any-load"
        v-model="contractInfo.acceptsAnyLoad"
        color="primary"
        :label="$t('acceptsAnyLoad')"
      />
      <v-checkbox
        data-testid="contract-requires-logger"
        v-model="contractInfo.requiresLogger"
        color="primary"
        :label="$t('requiresLogger')"
      />
    </v-col>
    <v-col cols="12" sm="4" lg="auto" v-if="contractMode.value !== ContractMode.Transfers.value">
      <v-checkbox
        data-testid="contract-requires-ext-ticket-1"
        v-model="contractInfo.requiresExt1"
        color="primary"
        :label="$t('requiresExt1')"
      />
      <v-checkbox
        data-testid="contract-requires-ext-ticket-2"
        v-model="contractInfo.requiresExt2"
        color="primary"
        :label="$t('requiresExt2')"
      />
    </v-col>
    <v-col cols="12" xs="12" sm="4" md="3" lg="auto">
      <v-checkbox
        v-if="!hideDraft"
        data-testid="contract-is-draft"
        v-model="contractInfo.isDraft"
        color="primary"
        :label="$t('markAsDraft')"
        :disabled="lockAsDraft"
      />
      <v-checkbox
        data-testid="contract-on-pause"
        v-model="contractInfo.paused"
        color="primary"
        :label="$t('markOnPause')"
      />
    </v-col>
    <v-col cols="12" md="3" lg="auto" xl="auto" v-if="isByproductsContract && !isByproductPurchase">
      <v-checkbox
      v-model="contractInfo.allowPickupLoads"
      color="primary"
      :label="$t('allowLoadPickup')"
      @change="storeAllowPickupLoadsSelection()"/>
    </v-col>
  </v-row>
</template>

<script>
import fieldRules from '@/utils/rules.js'
import moment from 'moment'
import { ContractType, ContractMode } from '@/utils/Enumerations.js'
import { LocalStorageKeys } from '@/utils/constants.js'

export default {
  name: 'ContractInfo',
  components: {
    DatePicker: () => import('../helper/DatePicker.vue')
  },

  props: {
    propContractInfo: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: false,
      default: false
    },
    contractMode: {
      type: Object,
      required: true
    },
    tract: {
      type: Object,
      default: undefined
    },
    lockAsDraft: {
      type: Boolean,
      default: false
    },
    hideDraft: {
      type: Boolean,
      default: false
    },
    contractType: {
      type: Object,
      required: false
    }
  },

  created () {
    this.contractInfo = JSON.parse(JSON.stringify(this.propContractInfo))
    if (!this.editing) {
      this.contractInfo.effectiveDate = moment().format()
      this.contractInfo.expirationDate = moment().add(90, 'days').format()

      if (this.isByproductsContract && !this.isByproductPurchase) {
        this.contractInfo.allowPickupLoads = localStorage.getItem(LocalStorageKeys.BP_CONTRACT_PICK_UP_LOAD) === 'true'
      }
    }
  },

  data: () => ({
    ContractMode,
    contractInfo: {},
    isValidStartDate: true,
    isValidEndDate: true,
    showStartDate: true,
    showEndDate: true,
    rules: fieldRules.rules
  }),

  watch: {
    contractInfo: {
      handler (val) {
        this.$emit('contract-info-changed', val)
        this.isButtonDisabled()
      },
      deep: true
    },

    isValidStartDate () {
      this.isButtonDisabled()
    },
    isValidEndDate () {
      this.isButtonDisabled()
    },

    propStartDate (val) {
      if (val && !this.editing) {
        this.contractInfo.effectiveDate = val
        this.showStartDate = false
        this.$nextTick(_ => {
          this.showStartDate = true
        })
      }
    },

    propEndDate (val) {
      if (val && !this.editing) {
        this.contractInfo.expirationDate = val
        this.showEndDate = false
        this.$nextTick(_ => {
          this.showEndDate = true
        })
      }
    },

    isByproductPurchase (val) {
      if (!val) return
      this.contractInfo.allowPickupLoads = false
      this.contractInfo.tareWeight = 0
    }
  },

  computed: {
    propStartDate () {
      return this.tract?.harvestStartDate
    },

    propEndDate () {
      return this.tract?.harvestEndDate
    },

    isLogsContract () {
      return this.contractMode.value === 0
    },

    isByproductsContract () {
      return this.contractMode.value === 1
    },

    isByproductPurchase () {
      return this.contractType?.value === ContractType.ByproductPurchase.value
    }
  },

  methods: {
    endDatePicked (val) {
      this.contractInfo.expirationDate = val
    },

    setStartDate (val) {
      this.contractInfo.effectiveDate = val
    },

    isButtonDisabled () {
      const tempEndDate = new Date(this.contractInfo.endDate).setHours(0, 0, 0, 0)
      const tempStartDate = new Date(this.contractInfo.startDate).setHours(0, 0, 0, 0)
      this.$emit('is-button-disabled', !(this.isValidStartDate && this.isValidEndDate) || (tempStartDate > tempEndDate))
      if (tempStartDate > tempEndDate) {
        this.setSnackError(this.$t('endDateBeforeStartDate'))
      }
    },

    storeAllowPickupLoadsSelection () {
      localStorage.setItem(LocalStorageKeys.BP_CONTRACT_PICK_UP_LOAD, this.contractInfo.allowPickupLoads)
    }
  }
}
</script>
