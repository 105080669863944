<template>
  <v-card :flat="hideTitle">
    <v-card-title class="primary tertiary--text" v-if="!hideTitle">
      <span>{{$t('updateUserInfo')}}</span>
      <v-spacer/>
      <Icon
      icon="mdi-close"
      iconColor="tertiary"
      :small="false"
      :large="true"
      dataTestId="user-info-close-button"
      :tooltipText="$t('close')"
      @icon-clicked="closeDialog"/>
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper
        ref="form"
        formRef="person-form"
        :hideButton="!showSaveChanges"
        @submit="saveUserInfo"
      >
        <v-row class="mb-1">
          <v-col cols="12" xs="12" sm="12" md="6" lg="3">
            <v-text-field
            v-model="person.firstName"
            color="black"
            maxlength="64"
            counter
            :label="$t('firstName')"
            :rules="[rules.required]"/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="3">
            <v-text-field
            v-model="person.lastName"
            color="black"
            maxlength="64"
            counter
            :label="$t('lastName')"
            :rules="[rules.required]"/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="3">
            <v-text-field
            v-model="person.email"
            color="black"
            maxlength="64"
            :label="$t('email')"
            counter
            :rules="[rules.required, rules.validEmail]"/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="3">
            <v-text-field
              v-model="person.phoneNumber"
              v-mask="phoneMask"
              :label="$t('phoneNumber')"
              :rules="[rules.required, rules.phoneNumber]"
              data-testid="phone-number"
              color="black"
            />
          </v-col>
        </v-row>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>
<script>
import { rules } from '@/utils/FormRules.js'
import { mask } from 'vue-the-mask'
import isEqual from 'lodash/isEqual'
import { mapActions, mapGetters } from 'vuex'
import { ErrorSource } from '@/utils/Enumerations'
export default {
  name: 'UserInfoFormDialog',

  props: {
    hideTitle: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  directives: {
    mask
  },

  data: () => ({
    person: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    rules,
    phoneMask: '(###)-###-####'
  }),

  computed: {
    ...mapGetters('user', ['userInfo']),

    showSaveChanges () {
      if (this.userInfo) {
        return !isEqual(this.userInfo.person, this.person)
      } else {
        return true
      }
    }
  },

  watch: {
    userInfo: {
      handler (val) {
        if (val) {
          if (val.person) {
            this.person = JSON.parse(JSON.stringify(val.person))
          }
        }
      },
      deep: true
    }
  },

  async created () {
    await this.getUserInfo()
    if (this.userInfo) {
      if (this.userInfo.person !== null) {
        this.person = JSON.parse(JSON.stringify(this.userInfo.person))
      }
    }
  },

  methods: {
    ...mapActions('user', ['updateUserInfo', 'getUserInfo']),

    closeDialog () {
      this.$emit('close')
    },
    async saveUserInfo () {
      if (!this.$refs.form.$refs['person-form'].validate()) {
        this.setSnackError({
          code: 'Form Validating',
          source: ErrorSource.WebClient,
          message: 'Invalid or missing form fields.'
        })
        return
      }

      const userInfo = JSON.parse(JSON.stringify(this.userInfo))
      userInfo.person = this.person
      await this.updateUserInfo(userInfo)
      await this.getUserInfo()
      this.$emit('user-info-added')
    }
  }
}
</script>
