<template>
  <v-container class="pa-0">
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">{{ title }}</span>
        <v-spacer/>
        <Icon
          icon="mdi-close"
          iconColor="white"
          :small="false"
          large
          :tooltipText="$t('close')"
          @icon-clicked="$emit('form-closed')"
        />
      </v-card-title>
      <v-card-text>
        <FormWrapper
          :buttonText="submitText"
          @submit="submitCertification"
          :disabled="!isValid"
        >
          <v-container fluid grid-list-md v-if="!loading">
            <v-row v-if="!isEditing">
              <v-col cols="12" md="6">
                <v-select
                  :items="availableCertifications"
                  v-model="selectedCertificationId"
                  :label="selectorLabel"
                  :no-data-text="$t('noAvailableCertifications')"
                  item-text="name"
                  item-value="certificationId"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" lg="4">
                <CompactDatePicker
                  :initialDate="certification.effectiveDate"
                  :label="$t('effectiveDate')"
                  @date-picked="setEffectiveDate"
                />
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <CompactDatePicker
                  :initialDate="certification.expirationDate"
                  :label="$t('expirationDate')"
                  @date-picked="setExpirationDate"
                />
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  v-model="certification.certificateNumber"
                  :label="$t('certificateNumber')"
                  :maxLength="64"
                />
              </v-col>
              <v-col cols="12" v-if="!fileInputLoading && !hideFileUpload && isEditing && accountId">
                <v-file-input
                v-model="certificationFile"
                underlined
                color="primary"
                :label="$t('uploadFile')"
                :disabled="importedCertificationFile !== null"
                @change="onFileChanged"
                :hint="certificationFileHint ?? ''"
                persistent-hint
                >
                  <template #append-outer>
                    <Icon
                    :disabled="!certificationFile || importedCertificationFile !== null"
                    icon="mdi-upload"
                    iconColor="primary"
                    large
                    :small="false"
                    :tooltipText="$t('importFile')"
                    @icon-clicked="uploadToBlob"
                    />
                    <Icon
                    v-if="importedCertificationFile !== null"
                    iconColor="primary"
                    :small="false"
                    large
                    icon="mdi-download"
                    :tooltipText="$t('downloadFile')"
                    @icon-clicked="downloadFile()"
                    />
                    <Icon
                    v-if="importedCertificationFile !== null"
                    icon="mdi-file-document-remove-outline"
                    iconColor="primary"
                    large
                    :small="false"
                    :tooltipText="$t('deleteFile')"
                    @icon-clicked="deleteCertificationFileConfirmation = true"
                    />
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </FormWrapper>
      </v-card-text>
    </v-card>
    <v-dialog v-if="this.importedCertificationFile" v-model="deleteCertificationFileConfirmation" width="400px">
      <ConfirmDialog
      :title="$t('deleteFile')"
      :body="$t('deleteCertificationFileBody', {fileName: this.importedCertificationFile.name, certificationName: propCertification.name})"
      :confirmText="$t('deleteFile')"
      @confirm="confirmDeleteFile"
      @cancel="closeDialog">
      </ConfirmDialog>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import fieldRules from '@/utils/rules.js'
import { CertificationCategory, AccountFileCategory } from '@/utils/Enumerations.js'
import { accountFilesClient } from '../../../utils/AccountFiles'
import { saveAs } from 'file-saver'
import { utcToLocalDate, localToUTC } from '@/utils/DateFormatter.js'
export default {
  name: 'AccountCertificationForm',

  props: {
    propCertification: {
      type: Object,
      default: undefined
    },
    accountId: {
      type: Number,
      default: undefined
    },
    certificationsFilter: {
      type: Array,
      default: undefined
    },
    hideFileUpload: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    CompactDatePicker: () => import('@/components/helper/CompactDatePicker.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  data: () => ({
    loading: undefined,
    certification: {
      effectiveDate: undefined,
      expirationDate: undefined,
      certificateNumber: undefined
    },
    selectedCertificationId: undefined,
    availableCertifications: [],
    rules: fieldRules.rules,
    certificationFile: null,
    importedCertificationFile: null,
    deleteCertificationFileConfirmation: false,
    allowedFileMimes: undefined,
    fileInputLoading: true,
    certificationFileHint: undefined,
    users: undefined
  }),

  watch: {
    selectedCertificationId (val) {
      this.certification.certificationId = val
      if (!this.accountId && !this.isEditing) {
        this.certification.name = this.availableCertifications.find(c => c.certificationId === val).name
      }
    }
  },

  computed: {
    isEditing () {
      return this.propCertification !== undefined
    },

    submitText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('addCertification')
    },

    title () {
      return this.isEditing
        ? this.$t('editingAccountCertification', { certification: this.propCertification.name })
        : this.$t('addingCertification')
    },

    datesAreValid () {
      return !this.certification.effectiveDate ||
        !this.certification.expirationDate ||
        this.certification.effectiveDate < this.certification.expirationDate
    },

    fieldsChanged () {
      return (!this.isSameDay(this.certification.effectiveDate, this.propCertification.effectiveDate) ||
        !this.isSameDay(this.certification.expirationDate, this.propCertification.expirationDate) ||
        (this.certification.certificateNumber?.trim() ?? '') !== (this.propCertification.certificateNumber?.trim() ?? ''))
    },

    isValid () {
      return this.datesAreValid &&
      (this.isEditing || this.selectedCertificationId) &&
      (!this.isEditing || this.fieldsChanged)
    },

    selectorLabel () {
      return this.availableCertifications.length > 0
        ? this.$t('certifications')
        : this.$t('noAvailableCertifications')
    },

    certificationFileCategory () {
      return AccountFileCategory.Certification
    }
  },

  async created () {
    this.loading = true
    if (this.isEditing) {
      this.certification = {
        effectiveDate: this.propCertification.effectiveDate,
        expirationDate: this.propCertification.expirationDate,
        certificateNumber: this.propCertification.certificateNumber
      }
      if (!this.hideFileUpload) {
        this.users = await this.getAllUsers()
        this.setFileMimes()
        await this.loadFiles()
      }
    } else {
      this.availableCertifications = (await this.fetchCertifications()).filter(c => c.category === CertificationCategory.Account.value)
      if (this.certificationsFilter !== undefined) {
        this.availableCertifications = this.availableCertifications.filter(c => !this.certificationsFilter.includes(c.certificationId))
      }
      this.certification.effectiveDate = moment().startOf('day').format()
      if (this.availableCertifications.length === 1) this.selectedCertificationId = this.availableCertifications[0].certificationId
    }
    this.loading = false
  },

  methods: {
    ...mapActions('certification', ['fetchCertifications']),
    ...mapActions('user', ['getAllUsers']),

    submitCertification () {
      const certificationRequest = {
        accountId: this.accountId ?? this.propCertification?.accountId,
        certificateNumber: this.certification?.certificateNumber?.trim(),
        effectiveDate: this.certification.effectiveDate,
        expirationDate: this.certification.expirationDate
      }

      if (this.isEditing) {
        certificationRequest.certificationId = this.propCertification?.certificationId
        this.$emit('certification-edited', certificationRequest)
      } else {
        certificationRequest.certificationId = this.selectedCertificationId
        this.$emit('certification-added', { ...certificationRequest, name: this.certification.name })
      }
    },

    setEffectiveDate (effectiveDate) {
      this.certification.effectiveDate = effectiveDate ? localToUTC(effectiveDate) : undefined
      if (!this.datesAreValid) this.setSnackError(this.$t('effectiveDateBeforeExpirationDate'))
    },

    setExpirationDate (expirationDate) {
      this.certification.expirationDate = expirationDate ? localToUTC(expirationDate) : undefined
      if (!this.datesAreValid) this.setSnackError(this.$t('effectiveDateBeforeExpirationDate'))
    },

    isSameDay (date1, date2) {
      if ((date1 === null || date1 === undefined) && (date2 === null || date2 === undefined)) return true
      if ((date1 === null || date1 === undefined) && !(date2 === null || date2 === undefined)) return false
      if (!(date1 === null || date1 === undefined) && (date2 === null || date2 === undefined)) return false
      return moment(date1).isSame(date2, 'day')
    },

    async loadFiles () {
      const loadedFiles = await accountFilesClient.getFileByPolicy(this.propCertification, this.certificationFileCategory)
      const loadedFile = loadedFiles && loadedFiles.length > 0 ? loadedFiles[0] : null
      if (loadedFile) {
        this.setFile(loadedFile)
      }
      this.fileInputLoading = false
    },

    confirmDeleteFile () {
      this.deleteFile(this.importedCertificationFile)
      this.importedCertificationFile = null
      this.certificationFile = null
      this.closeDialog()
    },

    closeDialog () {
      this.deleteCertificationFileConfirmation = false
    },

    setFile (file) {
      this.certificationFile = file
      this.importedCertificationFile = file
      this.certificationFileHint = this.$t('uploadedDateByUser', { date: this.dateFromMilliseconds(this.importedCertificationFile.date), user: this.userFromId(this.importedCertificationFile.user) })
    },

    async uploadToBlob () {
      if (!this.certificationFile) return
      if (!this.validateUpload(this.certificationFile)) return
      await accountFilesClient.uploadAccountFile(this.certificationFile, this.propCertification, this.certificationFileCategory)
      this.loadFiles()
      this.$emit('file-edited')
    },

    async deleteFile (file) {
      await accountFilesClient.deleteFile(file.ref).then(async () => {
        this.certificationFile = null
        this.certificationFileHint = undefined
        await this.loadFiles()
        this.deleteConfirmation = false
        this.$emit('file-edited')
      })
    },

    async downloadFile () {
      const fetchedFile = await fetch(this.importedCertificationFile.ref)
      const blob = await fetchedFile.blob()
      saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
    },

    onFileChanged (e) {
      this.certificationFile = e
    },

    validateUpload (file) {
      const limit = 1000 * 1000
      if (file.size > limit) { // Exceeds 1MB
        this.setSnackError(this.$t('fileTooLargeSnack', { limit: '1MB' }))
        this.certificationFile = null
        return false
      }
      if (!this.allowedFileMimes.has(file.type)) {
        this.setSnackError(this.$t('fileMimeNotAllowedSnack'))
        this.certificationFile = null
        return false
      }
      return true
    },

    dateFromMilliseconds (milliseconds) {
      const d = new Date(+milliseconds)
      return utcToLocalDate(d, 'MM/DD/YYYY - LT')
    },

    userFromId (id) {
      return this.users.find(u => u.applicationUserId?.toString() === id.toString())?.name
    },

    setFileMimes () {
      this.allowedFileMimes = new Set([
        'application/pdf',
        'application/msword',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'image/png',
        'image/jpeg',
        'image/heic',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.ms-word'
      ])
    }
  }
}
</script>
