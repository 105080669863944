<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="auto">
        <Selector
        :label="$t('category')"
        :items="tractCategories"
        @item-selected="categoryChosen"/>
      </v-col>
      <v-col cols="auto">
        <Selector
        label="Entities"
        :items="entities"
        @item-selected="entitySelected"
        style="max-width:16rem"
        itemText="name"/>
      </v-col>
      <v-col cols="auto">
        <TimeframePicker
        @date-range-chosen="dateRangeChosen"/>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <Icon v-for="action in tableActions" :key="action.actionName"
        :dataTestId="`product-${action.actionName}-button`"
        :icon="action.icon"
        :tooltipText="action.text"
        :small="false"
        @icon-clicked="action.action"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" lg="9" xl="10">
        <ProductSummaryTable :productSummary="productCosts.productSummaries"/>
      </v-col>
      <v-col cols="12" lg="3" xl="2">
        <ProductSummaryChart v-if="productCosts.productSummaries.length > 0" :productSummary="productCosts.productSummaries"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <DataTable
          :items="productCosts.tractSummaries"
          :headers="tractSummaryHeaders"
          :customCells="customCells"
          :showDense="false"
          resize
          defaultHeight="256px"
        >
        <template #custom-controls>
          <v-col cols="auto">
            <ColumnAdjuster
            :propColumnSet.sync="columnSet"
            @new-columns-selected="newColumnsSelected"/>
          </v-col>
        </template>
        <template #average-net-tons="{item}">
          <span>
            {{ tonStringFromPounds(item.netWeight / item.loads) }}
          </span>
        </template>
        <template #production-cost-per-ton="{item}">
          <span>
            {{formatMoney(item.productionCost / (item.netWeight / 2000))}}
          </span>
        </template>
        <template #stumpage-cost-per-ton="{item}">
          <span>
            {{formatMoney(item.stumpageCost / (item.netWeight / 2000))}}
          </span>
        </template>
        <template #actions="{item}">
          <Icon
          icon="mdi-information-outline"
          :dataTestId="`product-${item.name}`"
          :small="false"
          :tooltipText="$t('viewDetailedBreakdown')"
          @icon-clicked="openDetailDialog(item)"/>
        </template>
        </DataTable>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs">
      <TractCostDetail
      v-if="focusedTractSummary"
      :dateRange="dateRange"
      :entity="selectedEntity"
      :tractSummary="focusedTractSummary"
      :tractTypeCategory="tractTypeCategory"/>
    </Dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { getDateRangeForPeriod } from '@/utils/DateFormatter.js'
import { TractTypeCategory } from '@/utils/Enumerations.js'
import TractHeaders from '@/headers/Tract.js'
export default {
  name: 'ProductCosts',

  data: () => ({
    dialogId: 'product-costs',
    loading: false,
    productCosts: {
      productSummaries: [],
      tractSummaries: []
    },
    selectedEntity: undefined,
    tractCategories: TractTypeCategory.names,
    dateRange: undefined,
    chosenCategory: 0,
    focusedTractSummary: null,
    columnSet: null
  }),

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    TimeframePicker: () => import('@/components/tract/tract-analysis/TimeframePicker.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    ProductSummaryChart: () => import('@/components/settlements/accounts-payable-module/production-summary/ProductSummaryChart.vue'),
    ProductSummaryTable: () => import('@/components/settlements/accounts-payable-module/production-summary/ProductSummaryTable.vue'),
    TractCostDetail: () => import('@/components/tract/tract-analysis/TractCostDetail.vue')
  },

  computed: {
    ...mapGetters('user', ['businessEntities']),
    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh'),
          action: this.refresh
        }
      ]
    },

    customCells () {
      return [
        {
          slotName: 'average-net-tons',
          value: 'averageWeight'
        },
        {
          slotName: 'production-cost-per-ton',
          value: 'productionCostPerTon'
        },
        {
          slotName: 'stumpage-cost-per-ton',
          value: 'stumpageCostPerTon'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },

    entities () {
      if (!this.businessEntities) return []
      const businessEntities = JSON.parse(JSON.stringify(this.businessEntities))
      if (this.chosenCategory === 1) {
        businessEntities.unshift({
          name: 'All Entities',
          businessEntityId: null
        })
      }
      return businessEntities
    },

    tractSummaryHeaders () {
      if (!this.columnSet) return []
      return this.columnSet.getColumns()
    },

    tractTypeCategory () {
      return TractTypeCategory.forInt(this.chosenCategory)
    }
  },

  async created () {
    this.columnSet = TractHeaders.tractSummaryHeaders(this.$i18n.locale)
    await this.fetchAllBusinessEntities()
    this.refresh()
  },

  methods: {
    ...mapActions('tract', ['getTractProductCosts', 'getDetailedTractProductCosts']),
    ...mapActions('user', ['fetchAllBusinessEntities']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    tonStringFromPounds,
    getDateRangeForPeriod,

    entitySelected (entity) {
      if (JSON.stringify(entity) === JSON.stringify(this.selectedEntity)) return
      this.selectedEntity = entity
      this.refresh()
    },

    categoryChosen (category) {
      if (TractTypeCategory.fromInt(this.chosenCategory) === category) return
      this.chosenCategory = TractTypeCategory.toInt(category)
      this.$emit('tract-category-selected', this.chosenCategory)
      this.refresh()
    },

    dateRangeChosen (dateRange) {
      this.dateRange = dateRange
      this.refresh()
    },

    openDetailDialog (tract) {
      this.focusedTractSummary = tract
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    getCSVObjects (deliveredCosts) {
      return deliveredCosts.map(dc => {
        return {
          name: dc.tractName,
          supplier: dc.accountName,
          type: dc.tractType,
          plf: dc.plf,
          loads: dc.loads,
          grossTons: dc.grossTons,
          netTons: dc.netTons,
          costPerTon: dc.costPerTon,
          totalCost: dc.totalCost
        }
      })
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    },

    async refresh () {
      if (!this.dateRange) return
      if (!this.selectedEntity) return
      this.loading = true
      try {
        const response = await this.getTractProductCosts({
          category: this.chosenCategory,
          businessEntityId: this.selectedEntity.businessEntityId,
          ...this.dateRange
        })
        this.productCosts = response
      } finally {
        this.loading = false
      }
    },

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedTractSummary = null
    }
  }
}
</script>
