<template>
  <v-card data-testid="receiver-settings-form">
    <v-card-title class="primary">
      <span class="tertiary--text headline">{{ title }}</span>
      <v-spacer />
      <BaseDialogActions hideRefresh />
    </v-card-title>
    <v-card-text>
      <FormWrapper
        testId="save-receiver-settings"
        :buttonText="$t('saveSettings')"
        strongValidation
        :disabled="!enableSubmit"
        @submit="saveButtonClicked"
      >
        <v-container fluid grid-list-lg>
          <v-row>
            <v-col cols="12">
              <span class="mr-2 text-h6">
                {{ $t('name') }}
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-text-field
                v-model="settings.name"
                data-testid="settings-name"
                ref="nameField"
                color="black"
                outlined
                maxLength="32"
                counter="32"
                :label="$t('receiverSettingsName')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="mr-2 text-h6">
                {{ $t('loadCreationSettings') }}
              </span>
            </v-col>
            <v-col>
              <LoadCreationSettingsForm
                :propSettings="settings.loadCreationSetting"
                :displayDetails="displayDetails"
                @updateLoadCreationSettings="updateLoadCreationSettings"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="mr-2 text-h6">
                {{ $t('scales') }}
              </span>
              <Icon
                icon="mdi-plus"
                iconColor="primary"
                :tooltipText="$t('addScale')"
                :small="false"
                dataTestId="deck-create-button"
                @icon-clicked="editScale(undefined)"
                tabindex="0"
              />
            </v-col>
            <v-col v-for="(scale, index) in settings.scaleSettings" :key="index" cols="12" lg="3">
              <ScaleCard
                :scale="scale"
                editing
                @click:edit="editScale"
                @click:delete="deleteScale"
              />
            </v-col>
            <v-col v-if="!hasScales" cols="auto">
              <span class="mr-2">
                {{ noScalesText }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="mr-2 text-h6">
                {{ $t('printerSettings') }}
              </span>
            </v-col>
            <v-col cols="12">
              <PrinterSettingsForm
                :propPrinter="settings.printerSetting"
                :displayDetails="displayDetails"
                @updatePrinter="updatePrinterSettings"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span class="mr-2 text-h6">
                {{ $t('defectCollectionSettings') }}
              </span>
            </v-col>
            <v-col cols="12">
              <DefectCollectionSettingsForm
                :propDefectSettings="settings.defectCollectionSetting"
                @changeDefectSettings="updateDefectSettings"
              />
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
      <Dialog :stateId="dialogId" maxWidth="80vw" @close="resetDialogs">
        <ScaleForm
          v-if="this.editScaleForm"
          :propScale="focusedScale"
          :uniqueScaleItems="uniqueScaleItems"
          @close="resetDialogs"
          @create-scale="createScale"
          @edit-scale="modifyScale"
        />
      </Dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import fieldRules from '@/utils/rules.js'
import { isEqual } from 'lodash'
import { uniqueDialogId } from '@/utils/componentHelpers'
import {
  ReceiverScaleConnectionTypes,
  ReceiverLoadCreationTypes,
  ReceiverLogContractFilterTypes,
  ReceiverByproductSaleContractFilterTypes,
  ReceiverByproductPurchaseContractFilterTypes,
  ReceiverLogContractLoadCountTimeFilter,
  ReceiverDefectCollectionBehavior,
  ReceiverDefectCollectionUnit,
  SystemDayOfWeek,
  DefectCollectionType
} from '@/utils/Enumerations.js'
export default {
  name: 'ReceiverSettingsForm',

  props: {
    propSettings: {
      type: Object,
      default: undefined
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    locationId: {
      type: Number,
      required: true
    },
    defectCollectionMode: {
      type: Number,
      required: true
    }
  },

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    ScaleCard: () => import('@/components/settings-components/company-config/receiver-settings/ScaleCard.vue'),
    ScaleForm: () => import('@/components/settings-components/company-config/receiver-settings/ScaleForm.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    PrinterSettingsForm: () => import('@/components/settings-components/company-config/receiver-settings/PrinterSettingsForm.vue'),
    LoadCreationSettingsForm: () => import('@/components/settings-components/company-config/receiver-settings/LoadCreationSettingsForm.vue'),
    DefectCollectionSettingsForm: () => import('@/components/settings-components/company-config/receiver-settings/DefectCollectionSettingsForm.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId('receiver-form'),
    editScaleForm: false,
    settings: {
      name: '',
      locationId: undefined,
      scaleSettings: [],
      printerSetting: {
        printerSettingId: undefined,
        address: '',
        logTicketCopies: 1,
        byproductTicketCopies: 1,
        bolCopies: 1,
        showDefectQuantities: false,
        displayBarcode: false,
        enableCustomBol: false
      },
      loadCreationSetting: {
        receiverLoadCreationSettingId: undefined,
        receiverLoadCreationType: ReceiverLoadCreationTypes.LogsOnly,
        scanQR: false,
        printLoopPasses: false,
        jumpToWeighInScreen: false,
        specifyDeckOnWeighIn: false,
        showExternalTicket: false,
        forcedProductSelection: false,
        forcedDeckSelection: false,
        collectAverageLength: false,
        logContractLoadCountTimeFilter: ReceiverLogContractLoadCountTimeFilter.Day,
        logContractFilterType: ReceiverLogContractFilterTypes.Account,
        byproductSaleContractFilterType: ReceiverByproductSaleContractFilterTypes.Account,
        byproductPurchaseContractFilterType: ReceiverByproductPurchaseContractFilterTypes.FromAccountTract,
        createByproductDeliveryLoads: true,
        createByproductPickupLoads: false,
        createByproductPurchaseLoads: false,
        showUnloadingTruckCount: false,
        showLoadCount: false,
        hidePausedContracts: false,
        averageLengthMinimum: undefined,
        averageLengthMaximum: undefined,
        pieceCountMinimum: undefined,
        pieceCountMaximum: undefined,
        loadCountDurationDays: 1,
        loadCountDurationStartOfWeek: SystemDayOfWeek.Sunday
      },
      defectCollectionSetting: {
        defectCollectionSettingId: undefined,
        collectionBehavior: ReceiverDefectCollectionBehavior.Inbound,
        collectionUnit: ReceiverDefectCollectionUnit.Pounds
      }
    },
    rules: fieldRules.rules,
    staleSettings: {},
    focusedScale: undefined
  }),

  created () {
    if (this.propSettings) {
      const set = JSON.parse(JSON.stringify(this.propSettings))
      set.loadCreationSetting.receiverLoadCreationType = ReceiverLoadCreationTypes.forInt(set.loadCreationSetting.receiverLoadCreationType)
      set.loadCreationSetting.logContractLoadCountTimeFilter = ReceiverLogContractLoadCountTimeFilter.forInt(set.loadCreationSetting.logContractLoadCountTimeFilter)
      set.loadCreationSetting.logContractFilterType = ReceiverLogContractFilterTypes.forInt(set.loadCreationSetting.logContractFilterType)
      set.loadCreationSetting.byproductSaleContractFilterType = ReceiverByproductSaleContractFilterTypes.forInt(set.loadCreationSetting.byproductSaleContractFilterType)
      set.loadCreationSetting.byproductPurchaseContractFilterType = ReceiverByproductPurchaseContractFilterTypes.forInt(set.loadCreationSetting.byproductPurchaseContractFilterType)
      set.loadCreationSetting.loadCountDurationStartOfWeek = SystemDayOfWeek.forInt(set.loadCreationSetting.loadCountDurationStartOfWeek)

      set.defectCollectionSetting.collectionBehavior = ReceiverDefectCollectionBehavior.forInt(set.defectCollectionSetting.collectionBehavior)
      set.defectCollectionSetting.collectionUnit = ReceiverDefectCollectionUnit.forInt(set.defectCollectionSetting.collectionUnit)

      set.scaleSettings.forEach(scale => {
        scale.connectionType = ReceiverScaleConnectionTypes.forInt(scale.connectionType)
      })
      this.settings = JSON.parse(JSON.stringify(set))
    } else {
      if (this.defectCollectionMode === DefectCollectionType.Segmented.value) {
        this.settings.defectCollectionSetting.collectionBehavior = ReceiverDefectCollectionBehavior.Unloader
        this.settings.defectCollectionSetting.collectionUnit = ReceiverDefectCollectionUnit.Segmented
      } else if (this.defectCollectionMode === DefectCollectionType.Itemized.value) {
        this.settings.defectCollectionSetting.collectionUnit = ReceiverDefectCollectionUnit.Itemized
      }
    }
    this.settings.locationId = this.locationId
    this.staleSettings = JSON.parse(JSON.stringify(this.settings))
  },

  computed: {
    hasScales () {
      return this.settings.scaleSettings.length > 0
    },

    noScalesText () {
      return this.isEditing ? this.$t('noScalesText', { mode: this.$t('toEdit') }) : this.$t('noScalesText', { mode: this.$t('toCreate') })
    },

    title () {
      return this.isEditing ? this.$t('editing', { name: this.propSettings.name }) : this.$t('modifyReceiverSettings', { modify: this.$t('creating') })
    },

    displayDetails () {
      return {
        logs: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.LogsOnly.value,
        both: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.LogsAndByproducts.value,
        byproducts: this.settings.loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.ByproductsOnly.value,
        byproductDelivery: this.settings.loadCreationSetting.createByproductDeliveryLoads
      }
    },
    enableSubmit () {
      const formChanged = !isEqual(this.settings.defectCollectionSetting, this.staleSettings.defectCollectionSetting) ||
        !isEqual(this.settings.loadCreationSetting, this.staleSettings.loadCreationSetting) ||
        !isEqual(this.settings.printerSetting, this.staleSettings.printerSetting) ||
        this.settings.name !== this.staleSettings.name ||
        !isEqual(this.settings.scaleSettings, this.staleSettings.scaleSettings)
      return formChanged && this.isValid
    },

    uniqueScaleItems () {
      const scaleNames = new Set()
      const scaleAddresses = new Set()
      this.settings.scaleSettings.forEach(item => {
        scaleNames.add(item.name.toLowerCase())
        scaleAddresses.add(item.address.toLowerCase())
      })
      return {
        scaleNames,
        scaleAddresses
      }
    },

    isValid () {
      const loadCreationSetting = this.settings.loadCreationSetting
      return (loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.LogsAndByproducts.value ||
        loadCreationSetting.receiverLoadCreationType.value === ReceiverLoadCreationTypes.ByproductsOnly.value)
        ? loadCreationSetting.createByproductDeliveryLoads || loadCreationSetting.createByproductPickupLoads || loadCreationSetting.createByproductPurchaseLoads
        : true
    }
  },

  methods: {
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('receiver-settings', ['updateReceiverSettingsById', 'createReceiverSettings', 'fetchReceiverSettings']),

    resetDialogs () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.editScaleForm = false
    },

    editScale (scale) {
      this.focusedScale = scale
      this.editScaleForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    },

    modifyScale (scale) {
      const index = this.findScaleWithInfo(this.focusedScale)
      this.settings.scaleSettings[index].locationScaleSettingId = scale.locationScaleSettingId
      this.settings.scaleSettings[index].name = scale.name
      this.settings.scaleSettings[index].address = scale.address
      this.settings.scaleSettings[index].port = scale.port
      this.settings.scaleSettings[index].delimiter = scale.delimiter
      this.settings.scaleSettings[index].weighInMotion = scale.weighInMotion
      this.settings.scaleSettings[index].skip = scale.skip
      this.settings.scaleSettings[index].connectionType = scale.connectionType
    },

    refreshScale (scale) {
      const isHttp = scale.connectionType.value === ReceiverScaleConnectionTypes.HTTP.value
      const isTCP = scale.connectionType.value === ReceiverScaleConnectionTypes.TCP.value
      const isCustom = scale.connectionType.value === ReceiverScaleConnectionTypes.Custom.value

      return {
        locationScaleSettingId: scale.locationScaleSettingId,
        name: scale.name.trim(),
        address: scale.address.trim(),
        port: !isHttp ? Number(scale.port) : undefined,
        delimiter: isCustom ? Number(scale.delimiter) : undefined,
        weighInMotion: scale.weighInMotion && isTCP,
        skip: isCustom ? Number(scale.skip) : undefined,
        connectionType: scale.connectionType.value
      }
    },

    createScale (scale) {
      this.settings.scaleSettings.push(JSON.parse(JSON.stringify(scale)))
    },

    deleteScale (scale) {
      const index = this.findScaleWithInfo(scale)
      this.settings.scaleSettings.splice(index, 1)
    },

    findScaleWithInfo (scale) {
      return this.settings.scaleSettings.findIndex(s => {
        return s.address === scale.address && s.name === scale.name
      })
    },

    updatePrinterSettings (printer) {
      this.settings.printerSetting = { ...printer }
    },

    updateDefectSettings (defect) {
      this.settings.defectCollectionSetting = {
        defectCollectionSettingId: defect.defectCollectionSettingId,
        collectionBehavior: defect.collectionBehavior,
        collectionUnit: defect.collectionUnit
      }
    },

    updateLoadCreationSettings (loadCreation) {
      this.settings.loadCreationSetting = {
        receiverLoadCreationSettingId: loadCreation.receiverLoadCreationSettingId,
        receiverLoadCreationType: loadCreation.receiverLoadCreationType,
        scanQR: loadCreation.scanQR,
        printLoopPasses: loadCreation.printLoopPasses,
        jumpToWeighInScreen: loadCreation.jumpToWeighInScreen,
        specifyDeckOnWeighIn: loadCreation.specifyDeckOnWeighIn,
        showExternalTicket: loadCreation.showExternalTicket,
        forcedProductSelection: loadCreation.forcedProductSelection,
        forcedDeckSelection: loadCreation.forcedDeckSelection,
        collectAverageLength: loadCreation.collectAverageLength,
        logContractLoadCountTimeFilter: loadCreation.logContractLoadCountTimeFilter,
        logContractFilterType: loadCreation.logContractFilterType,
        byproductSaleContractFilterType: loadCreation.byproductSaleContractFilterType,
        byproductPurchaseContractFilterType: loadCreation.byproductPurchaseContractFilterType,
        createByproductDeliveryLoads: loadCreation.createByproductDeliveryLoads,
        createByproductPickupLoads: loadCreation.createByproductPickupLoads,
        createByproductPurchaseLoads: loadCreation.createByproductPurchaseLoads,
        showUnloadingTruckCount: loadCreation.showUnloadingTruckCount,
        showLoadCount: loadCreation.showLoadCount,
        hidePausedContracts: loadCreation.hidePausedContracts,
        averageLengthMinimum: loadCreation.averageLengthMinimum,
        averageLengthMaximum: loadCreation.averageLengthMaximum,
        pieceCountMinimum: loadCreation.pieceCountMinimum,
        pieceCountMaximum: loadCreation.pieceCountMaximum,
        loadCountDurationDays: loadCreation.loadCountDurationDays,
        loadCountDurationStartOfWeek: loadCreation.loadCountDurationStartOfWeek
      }
    },

    createSettingsObject () {
      const scaleSettings = []
      this.settings.scaleSettings.forEach(scale => {
        scaleSettings.push(this.refreshScale(scale))
      })
      const printerSetting = {
        printerSettingsId: this.settings.printerSettingId,
        address: this.settings.printerSetting.address,
        logTicketCopies: Number(this.settings.printerSetting.logTicketCopies),
        byproductTicketCopies: Number(this.settings.printerSetting.byproductTicketCopies),
        bolCopies: Number(this.settings.printerSetting.bolCopies),
        showDefectQuantities: this.settings.printerSetting.showDefectQuantities,
        displayBarcode: (this.displayDetails.byproducts || this.displayDetails.both) && this.displayDetails.byproductDelivery ? this.settings.printerSetting.displayBarcode : false,
        enableCustomBol: this.settings.printerSetting.enableCustomBol
      }
      const loadCreationSetting = {
        loadCreationSettingId: this.settings.loadCreationSetting.defectCollectionSettingId,
        receiverLoadCreationType: this.settings.loadCreationSetting.receiverLoadCreationType.value,
        scanQR: this.settings.loadCreationSetting.scanQR,
        printLoopPasses: this.settings.loadCreationSetting.printLoopPasses,
        enableCustomBol: this.settings.loadCreationSetting.enableCustomBol,
        jumpToWeighInScreen: this.settings.loadCreationSetting.jumpToWeighInScreen,
        specifyDeckOnWeighIn: this.settings.loadCreationSetting.specifyDeckOnWeighIn,
        showExternalTicket: this.settings.loadCreationSetting.showExternalTicket,
        forcedProductSelection: this.settings.loadCreationSetting.forcedProductSelection,
        forcedDeckSelection: this.settings.loadCreationSetting.forcedDeckSelection,
        collectAverageLength: this.settings.loadCreationSetting.collectAverageLength,
        logContractLoadCountTimeFilter: this.settings.loadCreationSetting.logContractLoadCountTimeFilter.value,
        logContractFilterType: this.settings.loadCreationSetting.logContractFilterType.value,
        byproductSaleContractFilterType: this.settings.loadCreationSetting.byproductSaleContractFilterType.value,
        byproductPurchaseContractFilterType: this.settings.loadCreationSetting.byproductPurchaseContractFilterType.value,
        createByproductDeliveryLoads: this.settings.loadCreationSetting.createByproductDeliveryLoads,
        createByproductPickupLoads: this.settings.loadCreationSetting.createByproductPickupLoads,
        createByproductPurchaseLoads: this.settings.loadCreationSetting.createByproductPurchaseLoads,
        showUnloadingTruckCount: this.settings.loadCreationSetting.showUnloadingTruckCount,
        showLoadCount: this.settings.loadCreationSetting.showLoadCount,
        hidePausedContracts: this.settings.loadCreationSetting.hidePausedContracts,
        averageLengthMinimum: this.settings.loadCreationSetting.averageLengthMinimum,
        averageLengthMaximum: this.settings.loadCreationSetting.averageLengthMaximum,
        pieceCountMinimum: this.settings.loadCreationSetting.pieceCountMinimum,
        pieceCountMaximum: this.settings.loadCreationSetting.pieceCountMaximum,
        loadCountDurationDays: Number(this.settings.loadCreationSetting.loadCountDurationDays),
        loadCountDurationStartOfWeek: this.settings.loadCreationSetting.loadCountDurationStartOfWeek.value
      }
      const defectCollectionSetting = {
        defectCollectionSettingId: this.settings.defectCollectionSettingId,
        collectionBehavior: this.settings.defectCollectionSetting.collectionBehavior.value,
        collectionUnit: this.settings.defectCollectionSetting.collectionUnit.value
      }

      return {
        name: this.settings.name,
        locationId: this.settings.locationId,
        locationReceiverSettingId: this.settings.locationReceiverSettingId,
        scaleSettings,
        printerSetting,
        defectCollectionSetting,
        loadCreationSetting
      }
    },

    async saveButtonClicked () {
      const object = this.createSettingsObject()
      if (this.settings.locationReceiverSettingId) {
        await this.updateReceiverSettingsById({ receiverId: this.settings.locationReceiverSettingId, locationId: this.locationId, settings: object })
      } else {
        await this.createReceiverSettings({ locationId: this.locationId, settings: object })
      }
      await this.fetchReceiverSettings(this.locationId)
      this.$emit('close')
    }
  }
}
</script>
