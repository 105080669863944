<template>
<v-col cols="12">
  <v-row>
    <v-col cols="12" lg="6" v-if="changedContract">
      <DetailCard :title="$t('changedContract')">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="6">
              <v-row>
                <v-chip small>{{$t('old')}}</v-chip>
              </v-row>
              <v-row v-for="cf in contractFields(changedContract.oldContract)" :key="`cf-old-${cf.icon}`">
                <v-icon small class="mr-2">{{ cf.icon }}</v-icon>
                <span class="text">{{ cf.value }}</span>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-chip small color="success">{{$t('new')}}</v-chip>
              </v-row>
              <v-row v-for="cf in contractFields(changedContract.newContract)" :key="`cf-old-${cf.icon}`">
                <v-icon small class="mr-2">{{ cf.icon }}</v-icon>
                <span class="text">{{ cf.value }}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </DetailCard>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedWeight">
      <DetailCard :title="$t('weightInformation').concat(useMetric ? ' (mt)' : ' (t)')">
        <template #title.append>
          <Icon
            :icon="!useMetric ? 'mdi-weight' : 'mdi-weight-pound'"
            @icon-clicked="useMetric = !useMetric"
            dataTestId="weight-info-button"
            v-if="showMetricToggle"
            :small="false"
            :tooltipText="useMetric ? $t('shortTons') : $t('metricTons')"
          />
        </template>
        <v-row no-gutters>
          <v-col cols="6">
            {{ $t('inWeight') }}:
          </v-col>
          <v-col>
            <span v-html="changedWeight.inWeight"/>
          </v-col>
          <v-col cols="6">
            {{ $t('outWeight') }}:
          </v-col>
          <v-col>
            <span v-html="changedWeight.outWeight"/>
          </v-col>
          <v-col cols="6" v-if="isSimpleDefect">
            {{$t('defectWeight')}}:
          </v-col>
          <v-col v-if="isSimpleDefect">
            <span v-html="changedWeight.defectWeight"/>
          </v-col>
          <v-col cols="6" v-if="!isSimpleDefect">
            {{$t('natureDefectWeight')}}:
          </v-col>
          <v-col v-if="!isSimpleDefect">
            <span v-html="changedWeight.natureDefectWeight"/>
          </v-col>
          <v-col cols="6" v-if="!isSimpleDefect">
            {{$t('mismanufacturedDefectWeight')}}:
          </v-col>
          <v-col v-if="!isSimpleDefect">
            <span v-html="changedWeight.mismanufactureDefectWeight"/>
          </v-col>
          <v-col cols="6">
            {{$t('newNetWeight')}}:
          </v-col>
          <v-col>
            <span v-html="changedWeight.newNetWeight"/>
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedProduct">
      <DetailCard :title="$t('changedProduct')">
        <v-row dense>
          <v-col cols="6">
            <v-chip small>{{$t('old')}}</v-chip>
          </v-col>
          <v-col>
            {{changedProduct.oldProduct}}
          </v-col>
          <v-col cols="6">
            <v-chip small color="success">{{$t('new')}}</v-chip>
          </v-col>
          <v-col>
            {{changedProduct.newProduct}}
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
    <v-col cols="12" lg="6" v-if="changedDriver">
      <DetailCard :title="$t('changedDriver')">
        <v-row dense>
          <v-col cols="6">
            <v-chip small>{{$t('old')}}</v-chip>
          </v-col>
          <v-col>
            {{ticketDriverAccount ?? $t('notAvailable')}}
          </v-col>
          <v-col cols="6">
            <v-chip small color="success">{{$t('new')}}</v-chip>
          </v-col>
          <v-col>
            {{correctionDriverAccount ?? $t('notAvailable')}}
          </v-col>
        </v-row>
      </DetailCard>
    </v-col>
  </v-row>
</v-col>
</template>

<script>
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { ContractMode, DefectCategory, PayBy } from '../../../utils/Enumerations'
import { mapActions } from 'vuex'
export default {
  name: 'NewTicketValues',

  props: {
    correction: {
      type: Object,
      required: true
    },
    contractMode: {
      type: Object,
      required: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DetailCard: () => import('@/components/core/DetailCard.vue')
  },

  data: () => ({
    correctionDriverAccount: undefined,
    ticketDriverAccount: undefined,
    useMetric: false
  }),

  computed: {
    changedProduct () {
      const { product, ticket } = this.correction.correction
      if (!ticket || !product) { return false }
      if (ticket.productID === product.productId) { return false }

      return {
        oldProduct: ticket.productID === 0 ? 'Unspecified' : ticket.product,
        newProduct: product.name
      }
    },

    changedDriver () {
      return this.correction.correction.driverAccountId !== this.correction.correction.ticket.driverAccountId
    },

    showMetricToggle () {
      return this.correction?.newReceivables?.some(r => r.computedAtPayBy === PayBy.MetricWeight.value) ||
        this.correction?.correctedReceivables?.some(r => r.computedAtPayBy === PayBy.MetricWeight.value)
    },

    changedContract () {
      const { contract, ticket } = this.correction.correction
      if (!ticket || !contract) { return false }
      if (ticket.contractId === contract.contractId) { return false }

      return {
        oldContract: {
          account: ticket.account,
          tract: ticket.tract,
          fromAccount: ticket.fromAccount,
          destination: ticket.destination,
          setting: ticket.setting
        },
        newContract: {
          account: contract.account,
          tract: contract.tract,
          fromAccount: contract.fromAccount,
          destination: contract.destination,
          setting: contract.setting
        }
      }
    },

    changedWeight () {
      const correction = this.correction.correction
      const ticket = this.correction.correction.ticket

      if (!ticket) { return false }

      const oldNetWeight = ticket.inWeight - ticket.outWeight - ticket.defectWeight
      const newNetWeight = correction.inWeight - correction.outWeight - correction.defectWeight

      if (oldNetWeight === newNetWeight) return false

      const { natureDefectWeight, mismanufactureDefectWeight } = ticket.defects.reduce((weights, d) => {
        weights.natureDefectWeight += d.category === DefectCategory.Nature.value ? d.totalLBS : 0
        weights.mismanufactureDefectWeight += d.category === DefectCategory.Mismanufactured.value ? d.totalLBS : 0
        return weights
      }, { natureDefectWeight: 0, mismanufactureDefectWeight: 0 })

      const weightsToString = (oldWeight, newWeight) => {
        return oldWeight === newWeight
          ? this.$t('unchangedString', { str: tonStringFromPounds(oldWeight, 3, this.useMetric) })
          : `${tonStringFromPounds(oldWeight, 3, this.useMetric)} &rarr; ${tonStringFromPounds(newWeight, 3, this.useMetric)}`
      }

      return {
        inWeight: weightsToString(ticket.inWeight, correction.inWeight),
        outWeight: weightsToString(ticket.outWeight, correction.outWeight),
        defectWeight: weightsToString(ticket.defectWeight, correction.defectWeight),
        natureDefectWeight: weightsToString(natureDefectWeight, correction.natureDefectWeight),
        mismanufactureDefectWeight: weightsToString(mismanufactureDefectWeight, correction.mismanufactureDefectWeight),
        newNetWeight: weightsToString(oldNetWeight, newNetWeight)
      }
    },

    isSimpleDefect () { return this.correction.correction.ticket.defects.length === 0 }
  },

  async created () {
    if (!this.changedDriver) return

    if (this.correction.correction.driverAccountId) {
      const correctionAccount = await this.fetchAccountById(this.correction.correction.driverAccountId)
      this.correctionDriverAccount = correctionAccount.name
    }

    if (this.correction.correction.ticket.driverAccountId) {
      const ticketAccount = await this.fetchAccountById(this.correction.correction.ticket.driverAccountId)
      this.ticketDriverAccount = ticketAccount.name
    }
  },

  methods: {
    ...mapActions('account', ['fetchAccountById']),
    contractFields (contract) {
      return (this.contractMode.value === ContractMode.Logs.value && contract.fromAccount === null)
        ? [
          { icon: 'mdi-account', value: contract.account },
          { icon: 'mdi-crosshairs-gps', value: contract.tract },
          { icon: 'mdi-domain', value: contract.destination },
          { icon: 'mdi-map-marker-circle', value: contract.setting }
        ] : [
          { icon: 'mdi-account', value: contract.account },
          { icon: 'mdi-account-arrow-right', value: contract.fromAccount },
          { icon: 'mdi-domain', value: contract.destination }
        ]
    }
  }
}
</script>
