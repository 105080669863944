<template>
  <v-container fluid>
    <v-tabs
    v-model="currentSubModuleTab">
      <v-tab v-for="subModuleName in subModuleNames" :key="subModuleName">
        <span>
          {{subModuleName}}
        </span>
      </v-tab>
      <v-spacer/>
      <Icon
        :icon="!metricTonsToggle ? 'mdi-weight' : 'mdi-weight-pound'"
        @icon-clicked="metricTonsToggle = !metricTonsToggle"
        :small="false"
        :tooltipText="metricTonsToggle ? $t('useShortTons') : $t('useMetricTons')"
      />
    </v-tabs>
    <v-tabs-items v-model="currentSubModuleTab" v-if="!loading && currentSubModule !== undefined && !showNoDataText" class="mt-2" touchless>
      <v-tab-item>
        <ReceivableRegister
        isByproduct
        :receivableRegisterObject="currentSubModule"/>
      </v-tab-item>
      <v-tab-item>
        <ReceivablesByPayer
        isByproduct
        :receivablesByContractObject="currentSubModule"
        @refresh-settlement="refreshSettlement"/>
      </v-tab-item>
    </v-tabs-items>
    <v-row class="my-12 pa-12" v-if="loading" justify="center" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </v-row>
    <v-row class="mt-4" v-if="showNoDataText" justify="center" align="center">
      <v-col cols="12">
        <NoData>
          {{ $t('noDataForSelection') }}
        </NoData>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SettlementKeys, SettlementSubKeys, SubKeyToTabMap } from '@/components/settlements/SettlementKeys.js'

export default {
  name: 'BPAccountsReceivableModule',

  components: {
    ReceivableRegister: () => import('@/components/settlements/accounts-receivable-module/receivable-register/ReceivableRegister.vue'),
    ReceivablesByPayer: () => import('@/components/settlements/accounts-receivable-module/ReceivablesByPayer.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    NoData: () => import('@/components/core/NoData.vue')
  },

  data: () => ({
    currentSubModuleTab: 0
  }),

  watch: {
    currentSubModuleTab (tab) {
      const subKey = SubKeyToTabMap.AR.Byproduct.find(kv => kv.Tab === tab)?.Key ?? SettlementSubKeys.BPReceivableRegister
      this.setCurrentModule({
        moduleKey: SettlementKeys.AccountsReceivable,
        subModuleKey: subKey
      })
    }
  },

  computed: {
    ...mapGetters('settlements', ['receivableModule', 'currentSubModuleKey', 'loading', 'useByproductMetricTons']),

    subModuleNames () {
      return [
        this.$t('receivableRegister'),
        this.$t('receivableByPayer')
      ]
    },

    metricTonsToggle: {
      get () {
        return this.useByproductMetricTons
      },
      set (value) {
        this.setUseByproductMetricTons(value)
      }
    },

    currentSubModule () {
      return this.receivableModule[this.currentSubModuleKey]
    },

    showNoDataText () {
      if (this.currentSubModule === undefined || this.loading) {
        return false
      }

      switch (this.currentSubModuleKey) {
        case SettlementSubKeys.AR.BPReceivableRegister:
          return this.currentSubModule.receivables.length === 0
        case SettlementSubKeys.AR.BPReceivablesByPayer:
          return this.currentSubModule.counterpartyDetails.length === 0
        default:
          return true
      }
    }
  },

  created () {
    this.currentSubModuleTab = SubKeyToTabMap.AR.Byproduct.find(kv => kv.Key === this.currentSubModuleKey)?.Tab ?? 0
  },

  methods: {
    ...mapActions('settlements', ['setCurrentModule', 'refreshModuleData', 'clearStaleSubModuleData']),
    ...mapMutations('settlements', ['setUseByproductMetricTons']),

    async refreshSettlement () {
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: true })
    }
  }
}
</script>
