<template>
  <v-col cols="auto" :align="!showEntitySelector ? 'right' : 'left'">
    <v-row dense>
      <v-col cols="auto">
        <Selector
        v-if="showEntitySelector"
        data-testid="dash-entity-select"
        :items="entities"
        :label="$t('entities')"
        :initialItem="initialEntity"
        @item-selected="entitySelected"
        itemText="name"
        itemValue="businessEntityId"
        class="mb-n8"
        />
      </v-col>
      <v-col cols="auto">
        <Selector
        v-show="!locationsLoading"
        data-testid="dash-location-select"
        :items="locations"
        :label="$t('locations')"
        :initialItem="initialLocation"
        :selectFirstOnChange="showEntitySelector"
        @item-selected="locationSelected"
        :equality="(a, b) => a?.locationId === b?.locationId"
        itemText="name"
        itemValue="locationId"
        class="mb-n8"
        />
      </v-col>
      <v-col cols="auto">
        <Selector
        v-show="!locationsLoading"
        data-testid="dash-deck-select"
        :items="decks"
        :initialItem="initialDeck"
        :label="$t('decks')"
        :selectFirstOnChange="true"
        @item-selected="deckSelected"
        itemValue="deckId"
        class="mb-n8"
        useCustomSlots>
        <template #selection="{item}">
          <span id="abbreviated-text" class="mr-2">
            {{item.name}}
          </span>
          <Icon
            :icon="getDeckTooltip(item).icon"
            :tooltipText="getDeckTooltip(item).text"
            :small="true"
            iconColor="black"
            propagateClick
            />
        </template>
        <template #item="{item}">
          <span class="mr-2">
            {{item.name}}
          </span>
          <Icon
            :icon="getDeckTooltip(item).icon"
            :tooltipText="getDeckTooltip(item).text"
            :small="true"
            iconColor="black"
            propagateClick
            />
        </template>
        </Selector>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import isEqual from 'lodash/isEqual'
import { LocalStorageKeys } from '@/utils/constants.js'
import { ConsumptionMode, DeckStatus, DeckContentType } from '../../utils/Enumerations'
export default {
  name: 'DeckSelector',

  props: {
    showEntitySelector: {
      type: Boolean,
      default: false,
      required: false
    },
    showAll: {
      type: Boolean,
      default: true
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    propInitialLocation: {
      type: Object,
      required: false,
      default: undefined
    },
    propInitialEntity: {
      type: Object,
      required: false,
      default: undefined
    },
    limitToOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    propDeckId: {
      type: Number,
      required: false,
      default: undefined
    },
    propLocationId: {
      type: Number,
      required: false,
      default: undefined
    },
    currentDeckId: {
      type: Number,
      required: false,
      default: undefined
    },
    consumptionMode: {
      type: Number
    },
    contentType: {
      type: Number,
      required: false,
      default: undefined
    }
  },

  components: {
    Selector: () => import('@/components/core/Selector.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    initialEntity: undefined,
    selectedEntity: undefined,
    initialLocation: undefined,
    selectedLocation: undefined,
    initialDeck: undefined,
    selectedDeck: undefined
  }),

  computed: {
    ...mapGetters('locations', ['allLocations', 'locationsLoading']),
    ...mapGetters('user', ['businessEntities']),
    ...mapGetters('ticket', ['contractMode']),

    locationsFromSelectedEntity () {
      const sourceEntity = this.selectedEntity ?? this.initialEntity
      if (sourceEntity === undefined) return this.locationsByDeckContents
      return this.locationsByDeckContents.filter(l => sourceEntity.locations.find(entityLocation => entityLocation.locationId === l.locationId))
    },

    currentLocations () {
      return this.showEntitySelector ? this.locationsFromSelectedEntity : this.allLocations
    },

    locationsByDeckContents () {
      return this.allLocations.filter(l => l.decks.some(d => d.contentType === (this.contentType ?? DeckContentType.Logs.value)))
    },

    decks () {
      if (this.selectedLocation === undefined) { return [] }
      let { decks } = JSON.parse(JSON.stringify(this.selectedLocation))
      if (this.limitToOpen) decks = decks.filter(d => d.status === DeckStatus.Open.value)
      if (decks.length === 1) { return decks }

      if (this.showAll) {
        decks.unshift({
          name: 'All Decks',
          deckId: -1,
          allDecks: decks.filter(d => d.deckId !== -1),
          contentType: this.contentType
        })
      }

      const filteredDecks = decks
        .filter(d => this.consumptionMode === undefined || (d.consumptionMode === ConsumptionMode.WeightBased.value || d.consumptionMode === this.consumptionMode))
        .filter(d => this.contentType === undefined || d.contentType === this.contentType)
        .filter(d => !this.currentDeckId || d.deckId !== this.currentDeckId)
      return filteredDecks
    },

    locations () {
      const deckMatchesContentType = deck => this.contentType === undefined || deck.contentType === this.contentType
      const locationHasDecksWithCorrectConsumptionMode = location =>
        this.consumptionMode === undefined ||
        location.decks.some(d => deckMatchesContentType(d) && d.deckId !== this.currentDeckId && (d.consumptionMode === ConsumptionMode.WeightBased.value || d.consumptionMode === this.consumptionMode))

      const filteredLocations = this.currentLocations
        .filter(location => location.decks.some(deckMatchesContentType))
        .filter(location => !this.limitToOpen || location.decks.filter(deckMatchesContentType).some(d => d.status === DeckStatus.Open.value))
        .filter(locationHasDecksWithCorrectConsumptionMode)

      filteredLocations.forEach(location => {
        location.decks.forEach(deck => {
          deck.locationName = location.name
          deck.locationId = location.locationId
        })
      })

      if (filteredLocations.length > 1 && this.showAll) {
        filteredLocations.unshift({
          locationId: -1,
          name: 'All Locations',
          decks: filteredLocations.map(location => location.decks).reduce((a, b) => a.concat(b), [])
        })
      }
      return filteredLocations
    },

    entities () {
      const locationsFromEntity = entity => entity === undefined
        ? []
        : this.locationsByDeckContents
          .filter(l => entity.locations.find(entityLocation => entityLocation.locationId === l.locationId))

      return this.businessEntities
        .filter(e => locationsFromEntity(e).some(l => l.decks.length > 0))
    }
  },

  created () {
    this.initFieldsByLocation()
    if (this.showEntitySelector && this.businessEntities.length === 0) {
      this.fetchAllBusinessEntities()
    }
  },

  methods: {
    ...mapActions('user', ['fetchAllBusinessEntities']),
    initFieldsByLocation () {
      if (this.isDashboard) {
        const locationIdFromStorage = localStorage.getItem(LocalStorageKeys[this.contractMode.value === 0 ? 'DASHBOARD_LOCATION_ID' : 'DASHBOARD_BP_LOCATION_ID'])
        if (locationIdFromStorage) {
          this.initialLocation = this.locations.find(l => l.locationId === parseInt(locationIdFromStorage))
          this.initialDeck = this.initialLocation.decks[0]
        } else {
          this.initialLocation = this.locations[0]
        }
      } else if (this.propInitialLocation) {
        this.initialLocation = this.locationsByDeckContents.find(l => l.locationId === this.propInitialLocation.locationId)
        this.initialDeck = this.initialLocation?.decks.find(d => d.deckId === this.propDeckId)

        if (this.showEntitySelector) {
          this.initialEntity = this.businessEntities.find(e => {
            return e.locations.find(entityLocation => entityLocation.locationId === this.propInitialLocation.locationId)
          })
        }
      }
    },

    getDeckTooltip ({ consumptionMode }) {
      switch (consumptionMode) {
        case 0:
          return {
            text: this.$t('ticketBased'),
            icon: 'mdi-clipboard-outline'
          }
        case 1:
          return {
            text: this.$t('weightBased'),
            icon: 'mdi-weight'
          }
        default:
          return {
            text: this.$t('ticketBased'),
            icon: 'mdi-clipboard-outline'
          }
      }
    },

    entitySelected (entity) {
      if (this.selectedEntity?.businessEntityId === entity?.businessEntityId) return
      this.selectedEntity = entity

      this.$emit('entity-selected', entity)
      if (this.propLocationId !== undefined) {
        const location = this.locationsFromSelectedEntity.find(l => l.locationId === this.propLocationId)
        if (location !== undefined) {
          this.locationSelected(location)
          return
        }
      }
      if (this.locationsFromSelectedEntity.length > 0) {
        this.locationSelected(this.locationsFromSelectedEntity[0])
      } else {
        this.selectedLocation = undefined
        this.selectedDeck = undefined
      }
    },

    locationSelected (location) {
      if (isEqual(location, this.selectedLocation) && this.selectedLocation !== undefined) { return }
      this.selectedLocation = location
      this.$emit('location-selected', location)
      if (this.isDashboard) localStorage.setItem(LocalStorageKeys[this.contractMode.value === 0 ? 'DASHBOARD_LOCATION_ID' : 'DASHBOARD_BP_LOCATION_ID'], location.locationId)
      if (this.decks.length > 0) {
        this.deckSelected(this.decks[0])
      } else {
        this.selectedDeck = undefined
      }
    },

    deckSelected (deck) {
      if (!isEqual(deck, this.selectedDeck)) {
        this.$emit('deck-selected', deck)
      }
      this.selectedDeck = deck
    }
  }
}
</script>

<style scoped>
  #abbreviated-text {
    display: inline-block;
    max-width: 15em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
