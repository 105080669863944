<template>
  <v-row no-gutters dense>
    <v-col cols="12">
      <v-card flat>
        <v-card-title class="subtitle-1">
          {{$t('corrections')}}
        </v-card-title>
        <v-card-text>
          <v-data-table
          v-if="ticketCorrections.length > 0"
          :items="ticketCorrections"
          hide-default-footer
          dense
          :headers="headers">
            <template #item.exportStatus="{item}">
              <Icon
                v-if="showExportedIcon(item)"
                icon="mdi-checkbox-marked-circle"
                iconColor="success"
                :tooltipText="$t('exported')"
                @icon-clicked="viewDetailedCorrection(item)"
              />
            </template>
            <template #item.type="{ item }">
              <span id="table-shortcut" @click="viewDetailedCorrection(item)">{{correctionTypeFromInt(item.correction.type)}}</span>
            </template>
            <template #item.createdBy="{item}">
              <span id="table-shortcut" @click="viewDetailedCorrection(item)">{{item.correction.creationUser.name}}</span>
            </template>
            <template #item.notes="{item}">
              <span id="table-shortcut" @click="viewDetailedCorrection(item)">{{ item.correction.notes }}</span>
            </template>
            <template #item.createdAt="{item}">
              <span id="table-shortcut" @click="viewDetailedCorrection(item)">{{utcToLocalDate(item.correction.createdAt, 'L - LT')}}</span>
            </template>
            <template #item.actions="{item}">
              <Icon
              icon="mdi-delete-forever"
              :small="false"
              :tooltipText="$t('deleteCorrection')"
              iconColor="error"
              @icon-clicked="deleteCorrection(item)"
              :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
            </template>
            <template #top>
              <DetailedCorrectionDialog
              v-if="correctionDetailDialog"
              :ticketId="focusedCorrection.correction.ticketId"
              :correctionId="focusedCorrection.correction.ticketCorrectionId"
              @close="closeCorrectionDetail"
              v-bind="{ contractMode }"/>
            </template>
          </v-data-table>
          <span v-else>
            {{$t('noTicketCorrections')}}
          </span>
        </v-card-text>
      </v-card>
      <v-dialog v-model="confirmDeleteDialog" width="400">
        <ConfirmDialog
        :title="$t('deleteCorrection')"
        :body="$t('deleteCorrectionBody')"
        @confirm="confirmDelete"
        @cancel="closeDeleteDialog"/>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import TicketHeaders from '@/headers/Ticket'
import { tonStringFromPounds } from '@/utils/NumericMutations.js'
import { CorrectionType, UserClaims, CorrectionStatus } from '@/utils/Enumerations.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { mapActions } from 'vuex'
export default {
  name: 'CorrectionTable',
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    ticket: {
      type: Object
    },
    ticketCorrections: {
      type: Array,
      default: () => []
    },
    contractMode: {
      type: Object,
      required: true
    }
  },
  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    DetailedCorrectionDialog: () => import('@/components/ticket/corrections/DetailedCorrectionDialog.vue')
  },

  data: () => ({
    focusedCorrection: undefined,
    correctionDetailDialog: false,
    confirmDeleteDialog: false,
    UserClaims,
    CorrectionStatus
  }),

  computed: {
    headers () {
      const headers = TicketHeaders.ticketCorrectionHeaders()
      if (this.ticketCorrections && this.ticketCorrections.filter(tc => tc?.correction?.status === CorrectionStatus.Exported.value).length === 0) {
        return headers.slice(1, headers.length)
      }
      return headers
    }
  },

  methods: {
    tonStringFromPounds,
    utcToLocalDate,
    userAssignedClaim,
    ...mapActions('ticket', ['deleteTicketCorrection']),
    correctionTypeFromInt (x) {
      return CorrectionType.fromInt(x)
    },

    closeCorrectionDetail (shouldRefresh) {
      this.focusedCorrection = undefined
      this.correctionDetailDialog = false
      if (shouldRefresh) {
        this.$emit('refresh')
      }
    },

    async viewDetailedCorrection (correction) {
      this.focusedCorrection = correction
      this.correctionDetailDialog = true
    },

    deleteCorrection (correction) {
      if (correction.correction.status === CorrectionStatus.Exported.value) {
        this.setSnackError(this.$t('exportedCorrectionsCannotBeDeleted'))
        return
      }
      this.focusedCorrection = correction
      this.confirmDeleteDialog = true
    },

    closeDeleteDialog () {
      this.focusedCorrection = undefined
      this.confirmDeleteDialog = false
    },

    showExportedIcon (correction) {
      return correction?.correction?.status === CorrectionStatus.Exported.value
    },

    async confirmDelete () {
      const deleteRequest = {
        ticketId: this.focusedCorrection.correction.ticketId,
        ticketCorrectionId: this.focusedCorrection.correction.ticketCorrectionId
      }
      await this.deleteTicketCorrection(deleteRequest)
      this.$emit('refresh')
      this.closeDeleteDialog()
    }
  }
}
</script>
