<template>
  <v-card flat class="grey lighten-3">
    <v-card-text class="fill-height">
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="auto">
          <span>
            <slot/>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'NoData'
}
</script>
