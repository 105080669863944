<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="item in items"
          :key="`item-key-${item.value}`"
          cols="12" lg="6" xl="4"
          @click="getCsv(item)"
        >
          <v-card
            hover
            min-height=150
            class="d-flex align-center justify-center"
          >
            <v-card-title>
              <span>{{$t('modificationHistoryTitle', { type: $t(item.label) })}}</span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="getCsvDialog" width="500px">
      <GetCsvDialog
        v-if="getCsvDialog"
        :csvType="selected"
        @csv-request-submitted="close"
        @cancel="close"
      />
    </v-dialog>
  </div>
</template>

<script>
import { ModificationExportType } from '@/utils/Enumerations.js'
export default {
  name: 'ModificationHistoryDownloads',

  data: () => ({
    items: ModificationExportType.enums,
    getCsvDialog: false,
    selected: undefined
  }),

  components: {
    GetCsvDialog: () => import('./GetCsvDialog.vue')
  },

  methods: {
    getCsv (item) {
      this.selected = item
      this.getCsvDialog = true
    },

    close () {
      this.getCsvDialog = false
    }
  }
}
</script>
