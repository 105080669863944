<template>
  <v-card data-testid="certification-form">
    <v-card-title class="primary white--text">
      <span class="headline">{{ title }}</span>
      <v-spacer/>
      <BaseDialogActions hideRefresh hideFullscreen/>
    </v-card-title>
    <v-card-text>
      <FormWrapper
        ref="form"
        formRef="certificationForm"
        testId="save-certification"
        :lazyValidation="true"
        :buttonText="saveBtnText"
        @submit="saveCertification"
      >
      <v-container grid-list-lg>
        <v-row wrap>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="certification.name"
              data-testid="certification-name"
              :rules="[rules.required]"
              :label="$t('name')"
              ref="firstField"
              color="black"
              counter="64"
              maxlength="64"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
            <v-text-field
              v-model="certification.code"
              data-testid="certification-code"
              :label="$t('code')"
              color="black"
              counter="5"
              maxlength="5"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import fieldRules from '@/utils/rules'
import { CertificationCategory } from '@/utils/Enumerations'
import { mapActions } from 'vuex'
export default {
  name: 'CertificationForm',

  components: {
    BaseDialogActions: () => import('@/components/core/BaseDialogActions.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  props: {
    isEditing: Boolean,
    certificationCategory: String,
    propCertification: Object
  },

  data: () => ({
    rules: fieldRules.rules,
    certification: {
      certificationId: -1,
      code: '',
      name: ''
    }
  }),

  created () {
    if (this.isEditing) {
      this.certification = JSON.parse(JSON.stringify(this.propCertification))
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  computed: {
    title () {
      return this.isEditing ? this.$t('editingCertification', { type: this.certificationCategory }) : this.$t('creatingCertification', { type: this.certificationCategory })
    },

    saveBtnText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('createNewCertification')
    },
    category () {
      return this.propCertification?.category ?? this.certificationCategory
    }
  },

  methods: {
    ...mapActions('certification', ['createCertification', 'updateCertification']),

    async saveCertification () {
      if (!this.$refs.form.$refs.certificationForm.validate()) {
        return
      }

      if (this.isEditing) {
        await this.updateCertification(this.certification)
        this.$emit('certification-mutated')
      } else {
        const category = CertificationCategory.toInt(this.category)
        const response = await this.createCertification({
          name: this.certification.name,
          code: this.certification.code,
          category
        })
        this.$emit('certification-mutated', response)
      }
    }
  }
}
</script>
