<template>
  <v-autocomplete
  v-model="selectedProducts"
  :items="products"
  :label="$t('products')"
  :hint="hintText"
  :persistent-hint="cruiseProductIds !== null"
  item-value="name"
  item-text="name"
  return-object
  multiple
  chips
  deletable-chips>
    <template #selection="{item}">
      <v-chip close @click:close="selectedProducts = selectedProducts.filter(sp => sp.productId !== item.productId)">
        {{ item.name }}
        <v-tooltip bottom color="byproduct" v-if="item.isByProduct && includeLogsProducts">
          <template #activator="{on}">
            <v-icon right color="byproduct" v-on="on">
              mdi-circular-saw
            </v-icon>
          </template>
          <span class="subtitle-1 white--text" style="white-space: pre-line">
            {{ $t('byProduct') }}
          </span>
        </v-tooltip>
      </v-chip>
    </template>
    <template #item="{item}">
      {{ item.name }}
      <v-tooltip bottom color="byproduct" v-if="item.isByProduct && includeLogsProducts">
          <template #activator="{on}">
            <v-icon right color="byproduct" v-on="on">
              mdi-circular-saw
            </v-icon>
          </template>
          <span class="subtitle-1 white--text" style="white-space: pre-line">
            {{ $t('byProduct') }}
          </span>
        </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ProductSelector',

  props: {
    propProducts: {
      type: Array
    },
    cruiseProductIds: {
      type: Array,
      default: null
    },
    includeByproducts: {
      type: Boolean,
      default: true
    },
    includeLogsProducts: {
      type: Boolean,
      default: true
    },
    excludeProductIds: {
      type: Array,
      default: undefined
    }
  },

  data: () => ({
    products: [],
    selectedProducts: []
  }),

  computed: {
    hintText () {
      return this.cruiseProductIds !== null
        ? this.$t('filteredByCruiseData')
        : ''
    }
  },

  watch: {
    selectedProducts: {
      handler (val) {
        this.$emit('products-selected', val)
      },
      deep: true
    },

    includeByproducts: {
      async handler (_) {
        await this.getProducts()
      }
    }
  },

  created () {
    this.getProducts()
  },

  methods: {
    ...mapActions('product', ['fetchProducts']),
    async getProducts () {
      const products = await this.fetchProducts({ includeByProducts: this.includeByproducts, includeProduction: this.includeLogsProducts })

      this.products = products.filter(p =>
        (!this.cruiseProductIds || this.cruiseProductIds.includes(p.productId)) &&
        (!this.excludeProductIds || !this.excludeProductIds.includes(p.productId))
      )

      if (!this.propProducts || this.propProducts.length === 0) return
      products.forEach(p => {
        const isProp = this.propProducts.findIndex(product => product.productId === p.productId) !== -1
        const isSelected = this.selectedProducts.findIndex(product => product.productId === p.productId) !== -1
        if (isProp && !isSelected) {
          this.selectedProducts.push(p)
        }
      })
    }
  }
}
</script>
