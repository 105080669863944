<template>
  <GridChartCard
    :title="title"
    ref="chartCard"
    @draw="createChart"
    :hasData="chartData.labels.length > 0"
    :noDataText="viewingPaused ? $t('noLoadsCurrentlyPaused') : undefined"
  >
    <template #actions>
      <Icon
      v-if="isInteractive && getIcon"
      iconColor="white"
      :tooltipText="productGroupingTooltip"
      :small="false"
      margin="mr-3"
      :disabled="data.data.length === 0"
      @icon-clicked="groupByProduct = !groupByProduct"
      :icon="getIcon"/>
      <Icon
      iconColor="white"
      :tooltipText="viewingPaused ? $t('allLoads') : $t('pausedLoads')"
      :small="false"
      margin="mr-3"
      :disabled="data.data.length === 0 || noPausedLoads"
      @icon-clicked="viewingPaused = !viewingPaused"
      :icon="viewingPaused ? 'mdi-pound' : 'mdi-pause-circle-outline'"/>
    </template>
  </GridChartCard>
</template>

<script>
import { Chart } from 'chart.js'
import { mapActions, mapGetters } from 'vuex'
import { byproductChartColorFor, logChartColorFor } from './ChartColors'
import IconHelpers from '@/utils/IconHelpers'

export default {
  name: 'StackedBarChartCardForTicketsForPickup',

  components: {
    GridChartCard: () => import('./GridChartCard.vue'),
    Icon: () => import('@/components/helper/Icon.vue')
  },

  data: () => ({
    IconHelpers,
    chart: null,
    viewingPaused: false,
    groupByProduct: true
  }),

  props: {
    data: {
      type: Object,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    isByproduct: {
      type: Boolean,
      default: false
    },
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    data: {
      handler () {
        this.scheduleDraw()
      },
      deep: true
    },

    viewingPaused () {
      this.scheduleDraw()
    },

    groupByProduct () {
      this.scheduleDraw()
    }
  },

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    title () {
      return (this.viewingPaused) ? this.$t('pausedLoads') : this.$t('loadsReadyForPickup')
    },

    headerStyle () {
      return this.isByproduct ? 'background-color: #363636; color: white;' : 'background-color: #d15f27; color: white;'
    },

    getToolTipText () {
      return this.viewingPaused ? this.countUnitToggleToolTip : this.weightUnitToggleToolTip
    },

    weightUnitToggleToolTip () {
      return (this.type === 'ItemizedDefectSummaryChart') ? 'Percentage by Tons' : 'Tons'
    },

    countUnitToggleToolTip () {
      return (this.type === 'ItemizedDefectSummaryChart') ? 'Percentage by Quantity' : 'Load Count'
    },

    noPausedLoads () {
      return this.data.data.every(d => d.paused === 0)
    },

    chartData () {
      const { dataSets } = this.data
      const missing = new Set(dataSets.labels.map((_, i) => dataSets.sets.every(s => (this.viewingPaused) ? s.data[i].pausedLoads === 0 : s.data[i].readyLoads === 0) ? i : -1))
      const notMissingIndex = (_, i) => !missing.has(i)

      const data = this.groupByProduct
        ? {
          labels: dataSets.labels.filter(notMissingIndex),
          datasets: dataSets.sets.map((set, index) => {
            return {
              label: set.label,
              data: set.data.filter(notMissingIndex).map(d => (this.viewingPaused) ? d.pausedLoads : d.readyLoads),
              ticketIds: set.ticketIds.filter(notMissingIndex).map(d => (this.viewingPaused) ? d.paused : d.ready),
              backgroundColor: this.isByproduct ? byproductChartColorFor(index, dataSets.sets.length) : logChartColorFor(index, dataSets.sets.length)
            }
          })
        }
        : {
          labels: dataSets.labels.filter(notMissingIndex),
          datasets: dataSets.labels.filter(notMissingIndex).reduce((a, _, index) => {
            a[0].data.push(dataSets.sets.map(s => s.data.filter(notMissingIndex)[index][this.viewingPaused ? 'pausedLoads' : 'readyLoads']).reduce((a, c) => a + c, 0))
            a[0].backgroundColor.push(this.isByproduct ? byproductChartColorFor(index, dataSets.labels.filter(notMissingIndex).length) : logChartColorFor(index, dataSets.labels.filter(notMissingIndex).length))
            const tn = []
            dataSets.sets.map(s => s.ticketIds.filter(notMissingIndex)[index][this.viewingPaused ? 'paused' : 'ready']).forEach(t => { t.forEach(t => tn.push(t)) })
            a[0].ticketIds.push(tn)
            return a
          }, [{ data: [], backgroundColor: [], ticketIds: [] }])
        }
      return data
    },

    getIcon () {
      return IconHelpers.getIconForObjectType('product', this.groupByProduct)
    },

    productGroupingTooltip () {
      return this.$t(this.groupByProduct
        ? 'disableGroupingByCategory'
        : 'groupByCategory',
      {
        category: this.$t('product')
      })
    }
  },

  methods: {
    ...mapActions('dashboard', ['getNestedCSV', 'getWidgetInteractionStatus']),

    scheduleDraw () {
      this.$nextTick(() => {
        if (this.$refs.chartCard) this.$refs.chartCard.draw()
      })
    },

    updateChart () {
      const { dataSets } = this.data
      this.chart.data.datasets.forEach((dataset, index) => {
        dataset.data = (this.viewingPaused) ? dataSets[index].data.pausedLoads : dataSets[index].data.readyLoads
      })
    },

    createChart (canvas) {
      const chart = new Chart(canvas, {
        type: 'bar',
        data: this.chartData,
        options: {
          maintainAspectRatio: false,
          responsive: true,
          resizeDelay: 500,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    },

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'point', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        const yAxisValue = data.datasets[element[0]?.datasetIndex]?.label
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue, y: yAxisValue })) this.$emit('data-clicked', { xAxisValue: xAxisValue, yAxisValue: yAxisValue, paused: this.viewingPaused })
      }
    }
  }
}
</script>
