import ApiServer from '@/utils/api/ApiServer.js'

const getContractPath = (query) => {
  if (query === undefined) {
    return 'v2/contracts'
  }

  const defaults = {
    includeOpen: false,
    includeOnHold: false,
    includeClosed: false,
    includeExpired: false,
    limitToDraft: false,
    limitToInternal: false,
    includeProduction: false,
    includeWoodsSale: false,
    includeByProduct: false,
    includeLogYardSale: false,
    limitToPaused: false,
    includeTransfer: false
  }

  const p = ApiServer.urlFor('contracts', {
    version: 2,
    params: {
      ...defaults,
      ...query
    }
  })

  return p
}

export default {
  initializeFilter (context) {
    context.commit('initializeFilter')
  },

  removeFilter (context, filter) {
    const currentFilter = JSON.parse(JSON.stringify(context.getters.filter))
    currentFilter[filter.key] = filter.defaultValue
    context.commit('setFilter', currentFilter)
  },
  setFilter (context, filter) {
    context.commit('setFilter', filter)
  },
  async fetchContracts (context, query) {
    try {
      context.commit('setLoading', true)
      const path = getContractPath(query)
      const response = await ApiServer.get(path)
      context.commit('setContracts', response.data)
      return response.data
    } finally {
      context.commit('setLoading', false)
    }
  },
  async fetchContractsForAutocomplete (_, query) {
    const { data: contracts } = await ApiServer.get(getContractPath(query))
    return contracts
  },
  async getContract (context, contractId) {
    context.commit('setLoading', true)
    const response = await ApiServer.get(`contracts/${contractId}`)

    context.commit('setLoading', false)
    return {
      ...response.data,
      productIds: response.data.products.map(p => p.productId)
    }
  },
  async deleteContract (context, contractId) {
    const path = `contracts/${contractId}`
    context.commit('setLoading', true)
    const response = await ApiServer.delete(path)
    context.commit('setLoading', false)
    return response.data
  },
  async createContract (context, contract) {
    context.commit('setLoading', true)
    const response = await ApiServer.post('contracts', contract)
    context.commit('setLoading', false)
    return response.data
  },
  async updateContract (context, contract) {
    context.commit('setLoading', true)
    try {
      const response = await ApiServer.put(`contracts/${contract.contractId}`, contract)
      return response
    } catch (e) {
      console.error(e)
    } finally {
      context.commit('setLoading', false)
    }
  }
}
