<template>
  <v-card data-testid="ticket-form">
    <v-card-title class="headline primary white--text">
      <span>
        {{ $t("ticketDefects") }}
      </span>
      <v-spacer />
      <Icon
        icon="mdi-close"
        iconColor="tertiary"
        dataTestId="ticket-defect-edit-close-button"
        :small="false"
        :large="true"
        margin=""
        :tooltipText="$t('close')"
        @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <v-row v-if="loading" class="mt-6" justify="center" align="center">
        <v-progress-circular indeterminate color="primary" />
      </v-row>
      <v-row v-else-if="!itemized" class="mt-6" justify="center" align="center">
        <p class="text-left">Cannot edit defects. Location uses segmented defects.</p>
      </v-row>
      <v-row v-else>
        <v-col>
          <DataTable
            item-key="defectDefinitionId"
            :items="allDefects"
            :headers="headers"
            :showSearch="false"
            :showDense="false"
            :customCells="customColumns"
          >
            <template #quantity="{ item }">
              <v-row class="flex-nowrap">
                <v-col cols="auto">
                  <Icon
                    iconColor="error"
                    icon="mdi-minus"
                    dataTestId="ticket-defect-decrement"
                    :disabled="
                      !item.isActive ||
                      defectDefinitionWeightChange ||
                      isSegmented
                    "
                    :small="false"
                    :tooltipText="$t('decrement')"
                    @icon-clicked="decrementQuantity(item)"
                  />
                </v-col>
                <v-col>
                  <span :class="item.isActive ? 'black--text' : 'grey--text'">{{
                    item.quantity
                  }}</span>
                </v-col>
                <v-col cols="auto">
                  <Icon
                    iconColor="success"
                    icon="mdi-plus"
                    dataTestId="ticket-defect-increment"
                    :disabled="
                      !item.isActive ||
                      defectDefinitionWeightChange ||
                      isSegmented ||
                      !enableIncrementIconTotalWeight ||
                      !enableIncrementIconQuantity(item)
                    "
                    :small="false"
                    :tooltipText="$t('increment')"
                    @icon-clicked="incrementQuantity(item)"
                  />
                </v-col>
              </v-row>
            </template>
            <template #body.append>
              <tr class="font-weight-bold">
                <td class="text-left">{{ $t("defectWeight") }}</td>
                <td>-</td>
                <td class="text-center">
                  {{ numberWithCommas(totalDefectWeight / 2000, 3) }} (t)
                </td>
              </tr>
            </template>
          </DataTable>
        </v-col>
      </v-row>
      <v-row v-if="itemized">
        <v-btn
          color="error"
          @click="deleting = true"
        >
          {{$t('deleteAll')}}
        </v-btn>
        <v-spacer />
        <v-btn
          data-testid="save-ticket-defect"
          color="primary"
          :disabled="defectDefinitionWeightChange || isSegmented"
          @click="inactiveTicketDefect ? inactiveDialog() : saveDefects()"
        >
          {{ $t("saveChanges") }}
        </v-btn>
      </v-row>
      <v-row v-else>
        <v-btn
          color="error"
          @click="deleting = true"
        >
          {{$t('deleteAll')}}
        </v-btn>
        <v-spacer/>
        <v-btn
          color="primary"
          @click="$emit('close')"
          >
          {{$t('cancel')}}
        </v-btn>
      </v-row>
    </v-card-text>
    <v-dialog v-model="deleting" max-width="400px">
      <ConfirmDialog
        :title="$t('deleteAllDefects')"
        :body="$t('deleteTicketDefects', { ticketNumber: ticket.ticketNumber })"
        :confirmText="$t('deleteAll')"
        @confirm="deleteAllDefects"
        @cancel="deleting = false"
      />
    </v-dialog>
    <v-dialog v-model="isInactiveDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('warning')"
        :body="$t('inactiveDefectDialogBody')"
        :confirmText="$t('saveChanges')"
        @confirm="saveDefects"
        @cancel="isInactiveDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="weightChangeDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('warning')"
        :body="$t('defectWeightChangeBody')"
        :confirmText="$t('okay')"
        :canCancel="false"
        @confirm="weightChangeDialog = false"
      />
    </v-dialog>
    <v-dialog v-model="segmentedDefectsDialog" max-width="400px">
      <ConfirmDialog
        :title="$t('warning')"
        :body="$t('segmentedDefectsBody')"
        :confirmText="$t('okay')"
        :canCancel="false"
        @confirm="segmentedDefectsDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { ticketDefectFormHeaders } from '@/headers/Defect.js'
import { mapGetters, mapActions } from 'vuex'
import { numberWithCommas } from '@/utils/NumericMutations.js'
import { DefectCollectionType } from '../../utils/Enumerations'

const UPPER_DEFECT_WEIGHT_BOUND = 500000
const UPPER_DEFECT_QUANTITY_BOUND = 5000

export default {
  name: 'TicketDefectsForm',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    ticket: {
      type: Object,
      default: undefined
    },
    contract: {
      type: Object,
      default: undefined
    },
    weightInPounds: {
      type: Boolean,
      default: true
    }
  },

  data: () => {
    return {
      loading: true,
      wasAltered: false,
      deleting: false,
      segmentedDefectsDialog: false,
      defectLocationId: undefined,
      allDefects: [],
      activeLocationDefects: [],
      totalDefectWeight: 0,
      alteredTicketDefectBody: [],
      inactiveTicketDefect: false,
      isInactiveDialog: false,
      defectDefinitionWeightChange: false,
      weightChangeDialog: false,
      itemized: true
    }
  },

  computed: {
    ...mapGetters('defects', ['allLocationDefects', 'allTicketDefects']),
    ...mapGetters('locations', ['allLocations']),

    headers () {
      return ticketDefectFormHeaders(this.$i18n.locale)
    },

    customColumns () {
      return [
        {
          slotName: 'quantity',
          value: 'quantity'
        }
      ]
    },

    isSegmented () {
      if (this.allTicketDefects.length === 0) return false
      const firstDefect = this.allTicketDefects[0]
      return firstDefect.diameter !== null
    },

    isItemized () {
      return this.allLocations.find(l => l.accountId === this.contract.destinationAccountId).defectCollectionMode === DefectCollectionType.Itemized.value
    },

    enableIncrementIconTotalWeight () {
      return this.totalDefectWeight < UPPER_DEFECT_WEIGHT_BOUND
    }
  },

  async created () {
    let matchingDefect
    this.loading = true
    if (this.isItemized) {
      try {
        this.totalDefectWeight = this.ticket.defectWeight * 1
        if (!this.weightInPounds) {
          this.totalDefectWeight *= 2000
        }
        await this.fetchLocations()
        this.defectLocationId = this.allLocations.find(location => location.name === this.contract.destination).locationId
        const ticketDefects = await this.fetchDefectsForTicketById(this.ticket.ticketId)
        const locationDefectDefinitions = await this.fetchDefectsForLocation(this.defectLocationId)
        this.initializeDefects(locationDefectDefinitions, ticketDefects)
        this.allTicketDefects.forEach(ticketDefect => {
          matchingDefect = this.allLocationDefects.find(locationDefect => ticketDefect.defectDefinitionId === locationDefect.defectDefinitionId)
          this.pushAlteredTicketToBody(ticketDefect, matchingDefect.isActive)
        })
        this.setDefectDefinitionWeightChange()
      } finally {
        this.loading = false
      }
    } else {
      this.itemized = false
      this.loading = false
    }
  },

  methods: {
    ...mapActions('defects', [
      'createLocationDefects',
      'fetchDefectsForTicketById',
      'fetchDefectsForLocation',
      'deleteAllTicketDefects'
    ]),
    ...mapActions('locations', ['fetchLocations']),
    numberWithCommas,

    incrementQuantity (item) {
      this.wasAltered = true
      item.quantity += 1
      this.updateDefectWeight(item, true)
      const matchingDefect = this.alteredTicketDefectBody.find(
        (bodyDefect) =>
          bodyDefect.defectDefinitionId === item.defectDefinitionId
      )
      if (!matchingDefect) {
        this.pushAlteredTicketToBody(item, item.isActive)
      } else {
        matchingDefect.quantity = item.quantity
      }
    },

    decrementQuantity (item) {
      this.wasAltered = true
      if (item.quantity > 1) {
        item.quantity -= 1
        this.generalDecrement(item)
        this.updateDefectWeight(item, false)
      } else {
        if (item.quantity === 1) {
          item.quantity -= 1
          this.updateDefectWeight(item, false)
        }
        this.zeroDecrement(item)
      }
    },

    generalDecrement (item) {
      const matchingDefect = this.alteredTicketDefectBody.find(
        (bodyDefect) =>
          bodyDefect.defectDefinitionId === item.defectDefinitionId
      )
      if (!matchingDefect) {
        this.pushAlteredTicketToBody(item, item.isActive)
      } else {
        matchingDefect.quantity = item.quantity
      }
    },

    zeroDecrement (item) {
      const defectIndex = this.alteredTicketDefectBody.findIndex(
        (bodyDefect) =>
          bodyDefect.defectDefinitionId === item.defectDefinitionId
      )
      if (defectIndex !== -1) {
        this.alteredTicketDefectBody.splice(defectIndex, 1)
      }
    },

    enableIncrementIconQuantity (item) {
      return item.quantity < UPPER_DEFECT_QUANTITY_BOUND
    },

    updateDefectWeight (item, isAdd) {
      if (isAdd) {
        this.totalDefectWeight += item.defectLBS
      } else {
        this.totalDefectWeight -= item.defectLBS
      }
    },

    pushAlteredTicketToBody (item, isActive) {
      if (isActive) {
        this.alteredTicketDefectBody.push({
          ticketId: this.ticket.ticketId,
          name: item.defectDefinitionId,
          defectDefinitionId: item.defectDefinitionId,
          quantity: item.quantity,
          isActive: isActive
        })
      } else {
        this.inactiveTicketDefect = true
      }
    },

    async deleteAllDefects () {
      this.loading = true
      try {
        await this.deleteAllTicketDefects(this.ticket)
        this.wasAltered = false
        this.inactiveTicketDefect = false
        this.isInactiveDialog = false
        this.$emit('close')
        this.$emit('refresh')
      } finally {
        this.loading = false
      }
    },

    async saveDefects () {
      if (this.alteredTicketDefectBody.length > 0) {
        this.loading = true
        try {
          await this.deleteAllTicketDefects(this.ticket)
          await this.createLocationDefects({
            defects: this.alteredTicketDefectBody,
            ticket: this.ticket
          })
          this.wasAltered = false
          this.inactiveTicketDefect = false
          this.isInactiveDialog = false
          this.$emit('close')
          this.$emit('refresh')
        } finally {
          this.loading = false
        }
      } else if (this.wasAltered || this.inactiveTicketDefect) {
        this.deleteAllDefects()
      }
    },

    initializeDefects (defectDefinitions, ticketDefects) {
      const ticketDefectQuantityMap = ticketDefects.reduce((map, defect) => {
        map[defect.defectDefinitionId] = defect.quantity
        return map
      }, {})
      this.allDefects = defectDefinitions.map(dd => ({
        ...dd,
        ticketId: this.ticket.ticketId,
        quantity: ticketDefectQuantityMap[dd.defectDefinitionId] || 0
      }))
    },

    inactiveDialog () {
      this.isInactiveDialog = true
    },

    setDefectDefinitionWeightChange () {
      if (this.isSegmented) {
        this.segmentedDefectsDialog = true
        return
      }
      let matchingDefect
      this.allTicketDefects.forEach((ticketDefect) => {
        matchingDefect = this.allLocationDefects.find(
          (locationDefect) =>
            ticketDefect.defectDefinitionId ===
            locationDefect.defectDefinitionId
        )
        if (ticketDefect.defectLBSAtRecording !== matchingDefect.defectLBS) {
          this.defectDefinitionWeightChange = true
        }
      })
      if (this.defectDefinitionWeightChange) {
        this.weightChangeDialog = true
      }
    }
  }
}
</script>
