<template>
  <v-col cols="12" lg="12">
    <DataTable
    :items="deliveredCosts"
    :headers="headers"
    :showSearch="false"
    :loading="loading"
    :showDense="false"
    :sumHeaders="headers.filter(h => h.total !== undefined).map(c => c.value)"
    :customCells="customCells"
    showTotals
    resize
    defaultHeight="calc(100vh - 471px)"
    >
      <template #custom-controls>
        <v-col cols="auto">
          <ColumnAdjuster
          :propColumnSet.sync="columnSet"
          @new-columns-selected="newColumnsSelected" />
        </v-col>
        <v-col cols="auto">
          <Icon
          icon="mdi-file-delimited-outline"
          dataTestId="delivered-download-button"
          :tooltipText="$t('downloadCSV')"
          @icon-clicked="downloadCSV"
          :small="false"/>
        </v-col>
        <v-col cols="auto">
          <Icon
          icon="mdi-refresh"
          dataTestId="refresh-icon-delivered-cost"
          :tooltipText="$t('refresh')"
          @icon-clicked="refresh"
          :small="false"/>
        </v-col>
      </template>
      <template #accountName="{item}">
        <AccountName
          :accountId="item.accountId"
          :certificationStatus="item.accountCertificationStatus"
        >
          {{item.accountName}}
        </AccountName>
      </template>
    </DataTable>
  </v-col>
</template>

<script>
import { mapActions } from 'vuex'
import { exportCSVFile } from '@/utils/CSVUtility.js'
import TractHeaders from '@/headers/Tract.js'
import moment from 'moment'
export default {
  name: 'DeliveredLogCosts',

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    AccountName: () => import('@/components/account/AccountName.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ColumnAdjuster: () => import('@/components/core/ColumnAdjuster.vue')
  },

  data: () => ({
    loading: false,
    deliveredCosts: [],
    columnSet: null
  }),

  computed: {
    customCells () {
      return [
        {
          slotName: 'accountName',
          value: 'accountName'
        }
      ]
    },

    headers () {
      return this.columnSet?.getColumns() ?? []
    }
  },

  created () {
    this.refresh()
    this.columnSet = TractHeaders.deliveredWoodHeaders()
  },

  methods: {
    ...mapActions('tract', ['getTractDeliveredCosts']),
    downloadCSV () {
      const headers = [
        'name',
        'supplier',
        'type',
        'rpi',
        'dbh',
        'age',
        'plf',
        'sed',
        'loads',
        'grossTons',
        'netTons',
        'costPerTon',
        'totalCost'
      ].map(h => this.$t(h))
      const t = moment()
      const timestamp = `${t.month() + 1}-${t.date()}-${t.year()}-${t.format('LT')}`
      exportCSVFile(headers, this.getCSVObjects(this.deliveredCosts), timestamp)
    },

    getCSVObjects (deliveredCosts) {
      return deliveredCosts.map(dc => {
        return {
          name: dc.tractName,
          supplier: dc.accountName,
          type: dc.tractType,
          rpi: dc.rpi,
          dbh: dc.dbh,
          age: dc.age,
          plf: dc.plf,
          sed: dc.averageSED,
          loads: dc.loads,
          grossTons: dc.grossTons,
          netTons: dc.netTons,
          costPerTon: dc.costPerTon,
          totalCost: dc.totalCost
        }
      })
    },

    async refresh () {
      this.loading = true
      try {
        const response = await this.getTractDeliveredCosts()
        this.deliveredCosts = response
      } finally {
        this.loading = false
      }
    },

    newColumnsSelected (columnSet) {
      this.columnSet = columnSet
    }
  }
}
</script>
