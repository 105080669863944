<template>
  <div fluid data-testid="setup-form">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row justify="end">
          <v-select
          v-model="currentTable"
          :items="filteredTables"
          :menu-props="{ bottom: true, offsetY: true, width: 200 }"
          color="primary"
          class="mr-8 mt-3 mb-n4"
          style="max-width:200px"
          return-object
          solo
          @change="changeTable"
          ref="setupMenu">
            <template #selection="{item}">
              {{ $t(item.name) }}
            </template>
            <template #item="{item}">
              {{ $t(item.name) }}
            </template>
          </v-select>
        </v-row>
      </v-col>
      <v-col cols="12">
        <PayPeriodSettings v-if="currentTable.value === 0"/>
        <TractTypeSettings v-if="currentTable.value === 1"/>
        <SettingSettings v-if="currentTable.value === 2"/>
        <CertificationSettings v-if="currentTable.value === 3"/>
        <TeamSettings v-if="currentTable.value === 10"/>
        <TemplateSettings v-if="currentTable.value === 4"/>
        <ProductSettings v-if="currentTable.value === 5"/>
        <ByProductSettings v-if="currentTable.value === 6"/>
        <TagSettings v-if="currentTable.value === 7"/>
        <Imports v-if="currentTable.value === 8"/>
        <Downloads v-if="currentTable.value === 9"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { userAssignedClaim } from '../../utils/ClaimUtility'
import { UserClaims } from '../../utils/Enumerations'

export default {
  name: 'Setup',

  components: {
    TemplateSettings: () => import('./activity-template/TemplateSettings.vue'),
    ProductSettings: () => import('./product/ProductSettings.vue'),
    ByProductSettings: () => import('./product/ByProductSettings.vue'),
    SettingSettings: () => import('./setting/SettingSettings.vue'),
    CertificationSettings: () => import('./certification/CertificationSettings.vue'),
    TractTypeSettings: () => import('./tract-type/TractTypeSettings.vue'),
    PayPeriodSettings: () => import('./pay-period/PayPeriods.vue'),
    Imports: () => import('./imports/Imports.vue'),
    Downloads: () => import('./downloads/Downloads.vue'),
    TagSettings: () => import('./tags/TagSettings.vue'),
    TeamSettings: () => import('./team/TeamSettings.vue')
  },

  computed: {
    ...mapGetters('global', ['settingName']),
    ...mapGetters('user', ['userClaims']),

    filteredTables () {
      const tableObjects = [
        {
          value: 0,
          name: 'calendar',
          cacheSettingName: 'pay-period'
        },
        {
          value: 1,
          name: 'tractTypes',
          cacheSettingName: 'tracttypes'
        },
        {
          value: 2,
          name: 'settings',
          cacheSettingName: 'settings'
        },
        {
          value: 3,
          name: 'certifications',
          cacheSettingName: 'certifications'
        },
        {
          value: 10,
          name: 'teams',
          cacheSettingName: 'teams'
        },
        {
          value: 4,
          name: 'activityTemplates',
          cacheSettingName: 'templates'
        },
        {
          value: 5,
          name: 'products',
          cacheSettingName: 'products'
        },
        {
          value: 6,
          name: 'byproducts',
          cacheSettingName: 'byproducts'
        },
        {
          value: 7,
          name: 'tags',
          cacheSettingName: 'tags'
        },
        {
          value: 8,
          name: 'imports',
          cacheSettingName: 'imports',
          allowedRoles: [UserClaims.ContractManager, UserClaims.GlobalAdmin, UserClaims.TemplateManager, UserClaims.InventoryManager, UserClaims.AccountManager]
        },
        {
          value: 9,
          name: 'downloads',
          cacheSettingName: 'downloads',
          allowedRoles: [UserClaims.ContractManager]
        }
      ]
      return tableObjects.filter(t => t.allowedRoles ? userAssignedClaim(...(t.allowedRoles)) : true)
    }
  },

  watch: {
    settingName (val) {
      if (this.settingName) {
        this.settingNameChanged(val)
        this.setSettingName(undefined)
      }
    },

    userClaims () {
      if (this.currentTable.allowedRoles && !userAssignedClaim(...(this.currentTable.allowedRoles))) {
        this.currentTable = this.filteredTables[0]
      }
    }
  },

  created () {
    if (this.settingName) {
      this.settingNameChanged(this.settingName)
    }

    this.setSettingName(undefined)

    this.currentTable = this.filteredTables[0]
  },

  data: () => ({
    currentTable: undefined
  }),

  methods: {
    ...mapMutations('global', ['setSettingName']),
    changeTable (e) {
      this.currentTable = e
      this.$refs.setupMenu.blur()
    },

    settingNameChanged (name) {
      const currentTable = this.filteredTables.find(t => t.cacheSettingName === name)
      if (currentTable) {
        this.currentTable = currentTable
      }
    }
  }
}
</script>
