<template>
  <GridChartCard
  :title="title"
  ref="chartCard"
  :hasData="!noDataText"
  @download-csv="exportCSV"
  @draw="createChart"
  />
</template>

<script>
import { Chart } from 'chart.js'
import { formatMoney, numberWithCommas } from '@/utils/NumericMutations.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LogAnalysisChart',

  components: {
    GridChartCard: () => import('./GridChartCard.vue')
  },

  props: {
    logAnalysis: {
      type: Array,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    selectedLocation: {
      type: Object
    },
    isInteractive: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    chartColors: [
      '#D15F27',
      '#151411',
      '#D68D5F',
      '#32312D',
      '#E9BE9D'
    ],
    max: 0,
    dataObj: undefined,
    chart: null
  }),

  computed: {
    ...mapGetters('dashboard', ['interactiveChartTooltipFooter']),

    title () {
      return `${this.$t('dailyInventoryCost')} (Avg: ${this.averageCost}/t)`
    },
    averageCost () {
      const { tons, cost } = this.logAnalysis.reduce((acc, la) => {
        acc.tons += la.totalTons
        acc.cost += la.totalCost
        return acc
      }, { cost: 0, tons: 0 })

      if (tons === 0 || cost === 0) {
        return formatMoney(0)
      }

      return formatMoney(parseFloat((cost / tons).toFixed(2)))
    },

    noDataText () {
      return this.selectedLocation.locationId === undefined || this.selectedLocation.locationId === -1 || this.logAnalysis.length === 0
    }
  },

  watch: {
    logAnalysis () {
      this.$nextTick(() => {
        this.$refs.chartCard.draw()
      })
    }
  },

  methods: {
    ...mapActions('dashboard', ['getWidgetInteractionStatus']),

    getDatasets () {
      let labels
      if (this.logAnalysis.length > 0) {
        labels = this.logAnalysis.map(data => moment(data.day).format('MM/DD/YYYY'))
      } else {
        return
      }

      this.dataObj = {
        date: labels,
        averageCost: { data: this.logAnalysis.map(data => data.averageCost), label: this.$t('averageCost:') },
        totalCost: { data: this.logAnalysis.map(data => data.totalCost), label: this.$t('totalCost:') },
        totalProductionCost: { data: this.logAnalysis.map(data => data.totalProductionCost), label: this.$t('totalProductionCost:') },
        totalStumpageCost: { data: this.logAnalysis.map(data => data.totalStumpageCost), label: this.$t('totalStumpageCost:') },
        totalTons: { data: this.logAnalysis.map(data => data.totalTons), label: this.$t('totalTons:') },
        loadCount: { data: this.logAnalysis.map(data => data.loadCount), label: this.$t('loadCount:') }
      }

      const averageCostDataSet = [{
        data: this.dataObj.averageCost.data,
        label: 'Average Cost Per Day',
        fill: false,
        borderColor: this.chartColors[0],
        pointBackgroundColor: this.chartColors[0]
      }]

      return {
        datasets: averageCostDataSet,
        labels: labels
      }
    },

    async onDataClicked (e) {
      if (this.isInteractive) {
        const element = this.chart.getElementsAtEventForMode(e, 'nearest', true)
        const data = this.chart.config._config.data
        const xAxisValue = data.labels[element[0]?.index]
        if (await this.getWidgetInteractionStatus({ widgetId: this.widgetId, x: xAxisValue })) this.$emit('data-clicked', { xAxisValue: xAxisValue })
      }
    },

    createChart (canvas) {
      this.dataObj = undefined
      const chartData = this.getDatasets()
      const localData = this.dataObj
      const chart = new Chart(canvas, {
        type: 'line',
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          resizeDelay: 500,
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const averageCost = tooltipItem.parsed.y
                  const tooltipText = [`${localData.averageCost.label} ${formatMoney(averageCost)}`]
                  tooltipText.push(`${localData.totalCost.label} ${formatMoney(localData.totalCost.data[tooltipItem.dataIndex])}`)
                  tooltipText.push(`${localData.totalProductionCost.label} ${formatMoney(localData.totalProductionCost.data[tooltipItem.dataIndex])}`)
                  tooltipText.push(`${localData.totalStumpageCost.label} ${formatMoney(localData.totalStumpageCost.data[tooltipItem.dataIndex])}`)
                  tooltipText.push(`${localData.totalTons.label} ${numberWithCommas(localData.totalTons.data[tooltipItem.dataIndex], 3)}`)
                  tooltipText.push(`${localData.loadCount.label} ${numberWithCommas(localData.loadCount.data[tooltipItem.dataIndex])}`)
                  return tooltipText
                }
              },
              titleFont: {
                family: 'Roboto, sans-serif',
                size: '14',
                weight: 'bolder'
              },
              bodyFont: {
                family: 'Roboto, sans-serif'
              },
              footerColor: '#FFFFFFAA',
              footerFont: {
                family: 'Roboto, sans-serif',
                weight: 'normal',
                style: 'italic'
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              grid: {
                display: true
              }
            },
            y: {
              display: true,
              grid: {
                display: true
              },
              ticks: {
                callback: function (val, index) {
                  return formatMoney(val)
                }
              }
            }
          },
          onClick: (e) => this.onDataClicked(e)
        }
      })
      if (this.isInteractive) chart.options.plugins.tooltip.callbacks.footer = () => this.interactiveChartTooltipFooter
      this.chart = chart
    },

    exportCSV () {
      const headers = 'Date, Average Cost, Total Cost, Total Prouction Cost, Total Stumpage Cost, Total Tons, Load Count \n'
      let dataString = ''
      for (let i = 0; i < this.dataObj?.averageCost.data.length; i++) {
        dataString += `${this.dataObj.date[i]}, $${this.dataObj.averageCost.data[i]}, $${this.dataObj.totalCost.data[i]}, $${this.dataObj.totalProductionCost.data[i]}, $${this.dataObj.totalStumpageCost.data[i]}, ${this.dataObj.totalTons.data[i]}, ${this.dataObj.loadCount.data[i]}\n`
      }
      const blob = new Blob([headers + dataString], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, `${this.selectedLocation.name.replace(/\s/g, '')}InventoryCostTrend.csv`)
    }
  }
}
</script>
