<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <DataTable
        :items.sync="tractPayablesForDataTable"
        :headers="headers"
        :actions="tableActions"
        :loading.sync="loading"
        :customCells="customColumns"
        @refresh="refreshPayables"
        @create="createPayable">
          <template #type="{item}">
            <v-row dense justify="center" style="flex-wrap: nowrap;">
              <Icon
              v-if="item.preIssued"
              icon="mdi-invoice-text-check"
              iconColor="black"
              :tooltipText="$t('preIssued')"
              />
              <Icon
              v-if="item.specialization === TractPayableSpecialization.PerformanceBond.value"
              icon="mdi-speedometer"
              iconColor="black"
              :tooltipText="$t('performanceBond')"
              />
              <Icon
              v-if="item.specialization === TractPayableSpecialization.Cost.value"
              icon="mdi-cash-multiple"
              iconColor="black"
              :tooltipText="$t('tractCost')"/>
              <Icon
              v-if="!!item.exportBatchId"
              icon="mdi-checkbox-marked-circle"
              iconColor="success"
              :tooltipText="$t('exported')"
              />
            </v-row>
          </template>
          <template #payDateStatus="{item}">
            <v-row justify="center" class="flex-nowrap">
              <Icon
                dataTestId="recovery-period-status"
                :icon="statusFromInt(item.payPeriodStatus).icon"
                :tooltipText="statusFromInt(item.payPeriodStatus).tooltipText"
                :iconColor="statusFromInt(item.payPeriodStatus).color"
                :tooltipColor="statusFromInt(item.payPeriodStatus).color"
              />
              <span>{{ getPayDateForTractPayabale(item) }}</span>
          </v-row>
          </template>
          <template #amount="{item}">
            <span>{{formatMoney(item.amount)}}</span>
          </template>
          <template #actions="{item}">
            <Icon
            icon="mdi-pencil"
            iconColor="success"
            dataTestId="payable-edit"
            :tooltipText="$t('edit')"
            @icon-clicked="editPayable(item)"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
            <Icon
            icon="mdi-delete-forever"
            iconColor="error"
            dataTestId="payable-delete"
            :tooltipText="$t('delete')"
            @icon-clicked="deletePayable(item)"
            :disabled="!userAssignedClaim(UserClaims.ContractManager)"/>
          </template>
          <template #custom-controls>
            <v-col cols="auto">
              <Icon
              v-for="filter in filters"
              :key="filter.name"
              :icon="filter.icon"
              :tooltipText="$t(filter.active ? filter.activeTooltip : filter.inactiveTooltip)"
              :small="false"
              :iconColor="filter.active ? 'black' : 'grey'"
              @icon-clicked="filter.active = !filter.active"
              />
            </v-col>
            <v-col cols="auto">
              <div style="height: 48px; border-left: solid rgba(0, 0, 0, .12) 1px; margin-left: 17px; margin-right: 18px;"/>
            </v-col>
          </template>
          <template #filters>
            <v-layout wrap>
              <v-chip-group>
                  <v-chip
                  v-for="filter in filters.filter(f => f?.active)"
                  :key="filter.name"
                  @click:close="filter.active = !filter.active"
                  text-color="white"
                  color="black"
                  class="mx-2"
                  close>
                    <v-avatar left>
                      <v-icon>{{filter.icon}}</v-icon>
                    </v-avatar>
                    <span>
                      {{$t(filter.name)}}
                    </span>
                  </v-chip>
              </v-chip-group>
            </v-layout>
          </template>
        </DataTable>
      </v-col>
      <v-dialog
        v-model="paymentForm"
        max-width="1200px"
        v-if="paymentForm"
        :fullscreen="this.$vuetify.breakpoint.mdAndDown"
      >
        <TractPaymentForm
        v-if="paymentForm"
        :propTractPayment="focusedPayment"
        :tractId="propTract.tractId"
        :includeCost="propTract.type?.depletes"
        @close="closeDialog"
        @submitted="closeDialog"
        :performanceBonds="bondsFromPayables"
        @balance-changed="$emit('balance-changed')"/>
      </v-dialog>
      <v-dialog v-model="confirmDelete" v-if="confirmDelete" width="400">
        <ConfirmDialog title="Delete Tract Payment?"
        body="Are you sure you want to delete this tract payment? This operation cannot be undone."
        @cancel="confirmDelete = false"
        @confirm="deleteConfirmed"/>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import TractHeaders from '@/headers/Tract.js'
import { mapActions, mapMutations } from 'vuex'
import { PayPeriodStatus, UserClaims, TractPayableSpecialization } from '@/utils/Enumerations.js'
import { formatMoney } from '@/utils/NumericMutations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import moment from 'moment'
import { utcToLocalDate } from '../../../utils/DateFormatter'
export default {
  name: 'TractPayables',

  props: {
    propTract: Object
  },

  components: {
    Icon: () => import('../../helper/Icon.vue'),
    DataTable: () => import('../../core/table/DataTable.vue'),
    TractPaymentForm: () => import('./TractPaymentForm.vue'),
    ConfirmDialog: () => import('../../helper/ConfirmDialog.vue')
  },

  data: () => ({
    TractPayableSpecialization,
    tractPayables: [],
    loading: false,
    paymentForm: false,
    focusedPayment: undefined,
    confirmDelete: false,
    UserClaims,
    bondFilterApplied: false,
    filters: [
      {
        name: 'performanceBond',
        icon: 'mdi-speedometer',
        activeTooltip: 'showAllPayables',
        inactiveTooltip: 'showOnlyPerformanceBonds',
        active: false
      }
    ],
    moment
  }),

  watch: {
    paymentForm (val) {
      if (!val) {
        this.focusedPayment = undefined
      }
    },
    confirmDelete (val) {
      if (!val) {
        this.focusedPayment = undefined
      }
    }
  },

  computed: {
    customColumns () {
      return [
        {
          slotName: 'type',
          value: 'type'
        },
        {
          slotName: 'issuedAt',
          value: 'issuedAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'createdAt',
          value: 'createdAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'payDateStatus',
          value: 'payDate'
        },
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    },

    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.ContractManager)
        }
      ]
    },

    headers () {
      const headers = TractHeaders.tractPayableHeaders(this.$i18n.locale)
      if (this.tractPayables.filter(tp => !!tp.exportBatchId || tp.specialization === TractPayableSpecialization.PerformanceBond.value || tp.preIssued).length === 0) {
        return headers.slice(1, headers.length)
      }
      return headers
    },

    bondsFromPayables () {
      return this.tractPayables.filter(tp => tp.specialization === TractPayableSpecialization.PerformanceBond.value).map(tp => ({ amount: tp.amount, tractPayableId: tp.tractPayableId }))
    },

    tractPayablesForDataTable () {
      return this.filters.find(f => f.name === 'performanceBond').active ? this.tractPayables.filter(tp => tp.specialization === TractPayableSpecialization.PerformanceBond.value) : this.tractPayables
    }
  },

  created () {
    this.refreshPayables()
  },

  methods: {
    ...mapActions('tract', ['fetchTractPayables', 'deleteTractPayable']),
    ...mapMutations('dialog', ['toggleFullscreen', 'closeBtnPressed']),
    statusFromInt (status) {
      switch (status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'success',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('exported'),
            icon: 'mdi-export'
          }
        default:
          return {
            color: 'black',
            tooltipText: this.$t('error'),
            icon: 'mdi-exclamation'
          }
      }
    },
    formatMoney,
    userAssignedClaim,

    async refreshPayables () {
      this.loading = true
      try {
        const response = await this.fetchTractPayables(this.propTract.tractId)
        this.tractPayables = response
      } finally {
        this.loading = false
      }
    },

    createPayable () {
      this.paymentForm = true
    },

    deletePayable (tractPayable) {
      if (tractPayable.preIssued || tractPayable.payPeriodStatus !== PayPeriodStatus.Exported.value) {
        this.focusedPayment = tractPayable
        this.confirmDelete = true
      } else {
        this.setSnackError(this.$t('cantDeleteTractPayable'))
      }
    },

    getPayDateForTractPayabale ({ payDate }) {
      const payDateFormatted = moment.utc(payDate).subtract(1, 'day').format()
      return utcToLocalDate(payDateFormatted)
    },

    async deleteConfirmed () {
      this.focusedPayment.tractId = this.propTract.tractId
      await this.deleteTractPayable(this.focusedPayment)
      this.refreshPayables()
      this.confirmDelete = false
      this.$emit('payable-deleted')
    },

    editPayable (payment) {
      if (payment.payPeriodStatus !== PayPeriodStatus.Exported.value) {
        this.focusedPayment = payment
        this.paymentForm = true
      } else {
        this.setSnackError(this.$t('cantEditTractPayable'))
      }
    },

    closeDialog () {
      this.focusedPayment = undefined
      this.paymentForm = false
      this.refreshPayables()
      this.$emit('trigger-refresh')
    }
  }
}
</script>
