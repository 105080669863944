<template>
  <div>
    <slot name="left"></slot>
    <Icon
    v-if="!hideRefresh"
    icon="mdi-refresh"
    iconColor="tertiary"
    dataTestId="base-refresh-button"
    :small="false"
    :large="true"
    :tooltipText="$t('refresh')"
    @icon-clicked="$emit('refresh')"/>
    <Icon
    v-if="showFullscreenControl"
    :icon="fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
    iconColor="tertiary"
    dataTestId="base-fullscreen-button"
    :small="false"
    :large="true"
    :tooltipText="$t('toggleFullscreen')"
    @icon-clicked="handleFullscreen"/>
    <Icon
    v-if="!hideCancel"
    icon="mdi-close"
    iconColor="tertiary"
    dataTestId="base-close-button"
    :small="false"
    :large="true"
    :tooltipText="$t('close')"
    @icon-clicked="close"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index.js'
export default {
  name: 'BaseDialogActions',

  props: {
    hideRefresh: {
      type: Boolean,
      default: false
    },

    hideFullscreen: {
      type: Boolean,
      default: false
    },

    hideCancel: {
      type: Boolean,
      default: false
    },

    dialogId: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    dialogState: undefined
  }),

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  computed: {
    ...mapGetters('dialog', ['topDialogState', 'dialogStateById']),
    fullscreen () {
      return this.dialogState?.fullscreen ?? false
    },

    showFullscreenControl () {
      return !this.hideFullscreen && this.dialogState.allowFullscreen && !this.$vuetify.breakpoint.mdAndDown
    }
  },

  created () {
    if (this.dialogState === undefined) {
      this.dialogState = this.getBindingDialogState()
    }
    store.subscribe(mutation => {
      if (mutation.type.match(/dialog\/push(OrSet)?Dialog/) !== null && mutation.payload.id === this.dialogState?.id) {
        this.dialogState = this.dialogStateById(mutation.payload.id)
      }
    })
  },

  methods: {
    ...mapActions('dialog', ['setDialogFullscreenById', 'closeDialogById']),

    close () {
      this.closeDialogById(this.dialogState.id)
      this.$emit('close')
    },

    handleFullscreen () {
      this.setDialogFullscreenById({
        id: this.dialogState.id,
        fullscreen: !this.dialogState.fullscreen
      })
      this.$emit('fullscreen-toggled')
    },

    getBindingDialogState () {
      return (this.dialogId !== ''
        ? this.dialogStateById(this.dialogId)
        : undefined) ?? this.topDialogState
    }
  }
}
</script>
