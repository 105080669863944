<template>
  <v-card>
    <v-card-title class="primary">
      <span class="headline white--text">{{title}}</span>
      <v-spacer/>
      <Icon
      icon="mdi-close"
      iconColor="white"
      dataTestId="address-close-button"
      :small="false"
      :large="true"
      :tooltipText="$t('close')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text>
      <FormWrapper :buttonText="submitBtnText" @submit="submitAddress">
        <v-container fluid grid-list-md>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="address.street1"
              ref="firstField"
              maxLength="64"
              counter="64"
              color="black"
              data-testid="address-street1"
              :label="$t('streetAddressOne')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
              v-model="address.street2"
              maxLength="64"
              counter="64"
              color="black"
              data-testid="address-street2"
              :label="$t('streetAddressTwo')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
              v-model="address.city"
              maxLength="32"
              counter="32"
              color="black"
              data-testid="address-city"
              :label="$t('city')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
              v-model="address.state"
              color="black"
              item-color="primary"
              data-testid="address-state"
              :items="states"
              :label="$t('state')"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
              v-model="address.zip"
              color="black"
              maxlength="5"
              counter
              data-testid="address-zip"
              :label="$t('zipCode')"
              />
            </v-col>
          </v-row>
        </v-container>
            <template v-slot:right-action>
                <v-checkbox
                v-if="!propAddress && type === 0"
                v-model="addBillingAddress"
                class="mr-2"
                color="primary"
                data-testid="address-billing-check"
                :label="$t('addBillingAddress')"/>
            </template>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>

<script>
import { states } from '@/utils/constants.js'
export default {
  name: 'AddressForm',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    address: {
      street1: '',
      street2: '',
      city: '',
      zip: '',
      state: ''
    },
    addBillingAddress: false,
    states
  }),

  props: {
    propAddress: {
      type: Object,
      required: false,
      default: undefined
    },
    type: {
      type: Number,
      required: true
    }
  },

  computed: {
    isEditing () {
      return this.propAddress !== undefined
    },

    newAccount () {
      return this.accountId === -1
    },

    submitBtnText () {
      return this.isEditing ? this.$t('saveChanges') : this.$t('addAddress')
    },

    title () {
      return this.isEditing ? this.$t('editingAddress') : this.$t('creatingAddress', { type: this.type === 0 ? 'Shipping' : 'Billing' })
    }
  },

  created () {
    if (this.propAddress) {
      this.address = {
        street1: this.propAddress.address.addressLine1,
        street2: this.propAddress.address.addressLine2,
        city: this.propAddress.address.city,
        zip: this.propAddress.address.postalCode,
        state: this.propAddress.address.state
      }
    }
  },

  mounted () {
    setTimeout(_ => {
      this.$refs.firstField.focus()
    }, 0)
  },

  methods: {
    submitAddress () {
      if (this.address.street1 === '' || this.address.city === '' || this.address.zip === '' || this.address.zip === '' || this.address.state === '') {
        this.setSnackError(this.$t('fillAddress'))
      } else {
        this.addressValidated()
      }
    },

    addressValidated () {
      const editedAddress = {
        address: {
          type: this.type,
          addressLine1: this.address.street1,
          addressLine2: this.address.street2,
          city: this.address.city,
          postalCode: this.address.zip,
          state: this.address.state
        }
      }

      if (this.isEditing) {
        editedAddress.accountAddressId = this.propAddress.accountAddressId || undefined
        this.$emit('address-updated', editedAddress)
      } else {
        const addresses = []
        addresses.push(editedAddress)
        if (this.addBillingAddress) {
          const billingAddress = JSON.parse(JSON.stringify(editedAddress))
          billingAddress.address.type = 1
          addresses.push(billingAddress)
        }
        this.$emit('address-created', addresses)
      }
    }
  }
}
</script>
