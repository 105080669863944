<template>
  <v-card outlined rounded>
    <v-card-title>
      <v-row dense align="center">
        <v-col>
          <v-row no-gutters align="center">
            <v-icon class="mr-2" :color="randomColor">mdi-shield</v-icon>
            <span data-testid="contact-card-user-name" class="subtitle-1">{{ insurancePolicy.name }}</span>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-row no-gutters>
            <Icon
            v-if="file"
            icon="mdi-file-download-outline"
            iconColor="black"
            :tooltipText="downloadFileTooltip"
            tabindex="0"
            @icon-clicked="downloadFile"/>
            <Icon
            icon="mdi-pencil"
            v-if="showCrud"
            iconColor="success"
            :tooltipText="$t('editInsurancePolicy')"
            tabindex="0"
            @icon-clicked="editInsurancePolicy"/>
            <Icon
            icon="mdi-delete-forever"
            iconColor="error"
            v-if="showCrud"
            :tooltipText="$t('delete')"
            tabindex="0"
            @icon-clicked="confirmDeleteDialog = true"/>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="mt-n5">
      <v-row class="ml-n2 mt-n4 mb-0" align="end">
        <v-col>
          {{ insuranceExpiryDate }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog width="400px" v-model="confirmDeleteDialog">
      <ConfirmDialog
      v-if="confirmDeleteDialog"
      :title="$t('deleteInsurance')"
      :body="$t('deleteInsuranceBody')"
      @confirm="deleteInsurance"
      @cancel="confirmDeleteDialog = false"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { hashString32 } from '../../../utils/base'
import moment from 'moment'
import { saveAs } from 'file-saver'

export default {
  name: 'InsuranceCard',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue')
  },

  props: {
    insurancePolicy: {
      type: Object,
      required: true
    },
    showCrud: {
      type: Boolean,
      default: true
    },
    file: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    confirmDeleteDialog: false
  }),

  computed: {
    insuranceExpiryDate () {
      if (!this.insurancePolicy) return ''
      return this.insurancePolicy.expirationDate ? `${this.expiredString} ${utcToLocalDate(this.insurancePolicy.expirationDate)}` : this.$t('expirationDateNotSpecified')
    },

    expiredString () {
      const today = moment.utc()
      const expirationDate = moment.utc(this.insurancePolicy.expirationDate)
      return expirationDate.isBefore(today)
        ? this.$t('expiredOn')
        : this.$t('expiresOnVerbatim')
    },

    hashable () {
      const v = this.insurancePolicy.accountInsurancePolicyId
      let o = v
      for (let i = 0; i < 10; i++) {
        o ^= v << i
      }
      return o.toString()
    },

    randomColor () {
      return `#${(this.hashString32(this.hashable) & 0xffffff).toString(16)}`
    },

    downloadFileTooltip () {
      return `${this.$t('downloadFile')}
      (${this.file.name})`
    }
  },

  methods: {
    hashString32,

    editInsurancePolicy () {
      this.$emit('editPolicy', this.insurancePolicy)
    },

    deleteInsurance () {
      this.confirmDeleteDialog = false
      this.$emit('deletePolicy', this.insurancePolicy)
    },

    async downloadFile () {
      const fetchedFile = await fetch(this.file.ref)
      const blob = await fetchedFile.blob()
      saveAs(blob, decodeURIComponent(fetchedFile.url.split('?')[0].split('/').pop()))
    }
  }
}
</script>
