<template>
<v-container fluid>
  <v-row dense>
    <v-col cols="12">
      <DataTable
      :items="accountPayables"
      :headers="headers"
      :actions="tableActions"
      :customCells="customColumns"
      @refresh="refreshPayables"
      @create="createPaymentDialog">
        <template #exportBatchId="{item}">
          <Icon
            v-if="!!item.exportBatchId"
            icon="mdi-checkbox-marked-circle"
            iconColor="success"
            :tooltipText="$t('exported')"
          />
        </template>
        <template #amount="{item}">
          {{ formatMoney(item.amount) }}
        </template>
        <template #payDateStatus="{item}">
          <v-row justify="center" class="flex-nowrap">
              <Icon
                dataTestId="recovery-period-status"
                :icon="statusFromInt(item.payPeriodStatus).icon"
                :tooltipText="statusFromInt(item.payPeriodStatus).tooltipText"
                :iconColor="statusFromInt(item.payPeriodStatus).color"
              />
              <span>{{ moment(item.payDate).format('MM/DD/YYYY') }}</span>
          </v-row>
        </template>
        <template #actions="{item}">
          <Icon
          icon="mdi-delete-forever"
          iconColor="error"
          dataTestId="payable-delete"
          :tooltipText="$t('delete')"
          @icon-clicked="deletePayable(item)"
          :disabled="!userAssignedClaim(UserClaims.AccountManager)"
          />
          <Icon
          icon="mdi-pencil"
          iconColor="success"
          dataTestId="payable-edit"
          :tooltipText="$t('edit')"
          @icon-clicked="editPaymentDialog(item)"
          :disabled="!userAssignedClaim(UserClaims.AccountManager)"
          />
        </template>
      </DataTable>
    </v-col>
    <Dialog :stateId="dialogId" maxWidth="80vw" @close="closeDialogs(true)">
      <AccountPayableForm
      v-if="payableForm"
      :accountId="accountId"
      :propAccountPayable="focusedPayment"
      @edit-payable="fireUpdateRequest"
      @create-payable="fireCreateRequest"
      />
    </Dialog>
    <v-dialog v-model="confirmDelete" v-if="confirmDelete" width="400">
      <ConfirmDialog
      :title="$t('deleteAccountPaymentTitle')"
      :body="$t('deleteAccountPaymentBody')"
      @cancel="closeDialogs"
      @confirm="fireDeleteRequest"/>
    </v-dialog>
  </v-row>
</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
import { PayPeriodStatus, UserClaims } from '@/utils/Enumerations.js'
import { accountPayableHeaders } from '@/headers/Account.js'
import { uniqueDialogId } from '../../../utils/componentHelpers'
import { formatMoney } from '@/utils/NumericMutations.js'
import moment from 'moment'
export default {
  name: 'AccountPayments',

  props: {
    accountId: {
      type: Number,
      required: true
    }
  },

  components: {
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    Icon: () => import('../../helper/Icon.vue'),
    AccountPayableForm: () => import('@/components/account/account-payables/AccountPayableForm.vue'),
    ConfirmDialog: () => import('../../helper/ConfirmDialog.vue')
  },

  data: () => ({
    accountPayables: [],
    payableForm: false,
    focusedPayment: undefined,
    dialogId: uniqueDialogId('account-payable-form'),
    confirmDelete: false,
    UserClaims,
    moment
  }),

  computed: {
    tableActions () {
      return [
        {
          actionName: 'refresh',
          icon: 'mdi-refresh',
          text: this.$t('refresh')
        },
        {
          actionName: 'create',
          icon: 'mdi-plus',
          text: this.$t('new'),
          disabled: !userAssignedClaim(UserClaims.AccountManager)
        }
      ]
    },

    headers () {
      const headers = accountPayableHeaders()
      if (this.accountPayables.filter(ap => !!ap.exportBatchId).length === 0) {
        return headers.slice(1, headers.length)
      }
      return headers
    },

    customColumns () {
      return [
        {
          slotName: 'exportBatchId',
          value: 'exportBatchId'
        },
        {
          slotName: 'issuedAt',
          value: 'issuedAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'createdAt',
          value: 'createdAt',
          dateFormat: 'MM/DD/YYYY'
        },
        {
          slotName: 'payDateStatus',
          value: 'payDate'
        },
        {
          slotName: 'amount',
          value: 'amount'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  async created () {
    await this.refreshPayables()
  },

  methods: {
    formatMoney,
    userAssignedClaim,
    statusFromInt (status) {
      switch (status) {
        case PayPeriodStatus.Closed.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('closed'),
            icon: 'mdi-cancel'
          }
        case PayPeriodStatus.Open.value:
          return {
            color: 'success',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('open'),
            icon: 'mdi-checkbox-marked-circle'
          }
        case PayPeriodStatus.Inactive.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('inactive'),
            icon: 'mdi-circle-outline'
          }
        case PayPeriodStatus.Exported.value:
          return {
            color: 'black',
            tooltipText: this.$t('payPeriodStatus') + ': ' + this.$t('exported'),
            icon: 'mdi-export'
          }
        default:
          return {
            color: 'black',
            tooltipText: this.$t('error'),
            icon: 'mdi-exclamation'
          }
      }
    },
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('account', ['fetchAccountPayables', 'createAccountPayable', 'updateAccountPayable', 'deleteAccountPayable']),
    async refreshPayables () {
      this.accountPayables = await this.fetchAccountPayables(this.accountId)
    },

    createPaymentDialog () {
      this.payableForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    editPaymentDialog (item) {
      if (!this.validateUnexported(item)) return
      this.payableForm = true
      this.focusedPayment = item
      this.openOrUpdateDialog({ id: this.dialogId, width: '50vw' })
    },

    closeDialogs (refresh = false) {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.payableForm = false
      this.confirmDelete = false
      this.focusedPayment = undefined
      if (refresh) {
        this.refreshPayables()
      }
    },

    deletePayable (item) {
      if (!this.validateUnexported(item)) return
      this.focusedPayment = item
      this.confirmDelete = true
    },

    async fireCreateRequest (reqObj) {
      await this.createAccountPayable(reqObj)
      this.closeDialogs()
    },

    async fireUpdateRequest (reqObj) {
      await this.updateAccountPayable(reqObj)
      this.closeDialogs()
    },

    async fireDeleteRequest () {
      await this.deleteAccountPayable({
        accountId: this.accountId,
        accountPayableId: this.focusedPayment.accountPayableId
      })
      this.closeDialogs(true)
    },

    validateUnexported (payable) {
      if (payable.exportBatchId !== null) {
        this.setSnackError(this.$t('cannotEditOrDeleteExportedAccountPayments'))
        return false
      }
      return true
    }
  }
}
</script>
