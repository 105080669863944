import ApiServer from '@/utils/api/ApiServer.js'
import { ContractType, AccountingCategory } from '@/utils/Enumerations.js'
import Ticket from '@/model/Ticket.js'

export default {
  async fetchExportBatches (context, payPeriodId) {
    const url = ApiServer.urlFor(`payPeriods/${payPeriodId}/exportBatches`, { version: 2 })
    const response = await ApiServer.get(url)
    context.commit('setExportBatches', response.data)
  },

  async fetchExportBatchById (_, { payPeriodId, exportBatchId }) {
    const url = ApiServer.urlFor(`payPeriods/${payPeriodId}/exportBatches/${exportBatchId}`, { version: 2 })
    const response = await ApiServer.get(url)
    return response.data
  },

  async createExportBatch (_, { payPeriodId, exportBatch }) {
    const url = ApiServer.urlFor(`payPeriods/${payPeriodId}/exportBatches`, { version: 2 })
    const response = await ApiServer.post(url, exportBatch)
    return response.data
  },

  async deleteExportBatch (_, { payPeriodId, exportBatchId }) {
    const url = ApiServer.urlFor(`payPeriods/${payPeriodId}/exportBatches/${exportBatchId}`, { version: 2 })
    const response = await ApiServer.delete(url)
    return response.data
  },

  async patchExportBatch (_, { exportBatchId, body }) {
    const url = ApiServer.urlFor(`exportBatches/${exportBatchId}`, { version: 2 })
    const response = await ApiServer.patch(url, body)
    return response.data
  },

  async fetchFinancialIntegrations (_, { exportBatchId, accountingCategory }) {
    let path = `exportBatches/${exportBatchId}`

    switch (accountingCategory.value) {
      case AccountingCategory.Payable.value:
        path += '/apIntegrations'
        break
      case AccountingCategory.Receivable.value:
        path += '/arIntegrations'
        break
      case AccountingCategory.Accrual.value:
        path += '/jeIntegrations'
        break
      default:
        throw new Error(`Invalid accounting category: ${accountingCategory}`)
    }

    const url = ApiServer.urlFor(path, { version: 2 })
    const response = await ApiServer.get(url)
    return response.data
  },

  async getItemsForExporting (_, { payPeriodId, fiscalYearId }) {
    const tractPaymentsUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/tractPayments`, { version: 1 })
    const accountPaymentsUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/accountPayments`, { version: 1 })
    const ticketsUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/ticketsToExport`, { version: 2 })
    const advancesUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/advances`, { version: 2 })
    const correctionsUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/corrections`, { version: 2, params: { byproduct: false } })
    const byproductCorrectionsUrl = ApiServer.urlFor(`fiscalYears/${fiscalYearId}/payPeriods/${payPeriodId}/corrections`, { version: 2, params: { byproduct: true } })

    const [tractPayments, tickets, advances, corrections, byproductCorrections, accountPayments] = (await Promise.all([
      ApiServer.get(tractPaymentsUrl),
      ApiServer.get(ticketsUrl),
      ApiServer.get(advancesUrl),
      ApiServer.get(correctionsUrl),
      ApiServer.get(byproductCorrectionsUrl),
      ApiServer.get(accountPaymentsUrl)
    ])).map(r => r.data)

    return {
      tractPayments,
      tickets: tickets.filter(t => t.contractType === ContractType.Production.value || t.contractType === ContractType.WoodsSale.value).map(t => (new Ticket(t))),
      advances: advances.map(a => ({ ...a, recovered: a.amount - a.balance })),
      byproductTickets: tickets.filter(t => t.contractType === ContractType.ByproductSale.value || t.contractType === ContractType.ByproductPurchase.value).map(t => (new Ticket(t))),
      transferTickets: tickets.filter(t => t.contractType === ContractType.Transfer.value).map(t => (new Ticket(t))),
      logYardSaleTickets: tickets.filter(t => t.contractType === ContractType.LogYardSale.value).map(t => (new Ticket(t))),
      corrections,
      byproductCorrections,
      accountPayments
    }
  }
}
