<template>
  <v-menu
    v-model="menuOpen"
    :close-on-content-click="false"
    left
    max-width="95%"
    :top="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom color="primary">
        <template #activator="{ on: tooltip }">
          <v-icon color="primary" class="mr-1" v-bind="attrs" v-on="{...tooltip, ...menu}" :tabindex="removeFromTabIndex ? '-1' : ''" :id="elementId">mdi-application-edit</v-icon>
        </template>
        <span class="subtitle-1 white--text" style="white-space: pre-line">
          {{$t('adjustColumns')}}
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title :class="cardTitleClass">
        {{$t('adjustColumns')}}
      </v-card-title>
      <v-card-text class="mt-1">
        <v-container :style="containerStyle">
          <v-tabs>
            <v-tab v-for="tab in validTabs" :key="tab">
              <span class="mr-3">{{$t(tab)}}</span>
              <v-tooltip bottom color="primary" max-width="300px">
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-checkbox :input-value="tabsChecked[tab]" @click="toggleCheckbox(tab)" :ripple="false"/>
                  </span>
                </template>
                <span class="subtitle-1 white--text">{{$t(tooltipForCheckbox(tab), { tabType: $t(tab) })}}</span>
              </v-tooltip>
            </v-tab>
            <v-tab-item v-for="tab in validTabs" :key="tab">
              <v-row dense class="mt-1">
                <v-col cols="12" md="6" v-for="column in getColumnsByTab(tab)" :key="`item-id-${column.id}`">
                  <v-sheet :style="columnSheetStyles" outlined rounded @click="toggleVisibility(column)">
                    {{ column.columnAdjusterText ?? column.text }}
                    <v-icon :color="visibilityColor(column.visible)" style="float: right">{{ visibilityIcon(column.visible) }}</v-icon>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-container>
      </v-card-text>
      <v-card-actions bottom class="pb-4">
        <v-row no-gutters>
          <v-col>
            <v-btn v-if="showReset" color="primary" @click="reset" :disabled="!changed" outlined>{{$t('reset')}}</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="apply" :disabled="!changed">{{$t('apply')}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { ColumnSet } from '@/model/DataTableColumn.js'
import { colorClassForContractMode } from '@/utils/componentHelpers'
import { ContractMode } from '@/utils/Enumerations'

export default {
  name: 'TabbedColumnAdjuster',

  props: {
    propColumnSet: ColumnSet,
    dynamicSize: {
      type: Boolean,
      required: false,
      default: false
    },
    propTabs: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    },
    removeFromTabIndex: {
      type: Boolean,
      default: false
    },
    elementId: {
      type: String,
      required: false,
      default: undefined
    }
  },

  data: () => ({
    ContractMode,
    columnSet: null,
    menuOpen: false,
    changed: false,
    tabsChecked: undefined
  }),

  watch: {
    menuOpen (val) {
      if (val === true) {
        this.changed = false
      } else {
        this.$emit('closed')
      }
      this.columnSet = this.propColumnSet.clone()
    },

    isOpen (val) {
      this.menuOpen = val
    },

    columnSet: {
      handler () {
        this.changed = true
        this.validTabs.forEach(tab => { this.tabsChecked[tab] = this.getColumnsByTab(tab).every(column => column.visible) })
      },
      deep: true
    }
  },

  created () {
    this.tabsChecked = Object.fromEntries(this.propTabs.map(t => [t, false]))
    this.columnSet = this.propColumnSet.clone()
  },

  computed: {
    validTabs () {
      return this.propTabs.filter(tab => this.getColumnsByTab(tab).length > 0)
    },

    containerStyle () {
      const defaultWidthObject = { width: 'min(600px, 100vw)' }
      if (!this.dynamicSize) return defaultWidthObject

      const w = window.innerWidth
      const h = window.innerHeight
      const maxHeight = h - 200
      const maxWidth = w - 100
      const minWidth = w > 500 ? 400 : w - 100

      const styleObject = w > 550 && h > 800
        ? defaultWidthObject
        : {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          maxHeight: `${maxHeight}px`,
          maxWidth: `${maxWidth}px`,
          minWidth: `${minWidth}px`
        }

      return styleObject
    },

    columnSheetStyles () {
      return {
        fontSize: '18px',
        padding: '8px',
        cursor: 'pointer',
        userSelect: 'none'
      }
    },

    cardTitleClass () {
      const setName = this.propColumnSet.setName.toLowerCase()

      return colorClassForContractMode(
        setName.includes('logyardsale')
          ? ContractMode.LogYardSale.value
          : setName.includes('transfer')
            ? ContractMode.Transfers.value
            : setName.includes('byproducts')
              ? ContractMode.Byproducts.value
              : ContractMode.Logs.value
      )
    }
  },

  methods: {
    colorClassForContractMode,

    apply () {
      this.columnSet.saveColumnInfo()
      this.menuOpen = false
      this.$emit('new-columns-selected', this.columnSet)
    },

    reset () {
      this.menuOpen = false
      this.$emit('reset')
    },

    toggleVisibility (column) {
      column.visible = !column.visible
    },

    visibilityIcon (visible) {
      return visible ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
    },

    visibilityColor (visible) {
      return visible ? 'success' : 'black'
    },

    getColumnsByTab (tab) {
      return this.columnSet.columns.filter(column => column.group === tab)
    },

    selectAllByTab (tab) {
      this.getColumnsByTab(tab).forEach(column => { column.visible = true })
    },

    deSelectAllByTab (tab) {
      this.getColumnsByTab(tab).forEach(column => { column.visible = false })
    },

    toggleCheckbox (tab) {
      this.tabsChecked[tab] ? this.deSelectAllByTab(tab) : this.selectAllByTab(tab)
    },

    tooltipForCheckbox (tab) {
      return this.tabsChecked[tab] ? 'tabSelectNoneCheckboxTooltip' : 'tabSelectAllCheckboxTooltip'
    }
  }
}
</script>
