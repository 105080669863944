<template>
  <v-footer id="core-footer" absolute height="54" color="transparent">
    <v-row dense :style="{
      fontSize: '14px',
      color: 'rgba(0,0,0,0.6)'
    }">
      <v-col cols="auto">
        <span>
          &copy;
          {{ (new Date()).getFullYear() }}
          Legna Software, LLC
        </span>
      </v-col>
      <v-col cols="auto">
        <v-divider vertical/>
      </v-col>
      <v-col cols="auto">
        <span v-for="link in links" :key="link.name">
          <a id="footer-link" :href="link.Link" target="blank" >
            <span id="footer-link-text" :style="{ color: 'rgba(0,0,0,0.6)' }">{{ link.name }}</span>
          </a>
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    links () {
      return ([
        { name: this.$t('privacyPolicy'), Link: 'https://www.legnasoftware.com/privacy' }
      ])
    }
  }
}
</script>

<style scoped>
#footer-link-text:hover {
  color: #d15f27 !important;
  transition: 0.15s;
}
#footer-link-text {
  transition: 0.15s;
}
</style>
