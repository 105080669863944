var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CsvImport',_vm._b({scopedSlots:_vm._u([{key:"preview-item.activityStatus",fn:function({ value }){return [(value === _vm.enums.ActivityStatus.Active.value)?_c('Icon',{attrs:{"icon":"mdi-checkbox-marked-circle","iconColor":"success","tooltipText":_vm.enums.ActivityStatus.Active.name}}):_c('Icon',{attrs:{"icon":"mdi-cancel","iconColor":"black","tooltipText":"enums.ActivityStatus.Inactive.name"}})]}},{key:"preview-item.rate",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.baseCost.rate)))])]}},{key:"preview-item.businessEntityName",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.businessEntities.find(entity => entity.businessEntityId === item.businessEntityId)?.name))])]}}])},'CsvImport',{
  parse: _vm.parse,
  preview: _vm.preview,
  upload: _vm.upload,
  exampleData: _vm.exampleData,
  entityName: _vm.entityName,
  rowName: _vm.rowName,
  disabled: _vm.disabled
},false))
}
var staticRenderFns = []

export { render, staticRenderFns }