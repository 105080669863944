<template>
  <v-container fluid data-testid="defects-table">
    <v-row>
      <v-col cols="12" lg="12">
        <span class="mr-2 subtitle-1">
          {{$t('defectDefinitions')}}
        </span>
        <Icon
          icon="mdi-plus"
          iconColor="primary"
          :tooltipText="$t('createDefect')"
          :small="false"
          dataTestId="defect-create-button"
          @icon-clicked="openForm()"
          :disabled="!userAssignedClaim(UserClaims.InventoryManager)"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <DataTable
          class="mt-n8"
          :items="defects"
          :headers="headers"
          :showSearch="false"
          :showDense="false"
          :no-data-text="$t('noDefectsForLocation')"
          :customCells="customTableCells"
        >
          <template #total-tons="{item}">
            {{ numberWithCommas((item.defectLBS / 2000), 3) }}
          </template>
          <template #category="{item}">
            {{item.category ? $t('nature') : $t('mismanufactured')}}
          </template>
          <template #default-percentage="{item}">
            {{item.defaultPercentage}}%
          </template>
          <template #status="{item}">
            <Icon
              margin="ml-3"
              dataTestId="defect-active-icon"
              :small="false"
              :icon="item.isActive ? 'mdi-checkbox-marked-circle' : 'mdi-cancel'"
              :tooltipColor="item.isActive ? 'success' : 'black'"
              :iconColor="item.isActive ? 'success' : 'black'"
              :tooltipText="item.isActive ? $t('active') : $t('notActive')"/>
          </template>
          <template #actions="{item}">
            <Icon
              icon="mdi-history"
              iconColor="primary"
              dataTestId="template-modification-history-button"
              :tooltipText="$t('history')"
              @icon-clicked="viewDefectDefinitionModifications(item)"/>
            <Icon
            icon="mdi-pencil"
            iconColor="success"
            :tooltipText="$t('edit')"
            dataTestId="defect-edit-button"
            @icon-clicked="openForm(item)"
            :disabled="!userAssignedClaim(UserClaims.InventoryManager)"/>
            <Icon
              icon="mdi-delete-forever"
              iconColor="error"
              dataTestId="defect-delete-button"
              :tooltipText="$t('delete')"
              @icon-clicked="deleteLocationDefectOnClick(item)"
              :disabled="!userAssignedClaim(UserClaims.InventoryManager)"/>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <Dialog :stateId="dialogId" maxWidth="900" @close="close">
      <DefectDefinitionForm
      v-if="defectForm"
        @defect-mutated="defectMutated"
        :propDefect="focusedDefect"
        :locationId="locationId"
      />
      <ModificationHistory v-if="defectHistoryDialog" entityType="defectDefinition" :entityName="focusedDefect.name" :entityId="focusedDefect.defectDefinitionId"/>
    </Dialog>
  </v-container>
</template>

<script>
import { formatMoney, tonStringFromPounds } from '@/utils/NumericMutations.js'
import { defectHeaders } from '@/headers/Defect.js'
import { numberWithCommas } from '@/utils/NumericMutations'
import { mapActions } from 'vuex'
import { uniqueDialogId } from '../../utils/componentHelpers'
import { UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'
export default {
  name: 'MultiDefects',

  props: {
    locationId: {
      type: Number,
      required: true
    },
    defects: {
      type: Array,
      required: true
    }
  },

  components: {
    DefectDefinitionForm: () => import('./DefectDefinitionForm.vue'),
    DataTable: () => import('@/components/core/table/DataTable.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    ModificationHistory: () => import('@/components/admin/ModificationHistory.vue')
  },

  data: () => ({
    dialogId: uniqueDialogId(),
    historyDialogId: 'defect-history',
    focusedDefect: undefined,
    loading: false,
    defectForm: false,
    defectHistoryDialog: false,
    UserClaims
  }),

  watch: {
    dialog (val) {
      if (!val) {
        this.close()
      }
    }
  },

  computed: {
    headers () {
      return defectHeaders(this.$i18n.locale)
    },

    customTableCells () {
      return [
        {
          slotName: 'total-tons',
          value: 'defectLBS'
        },
        {
          slotName: 'default-percentage',
          value: 'defaultPercentage'
        },
        {
          slotName: 'status',
          value: 'isActive'
        },
        {
          slotName: 'category',
          value: 'category'
        },
        {
          slotName: 'actions',
          value: 'actions'
        }
      ]
    }
  },

  methods: {
    ...mapActions('defects', ['deleteLocationDefect']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    formatMoney,
    numberWithCommas,
    tonStringFromPounds,
    userAssignedClaim,

    close () {
      this.closeDialogsAtOrAbove(this.dialogId)
      this.focusedDefect = undefined
      this.defectHistoryDialog = false
      this.defectForm = false
    },
    async deleteLocationDefectOnClick (defect) {
      defect.locationId = this.locationId
      await this.deleteLocationDefect(defect)
      this.defectMutated()
    },
    defectMutated () {
      this.close()
      this.$emit('defect-mutated')
    },

    openForm (defect) {
      this.focusedDefect = defect
      this.defectForm = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '900' })
    },

    viewDefectDefinitionModifications (defect) {
      this.focusedDefect = defect
      this.defectHistoryDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80vw' })
    }
  }
}
</script>
