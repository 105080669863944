<template>
  <div>
    <v-autocomplete
      :data-testid="this.dataTestid"
      color="black"
      item-color="primary"
      ref="accountField"
      :disabled="disabled"
      v-model="chosenAccount"
      :label="title"
      :loading="loading"
      :items="decoratedAccounts"
      autocomplete="chrome-off"
      :clearable="clearable"
      @click:clear="cleared = true"
      item-text="decoratedName"
      return-object
      :filter="(item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1"
      :hint="hint"
      :persistent-hint="hint !== ''"
      :single-line="singleLine"
    >
      <template #selection="{item}">
        {{ item.name }}
        <slot name="selection" v-bind="{item}"/>
      </template>
      <template #item="{item}">
        {{ item.name }}
        <slot name="item" v-bind="{item}"/>
      </template>
      <template #prepend-inner>
        <v-icon>mdi-account</v-icon>
      </template>
      <template #append>
        <v-chip-group v-if="(userSetting || unsavedTags) && chipStyle === 'inline'" column style="max-width: 128px;" class="right-justify-chips">
          <v-chip v-for="tag in selectedTags" x-small :key="tag.tagId" close @click:close="tagClicked(tag)">
            <span style="overflow-x:hidden;">
              {{tag.value}}
            </span>
          </v-chip>
        </v-chip-group>
        <slot name="append"/>
      </template>
      <template #prepend>
        <slot name="prepend"/>
      </template>
      <template #append-outer>
        <v-icon v-if="!hideRefresh" @click="refreshAccounts(true)" color="primary" tabindex="-1">mdi-refresh</v-icon>
        <v-menu
        v-if="userSetting || unsavedTags"
        v-model="showMenu"
        offset-y
        style="max-width: 600px"
        data-testid="account-tag"
        :close-on-content-click="false"
        >
          <template #activator="{on}">
            <v-icon color="primary" v-on="on">mdi-filter</v-icon>
          </template>
          <v-list v-if="accountTags && accountTags.length > 0">
            <v-list-item @click="tagClicked(tag)" v-for="tag in accountTags" :key="tag.tagId">
              <v-list-item-title>
                {{tag.value}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <slot name="append-outer"/>
      </template>
    </v-autocomplete>
    <div v-if="chipStyle === 'below'" class="mt-2">
      <v-chip v-for="tag in selectedTags" :key="tag.tagId"
        close @click:close="tagClicked(tag)"
        color="primary"
      >
        {{tag.value}}
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SETTING_TYPES, SETTING_KEYS } from '@/utils/UserSettings.js'
import { CertificationStatus } from '@/utils/Enumerations.js'
export default {
  name: 'AccountAutocomplete',

  props: {
    accountId: { type: Number },
    userSetting: { type: String, default: undefined },
    clearable: { type: Boolean, default: false },
    title: { default: 'Account', type: String },
    hideRefresh: { type: Boolean, default: false },
    showCertified: { type: Boolean, default: false },
    dataTestid: String,
    propAccounts: { type: Array, default: () => [] },
    onlyInternal: { type: Boolean, default: false },
    onlyExternal: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    unsavedTags: { type: Boolean, default: false },
    chipStyle: { type: String, default: 'inline' },
    disabled: { type: Boolean, default: false },
    prohibitedSet: { type: Set, default: undefined },
    singleLine: { type: Boolean, default: false },
    isQuickAssign: { type: Boolean, default: false },
    propSelectedTags: { type: Array, required: false },
    fetchTagsAndAccounts: { type: Boolean, default: true },
    propLoading: { type: Boolean, required: false, default: false },
    noSelection: { type: Boolean, required: false, default: false }
  },

  data: () => ({
    initialAccount: null,
    chosenAccount: null,
    tags: [],
    loading: false,
    cleared: false,
    initialAccountSet: false,
    selectedTags: [],
    tagIdsFromStorage: [],
    showMenu: false,
    certifiedText: '(Certified)'
  }),

  watch: {
    chosenAccount (account) {
      this.$emit('account-chosen', account)
    },

    focus () {
      this.$refs.accountField.focus()
    },

    tagIdsFromStorage (t) {
      this.initializeSelectedTags(t)
    },

    accountTags () {
      this.initializeSelectedTags(this.tagIdsFromStorage)
    },

    onlyInternal () {
      this.refreshAccounts()
    },

    accounts (accounts) {
      if (this.chosenAccount && !accounts.find(a => a.accountId === this.chosenAccount.accountId)) this.chosenAccount = undefined
    },

    accountId (id) {
      this.chosenAccount = this.decoratedAccounts.find(a => a.accountId === id)
    },

    noSelection (value) {
      if (value) this.chosenAccount = undefined
    }
  },

  computed: {
    ...mapGetters('account', ['allAccounts']),
    ...mapGetters('tags', ['accountTags']),
    ...mapGetters('user-settings', ['mutatedUserSettings']),

    decoratedAccounts () {
      return this.accounts
        .map(account => ({
          ...account,
          decoratedName: (this.showCertified && CertificationStatus.isCertified(account?.certificationStatus))
            ? `${account.name} ${this.certifiedText}`
            : account?.name
        }))
    },

    accounts () {
      const allAccounts = this.initialAccount ? [...new Set([...this.allAccounts, this.initialAccount])] : this.allAccounts
      return this.filterAccounts(allAccounts)
    },

    isLoading () {
      return this.loading || this.propLoading
    }
  },

  created () {
    this.initialize(this.accountId)
  },

  methods: {
    ...mapActions('account', ['fetchAccounts', 'fetchAccountById']),
    ...mapActions('tags', ['fetchAccountTags']),
    ...mapMutations('user-settings', ['mutateUserSetting']),

    async initialize (accountId) {
      try {
        this.loading = true
        await this.getTags()
        await this.getAccounts(accountId)
        await this.setInitialAccount()
      } finally {
        this.loading = false
      }
    },

    async refreshAccounts () {
      try {
        this.loading = true
        await this.getAccounts(this.initialAccount?.accountId, true)
      } finally {
        this.loading = false
      }
    },

    tagClicked (tag) {
      const tagIndex = this.selectedTags.findIndex(t => t.tagId === tag.tagId)
      if (tagIndex === -1) {
        this.selectedTags.push(tag)
      } else {
        this.selectedTags.splice(tagIndex, 1)
      }

      if (this.userSetting) {
        this.setUserSettings()
      }
    },

    async getTags () {
      if (this.fetchTagsAndAccounts) await this.fetchAccountTags()
      if (this.userSetting) {
        this.checkUserSettings()
      } else if (this.propSelectedTags) {
        this.selectedTags = this.propSelectedTags
      }
    },

    async getAccounts (accountId, forceFetch = false) {
      if (this.fetchTagsAndAccounts || forceFetch) await this.fetchAccounts()

      if (accountId && accountId !== -1) {
        this.initialAccount = this.allAccounts.find(a => a.accountId === accountId)
        if (!this.initialAccount) {
          const account = await this.fetchAccountById(accountId)
          this.initialAccount = account
        }
      }
    },

    initializeSelectedTags (tagIds) {
      if (this.accountTags && this.accountTags.length > 0) this.selectedTags = tagIds.map(id => this.accountTags.find(at => at.tagId === id))
    },

    filterAccounts (accounts) {
      if (this.propAccounts.length > 0) {
        return this.propAccounts
      }

      const accountClause = (a) => {
        const violatesInternalRequirement = a.isExternal && this.onlyInternal
        const violatesExternalRequirement = !a.isExternal && this.onlyExternal

        const meetsTagRequirement = this.selectedTags.length > 0 ? a.accountTags.some(at => this.selectedTags.some(st => st.value === at)) : true

        const doesNotHaveProhibitedId = !this.prohibitedSet || !this.prohibitedSet.has(a.accountId)

        const isInitialAccount = this.initialAccount && this.initialAccount.accountId === a.accountId

        return (!violatesInternalRequirement && !violatesExternalRequirement && meetsTagRequirement && doesNotHaveProhibitedId) || isInitialAccount
      }
      return accounts.filter(accountClause)
    },

    checkUserSettings () {
      let tagIds = []

      switch (this.userSetting) {
        case 'contractAccount':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.CONTRACT_FORM][SETTING_KEYS.ACCOUNT_AUTOCOMPLETE]
          break
        case 'contractDestinationAccount':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.CONTRACT_FORM][SETTING_KEYS.DESTINATION_AUTOCOMPLETE]
          break
        case 'contractFromAccount':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.CONTRACT_FORM][SETTING_KEYS.FROM_ACCOUNT_AUTOCOMPLETE]
          break
        case 'activityAccount':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.ACTIVITY_FORM][SETTING_KEYS.ACCOUNT_AUTOCOMPLETE]
          break
        case 'defaultLoggerAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.DEFAULT_ACCOUNT_AUTOCOMPLETE]
          break
        case 'landOwnerAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.LAND_OWNER_ACCOUNT_AUTOCOMPLETE]
          break
        case 'driverAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.DRIVER_ACCOUNT_AUTOCOMPLETE]
          break
        case 'haulerAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.HAULER_ACCOUNT_AUTOCOMPLETE]
          break
        case 'supplierAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.SUPPLIER_ACCOUNT_AUTOCOMPLETE]
          break
        case 'tractContractAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.TRACT_CONTRACT_ACCOUNT_AUTOCOMPLETE]
          break
        case 'consultingForesterAccountAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.CONSULTING_FORESTER_ACCOUNT_AUTOCOMPLETE]
          break
        case 'tractDestinationAutocomplete':
          tagIds = this.mutatedUserSettings[SETTING_TYPES.TRACT_FORM][SETTING_KEYS.TRACT_DESTINATION_AUTOCOMPLETE]
          break
      }

      this.tagIdsFromStorage = tagIds
    },

    setUserSettings () {
      let newSetting
      const tagIds = this.selectedTags.map(tag => tag.tagId)

      const createMutatedSetting = (type, key, value) => ({ type, key, value })

      switch (this.userSetting) {
        case 'contractAccount':
          newSetting = createMutatedSetting(SETTING_TYPES.CONTRACT_FORM, SETTING_KEYS.ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'contractDestinationAccount':
          newSetting = createMutatedSetting(SETTING_TYPES.CONTRACT_FORM, SETTING_KEYS.DESTINATION_AUTOCOMPLETE, tagIds)
          break
        case 'contractFromAccount':
          newSetting = createMutatedSetting(SETTING_TYPES.CONTRACT_FORM, SETTING_KEYS.FROM_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'activityAccount':
          newSetting = createMutatedSetting(SETTING_TYPES.ACTIVITY_FORM, SETTING_KEYS.ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'defaultLoggerAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.DEFAULT_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'landOwnerAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.LAND_OWNER_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'driverAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.DRIVER_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'haulerAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.HAULER_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'supplierAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.SUPPLIER_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'tractContractAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.TRACT_CONTRACT_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'consultingForesterAccountAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.CONSULTING_FORESTER_ACCOUNT_AUTOCOMPLETE, tagIds)
          break
        case 'tractDestinationAutocomplete':
          newSetting = createMutatedSetting(SETTING_TYPES.TRACT_FORM, SETTING_KEYS.TRACT_DESTINATION_AUTOCOMPLETE, tagIds)
          break
      }

      if (newSetting !== undefined) {
        this.mutateUserSetting(newSetting)
      }
    },

    async setInitialAccount (force = false) {
      if (!force && (this.cleared || this.accountId === undefined || this.accountId === null)) {
        return
      }
      if (this.initialAccount !== null) {
        let accountIndex = this.accounts.findIndex(a => a?.accountId === this.initialAccount?.accountId)
        if (accountIndex === -1) {
          if (!this.isQuickAssign) {
            this.accounts.unshift(this.initialAccount)
            accountIndex = 0
          }
        }
        this.chosenAccount = this.decoratedAccounts[accountIndex]
      }
    }
  }
}
</script>
