<template>
  <v-card>
    <v-card-title class="primary white--text headline">
      <span>{{ title }}</span>
      <v-spacer/>
      <Icon
        @icon-clicked="$emit('close-dialog')"
        :tooltipText="$t('close')"
        dataTestId="close-dialog-button"
        iconColor="white"
        icon="mdi-close"
        :small="false"
        large
      />
    </v-card-title>
    <v-card-text>
      <v-container
        class="px-0"
        fluid>
        <v-radio-group v-model="chosenOption">
          <v-radio
            v-for="(option, i) in options"
            :key="option"
            color="primary"
            :label="$t(option)"
            :value="i">
          </v-radio>
        </v-radio-group>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn class="primary" @click="chooseOption">
        {{ submitButtonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'RadioOptionDialog',

  components: {
    Icon: () => import('@/components/helper/Icon.vue')
  },

  props: {
    options: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    submitButtonText: {
      type: String,
      required: true
    }
  },

  data: () => ({
    chosenOption: 0
  }),

  methods: {
    chooseOption () {
      this.$emit('optionChosen', this.options[this.chosenOption])
    }
  }
}
</script>
