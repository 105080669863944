<template>
  <v-card>
    <v-card-title class="primary white--text headline">
      <span>
        {{ $t('insuranceExpDate') }}
      </span>
      <v-spacer/>
      <Icon
      iconColor="white"
      icon="mdi-close"
      large
      :small="false"
      :tooltipText="$t('cancel')"
      @icon-clicked="$emit('close')"
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper @submit="$emit('close')">
        <v-container fluid>
          <v-row v-if="accountId" class="black--text" style="font-size: 14px">
            <v-col cols="12" style="font-size: 18px;">
              {{ $t('accountName') }}: {{ account?.name}}
            </v-col>
            <v-col v-for="(insurancePolicy, index) in orderedInsurancePolicies" :key="`policy-${index}`" cols="12">
              <InsuranceCard
              v-if="files"
              :insurancePolicy="insurancePolicy"
              @deletePolicy="deletePolicy"
              @editPolicy="editPolicy"
              :file="getFileByPolicy(insurancePolicy)"/>
            </v-col>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
    <v-dialog v-model="insuranceFormDialog" max-width="60%">
      <InsuranceForm
      v-if="insuranceFormDialog"
      :propInsurance="focusedPolicy"
      @close="closeForm()"
      @policy-edited="policyEdited"
      :file="getFileByPolicy(focusedPolicy)"
      @file-edited="getFiles"/>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { accountFilesClient } from '@/utils/AccountFiles'
import { AccountFileCategory } from '@/utils/Enumerations'
export default {
  name: 'UpdateAccountInsurancePolicies',

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue'),
    InsuranceCard: () => import('@/components/account/Insurance/InsuranceCard.vue'),
    InsuranceForm: () => import('@/components/account/Insurance/InsuranceForm.vue')
  },

  props: {
    accountId: {
      type: Number,
      default: undefined
    }
  },

  data: () => ({
    account: undefined,
    insuranceFormDialog: false,
    focusedPolicy: undefined,
    files: undefined
  }),

  computed: {
    orderedInsurancePolicies () {
      return [...this.account?.insurancePolicies ?? []].sort((a, b) => {
        const aDate = moment.utc(a.expirationDate)
        const bDate = moment.utc(b.expirationDate)
        return (moment.duration(aDate.diff(bDate)).asDays())
      })
    },

    insuranceFileCategory () {
      return AccountFileCategory.Insurance
    }
  },

  async created () {
    const account = await this.fetchAccountById(this.accountId)
    this.account = account
    this.getFiles()
  },

  methods: {
    ...mapActions('account-insurance-policies', ['fetchAccountInsurancePolicies', 'deleteAccountInsurancePolicy', 'createInsurancePolicy', 'updateInsurancePolicy']),
    ...mapActions('account', ['fetchAccountById']),

    editPolicy (policy) {
      this.insuranceFormDialog = true
      this.focusedPolicy = policy
    },

    async deletePolicy (policy) {
      if (policy.accountInsurancePolicyId && this.accountId) {
        await this.deleteAccountInsurancePolicy({
          accountId: this.accountId,
          accountInsurancePolicyId: policy.accountInsurancePolicyId
        }).then(async response => {
          if (response) {
            const fileRef = this.getFileByPolicy(policy)?.ref
            if (fileRef) {
              await accountFilesClient.deleteFile(fileRef)
            }
          }
        })
        const account = await this.fetchAccountById(this.accountId)
        this.account = account
      } else {
        // Should not be reachable
        this.setSnackError(this.$t('couldNotDeleteInsurancePolicy'))
      }
    },

    async policyEdited (policy) {
      if (policy.accountInsurancePolicyId && this.accountId) {
        await this.updateInsurancePolicy({
          identifiers: { accountId: this.accountId, accountInsurancePolicyId: policy.accountInsurancePolicyId },
          accountInsurancePolicy: policy
        })
        const account = await this.fetchAccountById(this.accountId)
        this.account = account
        this.closeForm()
      } else {
        // Should not be reachable
        this.setSnackError(this.$t('couldNotEditInsurancePolicy'))
      }
    },

    closeForm () {
      this.insuranceFormDialog = false
      this.focusedPolicy = undefined
    },

    async getFiles () {
      if (this.account.accountId) {
        this.files = await accountFilesClient.getFilesForAccount(this.account.accountId, this.insuranceFileCategory.value)
      }
    },

    getFileByPolicy (policy) {
      return this.files?.find(f => Number(f?.policyId) === policy?.accountInsurancePolicyId)
    }
  }
}
</script>
