<template>
  <v-card flat>
    <v-card-title class="headline">
      <v-spacer/>
      <v-btn icon color="black" :disabled="prevDisabled" @click="$emit('prev-clicked', -1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-col>
          <v-row>
            <p class="headline mb-n1" style="text-align: center; width: 100%;">Fiscal Year</p>
            <p class="headline" style="text-align: center; width: 100%;">
            {{title}}
            </p>
          </v-row>
        </v-col>
        <br/>
      <v-btn icon color="black" :disabled="nextDisabled" @click="$emit('next-clicked', 1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
      <v-spacer/>
    </v-card-title>
    <v-card-text>
      <v-layout wrap>
        <v-col cols="12">
          <v-row>
            <div style="text-align: center; width: 100%;">
              <v-btn icon color="black" @click="prevPressed"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <span class="subtitle-1">{{selectedYear}}</span>
              <v-btn icon color="black" @click="nextPressed"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="12" lg="4" xl="3" v-for="(month, i) in months" :key=i>
          <v-card>
            <v-card-title>
              <p style="text-align: center; width: 100%; border-radius: 4px;" class="headline grey lighten-3 py-2">
                {{month.name}}
              </p>
            </v-card-title>
            <v-card-text class="mt-n2">
              <table style="width: 100%; table-layout: fixed; border-collapse: separate; border-spacing: 0 6px;">
                <th v-for="(dayOfTheWeek, j) in daysOfTheWeek" :key="'j'+j">
                  {{dayOfTheWeek}}
                </th>
                <tr v-for="k in 6" :key="'k' + k">
                  <td id="date-cell" :style="getCellStyle(month, k, l)" v-for="l in 7" :key="'l'+l">
                    {{getCellContent(month, k, l)}}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { getVuetifyPalette } from '@/components/dashboard/ChartColors'
import { PayPeriodStatus } from '@/utils/Enumerations'
import theme from '@/plugins/theme'

export default {
  name: 'PayPeriodChart',

  props: {
    payPeriods: Array,
    fiscalYear: Object
  },

  computed: {
    ...mapGetters('fiscal-year', ['allFiscalYears']),
    months () {
      return [
        {
          name: 'January',
          numberOfDays: 31
        },
        {
          name: 'February',
          numberOfDays: this.februaryDays()
        },
        {
          name: 'March',
          numberOfDays: 31
        },
        {
          name: 'April',
          numberOfDays: 30
        },
        {
          name: 'May',
          numberOfDays: 31
        },
        {
          name: 'June',
          numberOfDays: 30
        },
        {
          name: 'July',
          numberOfDays: 31
        },
        {
          name: 'August',
          numberOfDays: 31
        },
        {
          name: 'September',
          numberOfDays: 30
        },
        {
          name: 'October',
          numberOfDays: 31
        },
        {
          name: 'November',
          numberOfDays: 30
        },
        {
          name: 'December',
          numberOfDays: 31
        }
      ]
    },

    title () {
      const propFisclaYear = JSON.parse(JSON.stringify(this.fiscalYear))
      let endDate = new Date(propFisclaYear.endDate)
      endDate.setDate(endDate.getDate() - 1)
      endDate = utcToLocalDate(endDate)
      const startDate = utcToLocalDate(this.fiscalYear.startDate)
      // const endDate = utcToLocalDate(this.fiscalYear.endDate)
      return `${startDate} - ${endDate}`
    },

    nextDisabled () {
      const index = this.allFiscalYears.findIndex(f => f.fiscalYearId === this.fiscalYear.fiscalYearId)
      return index === this.allFiscalYears.length - 1
    },
    prevDisabled () {
      const index = this.allFiscalYears.findIndex(f => f.fiscalYearId === this.fiscalYear.fiscalYearId)
      return index === 0
    }
  },

  data: () => ({
    daysOfTheWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    grayColors: getVuetifyPalette('grey'),
    indigoColors: getVuetifyPalette('indigo'),
    currentDate: new Date(),
    selectedYear: new Date().getFullYear()
  }),

  watch: {
    fiscalYear: {
      deep: true,
      handler (val, oldVal) {
        if (val.status === 1) {
          this.selectedYear = new Date().getFullYear()
        } else {
          const date = new Date(val.startDate)
          date.setDate(date.getDate() + 50)
          this.selectedYear = date.getFullYear()
        }
      }
    }
  },

  methods: {
    utcToLocalDate,
    nextPressed () {
      this.selectedYear++
    },

    prevPressed () {
      this.selectedYear--
    },

    februaryDays () {
      const year = new Date(this.fiscalYear.startDate).getFullYear()
      const isLeapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
      return isLeapYear ? 29 : 28
    },
    getCellContent (monthName, row, column) {
      let monthIndex
      for (let i = 0; i < this.months.length; i++) {
        if (this.months[i] === monthName) {
          monthIndex = i
          break
        }
      }

      const year = this.selectedYear
      const firstDay = new Date(year, monthIndex, 1).getDay()
      const day = ((row - 1) * 7) + column - firstDay
      if (day <= this.months[monthIndex].numberOfDays && day > 0) {
        return day
      }
    },

    getCellStyle (monthName, row, column) {
      let monthIndex

      for (let i = 0; i < this.months.length; i++) {
        if (this.months[i] === monthName) {
          monthIndex = i
          break
        }
      }

      const year = this.selectedYear
      const offset = new Date(year, monthIndex, 1).getDay()
      const day = ((row - 1) * 7) + column - offset

      if (day > 0 && day <= this.months[monthIndex].numberOfDays) {
        const date = new Date(year, monthIndex, day)
        for (let i = 0; i < this.payPeriods.length; i++) {
          const { startDate, endDate, payDate } = this.payPeriods[i]
          let exported = false
          let color = theme.primary
          switch (this.payPeriods[i].status) {
            case PayPeriodStatus.Closed.value: color = this.grayColors[(i * 3) % (this.grayColors.length)]; break
            case PayPeriodStatus.Exported.value: exported = true; color = this.grayColors[(i * 3) % (this.grayColors.length)]; break
            case PayPeriodStatus.Inactive.value: color = this.indigoColors[(i * 3) % (this.indigoColors.length)]; break
            case PayPeriodStatus.Open.value:
            default: color = theme.primary
          }
          const position = this.getPositionOfDate(startDate, endDate, date, payDate)
          switch (position) {
            case 'pay-end':
              return {
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                backgroundColor: exported ? theme.success.concat('16') : theme.success,
                color: exported ? theme.success : 'white',
                border: exported ? `1px solid ${theme.success}` : 'none'
              }
            case 'start':
              return {
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                backgroundColor: exported ? color.concat('16') : color,
                color: exported ? color : 'white',
                borderTop: exported ? `1px solid ${color}` : 'none',
                borderBottom: exported ? `1px solid ${color}` : 'none',
                borderLeft: exported ? `1px solid ${color}` : 'none'
              }
            case 'end':
              return {
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                backgroundColor: exported ? color.concat('16') : color,
                color: exported ? color : 'white',
                borderTop: exported ? `1px solid ${color}` : 'none',
                borderBottom: exported ? `1px solid ${color}` : 'none',
                borderRight: exported ? `1px solid ${color}` : 'none'
              }
            case 'between':
              return {
                backgroundColor: exported ? color.concat('16') : color,
                color: exported ? color : 'white',
                borderTop: exported ? `1px solid ${color}` : 'none',
                borderBottom: exported ? `1px solid ${color}` : 'none'
              }
            case 'pay':
              return {
                backgroundColor: exported ? theme.success.concat('16') : theme.success,
                color: exported ? theme.success : 'white',
                border: exported ? `1px solid ${theme.success}` : 'none'
              }
          }
        }
      }
    },

    getPositionOfDate (start, end, current, pay) {
      const startDate = start.substring(0, 10)
      let endDate = new Date(end)
      endDate.setDate(endDate.getDate() - 1)
      endDate = endDate.toISOString().substring(0, 10)
      const currentDate = current.toISOString().substring(0, 10)
      let payDate = new Date(pay)
      payDate.setDate(payDate.getDate() - 1)
      payDate = payDate.toISOString().substring(0, 10)

      if (payDate === endDate && currentDate === payDate) {
        return 'pay-end'
      }

      if (currentDate === endDate) {
        return 'end'
      } else if (currentDate > startDate && currentDate < endDate) {
        return 'between'
      } else {
        if (currentDate === startDate) {
          return 'start'
        } else if (currentDate === payDate) {
          return 'pay'
        } else {
          return 'outside'
        }
      }
    }
  }
}
</script>

<style scoped>
#date-cell {
  text-align: center;
  vertical-align: middle; height: 40px;
  font-weight: bold;
}

#date-cell-content {
  height: 100%;
  width: 100%;
}
</style>
